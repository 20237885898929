import { Schema } from 'nutso';
import { BasicItemSpec } from '../../models/item_spec/BasicItemSpec';
import { itemBaseSpecSchema } from './itemBaseSpecSchema';
import { modifiersSpecSchema } from './modifierSpecsSchema';
import { priceSpecSchema } from './priceSpecSchema';

export const basicItemSpecSchema: Schema<BasicItemSpec> = {
  type: 'object',
  properties: {
    ...itemBaseSpecSchema.properties,
    price: priceSpecSchema,
    modifiers: modifiersSpecSchema
  }
};
