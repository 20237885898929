import { Printer, PrinterId, printerSchema, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Form } from '../forms/Form';
import { FormTextInput } from '../forms/FormTextInput';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { validationUtils } from '../utils/validationUtils';

@observer
export class PrinterEditWidget extends DocumentEditWidget<PrinterId, Printer> {
  //

  name(): string {
    return 'Printer';
  }

  createDocument(): Printer {
    throw new Error(`Printer cannot be created on the client, call the server api instead`);
  }

  getWidgetNameForCssClass(): string {
    return 'printer-edit-widget';
  }

  getRepo(): DocumentRepo<PrinterId, Printer> {
    return repo.printer;
  }

  getSchema(): Schema<Printer> {
    return printerSchema;
  }

  nextRoute(): string {
    return routes.backoffice.printers.link({}, {});
  }

  getKey(): PrinterId {
    const { documentId: key, create } = this.props;
    // if create, get key from created doc
    if (create) throw new Error(`Cannot create printer on client-side`);
    // get key from props
    return key!;
  }

  onSubmit = () => {
    this.submit();
  };

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const submitLabel = this.props.create ? 'Create Printer' : 'Save Printer';
    return (
      <Form type="default" submit={{ label: submitLabel, handler: this.onSubmit }} validationResult={this.validation}>
        <FormTextInput
          label="Printer ID"
          value={this.document.id}
          onChange={value => {}}
          validation={validationUtils.success}
          disabled={true}
        />
        <FormTextInput
          label="Password"
          value={this.document.password}
          onChange={value => {}}
          validation={validationUtils.success}
          disabled={true}
        />
        <FormTextInput
          label="Name"
          value={this.document.name}
          onChange={value => (this.document.name = value)}
          validation={this.validation.properties.name}
          helpText="Give the store name for the printer? If the printer is used for multiple stores, use Store1 + Store2 etc"
        />
      </Form>
    );
  }
}
