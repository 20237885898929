import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from './Button';
export type DropDownListItem = { label: string; onClick: () => void };

type Props = {
  label: string;
  icon: string;
  list: DropDownListItem[];
};

@observer
export class DropDown extends React.Component<Props> {
  //
  @observable open = false;

  renderDropDownButton = () => {
    const { label, icon } = this.props;
    return (
      <Button icon={icon} onClick={() => (this.open = !this.open)}>
        {label}
      </Button>
    );
  };

  renderOption = (item: DropDownListItem, index: number) => {
    const onClick = () => {
      item.onClick();
      this.open = false;
    };
    return (
      <div key={item.label} onClick={onClick} className={`option`}>
        {item.label}
      </div>
    );
  };

  renderDropDownList = () => {
    if (!this.open) return <div></div>;
    const { list } = this.props;
    return (
      <div className="overlay" onClick={() => (this.open = false)}>
        <div className="options-wrapper">
          <div className="options">{list.map((item, index) => this.renderOption(item, index))}</div>
        </div>
      </div>
    );
  };

  // render selection box
  render() {
    return (
      <div className="dropdown">
        {this.renderDropDownButton()}
        {this.renderDropDownList()}
      </div>
    );
  }
}
