/**
 * Meta info displayed on the form items
 */

import React = require('react');
import { ValidationResult } from 'nutso';

type Props = {
  validation: ValidationResult;
  helpText?: string;
};

export class FormMeta extends React.Component<Props> {
  //
  renderErrorMessage = () => {
    const { validation } = this.props;
    if (!validation.errorMessage) return;
    return <div className="error">{validation.errorMessage}</div>;
  };

  renderHelpText = () => {
    const { helpText } = this.props;
    if (!helpText) return;
    return <div className="help">{helpText}</div>;
  };

  render() {
    const error = this.renderErrorMessage();
    const help = this.renderHelpText();
    if (error || help)
      return (
        <div className="meta">
          {error}
          {help}
        </div>
      );
    return null;
  }
}
