import { Schema } from 'nutso';
import { Pizza } from '../../models/item/Pizza';
import { pizzaSpecSchema } from '../item_spec/pizzaSpecSchema';
import { itemSchema } from './itemSchema';
import { pizzaSelectionSchema } from './pizzaSelectionSchema';

export const pizzaSchema: Schema<Pizza> = {
  type: 'object',
  properties: {
    ...itemSchema.properties,
    spec: pizzaSpecSchema,
    selection: pizzaSelectionSchema
  }
};
