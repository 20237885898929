import { RestaurantId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { Router } from '../elements/Router';
import { StoreCreatePage } from '../store/StoreCreatePage';
import { StoreRouter } from '../store/StoreRouter';
import { RestaurantWebsiteInfoEditPage } from '../website_info/RestaurantWebsiteInfoEditPage';
import { RestaurantEditPage } from './RestaurantEditPage';
import { RestaurantHomePage } from './RestaurantHomePage';
import React = require('react');

type Props = {};
type RouteProps = RestaurantId & {};

@observer
export class RestaurantRouter extends Router<RouteProps> {
  render() {
    return (
      <Switch>
        <Route exact path={routes.backoffice.restaurant.template} component={RestaurantHomePage} />
        <Route exact path={routes.backoffice.editRestaurant.template} component={RestaurantEditPage} />
        <Route
          exact
          path={routes.backoffice.restaurantWebsiteInfo.template}
          component={RestaurantWebsiteInfoEditPage}
        />
        <Route exact path={routes.backoffice.createStore.template} component={StoreCreatePage} />
        <Route path={routes.backoffice.store.template} component={StoreRouter} />
      </Switch>
    );
  }
}
