import { Schema } from 'nutso';
import { BasicItem } from '../../models/item/BasicItem';
import { basicItemSpecSchema } from '../item_spec/basicItemSpecSchema';
import { itemSchema } from './itemSchema';
import { categorySchema } from '../categorySchema';
import { basicItemSelectionSchema } from './basicItemSelectionSchema';

export const basicItemSchema: Schema<BasicItem> = {
  type: 'object',
  properties: {
    ...itemSchema.properties,
    category: categorySchema,
    selection: basicItemSelectionSchema,
    spec: basicItemSpecSchema
  }
};
