import { countries, CountryCode, CurrencySymbol, currencies } from '../models/base/country';

const isCurrencySymbol = (prefix: CountryCode | CurrencySymbol): prefix is CurrencySymbol => {
  if (currencies.includes(prefix as CurrencySymbol)) return true;
  return false;
};

const getPrefix = (prefix?: CountryCode | CurrencySymbol) => {
  if (!prefix) return '$';
  if (isCurrencySymbol(prefix)) return prefix;
  return countries[prefix]?.currencySymbol ?? '$';
};

/**
 * Based on:
 * https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
 *
 * This is basically to not depend on any formatting library, as we have had bad experience with a lot of these
 */
export const formatAmount = (amount: number | null, prefix?: CountryCode | CurrencySymbol): string => {
  if (amount === null) return '';

  const float = parseFloat(`${amount}`);
  if (isNaN(float)) return '';

  const sign = float < 0 ? '-' : '';
  const abs = Math.abs(float);
  const formattedAmount = abs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `${sign}${getPrefix(prefix)}${formattedAmount}`;
};
