import { RestaurantId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { RestaurantWebsiteInfoEditWidget } from './RestaurantWebsiteInfoEditWidget';
import React = require('react');

type Props = {};

export class RestaurantWebsiteInfoEditPage extends Page<Props, RestaurantId> {
  //
  getTitle(): string {
    return 'Restaurant Website';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <RestaurantWebsiteInfoEditWidget documentId={this.props.match.params} />;
  }
}
