import { toastStore } from '../toast/toastStore';

/**
 * Utility that performs an async operation with a message to the user, and also sets the busy flag
 */
export const asyncOperation = async <T>(args: {
  fn: () => Promise<T>;
  successMessage?: string;
  loadingMessage?: string;
  setBusyFlag?: (busyFlag: boolean) => void;
  onSuccess?: (result: T) => void;
  onComplete?: () => void;
}): Promise<T> => {
  //
  const { fn: asyncFn, successMessage, loadingMessage, setBusyFlag, onSuccess, onComplete } = args;

  let hide;
  if (loadingMessage) hide = toastStore.loading(`${loadingMessage}`);

  try {
    setBusyFlag && setBusyFlag(true);
    const result = await asyncFn();
    successMessage && toastStore.success(`${successMessage}`);
    onSuccess && onSuccess(result);
    return result;
  } catch (e) {
    console.error(e);
    toastStore.error(e.message ?? `Error: ${loadingMessage?.toLowerCase()}`);
    throw e;
  } finally {
    setBusyFlag && setBusyFlag(false);
    hide && hide();
    onComplete && onComplete();
  }
};
