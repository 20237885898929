import { Restaurant, Store, Order } from '..';
import { OrderId, RestaurantId, StoreId } from '../models/base/Id';

export const restaurantId = (args: { restaurant: Restaurant }): RestaurantId => {
  return {
    restaurantId: args.restaurant.id
  };
};

export const storeId = (args: { restaurant: Restaurant; store: Store }): StoreId => {
  return {
    restaurantId: args.restaurant.id,
    storeId: args.store.id
  };
};

export const orderId = (args: { restaurant: Restaurant; store: Store; order: Order }): OrderId => {
  return {
    orderId: args.order.id,
    restaurantId: args.restaurant.id,
    storeId: args.store.id
  };
};
