import { computed } from 'mobx';
import { repo } from '../repo/repo';
import { userProvider } from './userProvider';

class UserRestaurantsProvider {
  //
  @computed
  get bindRestaurants() {
    const user = userProvider.user;
    if (!user) return;
    return repo.userRestaurants.bindCollection({ uid: user.uid });
  }
}

export const userRestaurantsProvider = new UserRestaurantsProvider();
