import { StoreId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { CategoryEditWidget } from './CategoryEditWidget';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

export class CategoryCreatePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Create Category';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <CategoryEditWidget documentId={{ ...this.props.match.params, categoryId: '' }} create={true} />;
  }
}
