import { Schema } from 'nutso';
import { DeliveryAddress } from '../models/base/DeliveryAddress';

export const deliveryAddressSchema: Schema<DeliveryAddress> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    createdTime: {
      type: 'string'
    },
    line1: {
      type: 'string'
    },
    suburb: {
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        postcode: {
          type: 'string'
        }
      }
    }
  }
};
