import { DateTime } from 'luxon';

const ISO_MONTH = 'yyyy-MM';

const date = (): string => {
  return DateTime.local().toISODate();
};

const time = (): string => {
  return DateTime.local().toISO();
};

const toDateStr = (jsDate: Date) => {
  return DateTime.fromJSDate(jsDate).toISODate();
};

const toTimeStr = (jsDate: Date) => {
  return DateTime.fromJSDate(jsDate).toISO();
};

const toJsDate = (date: string) => {
  return DateTime.fromISO(date).toJSDate();
};

const toDateTime = (date: string) => {
  return DateTime.fromISO(date);
};

const timeLabel = (args: { time: string }) => {
  return DateTime.fromISO(args.time).toLocaleString(DateTime.DATETIME_MED);
};

const now = () => {
  return DateTime.local();
};

export const dateUtils = {
  date,
  time,
  toDateStr,
  toTimeStr,
  toJsDate,
  toDateTime,
  timeLabel,
  now,
  ISO_MONTH
};
