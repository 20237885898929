// prettier-ignore
import { ActiveLoyaltyCard, BasicItemSpec, Category, CategoryId, CheckoutSettings, Comment, CommentId, CustomerCart, CustomerCartId, CustomerId, CustomerMessage, CustomerMessageId, DealSpec, DeliveryAddress, DeliveryAddressId, DeliverySettings, fspaths, ItemId, ItemSpec, LoyaltyCard, LoyaltyCardId, LoyaltyProgram, LoyaltyReward, LoyaltyRewardId, Menu, Order, OrderId, PizzaSettings, PizzaSpec, RedeemedLoyaltyCard, RedeemedLoyaltyCardId, Restaurant, RestaurantId, RestaurantUser, RestaurantUserId, RestaurantWebsiteInfo, Store, StoreId, StoreTimings, StoreWebsiteInfo, Uid, User, UserRestaurant, OrderProcessSettings, PrinterId, Printer, StorePrinter } from "@restoplus/core";
import { CollectionRepo } from './CollectionRepo';
import { DocumentRepo } from './DocumentRepo';

export const repo = {
  restaurant: new DocumentRepo<RestaurantId, Restaurant>(fspaths.restaurant),
  restaurants: new CollectionRepo<void, Restaurant>(fspaths.restaurants),
  store: new DocumentRepo<StoreId, Store>(fspaths.store),
  stores: new CollectionRepo<RestaurantId, Store>(fspaths.stores),
  printer: new DocumentRepo<PrinterId, Printer>(fspaths.printer),
  printers: new CollectionRepo<void, Printer>(fspaths.printers),
  restaurantUser: new DocumentRepo<RestaurantUserId, RestaurantUser>(fspaths.restaurantUser),
  userRestaurants: new CollectionRepo<Uid, UserRestaurant>(fspaths.userRestaurants),
  menu: new DocumentRepo<StoreId, Menu>(fspaths.menu),
  category: new DocumentRepo<CategoryId, Category>(fspaths.category),
  categories: new CollectionRepo<StoreId, Category>(fspaths.categories),
  item: new DocumentRepo<ItemId, ItemSpec>(fspaths.item),
  items: new CollectionRepo<StoreId, ItemSpec>(fspaths.items),
  basicItem: new DocumentRepo<ItemId, BasicItemSpec>(fspaths.item),
  pizzaSpec: new DocumentRepo<ItemId, PizzaSpec>(fspaths.item),
  dealSpec: new DocumentRepo<ItemId, DealSpec>(fspaths.item),
  customerCart: new DocumentRepo<CustomerCartId, CustomerCart>(fspaths.customerCart),
  orders: new CollectionRepo<StoreId, Order>(fspaths.orders),
  order: new DocumentRepo<OrderId, Order>(fspaths.order),
  orderProcessSettings: new DocumentRepo<StoreId, OrderProcessSettings>(fspaths.orderProcessSettings),
  storePrinter: new DocumentRepo<StoreId, StorePrinter>(fspaths.storePrinter),
  customer: new DocumentRepo<Uid, User>(fspaths.customer),
  customerMessage: new DocumentRepo<CustomerMessageId, CustomerMessage>(fspaths.customerMessage),
  loyaltyProgram: new DocumentRepo<RestaurantId, LoyaltyProgram>(fspaths.loyaltyProgram),
  loyaltyCard: new DocumentRepo<LoyaltyCardId, LoyaltyCard>(fspaths.loyaltyCard),
  activeLoyaltyCard: new DocumentRepo<CustomerId, ActiveLoyaltyCard>(fspaths.activeLoyaltyCard),
  loyaltyReward: new DocumentRepo<LoyaltyRewardId, LoyaltyReward>(fspaths.loyaltyReward),
  loyaltyRewards: new CollectionRepo<LoyaltyCardId, LoyaltyReward>(fspaths.loyaltyRewards),
  redeemedLoyaltyCard: new DocumentRepo<RedeemedLoyaltyCardId, RedeemedLoyaltyCard>(fspaths.redeemedLoyaltyCard),
  redeemedLoyaltyCards: new CollectionRepo<RestaurantId, RedeemedLoyaltyCard>(fspaths.redeemedLoyaltyCards),
  /**
   * Settings
   */
  pizzaSettings: new DocumentRepo<StoreId, PizzaSettings>(fspaths.pizzaSettings),
  deliverySettings: new DocumentRepo<StoreId, DeliverySettings>(fspaths.deliverySettings),
  checkoutSettings: new DocumentRepo<StoreId, CheckoutSettings>(fspaths.checkoutSettings),
  /**
   * Website
   */
  restaurantWebsiteInfo: new DocumentRepo<RestaurantId, RestaurantWebsiteInfo>(fspaths.restaurantWebsiteInfo),
  storeWebsiteInfo: new DocumentRepo<StoreId, StoreWebsiteInfo>(fspaths.storeWebsiteInfo),
  orderComments: new CollectionRepo<CustomerId, Comment>(fspaths.orderComments),
  orderComment: new DocumentRepo<CommentId, Comment>(fspaths.orderComment),
  deliveryAddresses: new CollectionRepo<CustomerId, DeliveryAddress>(fspaths.customerAddresses),
  deliveryAddress: new DocumentRepo<DeliveryAddressId, DeliveryAddress>(fspaths.customerAddress),
  storeTimings: new DocumentRepo<StoreId, StoreTimings>(fspaths.storeTimings)
};
