import { ButtonType, RestaurantId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Button } from '../elements/Button';
import { Page } from '../elements/Page';
import { userProvider } from '../provider/userProvider';
import { RestaurantStoreListWidget } from './RestaurantStoreListWidget';
import React = require('react');
import { gotoPath } from '../utils/browserHistory';

type Props = {};
type RouteProps = RestaurantId & {};

@observer
export class RestaurantHomePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Stores';
  }

  renderActions(): React.ReactNode {
    return (
      <Button
        icon="la-plus-circle"
        type={ButtonType.primary}
        onClick={() =>
          gotoPath(routes.backoffice.createStore.link({ restaurantId: this.props.match.params.restaurantId }, {}))
        }
      >
        Create Store
      </Button>
    );
  }

  renderBody(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return <span></span>;
    return (
      <div className={'restaurant-home-page'}>
        <RestaurantStoreListWidget restaurantId={this.props.match.params.restaurantId} />
      </div>
    );
  }
}
