export const etaList = [
  'As Requested',
  '5 minutes',
  '10 minutes',
  '15 minutes',
  '20 minutes',
  '30 minutes',
  '45 minutes',
  '1 hour',
  '1 hour 15 min',
  '1 hour 30 min',
  '2 hours'
];
