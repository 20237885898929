import {
  AspectRatio,
  BasicItem,
  BasicItemSpec,
  Category,
  CategoryId,
  CollectionType,
  formatAmount,
  idFactory,
  ItemSpec,
  routes
} from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { observer } from 'mobx-react';
import { Imgix } from '../../elements/Imgix';
import { Page } from '../../elements/Page';
import { customerCart } from '../../provider/customerCartProvider';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { repo } from '../../repo/repo';
import { basicItemUtils } from '../../utils/basicItemUtils';
import { gotoPath } from '../../utils/browserHistory';
import { itemSpecUtils } from '../../utils/itemSpecUtils';
import { listUtils } from '../../utils/listUtils';
import React = require('react');
import { userProvider } from '../../provider/userProvider';
import { currentPath } from '../../utils/currentPath';

type Props = {};
type RouteProps = CategoryId & {};

@observer
export class ItemsPage extends Page<Props, RouteProps> {
  //
  $category = repo.category.bind(this.props.match.params);
  $items = repo.items.bindCollection(this.props.match.params);

  getTitle(): string {
    const category = this.$category.current();
    if (!category) return '';
    return category.name;
  }

  renderActions(): React.ReactNode {
    return null;
  }

  renderBody(): React.ReactNode {
    const items = this.$items.current();
    const category = this.$category.current();
    if (!items || !category) return null;

    return <div className="website-items-page">{this.renderItems(category, items)}</div>;
  }

  renderItems(category: Category, allItems: ItemSpec[]): React.ReactNode {
    const categoryItems = itemSpecUtils.getitemsForCategory(this.props.match.params.categoryId, allItems);
    const sorted = listUtils.sortBySortOrder(categoryItems);
    if (!sorted.length) return <div>No items found</div>;
    return <div className="items">{sorted.map((item, i, list) => this.renderItem(item, i, category))}</div>;
  }

  renderItem(itemSpec: ItemSpec, i: number, category: Category): any {
    const collectionType = customerCart.collectionType;
    if (!collectionType || !itemSpec.availableFor[collectionType]) return null;

    return (
      <div className="item" key={itemSpec.id} onClick={() => this.onClick(category, itemSpec, collectionType)}>
        <div className="body">
          <Imgix image={itemSpec.image} aspectRatio={AspectRatio.horizontal} />
          <div className="name">{itemSpec.name}</div>
          <div className="description">{itemSpec.description}</div>
          <div className="price">
            {formatAmount(
              itemSpecUtils.getMinPrice(itemSpec, collectionType),
              restaurantProvider.$restaurant?.current()?.country
            )}
          </div>
        </div>
        {this.renderAction(itemSpec, category)}
      </div>
    );
  }

  renderAction(item: ItemSpec, category: Category): React.ReactNode {
    let label = 'Add to Cart';
    switch (item.type) {
      case ItemType.basic:
        if (item.modifiers && item.modifiers.length) label = 'Customize';
        break;
      case ItemType.pizza:
        label = 'Customize';
        break;
      case ItemType.deal:
        label = 'Customize';
        break;
    }

    return (
      <div className="action">
        {label === 'Add to Cart' ? <img src="/images/add-icon.svg" /> : <img src="/images/long-arrow.svg" />}
        <span>{label}</span>
      </div>
    );
  }

  addToCart = (category: Category, spec: BasicItemSpec) => {
    const selection = { type: ItemType.basic, modifiersValue: {} } as const;
    const basicItem: BasicItem = {
      id: idFactory.tiny(),
      type: ItemType.basic,
      summary: {
        name: spec.name,
        quantity: 1,
        price: basicItemUtils.getPrice({ spec, selection }),
        description: spec.description
      },
      spec: spec,
      selection,
      category
    };

    customerCart.addItem(basicItem);
  };

  onClick(category: Category, item: ItemSpec, collectionType: CollectionType) {
    // if user is not logged-in, then ask to login
    if (!userProvider.user) {
      const ok = confirm(`Please login to add item to cart. It's super quick and secure ...`);
      if (ok) {
        gotoPath(routes.website.onlineOrdering.login.link(this.props.match.params, { continueLink: currentPath() }));
        return;
      }
      return;
    }
    switch (item.type) {
      case ItemType.basic:
        if (item.modifiers && item.modifiers.length) {
          gotoPath(routes.website.onlineOrdering.item.link({ ...this.props.match.params, itemId: item.id }, {}));
          return;
        }
        this.addToCart(category, item);
        return;
      case ItemType.pizza:
        gotoPath(routes.website.onlineOrdering.pizza.link({ ...this.props.match.params, itemId: item.id }, {}));
        return;
      case ItemType.deal:
        gotoPath(routes.website.onlineOrdering.deal.link({ ...this.props.match.params, itemId: item.id }, {}));
        return;
    }
  }
}
