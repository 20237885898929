import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { userProvider } from '../../provider/userProvider';
import { gotoPath } from '../../utils/browserHistory';
import { Switch, Route } from 'react-router-dom';
import { locationProvider } from '../../provider/locationProvider';
import { routes } from '@restoplus/core';

const Title = (props: { label: string }) => {
  return <div className="title">{props.label}</div>;
};

// const isSelected = (args:{path:string})
type LinkProps = {
  icon: string;
  label: string;
  path: string;
  dataCy?: string;
  onClick?: () => void;
};

@observer
class Link extends React.Component<LinkProps> {
  onClick = () => {
    backofficeSidebarController.hide();
    if (this.props.onClick) {
      this.props.onClick();
      return;
    }
    gotoPath(this.props.path);
  };

  isSelected = () => {
    return locationProvider.isSelected(this.props.path);
  };

  render() {
    const { icon, label, dataCy } = this.props;
    return (
      <div className={`link ${this.isSelected() ? 'selected' : ''}`} onClick={this.onClick} data-cy={dataCy}>
        <i className={`icon las ${icon}`} />
        <span className="label">{label}</span>
      </div>
    );
  }
}

class BackofficeSidebarController {
  @observable visible = false;

  toggle = () => {
    this.visible = !this.visible;
  };

  hide = () => {
    this.visible = false;
  };
}

export const backofficeSidebarController = new BackofficeSidebarController();

@observer
export class BackofficeSidebar extends React.Component {
  renderUserNav = () => {
    return (
      <>
        <Title label="User" />
        {/* <Link label="Messages" icon="la-envelope" path="/messages" /> */}
        <Link label="Logout" icon="la-sign-out-alt" path="/todo" onClick={() => userProvider.logout()} />
      </>
    );
  };

  renderStoreNav = () => {
    return (
      <Switch>
        <Route
          path={routes.backoffice.store.template}
          render={props => (
            <>
              <Title label="Store" />
              <Link label="Home" icon="la-home" path={routes.backoffice.store.link({ ...props.match.params }, {})} />
              <Link label="Website Link" icon="la-link" path={routes.website.store.link(props.match.params, {})} />
              <Link
                label="Basic Info"
                icon="la-info-circle"
                path={routes.backoffice.editStore.link({ ...props.match.params }, {})}
              />
              <Link
                label="Menu"
                icon="la-clipboard-list"
                path={routes.backoffice.menu.link({ ...props.match.params }, {})}
                dataCy="nav-menu"
              />
              <Link
                label="Pizza Settings"
                icon="la-pizza-slice"
                path={routes.backoffice.pizzaSettings.link({ ...props.match.params }, {})}
                dataCy="nav-pizza-settings"
              />
              <Link
                label="Delivery Settings"
                icon="la-truck"
                path={routes.backoffice.deliverySettings.link({ ...props.match.params }, {})}
                dataCy="nav-delivery-settings"
              />
              <Link
                label="Order Settings"
                icon="la-cog"
                path={routes.backoffice.orderSettings.link({ ...props.match.params }, {})}
                dataCy="nav-order-settings"
              />
              <Link
                label="Store Timings"
                icon="la-clock"
                path={routes.backoffice.storeTimings.link({ ...props.match.params }, {})}
                dataCy="nav-store-timings"
              />
              <Link
                label="Payments"
                icon="la-credit-card"
                path={routes.backoffice.payments.link({ ...props.match.params }, {})}
                dataCy="nav-payments"
              />
              {/* <Link label="Coupons" icon="la-ticket-alt" path="/todo" /> */}
              {/* <Link label="Table Bookings" icon="la-calendar" path="/todo" /> */}
            </>
          )}
        ></Route>
      </Switch>
    );
  };

  renderRestaurantNav = () => {
    return (
      <Switch>
        <Route
          path={routes.backoffice.restaurant.template}
          render={props => (
            <>
              <Title label="Restaurant" />
              <Link
                label="Home"
                icon="la-home"
                path={routes.backoffice.restaurant.link({ ...props.match.params }, {})}
                dataCy="nav-home"
              />
              <Link
                label="Basic Info"
                icon="la-info-circle"
                path={routes.backoffice.editRestaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
                dataCy="nav-basic-info"
              />
              <Link
                label="Website Settings"
                icon="la-globe-americas"
                path={routes.backoffice.restaurantWebsiteInfo.link(props.match.params, {})}
                dataCy="nav-website-settings"
              />
              <Link
                label="Website Link"
                icon="la-link"
                path={routes.website.restaurant.link(props.match.params, {})}
                dataCy="nav-website-link"
              />
              {/* <Link
                label="Menu"
                icon="la-clipboard-list"
                path={routes.backoffice.restaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
              />
              <Link
                label="Coupons"
                icon="la-ticket-alt"
                path={routes.backoffice.restaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
              />
              <Link
                label="Delivery Suburbs"
                icon="la-truck"
                path={routes.backoffice.restaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
              />
              <Link
                label="Orders"
                icon="la-poll-h"
                path={routes.backoffice.restaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
              />
              <Link
                label="Table Bookings"
                icon="la-calendar"
                path={routes.backoffice.restaurant.link({ restaurantId: props.match.params.restaurantId }, {})}
              /> */}
            </>
          )}
        />
      </Switch>
    );
  };

  renderSuperAdminNav = () => {
    if (!userProvider.isSuperAdmin) return null;
    return (
      <>
        <Title label="Super Admin" />
        <Link
          label="Browse"
          icon="la-stream"
          path={routes.backoffice.browseRestaurants.link({}, {})}
          dataCy="nav-super-admin"
        />
        <Link label="Printers" icon="la-print" path={routes.backoffice.printers.link({}, {})} dataCy="nav-printers" />
      </>
    );
  };

  renderMainNav = () => {
    return (
      <>
        <Title label="Main" />
        <Link label="Home" icon="la-hotel" path={routes.backoffice.home.template} dataCy="nav-main" />
      </>
    );
  };

  render() {
    return (
      <nav className={backofficeSidebarController.visible ? 'show' : 'hide'}>
        {this.renderMainNav()}
        {this.renderRestaurantNav()}
        {this.renderStoreNav()}
        {this.renderSuperAdminNav()}
        {this.renderUserNav()}
      </nav>
    );
  }
}
