import * as React from 'react';
import { StoreViewWidget } from './StoreViewWidget';
import { Restaurant, Store, AspectRatio, routes } from '@restoplus/core';
import { Imgix } from '../elements/Imgix';
import { gotoPath } from '../utils/browserHistory';

export class StoreCardWidget extends StoreViewWidget {
  renderStore(restaurant: Restaurant, store: Store): JSX.Element {
    return (
      <div className="store-card-view" onClick={() => gotoPath(routes.backoffice.store.link(this.props, {}))}>
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="name">
          <div className="restaurant-name">{restaurant.name}</div>
          <div className="store-name">{store.name}</div>
        </div>
      </div>
    );
  }
}
