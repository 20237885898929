import * as React from 'react';
import { GoogleAddress } from '@restoplus/core';
import { googlePlaces } from '../utils/googlePlacesUtils';

type Props = {
  onChange: (address: GoogleAddress) => void;
};

export class GooglePlacesSearch extends React.Component<Props> {
  //
  textInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    var autocomplete = new google.maps.places.Autocomplete(this.textInput.current!);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.props.onChange(googlePlaces.parse(autocomplete.getPlace()));
      console.log(autocomplete.getPlace());
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: autocomplete.getPlace().place_id }, args => {
        console.log(args);
      });
      geocoder.geocode({ address: '18/19 Hindmarsh Terrace, Northgate 5085' }, args => {
        console.log(args);
      });
    });
  }

  render() {
    return <input type="text" ref={this.textInput} />;
  }
}
