import { countries, CountryCode } from '@restoplus/core';
import React = require('react');

type Props = {
  countryCode: CountryCode;
};

export const CountryFlag = (props: Props) => {
  const country = countries[props.countryCode];
  return (
    <div className="icon">
      <img className="flag" src={`/images/flags/${country.code}.png`} />
    </div>
  );
};
