import * as React from 'react';
import { AlertType } from './AlertInfo';

type Props = {
  type: AlertType;
  title?: string;
  icon?: string;
  children: React.ReactNode;
};

/**
 * Alert is for displaying static information on the page
 */
export class Alert extends React.Component<Props> {
  renderIcon = () => {
    const { type, icon } = this.props;
    if (icon === 'loading') return <i className="icon spinner spin las la-circle-notch" />;
    switch (type) {
      case 'success':
        return <i className={`icon las ${icon || 'la-thumbs-up'}`}></i>;
      case 'error':
        return <i className={`icon las ${icon || 'la-exclamation-triangle'}`}></i>;
      case 'warning':
        return <i className={`icon las ${icon || 'la-exclamation-triangle'}`}></i>;
      case 'info':
        return <i className={`icon las ${icon || 'la-info'}`}></i>;
    }
  };

  render() {
    return (
      <div className={`alert ${this.props.type} ${this.props.title && 'has-title'}`}>
        <div className="icon">{this.renderIcon()}</div>
        {this.props.title && <div className="title">{this.props.title}</div>}
        <div className="message">{this.props.children}</div>
      </div>
    );
  }
}
