import { observer } from 'mobx-react';
import * as React from 'react';
import { AuthPage } from '../../auth/AuthPage';
import { Widget } from '../../elements/Widget';
import { userProvider } from '../../provider/userProvider';
import { BackofficeHeader } from './BackofficeHeader';
import { BackofficeSidebar } from './BackofficeSidebar';
import { BackofficeRouter } from './BackofficeRouter';

type Props = {};

@observer
export class BackofficeLayout extends Widget<Props> {
  render() {
    if (userProvider.user === undefined) return null;
    if (userProvider.user === null) return <AuthPage />;
    return (
      <div className="backoffice">
        <BackofficeHeader />
        <BackofficeSidebar />
        <BackofficeRouter />
      </div>
    );
  }
}
