import { Schema } from 'nutso';
import { Category } from '../models/category/Category';

export const categorySchema: Schema<Category> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    name: {
      type: 'string',
      minLength: 3,
      maxLength: 30
    },
    description: {
      type: 'string',
      optional: true,
      maxLength: 100
    },
    image: {
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
        path: {
          type: 'string'
        }
      }
    },
    sortOrder: {
      type: 'number'
    }
  }
};
