import { Schema } from 'nutso';
import { Hours } from '..';
import { hhmmUtils } from '../utils/hhmmUtils';

export const hoursSchema: Schema<Hours> = {
  type: 'object',
  properties: {
    duration: {
      type: 'number',
      min: 0.5,
      max: 23.5
    },
    startHhmm: {
      type: 'string',
      pattern: hhmmUtils.pattern
    }
  }
};
