import { GoogleAddress } from '@restoplus/core';

/**
 * Here we are assuming all the fields are present, we will make sure of it in the validation
 * https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
 * https://developers.google.com/maps/documentation/javascript/geocoding
 */
const parse = (place: google.maps.places.PlaceResult): GoogleAddress => {
  //
  const postcode = place.address_components?.find(component => component.types[0] === 'postal_code')?.long_name!;
  const suburb = place.address_components?.find(component => component.types[0] === 'locality')?.long_name!;

  const countryName = place.address_components?.find(component => component.types[0] === 'country')?.long_name!;
  const countryCode = place.address_components?.find(component => component.types[0] === 'country')?.short_name!;
  const formattedAddress = place.formatted_address!;

  console.log(place);
  return {
    formattedAddress,
    suburb,
    postcode,
    country: { name: countryName!, code: countryCode! },
    geoPoint: {
      latitude: `${place.geometry?.location.lat()}`,
      longitude: `${place.geometry?.location.lng()}`
    },
    googlePlaceId: place.place_id!
  };
};

export const googlePlaces = {
  parse
};
