import React = require('react');
import { formatAmount, ModifierOption, ModifierValue, ModifierSpec } from '@restoplus/core';
import { observer } from 'mobx-react';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { toastStore } from '../../toast/toastStore';
import { Badge } from '../../elements/Badge';

type Props = {
  value: ModifierValue;
  onChange: (value: ModifierValue) => void;
  spec: ModifierSpec;
};

@observer
export class ModifierWidget extends React.Component<Props> {
  //
  render() {
    const { spec } = this.props;
    return (
      <div key={spec.id} className="modifier">
        <div className="header">
          <div className="title">{spec.name}</div>
          {this.renderBadge()}
        </div>
        <div className="options">{spec.options.map(option => this.renderOption(spec, option))}</div>
      </div>
    );
  }

  renderBadge(): React.ReactNode {
    const { spec, value } = this.props;
    const currentSelectionCount = value.length;

    // return required/optional
    if (currentSelectionCount === 0) {
      if (spec.min > 0) {
        return <Badge type="primary">Required</Badge>;
      }
      return <Badge type="success">Optional</Badge>;
    }

    // use has selected at least one option, but has not choosen the minimun required
    if (currentSelectionCount < spec.min) {
      return <Badge type="primary">{`Choose ${spec.min - currentSelectionCount} more`}</Badge>;
    }

    return <div></div>;
  }

  renderOption(spec: ModifierSpec, option: ModifierOption): React.ReactNode {
    const { value } = this.props;
    const isSelected = value.includes(option.id);
    const isSingleSelect = spec.max === 1 ? true : false;

    return (
      <div
        className={`option ${isSelected ? 'selected' : ''}`}
        key={option.id}
        onClick={e => this.onOptionClick(option, isSingleSelect)}
      >
        {this.renderInput(spec, option, isSelected, isSingleSelect)}
        <span className="name">{option.name}</span>
        <span className="extra-charge">
          {option.extraCharge > 0 &&
            `+ ${formatAmount(option.extraCharge, restaurantProvider.$restaurant?.current()?.country)}`}
        </span>
      </div>
    );
  }

  renderInput(
    spec: ModifierSpec,
    option: ModifierOption,
    isSelected: boolean,
    isSingleSelect: boolean
  ): React.ReactNode {
    if (isSingleSelect) return <input type="radio" readOnly checked={isSelected} />;
    return <input type="checkbox" readOnly checked={isSelected} />;
  }

  onOptionClick(option: ModifierOption, isSingleSelect: boolean): void {
    //
    const { spec, value, onChange } = this.props;
    const alreadySelected = value.includes(option.id);

    if (alreadySelected) {
      // unselect
      onChange(value.filter(optionId => optionId !== option.id));
      return;
    }

    // user is selecting the option now
    if (isSingleSelect) {
      onChange([option.id]);
      return;
    }

    if (value.length >= spec.max) {
      toastStore.warning(`Max options selected.`);
      return;
    }

    onChange([...value, option.id]);
    return;
  }
}
