import { observable, toJS, computed } from 'mobx';
import * as React from 'react';
import { Spin } from '../elements/Spin';
import { DocumentRepo } from '../repo/DocumentRepo';
import { toastStore } from '../toast/toastStore';
import { gotoPath } from '../utils/browserHistory';
import { asyncOperation } from '../utils/asyncOperation';
import { validate, Schema } from 'nutso';

type Props<K> = {
  documentId: K;
  create: boolean;
};
// K = key, T = type
export abstract class DocumentEditWidget<K, T, AdditionalProps = {}> extends React.Component<
  Props<K> & AdditionalProps
> {
  //
  @observable private busy = false;
  @observable private ready = false;
  @observable protected document: T;

  @computed
  get validation() {
    const vr = validate(toJS(this.document), this.getSchema());
    return vr;
  }

  abstract name(): string;

  abstract createDocument(): T;
  abstract getRepo(): DocumentRepo<K, T>;
  abstract getSchema(): Schema<T>;
  abstract nextRoute(): string;
  abstract form(): JSX.Element;
  abstract getKey(): K;

  componentDidMount() {
    // fetch doc
    const { create: isCreate } = this.props;
    if (isCreate) {
      this.document = this.createDocument();
      this.ready = true;
      return;
    }
    this.fetchDoc();
  }

  abstract getWidgetNameForCssClass(): string;

  fetchDoc = async () => {
    const document = await asyncOperation({
      fn: () => this.getRepo().get(this.getKey()),
      loadingMessage: `${this.name()}`
    });
    // doc found
    if (document) {
      this.document = document;
      this.ready = true;
      return;
    }
    // doc missing
    toastStore.error(`Cannot find ${this.name()}`);
  };

  submit = async () => {
    await asyncOperation({
      fn: () => this.getRepo().put(this.getKey(), toJS(this.document)),
      loadingMessage: `Saving ${this.name()}`,
      setBusyFlag: (busy: boolean) => (this.busy = busy)
    });
    toastStore.success(`${this.name()} successfully saved`);
    this.nextRoute() && gotoPath(this.nextRoute());
  };

  render() {
    if (!this.ready) return null;
    return (
      <div className={`${this.getWidgetNameForCssClass()} document-edit-widget`}>
        <Spin spinning={this.busy}>{this.form()}</Spin>
      </div>
    );
  }
}
