import { Category, CollectionType, defaults, routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Imgix } from '../../elements/Imgix';
import { Page } from '../../elements/Page';
import { ResolveResource } from '../../elements/ResolveResource';
import { customerCart } from '../../provider/customerCartProvider';
import { repo } from '../../repo/repo';
import { gotoPath } from '../../utils/browserHistory';
import { listUtils } from '../../utils/listUtils';
import { CollectionTypeSelector } from '../widgets/CollectionTypeSelector';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

@observer
export class MenuPage extends Page<Props, RouteProps> {
  //
  $menu = repo.menu.bind(this.props.match.params, defaults.menu);
  $categories = repo.categories.bindCollection(this.props.match.params);
  $items = repo.items.bindCollection(this.props.match.params);
  $store = repo.store.bind(this.props.match.params);

  getTitle(): string {
    return 'Menu';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    const categories = this.$categories.current();
    if (!categories) return <div></div>;

    return (
      <div className={'menu-page'}>
        {this.renderCollectionTypeSelector()}
        {this.renderCategories(categories)}
      </div>
    );
  }

  renderCollectionTypeSelector(): React.ReactNode {
    return (
      <>
        <ResolveResource resource={this.$store}>
          {store => {
            if (!store) return null;
            return <CollectionTypeSelector selected={customerCart.collectionType} store={store} />;
          }}
        </ResolveResource>
      </>
    );
  }

  renderCategories(categories: Category[]): React.ReactNode {
    const collectionType = customerCart.collectionType;
    if (!collectionType) {
      return <div>Select collection type above 👆🏻</div>;
    }
    const sorted = listUtils.sortBySortOrder(categories);
    return (
      <div className="categories">
        {sorted.map((category, i, list) => this.renderCategory(category, i, list, collectionType))}
      </div>
    );
  }

  renderCategory(category: Category, i: number, list: Category[], collectionType: CollectionType): any {
    const menu = this.$menu.current();
    if (!menu) return;
    return (
      <div
        className="category"
        key={category.id}
        onClick={() =>
          gotoPath(
            routes.website.onlineOrdering.items.link({ ...this.props.match.params, categoryId: category.id }, {})
          )
        }
      >
        <Imgix image={category.image} aspectRatio={menu.categoryImageAspectRatio} />
        <div className="name">{category.name}</div>
        <div className="description">{category.description}</div>
      </div>
    );
  }
}
