import { AspectRatio, Hero, Image, RestaurantWebsiteInfo, routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Alert } from '../../alert/Alert';
import { Imgix } from '../../elements/Imgix';
import { Page } from '../../elements/Page';
import { repo } from '../../repo/repo';
import { gotoPath } from '../../utils/browserHistory';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

@observer
export class StoreWebsitePage extends Page<Props, RouteProps> {
  //
  $restaurantWesbiteInfo = repo.restaurantWebsiteInfo.bind(this.props.match.params);
  $store = repo.store.bind(this.props.match.params);

  getTitle(): string {
    return 'Home';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    const restaurantWebsiteInfo = this.$restaurantWesbiteInfo.current();
    if (restaurantWebsiteInfo === undefined) return <span></span>;
    if (restaurantWebsiteInfo === null)
      return (
        <Alert type={'error'} title="Website not configured">
          Contact store for more details.
        </Alert>
      );
    return (
      <div className={'store-website-page'}>
        {this.renderHeros(restaurantWebsiteInfo)}
        {this.renderCtas(restaurantWebsiteInfo)}
        {/* {this.renderQuickLinks(restaurantWebsiteInfo)} */}
        {this.renderAbout(restaurantWebsiteInfo)}
        {this.renderGallery(restaurantWebsiteInfo)}
      </div>
    );
  }

  renderCtas(restaurantWebsiteInfo: RestaurantWebsiteInfo): React.ReactNode {
    return (
      <div className="ctas">
        <div
          className={`cta food-order`}
          onClick={() => gotoPath(routes.website.onlineOrdering.menu.link(this.props.match.params, {}))}
        >
          <div className="icon">
            <i className={`las la-cookie-bite`}></i>
          </div>
          <div className="title">Order Online</div>
        </div>
        <a href={`tel:${this.$store.current()?.phoneNumber}`}>
          <div className={`cta book-a-table`}>
            <div className="icon">
              <i className={`las la-phone`}></i>
            </div>
            <div className="title">Call Store</div>
          </div>
        </a>
      </div>
    );
  }

  renderGallery(restaurantWebsiteInfo: RestaurantWebsiteInfo): React.ReactNode {
    const photos = restaurantWebsiteInfo.photos;
    if (!photos || !photos.length) return;
    return (
      <div className="photos">
        <div className="header">
          <div className="icon">
            <i className={`las la-store-alt`} />
          </div>
          <div className="title">Gallery</div>
        </div>
        <div className="body">{photos.map(photo => this.renderPhoto(photo))}</div>
      </div>
    );
  }

  renderPhoto(photo: Image): React.ReactNode {
    return <Imgix image={photo} key={photo.id} aspectRatio={AspectRatio.square} />;
  }

  renderAbout(restaurantWebsiteInfo: RestaurantWebsiteInfo): React.ReactNode {
    if (!restaurantWebsiteInfo.about) return;
    return (
      <div className="about">
        <div className="icon">
          <i className={`las la-store-alt`} />
        </div>
        <div className="title">About Restaurant</div>
        <div className="description">{restaurantWebsiteInfo.about}</div>
      </div>
    );
  }

  renderQuickLinks(restaurantWebsiteInfo: RestaurantWebsiteInfo): React.ReactNode {
    return (
      <div className="quick-links">
        {this.renderQuickLink({
          icon: '/images/istar/pickup.svg',
          label: 'Order Online',
          link: routes.website.onlineOrdering.menu.link(this.props.match.params, {})
        })}
        {this.renderQuickLink({ icon: '/images/istar/menu.svg', label: 'Menu', link: '' })}
        {this.renderQuickLink({ icon: '/images/istar/delivery.svg', label: 'Delivery', link: '' })}
        {this.renderQuickLink({ icon: '/images/istar/call.svg', label: 'Call Store', link: '' })}
        {this.renderQuickLink({ icon: '/images/istar/delivery.svg', label: 'Delivery', link: '' })}
        {this.renderQuickLink({ icon: '/images/istar/call.svg', label: 'Call Store', link: '' })}
      </div>
    );
  }

  renderQuickLink = (args: { icon: string; label: string; link: string }) => {
    return (
      <div className="quick-link" onClick={() => gotoPath(args.link)}>
        <div className="icon">
          <img src={args.icon} />
        </div>
        <div className="label">{args.label}</div>
      </div>
    );
  };

  renderHeros(restaurantWebsiteInfo: RestaurantWebsiteInfo): React.ReactNode {
    const heros = restaurantWebsiteInfo.heros;
    if (!heros || !heros.length) return;
    return <div className="heros">{heros.map(hero => this.renderHero(hero))}</div>;
  }

  renderHero(hero: Hero): React.ReactNode {
    return (
      <div className="hero" key={hero.id}>
        <Imgix image={hero.image} aspectRatio={AspectRatio.horizontalRegular} />
        <div className="content">
          <div className="title">{hero.title}</div>
          <div className="description">{hero.description}</div>
        </div>
      </div>
    );
  }
}
