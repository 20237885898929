import { Order } from '../models/order/Order';
import { OrderStatusCode } from '../models/order/OrderStatus';

const isRejected = (args: { prev: Order; current: Order }) => {
  const { prev, current } = args;
  return prev.status.code !== OrderStatusCode.rejected && current.status.code === OrderStatusCode.rejected;
};

const isCancelled = (args: { prev: Order; current: Order }) => {
  const { prev, current } = args;
  return prev.status.code !== OrderStatusCode.cancelled && current.status.code === OrderStatusCode.cancelled;
};

const isRevoked = (args: { prev: Order; current: Order }) => {
  return isRejected(args) || isCancelled(args);
};

export const orderUtils = {
  isRejected,
  isCancelled,
  isRevoked
};
