import {
  BaseItemSpec,
  DealItemSpec,
  DealPizzaSpec,
  DealSpec,
  dealSpecSchema,
  defaults,
  idFactory,
  ItemId,
  PizzaSettings,
  PizzaSpec,
  routes
} from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Button } from '../elements/Button';
import { Checkbox } from '../elements/Checkbox';
import { Form } from '../forms/Form';
import { FormItemContainer } from '../forms/FormItemContainer';
import { FormNumberInput } from '../forms/FormNumberInput';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { FormTextInput } from '../forms/FormTextInput';
import { NumberInput } from '../forms/NumberInput';
import { ItemSpecBaseEditWidget } from '../item_spec/ItemSpecBaseEditWidget';
import { ModifierSpecsEditWidget } from '../item_spec/ModifierSpecsEditWidget';
import { PriceSpecEditWidget } from '../item_spec/PriceSpecEditWidget';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { listUtils } from '../utils/listUtils';
import { validationUtils } from '../utils/validationUtils';

const NEW_DEAL_ITEM = {
  id: idFactory.dealItemId(),
  name: '',
  quantity: 1,
  modifiers: [
    {
      id: idFactory.modifierId(),
      min: 0,
      max: 1,
      name: '',
      options: [
        {
          id: idFactory.modifierId(),
          name: '',
          extraCharge: 0
        }
      ]
    }
  ]
};

type Props = {
  categoryId: string;
};

@observer
export class DealSpecEditWidget extends DocumentEditWidget<ItemId, DealSpec, Props> {
  //

  $pizzaSettings = repo.pizzaSettings.bind(this.props.documentId);
  $items = repo.items.bindCollection(this.props.documentId);

  name(): string {
    return 'Create Deal';
  }

  moveUpModifier = () => {};

  createDocument(): DealSpec {
    return {
      id: idFactory.itemId(),
      restaurantId: this.props.documentId.restaurantId,
      storeId: this.props.documentId.storeId,
      categoryId: this.props.categoryId,
      ...defaults.dealSpec
    };
  }

  getWidgetNameForCssClass() {
    return 'deal-edit-widget';
  }

  getRepo(): DocumentRepo<ItemId, DealSpec> {
    return repo.dealSpec;
  }

  getSchema(): Schema<DealSpec> {
    return dealSpecSchema;
  }

  nextRoute(): string {
    return routes.backoffice.category.link({ ...this.props.documentId, categoryId: this.props.categoryId }, {});
  }

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const item = this.document;
    const submitLabel = this.props.create ? 'Create Pizza' : 'Save Pizza';
    return (
      <Form type="responsive" submit={{ label: submitLabel, handler: this.submit }} validationResult={this.validation}>
        <ItemSpecBaseEditWidget item={this.document} validation={this.validation} />
        <FormItemContainer label="Price" validation={this.validation.properties.price} className="form-item-price-spec">
          <PriceSpecEditWidget priceSpec={item.price} validation={this.validation.properties.price} />
        </FormItemContainer>
        <FormItemContainer
          label="Pizzas"
          validation={this.validation.properties.pizzas}
          className="deal-pizzas-form-item"
        >
          {this.renderDealPizzas(item, this.validation.properties.pizzas)}
        </FormItemContainer>
        <FormItemContainer label="Items" validation={this.validation.properties.items} className="deal-items-form-item">
          {this.renderDealItems(item, this.validation.properties.items)}
        </FormItemContainer>
        {this.renderModifiers(item, this.validation)}
      </Form>
    );
  }

  renderModifiers = (item: DealSpec, validation: Result<DealSpec>) => {
    return (
      <ModifierSpecsEditWidget
        label="Modifiers"
        value={item.modifiers}
        onChange={value => (item.modifiers = value)}
        validation={validation.properties.modifiers}
      />
    );
  };

  renderDealPizzas(deal: DealSpec, validation: Result<DealPizzaSpec[]>): React.ReactNode {
    const pizzaSettings = this.$pizzaSettings.current();
    if (!pizzaSettings) return;
    const items = this.$items.current();
    if (!items) return null;
    const pizzas = items.filter(item => item.type === ItemType.pizza);

    return (
      <div className="deal-pizzas">
        {this.renderDealPizzaList(deal, validation, pizzaSettings, pizzas)}
        {this.renderDealPizzasActions(deal)}
      </div>
    );
  }

  renderDealPizzaList(
    deal: DealSpec,
    validation: Result<DealPizzaSpec[]>,
    pizzaSettings: PizzaSettings,
    pizzas: BaseItemSpec[]
  ): React.ReactNode {
    if (!deal.pizzas || !deal.pizzas.length) return;
    return (
      <div className="list">
        {deal.pizzas.map((dealPizza, i) =>
          this.renderDealPizza(dealPizza, i, deal, validation.items[i], pizzaSettings, pizzas)
        )}
      </div>
    );
  }

  renderDealPizza(
    dealPizza: DealPizzaSpec,
    i: number,
    deal: DealSpec,
    validation: Result<DealPizzaSpec>,
    pizzaSettings: PizzaSettings,
    pizzas: BaseItemSpec[]
  ): React.ReactNode {
    return (
      <div className="item" key={dealPizza.id}>
        <div className="content">
          <div className="info">
            <FormSingleSelect
              label="Size"
              options={pizzaSettings.sizes}
              value={pizzaSettings.sizes.find(size => size.id === dealPizza.sizeId)}
              onChange={selected => selected && (dealPizza.sizeId = selected.id)}
              optionKey={pizzaSizeSettings => pizzaSizeSettings?.id || ''}
              optionLabel={pizzaSizeSettings => pizzaSizeSettings?.name || ''}
              validation={validation.properties.sizeId}
            />
            <FormNumberInput
              label="Quantity"
              value={dealPizza.quantity}
              onChange={value => (dealPizza.quantity = value)}
              validation={validation.properties.quantity}
            />
          </div>
          {this.renderPizzaList(dealPizza, pizzas)}
        </div>
        {this.renderDealPizzaActions(deal, i)}
      </div>
    );
  }

  renderDealPizzaActions = (dealSpec: DealSpec, i: number) => {
    return (
      <div className="actions">
        <div className="action" onClick={() => (dealSpec.pizzas = listUtils.moveUp(dealSpec.pizzas, i))}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => (dealSpec.pizzas = listUtils.moveDown(dealSpec.pizzas, i))}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div className="action" onClick={() => (dealSpec.pizzas = listUtils.remove(dealSpec.pizzas, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  };

  renderPizzaList(dealPizza: DealPizzaSpec, _pizzas: BaseItemSpec[]): React.ReactNode {
    if (!dealPizza.sizeId) return;
    const pizzas = (_pizzas as PizzaSpec[]).filter(pizza => Object.keys(pizza.sizes).includes(dealPizza.sizeId));
    const pizzaIds = pizzas.map(pizza => pizza.id);
    const selectedPizzaIds = Object.keys(dealPizza.pizzas);
    const allSelected = pizzaIds.every(id => selectedPizzaIds.includes(id));
    return (
      <div className="pizza-list">
        <div className="head">
          <div className="">
            <Checkbox
              label=""
              value={allSelected}
              onChange={checked => {
                if (checked) {
                  pizzaIds.forEach(pizzaId => {
                    // already selected
                    if (selectedPizzaIds.includes(pizzaId)) return;
                    // select now
                    dealPizza.pizzas[pizzaId] = { extraCharge: 0 };
                  });
                  return;
                }
                // unselect all
                dealPizza.pizzas = {};
              }}
            />
          </div>
          <div className="">Name</div>
          <div className="">Extra Charge</div>
        </div>
        <div className="body">{pizzas.map(pizza => this.renderPizza(dealPizza, pizza))}</div>
      </div>
    );
  }

  renderPizza(dealPizza: DealPizzaSpec, pizza: BaseItemSpec): React.ReactNode {
    const current = dealPizza.pizzas[pizza.id] || { extraCharge: 0 };
    return (
      <div key={pizza.id} className="pizza">
        <div className="select-box">
          <Checkbox
            label=""
            value={Object.keys(dealPizza.pizzas).includes(pizza.id)}
            onChange={checked => {
              if (checked) {
                dealPizza.pizzas[pizza.id] = { extraCharge: 0 };
                return;
              }
              delete dealPizza.pizzas[pizza.id];
            }}
          />
        </div>
        <div>{pizza.name}</div>
        <div className="extra-charge">
          <NumberInput
            value={current.extraCharge}
            onChange={extraCharge => (current.extraCharge = extraCharge)}
            validation={validationUtils.success}
          ></NumberInput>
        </div>
      </div>
    );
  }

  renderDealPizzasActions(deal: DealSpec): React.ReactNode {
    return (
      <div className="actions">
        <Button onClick={() => deal.pizzas.push({ id: idFactory.dealItemId(), sizeId: '', quantity: 1, pizzas: {} })}>
          Add Pizza
        </Button>
      </div>
    );
  }

  renderDealItems(deal: DealSpec, validation: Result<DealItemSpec[]>): React.ReactNode {
    return (
      <div className="deal-items">
        {this.renderDealItemList(deal, validation)}
        {this.renderDealItemsActions(deal)}
      </div>
    );
  }

  renderDealItemList(item: DealSpec, validation: Result<DealItemSpec[]>): React.ReactNode {
    if (!item.items || !item.items.length) return;
    return (
      <div className="list">
        {item.items.map((dealItem, i) => this.renderDealItem(dealItem, i, item, validation.items[i]))}
      </div>
    );
  }

  renderDealItem(dealItem: DealItemSpec, i: number, item: DealSpec, validation: Result<DealItemSpec>): React.ReactNode {
    return (
      <div className="item" key={dealItem.id}>
        <div className="content">
          <div className="info">
            <FormTextInput
              label="Name"
              value={dealItem.name}
              onChange={value => (dealItem.name = value)}
              validation={validation.properties.name}
            />
            <FormNumberInput
              label="Quantity"
              value={dealItem.quantity}
              onChange={value => (dealItem.quantity = value)}
              validation={validation.properties.quantity}
            />
          </div>
          <ModifierSpecsEditWidget
            label="Modifiers"
            value={dealItem.modifiers}
            onChange={value => (dealItem.modifiers = value)}
            validation={validation.properties.modifiers}
          />
        </div>
        {this.renderDealItemActions(item, i)}
      </div>
    );
  }

  renderDealItemActions = (dealSpec: DealSpec, i: number) => {
    return (
      <div className="actions">
        <div className="action" onClick={() => (dealSpec.items = listUtils.moveUp(dealSpec.items, i))}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => (dealSpec.items = listUtils.moveDown(dealSpec.items, i))}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div className="action" onClick={() => (dealSpec.items = listUtils.remove(dealSpec.items, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  };

  renderDealItemsActions(deal: DealSpec): React.ReactNode {
    return (
      <div className="actions">
        <Button onClick={() => deal.items.push(NEW_DEAL_ITEM)}>Add Item</Button>
      </div>
    );
  }

  getKey(): ItemId {
    if (this.props.create) {
      return {
        ...this.props.documentId,
        itemId: this.document.id
      };
    }
    return this.props.documentId;
  }
}
