import { Schema } from 'nutso';
import { Restaurant } from '..';

export const restaurantSchema: Schema<Restaurant> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    name: {
      type: 'string',
      minLength: 3,
      maxLength: 30
    },
    description: {
      optional: true,
      type: 'string',
      maxLength: 1000
    },
    country: {
      type: 'string'
    },
    timezone: {
      type: 'string'
    },
    logo: {
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
        path: {
          type: 'string'
        }
      }
    },
    creatorUid: {
      type: 'string'
    },
    editorUid: {
      type: 'string'
    },
    createdTime: {
      type: 'string'
    },
    editedTime: {
      type: 'string'
    }
  }
};
