import * as React from 'react';
import { ValidationResult } from 'nutso';

type Props = {
  label: string;
  validation: ValidationResult;
  helpText?: string;
};

// @deprecated use FormItemContainer instead
// P => Additional Props
export abstract class FormItem<P> extends React.Component<P & Props> {
  //
  renderErrorMessage = () => {
    const { validation } = this.props;
    if (!validation.errorMessage) return;
    return <div className="error">{validation.errorMessage}</div>;
  };

  renderHelpText = () => {
    const { helpText } = this.props;
    if (!helpText) return;
    return <div className="help">{helpText}</div>;
  };

  renderMeta = () => {
    const error = this.renderErrorMessage();
    const help = this.renderHelpText();
    if (error || help)
      return (
        <div className="meta">
          {error}
          {help}
        </div>
      );
    return null;
  };

  abstract renderValue(): JSX.Element;

  // override to add more class-name to the item
  className() {
    return '';
  }

  render() {
    const { label, validation } = this.props;
    return (
      <div className={`form-item ${this.className()}`}>
        <label>{label}</label>
        <div className={`form-input ${validation.isValid ? 'valid' : 'invalid'}`}>{this.renderValue()}</div>
        {this.renderMeta()}
      </div>
    );
  }
}
