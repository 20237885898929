import React = require('react');
import { AspectRatio, aspectRatioList, Image, square } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { getImageUrl } from '../utils/getImageUrl';

type Props = {
  image: Image | undefined;
  aspectRatio: AspectRatio | null;
  height?: number;
};

@observer
export class Imgix extends React.Component<Props> {
  //
  element: HTMLImageElement | null;

  @observable width = 100;

  onLoad = () => {
    if (!this.element) return;
    // the rendered width is less than the current width, so no need to load a fresh image
    if (this.element.width <= this.width) return;
    // rendered width is greater than the loaded image's width, so load a bigger one
    this.width = this.element.width;
  };

  onError = () => {};

  getAr = () => {
    const { aspectRatio, height } = this.props;

    if (height) {
      return `&fit=crop&crop=edges&h=${height}`;
    }

    if (aspectRatio === null) return '';
    const ar = Object.values(aspectRatioList).find(ar => ar.id === aspectRatio) || square;
    return `&fit=crop&crop=edges&ar=${ar.width}:${ar.height}`;
  };

  render() {
    const { image, aspectRatio } = this.props;
    if (!image) return null;
    const { path } = image;
    const src = `${getImageUrl(path)}?auto=compress&w=${this.width}${this.getAr()}`;
    return (
      <div className="image imgix">
        <img
          src={src}
          srcSet={`${src} 1x, ${src}&dpr=2 2x, ${src}&dpr=3 3x`}
          onLoad={this.onLoad}
          onError={this.onError}
          ref={element => (this.element = element)}
        ></img>
      </div>
    );
  }
}
