import { Schema } from 'nutso';
import { DealPizza } from '../../models/item_selection/DealSelection';
import { isDealPizzaFull } from '../../utils/isDealPizzaFull';
import { isDealPizzaHalfAndHalf } from '../../utils/isDealPizzaHalfAndHalf';

export const dealPizzaSchema: Schema<DealPizza> = {
  type: 'object',
  properties: {
    pizzaId: {
      type: 'string',
      minLength: 1,
      optional: pizza => isDealPizzaHalfAndHalf(pizza)
    },
    addToppingsIds: {
      type: 'array',
      optional: pizza => isDealPizzaHalfAndHalf(pizza),
      items: {
        type: 'string'
      }
    },
    removeToppings: {
      type: 'array',
      optional: pizza => isDealPizzaHalfAndHalf(pizza),
      items: {
        type: 'string'
      }
    },
    firstHalf: {
      type: 'object',
      optional: pizza => isDealPizzaFull(pizza),
      properties: {
        pizzaId: {
          type: 'string'
        },
        addToppingsIds: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        removeToppings: {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    },
    secondHalf: {
      type: 'object',
      optional: pizza => isDealPizzaFull(pizza),
      properties: {
        pizzaId: {
          type: 'string'
        },
        addToppingsIds: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        removeToppings: {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    }
  }
};
