import { Schema } from 'nutso';
import { Deal } from '../../models/item/Deal';
import { dealSpecSchema } from '../item_spec/dealSpecSchema';
import { dealSelectionSchema } from './dealSelectionSchema';
import { itemSchema } from './itemSchema';

export const dealSchema: Schema<Deal> = {
  type: 'object',
  properties: {
    ...itemSchema.properties,
    spec: dealSpecSchema,
    selection: dealSelectionSchema
  }
};
