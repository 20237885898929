import * as React from 'react';

type Props = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export class Checkbox extends React.Component<Props> {
  render() {
    const { label, value, onChange } = this.props;
    return (
      <div className="checkbox" onClick={e => onChange(!value)}>
        <input type="checkbox" checked={value} readOnly />
        <label>{label}</label>
      </div>
    );
  }
}
