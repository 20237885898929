/**
 * This has inc/dec quantity and add to cart button
 */
import React = require('react');
import { formatAmount } from '@restoplus/core';
import { restaurantProvider } from '../../provider/restaurantProvider';

type Props = {
  price: number;
  quantity: number;
  onInc: () => void;
  onDec: () => void;
  addToCart: () => void;
};

export class ItemActionButtons extends React.Component<Props> {
  render() {
    return (
      <div className="item-actions">
        {this.renderQuantity()}
        {this.renderAddToCart()}
      </div>
    );
  }

  renderAddToCart(): React.ReactNode {
    const { addToCart } = this.props;

    return (
      <div className="add-to-cart-button" onClick={addToCart}>
        <div className="label">Add To Cart</div>
        <div className="amount">
          {formatAmount(this.props.price * this.props.quantity, restaurantProvider.$restaurant?.current()?.country)}
        </div>
      </div>
    );
  }

  renderQuantity(): React.ReactNode {
    const { quantity, onInc, onDec } = this.props;

    return (
      <div className="quantity">
        <div className="dec icon" onClick={onDec}>
          <i className="las la-minus"></i>
        </div>
        <div className="number">{quantity}</div>
        <div className="inc icon" onClick={onInc}>
          <i className="las la-plus"></i>
        </div>
      </div>
    );
  }
}
