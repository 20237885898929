export enum Dow {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday'
}

export const dowList: Dow[] = [
  Dow.monday,
  Dow.tuesday,
  Dow.wednesday,
  Dow.thursday,
  Dow.friday,
  Dow.saturday,
  Dow.sunday
];

export const dowLabels: { [key in Dow]: string } = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday'
};
