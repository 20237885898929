import * as React from 'react';
import { FormItem } from './FormItem';
import { FormItemProps, FormItemContainer } from './FormItemContainer';

type Props = {
  value: string;
  onChange: (value: string) => void;
  rows?: number;
  dataCy?: string;
} & FormItemProps;

export class FormTextArea extends React.Component<Props> {
  //
  render() {
    const { value, onChange, validation, rows, dataCy } = this.props;
    return (
      <FormItemContainer {...this.props}>
        <textarea
          rows={rows ?? 3}
          value={value}
          onChange={e => onChange(e.target.value)}
          className={validation.isValid ? 'valid' : 'invalid'}
          data-cy={dataCy}
        />
      </FormItemContainer>
    );
  }
}
