import {
  DeliverySettings,
  deliverySettingsSchema,
  DeliverySuburb,
  formatAmount,
  idFactory,
  isNil,
  StoreId
} from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import { StaticDocumentEditWidget } from '../../document/StaticDocumentEditWidget';
import { Button } from '../../elements/Button';
import { Page } from '../../elements/Page';
import { Form } from '../../forms/Form';
import { FormItemContainer } from '../../forms/FormItemContainer';
import { FormNumberInput } from '../../forms/FormNumberInput';
import { FormSwitch } from '../../forms/FormSwitch';
import { FormTextInput } from '../../forms/FormTextInput';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { DocumentRepo } from '../../repo/DocumentRepo';
import { repo } from '../../repo/repo';
import { listUtils } from '../../utils/listUtils';
import { optionalFlag } from '../../utils/optionalFlag';
import { validationUtils } from '../../utils/validationUtils';
import React = require('react');

@observer
export class DeliverySettingsEditWidget extends StaticDocumentEditWidget<StoreId, DeliverySettings> {
  //
  name(): string {
    return 'Delivery Info';
  }

  defaultDocument(): DeliverySettings {
    return {
      minOrderValue: 15,
      suburbs: [
        {
          id: idFactory.tiny(),
          name: '',
          deliveryCharges: 0,
          postcode: ''
        }
      ]
    };
  }

  getSchema(): Schema<DeliverySettings, DeliverySettings> {
    return deliverySettingsSchema;
  }

  getRepo(): DocumentRepo<StoreId, DeliverySettings> {
    return repo.deliverySettings;
  }

  nextRoute(): string {
    return '';
  }

  className() {
    return 'delivery-settings-widget';
  }

  form(deliverySettings: DeliverySettings): JSX.Element {
    return (
      <Form type="responsive" submit={{ label: 'Save', handler: this.submit }} validationResult={this.validation}>
        <FormNumberInput
          label={`Minimum order value`}
          value={deliverySettings.minOrderValue}
          onChange={value => (deliverySettings.minOrderValue = value)}
          validation={this.validation.properties.minOrderValue}
          helpText="The minimum order value for delivery orders"
          dataCy="delivery-minimum-order-value"
        />
        <FormItemContainer
          label="Delivery Areas"
          validation={this.validation.properties.suburbs}
          className="delivery-suburbs-form-item"
        >
          {this.renderDeliverySuburbs(deliverySettings.suburbs, this.validation.properties.suburbs)}
        </FormItemContainer>
      </Form>
    );
  }

  renderDeliverySuburbActions(suburb: DeliverySuburb, i: number, list: DeliverySuburb[]): React.ReactNode {
    return (
      <div className="actions">
        <div className="action" onClick={() => (this.document.suburbs = listUtils.remove(list, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  }

  renderDeliverySuburbInfo(
    deliverySuburb: DeliverySuburb,
    i: number,
    validation: Result<DeliverySuburb>
  ): React.ReactNode {
    return (
      <div className="info inline">
        <FormTextInput
          label={'Name'}
          value={deliverySuburb.name}
          onChange={value => (deliverySuburb.name = value)}
          validation={validation.properties.name}
          helpText="Enter the name of the delivery area"
          dataCy={`delivery-suburb-name-${i}`}
        />
        <FormTextInput
          label={'Postcode'}
          value={deliverySuburb.postcode || ''}
          onChange={value => (deliverySuburb.postcode = value)}
          validation={validation.properties.postcode || validationUtils.success}
          dataCy={`delivery-suburb-postcode-${i}`}
        />
        <FormNumberInput
          label={'Delivery Charges'}
          value={deliverySuburb.deliveryCharges}
          onChange={value => (deliverySuburb.deliveryCharges = value)}
          validation={validation.properties.deliveryCharges}
          dataCy={`delivery-suburb-charge-${i}`}
        />
        <FormSwitch
          label={'Variable Min Order Value'}
          helpText={`Set a minimum order value different from ${formatAmount(
            this.document.minOrderValue,
            restaurantProvider.$restaurant?.current()?.country
          )} for ${deliverySuburb.name}?`}
          {...optionalFlag(deliverySuburb, 'minOrderValue', 0)}
          dataCy={`delivery-suburb-var-mov-${i}`}
        />
        {!isNil(deliverySuburb.minOrderValue) && (
          <FormNumberInput
            label={'Minimum Order Value'}
            value={deliverySuburb.minOrderValue || 0}
            onChange={value => (deliverySuburb.minOrderValue = value)}
            validation={validation.properties.minOrderValue!}
            dataCy={`delivery-suburb-mov-${i}`}
          />
        )}
      </div>
    );
  }

  renderDeliverySuburb(suburb: DeliverySuburb, i: number, list: DeliverySuburb[], validation: Result<DeliverySuburb>) {
    return (
      <div className="delivery-suburb" key={suburb.id}>
        {this.renderDeliverySuburbInfo(suburb, i, validation)}
        {this.renderDeliverySuburbActions(suburb, i, list)}
      </div>
    );
  }

  renderDeliverySuburbsActions = (suburbs: DeliverySuburb[]) => {
    const onClick = () =>
      (this.document.suburbs = [...this.document.suburbs, { id: idFactory.tiny(), name: '', deliveryCharges: 0 }]);
    return (
      <div className="actions">
        <Button onClick={onClick} dataCy="delivery-add-area">
          Add Delivery Area
        </Button>
      </div>
    );
  };

  renderSuburbList(suburbs: DeliverySuburb[], validation: Result<DeliverySuburb[]>): React.ReactNode {
    return (
      <div className="list">
        {suburbs.map((suburb, i) => this.renderDeliverySuburb(suburb, i, suburbs, validation.items[i]))}
      </div>
    );
  }

  renderDeliverySuburbs(suburbs: DeliverySuburb[], validation: Result<DeliverySuburb[]>): React.ReactNode {
    if (!suburbs) return;
    return (
      <div className="delivery-suburbs">
        {this.renderSuburbList(suburbs, validation)}
        {this.renderDeliverySuburbsActions(suburbs)}
      </div>
    );
  }
}

type Props = {};
type RouteProps = StoreId & {};

export class DeliverySettingsPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Delivery Info';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <DeliverySettingsEditWidget documentId={this.props.match.params} />;
  }
}
