import { ContinueLink } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Page } from '../elements/Page';
import { userProvider } from '../provider/userProvider';
import { LoginWidget } from './LoginWidget';

type Props = {};
type RouteProps = {};

@observer
export class LoginPage extends Page<Props, RouteProps, ContinueLink> {
  getTitle(): string {
    return '';
  }
  renderActions(): React.ReactNode {
    return;
  }
  renderBody(): React.ReactNode {
    if (userProvider.loggedIn) {
      return <Redirect to={this.queryParams().continueLink} />;
    }
    return <LoginWidget />;
  }
}
