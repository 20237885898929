import { Schema } from 'nutso';
import { Comment } from '../models/base/Comment';

export const commentSchema: Schema<Comment> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    createdTime: {
      type: 'string'
    },
    value: {
      type: 'string',
      maxLength: 500
    }
  }
};
