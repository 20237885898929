import { Schema } from 'nutso';
import { PizzaSpec } from '../../models/item_spec/PizzaSpec';
import { itemBaseSpecSchema } from './itemBaseSpecSchema';
import { priceSpecSchema } from './priceSpecSchema';

export const pizzaSpecSchema: Schema<PizzaSpec> = {
  type: 'object',
  properties: {
    ...itemBaseSpecSchema.properties,
    sizes: {
      type: 'object',
      properties: {
        '/.*/': {
          type: 'object',
          properties: {
            halfAndHalfAvailable: {
              type: 'boolean'
            },
            price: priceSpecSchema
          }
        }
      }
    },
    removeToppings: {
      type: 'array',
      items: {
        type: 'string',
        minLength: 2,
        maxLength: 20
      }
    }
  }
};
