export const chunk = <T>(list: T[], size: number) => {
  // This prevents infinite loops
  if (size < 1) throw new Error('Size must be positive');

  const result = [];
  for (let i = 0; i < list.length; i += size) {
    result.push(list.slice(i, i + size));
  }

  return result;
};
