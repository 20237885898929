export enum CollectionType {
  pickup = 'pickup',
  delivery = 'delivery',
  dineIn = 'dineIn'
}

export const collectionTypes = [CollectionType.pickup, CollectionType.delivery, CollectionType.dineIn];
export const collectionTypeLabel: { [key in CollectionType]: string } = {
  pickup: 'Pickup',
  delivery: 'Delivery',
  dineIn: 'Dine In'
};
