import { createBrowserHistory } from 'history';
import { parse, stringify } from 'query-string';
import { QueryParams } from '@restoplus/core';

export const browserHistory = createBrowserHistory();

export const updateQueryParams = (params: QueryParams) => {
  // read existing params
  const search = browserHistory.location.search;
  if (!search) {
    browserHistory.push({
      search: `?${stringify(params)}`
    });
    return;
  }

  // add or replace new param
  const parsed = parse(search);
  const updated = { ...parsed, ...params };

  // update search param
  browserHistory.push({
    search: `?${stringify(updated)}`
  });
};

export const gotoPath = (link: string) => {
  browserHistory.push(link);
};
