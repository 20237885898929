import * as React from 'react';
import { FormItem } from './FormItem';
import { FormItemProps, FormItemContainer } from './FormItemContainer';

type Props = FormItemProps & {
  value: boolean;
  onChange: (value: boolean) => void;
  dataCy?: string;
};

export class FormSwitch extends React.Component<Props> {
  //
  render() {
    const { value, onChange, dataCy } = this.props;
    return (
      <FormItemContainer {...this.props} className="switch">
        <label className="form-switch">
          <input type="checkbox" checked={value} onChange={e => onChange(e.target.checked)} data-cy={dataCy} />
          <i></i>
        </label>
      </FormItemContainer>
    );
  }
}
