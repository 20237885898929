import { Category, CategoryId, categorySchema, gcsPaths, idFactory, sortOrder, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Form } from '../forms/Form';
import { FormImage } from '../forms/FormImage';
import { FormTextArea } from '../forms/FormTextArea';
import { FormTextInput } from '../forms/FormTextInput';
import { userProvider } from '../provider/userProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';

@observer
export class CategoryEditWidget extends DocumentEditWidget<CategoryId, Category> {
  //

  name(): string {
    return 'Category';
  }

  createDocument(): Category {
    return {
      id: idFactory.category(),
      name: '',
      description: '',
      sortOrder: sortOrder.get(0, 1),
      image: { id: idFactory.tiny(), path: '' }
    };
  }

  getWidgetNameForCssClass(): string {
    return 'category-edit-widget';
  }

  getRepo(): DocumentRepo<CategoryId, Category> {
    return repo.category;
  }

  getSchema(): Schema<Category> {
    return categorySchema;
  }

  nextRoute(): string {
    return routes.backoffice.listCategory.link(this.props.documentId, {});
  }

  getKey(): CategoryId {
    const { documentId: key, create } = this.props;
    // if create, get key from created doc
    if (create)
      return {
        restaurantId: key!.restaurantId,
        storeId: key!.storeId,
        categoryId: this.document.id
      };

    // get key from props
    return key!;
  }

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const category = this.document;
    const submitLabel = this.props.create ? 'Create Category' : 'Save Category';
    return (
      <Form type="responsive" submit={{ label: submitLabel, handler: this.submit }} validationResult={this.validation}>
        <FormImage
          label="Image"
          gcsFolder={gcsPaths.images.LogoImages({ uid: userProvider.uid! })}
          value={category.image}
          onChange={image => (category.image = image)}
          validation={this.validation.properties.image}
          dataCy="category-image"
        />
        <FormTextInput
          label="Name"
          value={category.name}
          onChange={value => (category.name = value)}
          validation={this.validation.properties.name}
          dataCy="category-name"
        />
        <FormTextArea
          label="Description"
          value={category.description}
          onChange={value => (category.description = value)}
          validation={this.validation.properties.description}
          dataCy="category-description"
        />
      </Form>
    );
  }
}
