import {
  ButtonType,
  Cart,
  collectionTimeService,
  CollectionType,
  collectionTypeLabel,
  dateUtils,
  displayNameSchema,
  formatAmount,
  idFactory,
  Item,
  OrderStatusCode,
  PaymentType,
  routes,
  StoreId
} from '@restoplus/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { validate } from 'nutso';
import * as React from 'react';
import { Alert } from '../../alert/Alert';
import { MobileUpdateWidget } from '../../auth/MobileUpdateWidget';
import { Button } from '../../elements/Button';
import { Page } from '../../elements/Page';
import { Form } from '../../forms/Form';
import { FormTextInput } from '../../forms/FormTextInput';
import { fb } from '../../integration/fb';
import { $stripe } from '../../integration/stripe';
import { customerCart } from '../../provider/customerCartProvider';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { userProvider } from '../../provider/userProvider';
import { repo } from '../../repo/repo';
import { asyncOperation } from '../../utils/asyncOperation';
import { gotoPath } from '../../utils/browserHistory';
import { promiseValue } from '../../utils/promiseValue';
import { remoteService } from '../../utils/remoteService';
import { validationUtils } from '../../utils/validationUtils';
import { CollectionTypeSelector } from '../widgets/CollectionTypeSelector';
import { DeliveryAddressWidget } from '../widgets/DeliveryAddressWidget';
import { CommentWidget } from '../widgets/CommentWidget';
import { Section, Amount, Body, Header, Title, TitleAddAction, TitleValue } from '../widgets/CheckoutElements';

type Props = {};
type RouteProps = StoreId & {};

@observer
export class CheckoutPage extends Page<Props, RouteProps> {
  //
  @observable
  placingOrder = false;

  @observable
  loadingStripe = false;

  @observable
  redirectingToStripe = false;

  @observable
  updatingDisplayName = false;

  @computed
  get busy() {
    return this.placingOrder || this.loadingStripe || this.redirectingToStripe;
  }

  @computed
  get displayNameValidation() {
    return validate(this.displayName, displayNameSchema);
  }

  onPageEnter() {
    this.reaction(
      () => ({ name: userProvider.displayName }),
      args => (this.displayName = args.name ?? '')
    );
  }

  @observable
  $store = repo.store.getCacheFirstObservablePromise(this.props.match.params);

  @observable
  $checkoutSettings = repo.checkoutSettings.bind(this.props.match.params);

  @observable
  $storeTimings = repo.storeTimings.bind(this.props.match.params);

  @observable
  showCollectionTimes = false;

  @observable
  showUpdateMobileNumber = false;

  @observable
  showUpdateDisplayName = false;

  @observable
  mobileNumber = '';

  @observable
  displayName = '';

  getTitle(): string {
    return 'Cart';
  }

  renderActions(): React.ReactNode {
    return <div></div>;
  }

  renderBody(): React.ReactNode {
    const loggedIn = userProvider.loggedIn;
    if (!loggedIn)
      return (
        <button
          onClick={() =>
            gotoPath(
              routes.website.onlineOrdering.login.link(this.props.match.params, {
                continueLink: routes.website.onlineOrdering.checkout.link(this.props.match.params, {})
              })
            )
          }
        >
          Login
        </button>
      );

    return (
      <div className="cart">
        {this.renderCollectionTypeSelector()} {/* how */}
        {this.renderCartComponents()}
      </div>
    );
  }

  renderCartComponents(): React.ReactNode {
    const items = customerCart.cartItems;
    if (!items.length) return this.renderEmptyCart();

    return (
      <>
        <div className="sections">
          {this.renderItems()} {/* what */}
          {this.renderCollectionTime()} {/* when */}
          {userProvider.uid && (
            <>
              {this.renderDeliveryAddress()} {/* where */}
              {this.renderComments()}
              {this.renderPayment()}
              {this.renderMobileNumber()}
              {this.renderDisplayName()}
            </>
          )}
          {this.renderAmounts()}
        </div>
        {this.renderPlaceOrderButton()}
      </>
    );
  }

  renderEmptyCart(): React.ReactNode {
    return <div>Cart empty!</div>;
  }

  renderAmounts(): React.ReactNode {
    return (
      <Section className="amounts">
        <Body>
          {this.renderSubTotal()}
          {this.renderDiscount()}
          {this.renderDeliveryCharges()}
          {this.renderCreditCardSurcharge()}
          {this.renderGrandTotal()}
        </Body>
      </Section>
    );
  }

  renderCollectionTime(): React.ReactNode {
    const collectionType = customerCart.collectionType;
    if (!collectionType || collectionType === CollectionType.dineIn) return null;
    return (
      <Section className="collection-time">
        <Header>
          <Title icon="la-clock">{`${collectionTypeLabel[collectionType]} Time`}</Title>
          {customerCart.collectionTime && (
            <TitleValue onClick={() => (this.showCollectionTimes = !this.showCollectionTimes)}>
              {customerCart.collectionTime}
            </TitleValue>
          )}
        </Header>
        {this.renderCollectionTimes(collectionType)}
      </Section>
    );
  }

  renderCollectionTimes(collectionType: CollectionType) {
    const storeTimings = this.$storeTimings.current();
    if (storeTimings === null)
      return (
        <Alert type="error">
          Store timings are not set! If you manage the restaurant,{' '}
          <a onClick={() => gotoPath(routes.backoffice.storeTimings.link(this.props.match.params, {}))}>click here</a>{' '}
          to update it
        </Alert>
      );
    const store = promiseValue(this.$store);
    if (!storeTimings || !store) return;
    const showCollectionTimes = this.showCollectionTimes || customerCart.collectionTime === undefined;
    if (customerCart.collectionTime && !showCollectionTimes) return;

    const collectionTimes = collectionTimeService.getCollectionTimes({
      now: dateUtils.now(),
      collectionType,
      store,
      storeTimings
    });
    const onClick = (collectionTime: string) => {
      customerCart.collectionTime = collectionTime;
      this.showCollectionTimes = false;
    };
    return (
      <div className="collection-times-wrapper">
        <div className="collection-times">
          {collectionTimes.map(collectionTime => (
            <div className="collection-time" onClick={() => onClick(collectionTime)} key={collectionTime}>
              {collectionTime}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderCollectionTypeSelector(): React.ReactNode {
    const store = promiseValue(this.$store);
    if (!store) return null;
    return <CollectionTypeSelector selected={customerCart.collectionType} store={store} />;
  }

  renderItems(): React.ReactNode {
    const items = customerCart.cartItems;
    if (!items.length) return <div>Cart is empty.</div>;
    return (
      <Section className="items">
        <Header>
          <Title icon="la-list-ul">Items</Title>
        </Header>
        <Body>{items.map((cartItem, i) => this.renderItem(cartItem, i))}</Body>
      </Section>
    );
  }

  renderItem(item: Item, i: number): React.ReactNode {
    const collectionType = customerCart.collectionType;
    if (!collectionType) return;

    return (
      <div className="cart-item" key={item.id}>
        <div className="header">
          <div className="name">{item.summary.name}</div>
          <div className="price">
            {formatAmount(
              (item.summary.price[collectionType] ?? 0) * item.summary.quantity,
              restaurantProvider.$restaurant?.current()?.country
            )}
          </div>
        </div>
        <div className="footer">
          <div className="summary">
            <pre>{item.summary.description}</pre>
          </div>
          <div className="quantity">
            <div className="dec icon" onClick={() => this.onRemoveItem(i, item.summary.quantity)}>
              <i className="las la-minus-circle" />
            </div>
            <div className="number">{item.summary.quantity}</div>
            <div className="inc icon" onClick={() => customerCart.incItem(i)}>
              <i className="las la-plus-circle"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onRemoveItem(i: number, quantity: number) {
    quantity === 1
      ? confirm('Do you wish to remove the item from the cart?') && customerCart.decItem(i)
      : customerCart.decItem(i);
  }

  renderComments(): React.ReactNode {
    const checkoutSettings = this.$checkoutSettings.current();
    const uid = userProvider.uid;
    if (!checkoutSettings || !checkoutSettings.hasComments || !uid) return null;
    return <CommentWidget restaurantId={{ restaurantId: this.props.match.params.restaurantId }} />;
  }

  renderDeliveryAddress(): React.ReactNode {
    const uid = userProvider.uid;
    if (!uid || customerCart.collectionType !== CollectionType.delivery) return;

    return (
      <DeliveryAddressWidget
        storeKey={{ storeId: this.props.match.params.storeId, restaurantId: this.props.match.params.restaurantId }}
      />
    );
  }

  renderPayment(): React.ReactNode {
    // if cash is accepted, then show this, else skip this
    const checkoutSettings = this.$checkoutSettings.current();
    const uid = userProvider.uid;
    // if cash not accepted, nothing to choose here, return
    if (!checkoutSettings || !checkoutSettings.hasCash || !uid) return null;
    return (
      <Section className="payment">
        <Header>
          <Title icon="la-wallet">Payment</Title>
        </Header>
        <Body>
          <div className="payment-types">
            <div className="cash payment-type" onClick={() => (customerCart.paymentType = PaymentType.cash)}>
              <input type="radio" readOnly checked={customerCart.paymentType === PaymentType.cash} />
              <span>Cash</span>
            </div>
            <div className="card payment-type" onClick={() => (customerCart.paymentType = PaymentType.card)}>
              <input type="radio" readOnly checked={customerCart.paymentType === PaymentType.card} />
              <span>Card / Apple Pay / Google Pay</span>
            </div>
            {this.renderPoweredByStripe()}
          </div>
        </Body>
      </Section>
    );
  }

  renderMobileNumber(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return null;
    return (
      <Section className="mobile-number">
        <Header>
          <Title icon="la-mobile">Mobile Number</Title>
          {!this.showUpdateMobileNumber && (
            <TitleValue onClick={() => (this.showUpdateMobileNumber = true)}>
              {parsePhoneNumberFromString(userProvider.phoneNumber ?? '')?.formatNational() ?? ''}
            </TitleValue>
          )}
        </Header>
        {this.renderUpdateMobileNumber()}
      </Section>
    );
  }

  renderDisplayName(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return null;
    return (
      <Section className="display-name">
        <Header>
          <Title icon="la-user">Name</Title>
          {!this.showUpdateDisplayName && (
            <TitleValue onClick={() => (this.showUpdateDisplayName = true)}>
              {userProvider.displayName ?? ''}
            </TitleValue>
          )}
        </Header>
        {this.renderUpdateDisplayName()}
      </Section>
    );
  }

  renderUpdateDisplayName(): React.ReactNode {
    const existingName = userProvider.displayName;
    if (existingName && !this.showUpdateDisplayName) return;

    return (
      <div className="update-display-name">
        <Form type="default" validationResult={validationUtils.success}>
          <FormTextInput
            label=""
            value={this.displayName ?? ''}
            onChange={value => (this.displayName = value)}
            helpText="We treat our customers with respect and call them by name 🙌🏻"
            validation={this.displayNameValidation}
          />
        </Form>
        <div className="actions">
          <Button
            onClick={() => this.onUpdateDisplayName()}
            validation={this.displayNameValidation}
            busy={this.updatingDisplayName}
          >
            Update Name
          </Button>
          {validate(userProvider.displayName, displayNameSchema).isValid && (
            <a onClick={() => (this.showUpdateDisplayName = false)}>Cancel</a>
          )}
        </div>
      </div>
    );
  }

  onUpdateDisplayName = async () => {
    await asyncOperation({
      fn: () => userProvider.user!.updateProfile({ displayName: this.displayName }),
      onSuccess: () => {
        userProvider.displayName = this.displayName;
        this.showUpdateDisplayName = false;
      },
      loadingMessage: `Updating name`,
      setBusyFlag: busy => (this.updatingDisplayName = busy)
    });
  };

  renderUpdateMobileNumber(): React.ReactNode {
    const existingNumber = userProvider.phoneNumber;
    if (existingNumber && !this.showUpdateMobileNumber) return;

    // number already exists, unlink first
    if (existingNumber) {
      const formattedMobileNumber = parsePhoneNumberFromString(existingNumber);
      return (
        <div className="unlink-mobile-number">
          <div className="message">
            Your registered mobile number is <b>{formattedMobileNumber?.formatNational()}</b>. Do you like to update it?
          </div>
          <div className="actions">
            <button onClick={() => this.onUnlinkMobileNumber()}>
              <span>Update Mobile</span>
            </button>
            <a onClick={() => (this.showUpdateMobileNumber = false)}>Cancel</a>
          </div>
        </div>
      );
    }

    return (
      <div className="update-mobile-number">
        <MobileUpdateWidget onSuccess={() => (this.showUpdateMobileNumber = false)} />
      </div>
    );
  }

  onUnlinkMobileNumber = async () => {
    await asyncOperation({
      fn: () => userProvider.user!.unlink(fb.auth.PhoneAuthProvider.PROVIDER_ID),
      loadingMessage: 'Updating mobile number',
      successMessage: 'Mobile number removed successfully',
      onSuccess: () => (userProvider.phoneNumber = null)
    });
  };

  // renderAddCreditCard(): React.ReactNode {
  //   const uid = userProvider.uid;
  //   if (!this.showAddCreditCard || !uid) return;
  //   return (
  //     <Elements stripe={$stripe} options={stripeElementOptions}>
  //       <ElementsConsumer>
  //         {({ stripe, elements }) => {
  //           return (
  //             <div className="add-credit-card">
  //               <Form type='default' validationResult={validationUtils.success}>
  //                 <CardElement options={stripeCardElementOptions} />
  //                 <Checkbox value={true} onChange={() => {}} label="Remember me" />
  //                 {this.renderPoweredByStripe()}
  //                 {/* <FormMeta
  //                   helpText="Payments processed using secure stripe payment gateway"
  //                   validation={validationUtils.success}
  //                 /> */}
  //               </Form>
  //               {/* <div className="actions">
  //                 <button onClick={() => this.onAddComment({ uid })}>
  //                   <img src="/images/add-icon.svg" />
  //                   <span>Add Credit Card</span>
  //                 </button>
  //                 <a onClick={() => (this.showAddCreditCard = false)}>Cancel</a>
  //               </div> */}
  //             </div>
  //           );
  //         }}
  //       </ElementsConsumer>
  //     </Elements>
  //   );
  // }

  renderPoweredByStripe = () => {
    return (
      <div className="powered-by-stripe">
        <div className="logo">
          <img src="/images/stripe-logo.svg" alt="Stripe Logo" />
        </div>
        <div className="description">Payments processed using secure and PCI compliant Stripe payment gateway.</div>
      </div>
    );
  };

  renderSavedCreditCards(): React.ReactNode {
    return null;
    // return <div className="saved-credit-cards payment-type"></div>;
  }

  renderSubTotal(): React.ReactNode {
    return <Amount className="sub-total" label="Sub-total" value={customerCart.itemTotal} />;
  }

  renderDiscount(): React.ReactNode {
    return <Amount className="discount" label="Discount" value={customerCart.discount} />;
  }

  renderDeliveryCharges(): React.ReactNode {
    return <Amount className="delivery-charges" label="Delivery" value={customerCart.deliveryCharges} />;
  }

  renderCreditCardSurcharge(): React.ReactNode {
    return (
      <Amount
        className="credit-card-surcharge"
        label="Credit Card Surcharge"
        value={customerCart.creditCardSurcharge}
      />
    );
  }

  renderGrandTotal(): React.ReactNode {
    return <Amount className="grand-total" label="Total" value={customerCart.grandTotal} />;
  }

  renderPlaceOrderButton(): React.ReactNode {
    const cart = customerCart.cart;
    const label = customerCart.paymentType === 'cash' ? 'Place Order' : 'Pay Now';
    const validationError = customerCart.validationError;
    if (validationError || !cart) {
      return (
        <Button
          busy={this.busy}
          type={ButtonType.primary}
          className="place-order disabled"
          onClick={() => alert(validationError ?? `Your cart is invalid.`)}
        >
          {label}
        </Button>
      );
    }
    return (
      <Button
        type={ButtonType.primary}
        className="place-order"
        busy={this.busy}
        onClick={() => confirm(`Are you sure?`) && this.onPlaceOrder(toJS(cart))}
      >
        {label}
      </Button>
    );
  }

  onPlaceOrder = async (cart: Cart) => {
    // place order
    const order = await asyncOperation({
      fn: () => remoteService.placeOrder({ ...this.props.match.params, cart }),
      loadingMessage: `Placing order ...`,
      setBusyFlag: busy => (this.placingOrder = busy)
    });

    switch (order.status.code) {
      case OrderStatusCode.processingPayment: {
        const stripe = await asyncOperation({
          fn: () => $stripe,
          loadingMessage: `Loading Stripe payments ...`,
          setBusyFlag: busy => (this.loadingStripe = busy)
        });

        if (!stripe) {
          alert(`Cannot find stripe object. Please try again.`);
          return;
        }

        const redirectError = await asyncOperation({
          fn: () => stripe.redirectToCheckout({ sessionId: order.payment!.stripe.sessionId }),
          loadingMessage: `Redirecting to Stripe, please wait ...`,
          setBusyFlag: busy => (this.redirectingToStripe = busy)
        });

        alert(redirectError.error.message);
        return;
      }
      case OrderStatusCode.new: {
        gotoPath(
          routes.website.onlineOrdering.secure.orderSuccess.link({ ...this.props.match.params, orderId: order.id }, {})
        );
        return;
      }
      case OrderStatusCode.failure: {
        alert(order.status.message);
        return;
      }
      case OrderStatusCode.accepted:
      case OrderStatusCode.rejected:
      case OrderStatusCode.cancelled:
      case OrderStatusCode.completed:
      default: {
        alert(`Unexpected. Status: ${order.status.code}`);
        return;
      }
    }
  };
}
