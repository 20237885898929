import * as React from 'react';
import { LoginWidget } from './LoginWidget';

type Props = {};

export class AuthPage extends React.Component<Props> {
  render() {
    return (
      <div id="auth-layout">
        <div className="nav-bar">
          <img src="/images/logo-black.svg" alt="Restoplus Logo" />
        </div>
        <div className="contents">
          <LoginWidget />
        </div>
      </div>
    );
  }
}
