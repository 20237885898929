import React = require('react');
import { Result } from 'nutso';

export type TextInputProps = {
  type?: 'password';
  value: string;
  onChange: (value: string) => void;
  validation: Result<string>;
  disabled?: boolean;
  placeholder?: string;
  dataCy?: string;
};

export const TextInput = (props: TextInputProps) => {
  return (
    <input
      type={props.type || 'text'}
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      className={props.validation.isValid ? 'valid' : 'invalid'}
      disabled={props.disabled}
      placeholder={props.placeholder}
      data-cy={props.dataCy}
    />
  );
};
