import { RestaurantId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Page } from '../../elements/Page';
import { StoreSelectWidget } from '../../store/StoreSelectWidget';
import React = require('react');

type Props = {};
type RouteProps = RestaurantId & {};

@observer
export class RestaurantWebsitePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Stores';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    return (
      <div className={'restaurant-website-page'}>
        <StoreSelectWidget restaurantId={this.props.match.params.restaurantId} />
      </div>
    );
  }
}
