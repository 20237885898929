export const undef2null = (obj: any) => {
  if (!obj) return;
  for (let k in obj) {
    if (obj[k] && typeof obj[k] === 'object') {
      undef2null(obj[k]);
    } else {
      if (obj[k] === undefined) obj[k] = null;
    }
  }
};
