import { Hours } from '../..';
import { CollectionType } from '../base/CollectionType';
import { Dow } from '../base/Dow';

export enum BusinessSession {
  session1 = 'session1',
  session2 = 'session2'
}

export const businessSessions: BusinessSession[] = [BusinessSession.session1, BusinessSession.session2];

export type SessionTimings = {
  available: boolean;
  hours: Hours;
};

export type StoreTimings = {
  [dow in Dow]: {
    [collectionType in CollectionType]: {
      [session in BusinessSession]: SessionTimings;
    };
  };
};
