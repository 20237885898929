import { routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { Widget } from '../../elements/Widget';
import { StoreWebsitePage } from '../pages/StoreWebsitePage';
import { StoreWebsiteHeader } from './StoreWebsiteHeader';

type Props = {};

@observer
export class StoreWebsiteLayout extends Widget<Props> {
  render() {
    return (
      <div className="store-website">
        <StoreWebsiteHeader />
        <Route path={routes.website.store.template} component={StoreWebsitePage} />
      </div>
    );
  }
}
