import { UserRestaurant } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { ResolveResource } from '../elements/ResolveResource';
import { RestaurantListWidget } from '../restaurant/RestaurantListWidget';
import { userRestaurantsProvider } from '../provider/userRestaurantsProvider';

type Props = {};

@observer
export class UserRestaurantListWidget extends React.Component<Props> {
  //
  $list = userRestaurantsProvider.bindRestaurants;

  renderList = (list: UserRestaurant[]) => {
    if (!list.length) {
      return <Alert type="info">List is empty</Alert>;
    }

    return <RestaurantListWidget restaurantIds={list.map(userRestaurant => userRestaurant.restaurantId)} />;
  };

  render() {
    if (!this.$list) return null;
    return (
      <div className="user-restaurant-list">
        <ResolveResource resource={this.$list}>{list => this.renderList(list)}</ResolveResource>
      </div>
    );
  }
}
