import { Env } from '@restoplus/core';

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

// oauth2 client ID required for one-tap login
type WebConfig = FirebaseConfig & {
  serverUrl: string;
  stripePk: string;
  env: Env;
  googleOAuth2ClientId: string;
  cypress: boolean;
};

const localConfig: WebConfig = {
  apiKey: 'AIzaSyDpiLjncaRXCN5aslCCgsnUqD7eieUGNzc',
  authDomain: 'restoplus-dev.firebaseapp.com',
  databaseURL: 'http://localhost:8081',
  projectId: 'restoplus-dev',
  storageBucket: 'restoplus-dev.appspot.com',
  messagingSenderId: '368677054390',
  appId: '1:368677054390:web:b3b5d55cf38d0dea8d0317',
  measurementId: 'G-VTP1BF2FRV',
  serverUrl: 'http://localhost:8080',
  stripePk: 'pk_test_smfeHpArmHVYrPonu8a4G57D',
  env: 'local',
  googleOAuth2ClientId: '368677054390-gsmknu8r6r0dh7q6ct9mpjp30gd7i6af.apps.googleusercontent.com',
  cypress: (window as any).Cypress ? true : false
};

const devConfig: WebConfig = {
  apiKey: 'AIzaSyDpiLjncaRXCN5aslCCgsnUqD7eieUGNzc',
  authDomain: 'restoplus-dev.firebaseapp.com',
  databaseURL: 'https://restoplus-dev.firebaseio.com',
  projectId: 'restoplus-dev',
  storageBucket: 'restoplus-dev.appspot.com',
  messagingSenderId: '368677054390',
  appId: '1:368677054390:web:b3b5d55cf38d0dea8d0317',
  measurementId: 'G-VTP1BF2FRV',
  serverUrl: 'https://restoplus-dev.web.app',
  stripePk: 'pk_test_smfeHpArmHVYrPonu8a4G57D',
  env: 'dev',
  googleOAuth2ClientId: '368677054390-gsmknu8r6r0dh7q6ct9mpjp30gd7i6af.apps.googleusercontent.com',
  cypress: false
};

const prodConfig: WebConfig = {
  apiKey: 'AIzaSyAmNF1T7ZeDRo_vk9b709LEpkcfpz0IkY0',
  authDomain: 'restoplus-prod.firebaseapp.com',
  databaseURL: 'https://restoplus-prod.firebaseio.com',
  projectId: 'restoplus-prod',
  storageBucket: 'restoplus-prod.appspot.com',
  messagingSenderId: '173549373837',
  appId: '1:173549373837:web:a251027dea313657b515c4',
  measurementId: 'G-N0NV2HT0ZL',
  serverUrl: 'https://app.restoplus.co',
  stripePk: 'pk_live_LUvQZvrwhFDJCQC5MvEVwENO',
  env: 'prod',
  // googleOAuth2ClientId: '173549373837-c5r32691gv8brkijq72qsqcm7er1m0so.apps.googleusercontent.com'
  googleOAuth2ClientId: '173549373837-0biqvf4sokpudkrtsphamc15tjeu5emd.apps.googleusercontent.com',
  cypress: false
};

export const PROD_HOSTNAMES = ['app.restoplus.co'];

const hostname = window.location.hostname;
const isLocal = hostname.includes('localhost') ? true : false;
const isProd = PROD_HOSTNAMES.includes(hostname) ? true : false;

export const webConfig = (function() {
  if (isLocal) {
    console.log(`*** LOCAL Mode ***`);
    return localConfig;
  }
  if (isProd) {
    console.log(`*** PRODUCTION Mode ***`);
    return prodConfig;
  }
  console.log(`*** DEV Mode ***`);
  return devConfig;
})();
