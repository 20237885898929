import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormItem } from './FormItem';
const ClickOutHandler = require('react-onclickout');

type Props<T> = {
  options: T[];
  optionKey: (t: T) => string;
  optionLabel: (t: T) => string;
  disabled?: boolean;
  //
  value: T[] | null;
  onChange: (value: T[]) => void;
};

@observer
export class FormMultiSelect<T> extends FormItem<Props<T>> {
  //
  @observable open = false;

  getItemForKey = (itemKey: string) => {
    const { options, optionKey } = this.props;
    return options.filter(option => optionKey(option) === itemKey)!;
  };

  getSelectedKeys = () => {
    const { optionKey, value } = this.props;
    if (!value) return [];
    return value.map(item => optionKey(item));
  };

  onClick = (clickedItem: T) => {
    const { options, value, optionKey, onChange } = this.props;
    const clickedKey = optionKey(clickedItem);
    const selectedKeys = this.getSelectedKeys();
    // if key is already selected, remove from selected
    if (selectedKeys.includes(clickedKey)) {
      const keysWithoutItemClicked = selectedKeys.filter(k => k !== clickedKey);
      const items = options.filter(option => keysWithoutItemClicked.includes(optionKey(option)));
      onChange(items);
      return;
    }

    // key is being selected, add to selected
    const selectedItem = options.find(option => optionKey(option) === clickedKey);
    return onChange([...(value || []), selectedItem!]);
  };

  isSelected = (item: T) => {
    const { optionKey } = this.props;
    return this.getSelectedKeys().includes(optionKey(item));
  };

  renderSelectedItems = () => {
    const { optionLabel, optionKey, value } = this.props;
    if (!value || !value.length) return <span>&nbsp;</span>;
    return value.map(item => <div key={optionKey(item)}>{optionLabel(item)}</div>);
  };

  renderInputBox = () => {
    const { validation } = this.props;
    return (
      <div className={`input-box ${validation.isValid ? 'valid' : 'invalid'}`} onClick={() => (this.open = !this.open)}>
        {this.renderSelectedItems()}
      </div>
    );
  };

  renderIcon = (selected: boolean) => {
    const icon = selected ? 'la-check-square' : 'la-stop';
    return <i className={`icon las ${icon}`} />;
  };

  renderOption = (item: T, index: number) => {
    const { optionKey, optionLabel } = this.props;
    const selected = this.isSelected(item);
    return (
      <div
        key={optionKey(item)}
        onClick={e => {
          this.onClick(item);
        }}
        className={`option ${selected ? 'selected' : ''}`}
      >
        {this.renderIcon(selected)}
        {optionLabel(item)}
      </div>
    );
  };

  renderOptions = () => {
    if (!this.open) return <div></div>;
    const { options } = this.props;
    return (
      <div className="overlay">
        <ClickOutHandler onClickOut={() => (this.open = false)}>
          <div className="options-wrapper">
            <div className="options">{options.map((option, index) => this.renderOption(option, index))}</div>
          </div>
        </ClickOutHandler>
      </div>
    );
  };

  // render selection box
  renderValue(): JSX.Element {
    return (
      <div className="form-select multiple">
        {this.renderInputBox()}
        {this.renderOptions()}
      </div>
    );
  }
}
