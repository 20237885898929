import { ButtonType } from '@restoplus/core';
import { Result } from 'nutso';
import * as React from 'react';
import { validationUtils } from '../utils/validationUtils';

type Props = {
  testId?: string;
  children: string;
  onClick: () => void;
  icon?: string;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
  validation?: Result<any>;
  busy?: boolean;
  busyLabel?: string;
  _ref?: (ref: HTMLButtonElement | null) => void;
  dataCy?: string;
};

export class Button extends React.Component<Props> {
  //
  renderIcon = () => {
    const { icon, busy } = this.props;
    if (busy) {
      return <i className="spin icon las la-circle-notch"></i>;
    }
    if (!icon) return;
    return <i className={`icon las ${icon}`}></i>;
  };

  render() {
    const {
      children,
      onClick,
      disabled,
      className,
      validation = validationUtils.success,
      testId,
      type,
      busy,
      busyLabel,
      _ref,
      dataCy
    } = this.props;
    const _disabled = disabled || !validation.isValid || busy;
    return (
      <button
        id={testId}
        disabled={_disabled}
        className={`${className ?? ''} ${_disabled ? 'disabled' : ''} ${type ?? ''}`}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        ref={_ref}
        data-cy={dataCy}
      >
        {this.renderIcon()}
        <span className="label">{busy ? busyLabel ?? children : children}</span>
      </button>
    );
  }
}
