import { PrinterId } from '@restoplus/core';
import { Page } from '../../elements/Page';
import { PrinterEditWidget } from '../../printer/PrinterEditWidget';
import React = require('react');
import { PrinterQueueWidget } from '../../printer/PrinterQueueWidget';

type Props = {};

export class PrinterPage extends Page<Props, PrinterId> {
  //
  getTitle(): string {
    return 'Printer Info';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  getAdditionalClassNames() {
    return 'printer-page';
  }
  renderBody(): React.ReactNode {
    return (
      <>
        <div>
          <PrinterEditWidget documentId={this.props.match.params} create={false} />
        </div>
        <div>
          <PrinterQueueWidget printerId={this.props.match.params.printerId} />
        </div>
      </>
    );
  }
}
