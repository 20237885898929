import { AspectRatio } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Imgix } from '../../elements/Imgix';
import { restaurantProvider } from '../../provider/restaurantProvider';

@observer
export class RestaurantWebsiteHeader extends React.Component {
  //
  render() {
    return (
      <header>
        <div className="wrapper">{this.renderRestaurant()}</div>
      </header>
    );
  }
  renderRestaurant(): React.ReactNode {
    const restaurant = restaurantProvider.$restaurant?.current();
    if (!restaurant) return;

    return (
      <div className="restaurant-header-widget">
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="name">{restaurant.name}</div>
      </div>
    );
  }
}
