import { Schema } from 'nutso';
import { ItemSummary } from '../../models/item/ItemSummary';

export const itemSummarySchema: Schema<ItemSummary> = {
  type: 'object',
  properties: {
    name: {
      type: 'string'
    },
    price: {
      type: 'object',
      properties: {
        pickup: {
          type: 'number',
          optional: true
        },
        delivery: {
          type: 'number',
          optional: true
        },
        dineIn: {
          type: 'number',
          optional: true
        }
      }
    },
    quantity: {
      type: 'number'
    },
    description: {
      type: 'string',
      optional: true
    }
  }
};
