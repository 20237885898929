import { observer } from 'mobx-react';
import * as React from 'react';
import { storeProvider } from '../../provider/storeProvider';
import { StoreHeaderWidget } from '../../store/StoreHeaderWidget';

@observer
export class StoreWebsiteHeader extends React.Component {
  //
  render() {
    const storeId = storeProvider.storeId;
    if (!storeId) return null;
    return (
      <header>
        <div className="wrapper">
          <StoreHeaderWidget {...storeId} />
        </div>
      </header>
    );
  }
}
