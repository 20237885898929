import { Schema } from 'nutso';
import { CheckoutSettings } from '../models/order/CheckoutSettings';

export const checkoutSettingsSchema: Schema<CheckoutSettings> = {
  type: 'object',
  properties: {
    hasCollectionTime: {
      type: 'boolean'
    },
    hasComments: {
      type: 'boolean'
    },
    hasCoupons: {
      type: 'boolean'
    },
    hasCash: {
      type: 'boolean'
    },
    creditCardSurcharge: {
      type: 'object',
      optional: true,
      properties: {
        amount: {
          type: 'number',
          min: 0,
          max: 10
        },
        percentage: {
          type: 'number'
        },
        applicableBelowAmount: {
          type: 'number'
        }
      }
    }
  }
};
