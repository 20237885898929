import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormItem } from './FormItem';
const ClickOutHandler = require('react-onclickout');

export type FormSingleSelectProps<T> = {
  options: T[];
  optionKey: (t: T) => string;
  optionLabel: (t: T) => string;
  value: T;
  onChange: (value: T) => void;
  disabled?: boolean;
  dataCy?: string;
};

@observer
export class FormSingleSelect<T> extends FormItem<FormSingleSelectProps<T>> {
  //
  @observable open = false;

  getSelected = (selectedKey: string) => {
    const { options, optionKey: key } = this.props;
    return options.find(option => key(option) === selectedKey)!;
  };

  isSelected = (selectedKey: string) => {
    const { value, optionKey } = this.props;
    return optionKey(value) === selectedKey;
  };

  // render selected value
  renderSelectedValue = () => {
    const { optionLabel, value } = this.props;
    const onClick = () => {
      if (this.props.disabled) return;
      this.open = !this.open;
    };
    return (
      <div className="input-box" onClick={onClick}>
        {optionLabel(value) || <span>&nbsp;</span>}
      </div>
    );
  };

  renderIcon = (selected: boolean) => {
    const icon = selected ? 'la-check-circle' : 'la-circle';
    return <i className={`icon las ${icon}`} />;
  };

  renderOption = (item: T, index: number) => {
    const { optionKey, optionLabel, onChange } = this.props;
    const selected = this.isSelected(optionKey(item));
    const onClick = () => {
      onChange(item);
      this.open = false;
    };
    return (
      <div key={optionKey(item)} onClick={onClick} className={`option ${selected ? 'selected' : ''}`}>
        {this.renderIcon(selected)}
        {optionLabel(item)}
      </div>
    );
  };

  renderOptions = () => {
    if (!this.open) return <div></div>;
    const { options } = this.props;
    return (
      <div className="overlay">
        <ClickOutHandler onClickOut={() => (this.open = false)}>
          <div className="options-wrapper">
            <div className="options">{options.map((option, index) => this.renderOption(option, index))}</div>
          </div>
        </ClickOutHandler>
      </div>
    );
  };

  // render selection box
  renderValue(): JSX.Element {
    return (
      <div className={`form-select single ${this.props.disabled && 'disabled'}`} data-cy={this.props.dataCy}>
        {this.renderSelectedValue()}
        {this.renderOptions()}
      </div>
    );
  }
}
