import { snakeCase } from './snakeCase';

export const testId = (_prefix: string, _label: string, _suffix: string = '') => {
  const prefix = snakeCase(_prefix);
  const label = snakeCase(_label);
  const suffix = snakeCase(_suffix);

  if (suffix) return `${prefix}_${label}_${suffix}`;

  return `${prefix}_${label}`;
};
