import {
  countries,
  dateUtils,
  gcsPaths,
  idFactory,
  Restaurant,
  RestaurantId,
  restaurantSchema,
  routes
} from '@restoplus/core';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Form } from '../forms/Form';
import { FormImage } from '../forms/FormImage';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { FormTextInput } from '../forms/FormTextInput';
import { userProvider } from '../provider/userProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { toastStore } from '../toast/toastStore';
import { webConfig } from '../utils/webConfig';

@observer
export class RestaurantEditWidget extends DocumentEditWidget<RestaurantId, Restaurant> {
  //

  name(): string {
    return 'Restaurant';
  }

  createDocument(): Restaurant {
    const id = webConfig.cypress ? 'restaurant_1' : idFactory.restaurant();
    return {
      id,
      name: '',
      country: 'AU',
      description: '',
      logo: { id: idFactory.tiny(), path: '' },
      timezone: '',
      creatorUid: userProvider.user!.uid,
      editorUid: userProvider.user!.uid,
      createdTime: dateUtils.time(),
      editedTime: dateUtils.time()
    };
  }

  getWidgetNameForCssClass(): string {
    return 'restaurant-edit-widget';
  }

  getRepo(): DocumentRepo<RestaurantId, Restaurant> {
    return repo.restaurant;
  }

  getSchema(): Schema<Restaurant> {
    return restaurantSchema;
  }

  nextRoute(): string {
    return routes.backoffice.home.link({}, {});
  }

  getKey(): RestaurantId {
    const { documentId: key, create } = this.props;
    // if create, get key from created doc
    if (create)
      return {
        restaurantId: this.document.id
      };

    // get key from props
    return key!;
  }

  renderTimezone = () => {
    if (!this.document.country) return null;
    const country = countries[this.document.country];
    return (
      <FormSingleSelect
        label="Timezone"
        options={country.timezones}
        value={this.document.timezone}
        onChange={selected => (this.document.timezone = selected)}
        optionKey={timezone => timezone}
        optionLabel={timezone => timezone}
        validation={this.validation.properties.timezone}
        dataCy="restaurant-timezone"
      />
    );
  };

  onSubmit = () => {
    // usually create takes some time
    if (this.props.create) toastStore.info(`Your restaurant is being created, please wait for at least 10 seconds...`);
    this.submit();
  };

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const submitLabel = this.props.create ? 'Create Restaurant' : 'Save Restaurant';
    return (
      <Form
        type="responsive"
        submit={{ label: submitLabel, handler: this.onSubmit }}
        validationResult={this.validation}
      >
        <FormImage
          label="Logo"
          gcsFolder={gcsPaths.images.LogoImages({ uid: userProvider.uid! })}
          value={this.document.logo}
          onChange={image => (this.document.logo = image)}
          validation={this.validation.properties.logo}
        />
        <FormTextInput
          label="Name"
          value={this.document.name}
          onChange={value => (this.document.name = value)}
          validation={this.validation.properties.name}
          helpText="What do you want to call your restaurant?"
          dataCy="restaurant-name"
        />
        <FormTextInput
          label="Description"
          value={this.document.description}
          onChange={value => (this.document.description = value)}
          validation={this.validation.properties.description}
          dataCy="restaurant-description"
        />
        <FormSingleSelect
          label="Country"
          options={Object.values(countries)}
          value={countries[this.document.country]}
          onChange={selected => (this.document.country = selected.code)}
          optionKey={country => country.code}
          optionLabel={country => country.name}
          disabled={!this.props.create}
          validation={this.validation.properties.country}
          dataCy="restaurant-country"
        />
        {this.renderTimezone()}
      </Form>
    );
  }
}
