import { tryCatch } from '@restoplus/core';
import { User } from 'firebase';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Alert } from '../alert/Alert';
import { Button } from '../elements/Button';
import { CountryFlag } from '../elements/CountryFlag';
import { Form } from '../forms/Form';
import { FormIconTextInput } from '../forms/FormIconTextInput';
import { FormTextInput } from '../forms/FormTextInput';
import { fb } from '../integration/fb';
import { restaurantProvider } from '../provider/restaurantProvider';
import { userProvider } from '../provider/userProvider';
import { asyncOperation } from '../utils/asyncOperation';
import { validationUtils } from '../utils/validationUtils';
import { webConfig } from '../utils/webConfig';
import React = require('react');

type Props = {
  onSuccess: Function;
};

enum Stage {
  enterMobile,
  enterOtp,
  duplicateMobile // number registered with another user
}

const RECAPTCHA_CONTAINER = 'recaptcha-container';
const RECAPTCHA_CONTAINER_WRAPPER = 'recaptcha-container-wrapper';

@observer
export class MobileUpdateWidget extends React.Component<Props> {
  //
  @observable stage: Stage = Stage.enterMobile;
  @observable mobileLocal = '';
  @observable verificationId = '';
  @observable otp = '';

  @observable recaptchaSolved = false;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  @observable requestingOtp = false;
  @observable verifyingOtp = false;

  mobileInternational = () => {
    const parsed = tryCatch(
      () => parsePhoneNumber(this.mobileLocal, restaurantProvider.$restaurant?.current()?.country),
      e => {
        alert(`Please check your mobile number. Error: ${e.message}`);
      }
    );
    return parsed?.number as string | undefined;
  };

  updateMobile = (mobile: string) => {
    const _mobile = new AsYouType(restaurantProvider.$restaurant?.current()?.country).input(mobile);
    this.mobileLocal = _mobile.replace(/[()]/g, '').replace(/[\s]$/g, '');
  };

  componentDidMount() {
    // disable recaptcha
    if (webConfig.env === 'local') fb.auth().settings.appVerificationDisabledForTesting = true;
    this.initRecaptchaVerifier();
  }

  initRecaptchaVerifier = () => {
    try {
      this.recaptchaVerifier = new fb.auth.RecaptchaVerifier(RECAPTCHA_CONTAINER, {
        size: 'invisible',
        callback: () => {
          console.log(`Recaptcha solved`);
          this.recaptchaSolved = true;
          return new Promise(function(resolve, reject) {
            resolve();
          });
        },
        expiredCallback: function() {
          console.log(`Recaptcha expired.`);
        },
        errorCallback: function() {
          console.log(`Recaptcha error.`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderMobileNumber(): React.ReactNode {
    return (
      <FormIconTextInput
        label="Mobile Number"
        value={this.mobileLocal}
        onChange={this.updateMobile}
        validation={validationUtils.success}
        disabled={this.stage !== Stage.enterMobile}
        icon={<CountryFlag countryCode={restaurantProvider.$restaurant?.current()?.country || 'AU'} />}
        helpText="You have to verify your mobile number only once and it's super quick!"
      />
    );
  }

  renderOtp(): React.ReactNode {
    return (
      <FormTextInput
        label="SMS Code"
        value={this.otp}
        onChange={value => (this.otp = value)}
        validation={validationUtils.success}
        disabled={this.requestingOtp}
        helpText="Please check your SMS for the 6 digit verification code. It might take upto 15 seconds."
      ></FormTextInput>
    );
  }

  render() {
    if (!userProvider.user) return null;
    return <div className="mobile-update-widget">{this.renderBody(userProvider.user)}</div>;
  }

  renderBody(user: User) {
    switch (this.stage) {
      case Stage.enterMobile:
        return this.renderEnterMobile(user);
      case Stage.enterOtp:
        return this.renderEnterOtp(user);
      case Stage.duplicateMobile:
        return this.renderDuplicateMobile(user);
    }
  }

  renderEnterMobile(user: User) {
    return (
      <>
        <Form type="default" validationResult={validationUtils.success}>
          {this.renderMobileNumber()}
        </Form>
        <div className="actions">
          <Button onClick={this.onRequestOtp} busy={this.requestingOtp}>
            Verify
          </Button>
        </div>
      </>
    );
  }

  renderEnterOtp(user: User) {
    return (
      <>
        <Form type="default" validationResult={validationUtils.success}>
          {this.renderMobileNumber()}
          {this.renderOtp()}
        </Form>
        <div className="actions">
          <Button onClick={this.onVerifyOtp} busy={this.verifyingOtp}>
            Verify
          </Button>
          <a onClick={this.onResetForm}>Reset</a>
        </div>
      </>
    );
  }

  renderDuplicateMobile(user: User) {
    return (
      <>
        <Alert type="warning">
          <b>{this.mobileLocal}</b> is already registered with a different email. Please use a another mobile number or
          login with the email registered with this mobile number.
        </Alert>
        <div className="actions">
          <button onClick={this.onResetForm}>
            <span>Use Different Mobile</span>
          </button>
          <a onClick={() => userProvider.logout()}>Logout</a>
        </div>
      </>
    );
  }

  onResetForm = () => {
    this.clearRecaptchaVerifier();
    this.initRecaptchaVerifier();
    this.mobileLocal = '';
    this.otp = '';
    this.stage = Stage.enterMobile;
  };

  onRequestOtp = async () => {
    const provider = new fb.auth.PhoneAuthProvider();
    const mobileInternational = this.mobileInternational();
    if (!mobileInternational) {
      alert(`Cannot validate mobile number`);
      return;
    }

    try {
      this.verificationId = await asyncOperation({
        fn: () => provider.verifyPhoneNumber(mobileInternational as string, this.recaptchaVerifier),
        loadingMessage: `Requesting verification code`,
        successMessage: `Verification code sent via. SMS`,
        onSuccess: () => {
          this.stage = Stage.enterOtp;
        },
        setBusyFlag: busy => (this.requestingOtp = busy)
      });
    } catch (e) {
      switch (e.code) {
        case 'auth/captcha-check-failed':
          alert('Captcha check failed, please try again.');
          return;
        case 'auth/invalid-phone-number':
          alert('Please check your mobile number');
          return;
        case 'auth/missing-phone-number':
          alert('Mobile number is missing');
          return;
        case 'auth/quota-exceeded':
          alert('Quota exceeded, please try again after some time!');
          return;
        case 'auth/user-disabled':
          alert('User disabled. Please contact the store for more details.');
          return;
      }
      console.error(e);
      alert('Error verifying phone number. Please try again.');
    }
  };

  onVerifyOtp = async () => {
    const credential = fb.auth.PhoneAuthProvider.credential(this.verificationId, this.otp);
    const user = userProvider.user!;

    try {
      await asyncOperation({
        fn: () => user.updatePhoneNumber(credential),
        onSuccess: () => {
          userProvider.phoneNumber = this.mobileInternational();
          this.props.onSuccess();
          this.clearRecaptchaVerifier();
        },
        onComplete: () => {},
        loadingMessage: `Verifying sms code ...`,
        successMessage: `Mobile number updated.`,
        setBusyFlag: busy => (this.verifyingOtp = busy)
      });
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-verification-code':
          // Commented to prevent two pop up alert when entering a wrong sms verification code.
          // alert(`Invalid verification code. Please try again.`);
          this.otp = '';
          return;
        case 'auth/invalid-verification-id':
          alert(`Invalid verification ID. Please refresh the page.`);
          return;
        case 'auth/credential-already-in-use':
          this.stage = Stage.duplicateMobile;
          return;
      }
      console.log(e);
    }
  };

  clearRecaptchaVerifier = () => {
    this.recaptchaVerifier.clear();
    document.getElementById(RECAPTCHA_CONTAINER_WRAPPER)!.innerHTML = `<div id="${RECAPTCHA_CONTAINER}"></div>`;
  };
}
