/**
 * We need our ids to be short enough and also be distributed over time.
 * We also need out ids to be neat, with just lowercase and numbers. Easy to select and to look good in the URLs.
 *
 * All ids should have a space and time component
 */
const space = () => Math.floor(Math.random() * 100000).toString(36); // moves randomly in space (within the space of a second)
const time = () => Math.floor(Date.now() / 1000).toString(36); // moves forward every second

// space should always come first, otherwise we will endup in sequential ids
const id = () => `${space()}${time()}`;

// for printers we simply need a number
// prettier-ignore
const printerId = () => Math.random().toString(10).substr(2, 6);

export const idFactory = {
  id,
  restaurant: id,
  store: id,
  order: id,
  category: id,
  loyaltyCard: id,
  itemId: id,
  pizzaSizeId: id,
  modifierId: id,
  dealItemId: id,
  toppingId: id,
  tiny: id,
  printerId,
  fileId: (args: { extension: string }) => `${id()}.${args.extension}`,
  loyaltyCardIdFromOrderId: (args: { orderId: string }) => `${args.orderId}`,
  messageIdForLoyaltyReward: (args: { orderId: string }) => `lr_${args.orderId}`
};
