import { DateTime } from 'luxon';
import { dateUtils } from './dateUtils';
import { DateRangePreset } from '../models/base/DateRangePreset';
import { TimeRange } from '../models/base/TimeRange';

export const toTimeRange = (range: DateRangePreset, yearMonth: string, timezone?: string): TimeRange => {
  switch (range) {
    case DateRangePreset.today: {
      let startTime = dateUtils
        .now()
        .startOf('day')
        .toISO();
      let endTime = dateUtils
        .now()
        .endOf('day')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.yesterday: {
      let startTime = dateUtils
        .now()
        .minus({ day: 1 })
        .startOf('day')
        .toISO();
      let endTime = dateUtils
        .now()
        .minus({ day: 1 })
        .endOf('day')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.week: {
      let startTime = dateUtils
        .now()
        .startOf('week')
        .toISO();
      let endTime = dateUtils
        .now()
        .endOf('week')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.lastWeek: {
      let startTime = dateUtils
        .now()
        .minus({ week: 1 })
        .startOf('week')
        .toISO();
      let endTime = dateUtils
        .now()
        .minus({ week: 1 })
        .endOf('week')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.month: {
      let startTime = dateUtils
        .now()
        .startOf('month')
        .toISO();
      let endTime = dateUtils
        .now()
        .endOf('month')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.lastMonth: {
      let startTime = dateUtils
        .now()
        .minus({ month: 1 })
        .startOf('month')
        .toISO();
      let endTime = dateUtils
        .now()
        .minus({ month: 1 })
        .endOf('month')
        .toISO();
      return { startTime, endTime };
    }
    case DateRangePreset.yearMonth: {
      const startTime = DateTime.fromISO(yearMonth, { zone: timezone })
        .startOf('month')
        .toISO();
      const endTime = DateTime.fromISO(yearMonth, { zone: timezone })
        .endOf('month')
        .toISO();
      return { startTime, endTime };
    }
  }
};
