/**
 * This file contines the routes used in the website or any client.
 * The reason this is in core, instead of web is because we need the routes in the client to create stripe session, where redirect URL has to be created
 */
import { Path } from 'path-parser';
import { stringify } from 'query-string';
import { CategoryId, ItemId, RestaurantId, StoreId } from '../..';
import { OrderId, PrinterId } from '../models/base/Id';

const buildPath = (template: string, values: object): string => {
  const path = new Path(template);
  return path.build(values);
};

export const buildQueryString = (queryParams: QueryParams) => {
  if (!queryParams || !Object.keys(queryParams).length) return ``;
  return `?${stringify(queryParams)}`;
};

export type BackLink = {
  backLink: string;
};

export type ContinueLink = {
  continueLink: string;
};

export type StripeLinksAccountUpdateStatus = {
  stripeLinksAccountUpdateStatus?: 'success' | 'failure';
};

export type QueryParams = Partial<BackLink & ContinueLink & StripeLinksAccountUpdateStatus>;

export const routeConfig = <P extends object, Q extends QueryParams>(template: string) => {
  const link = (pathParams: P, queryParams: Q) => `${buildPath(template, pathParams)}${buildQueryString(queryParams)}`;
  return {
    template,
    link
  };
};

export namespace routes {
  //
  export const demo = routeConfig<{}, {}>('/demo');
  export const home = routeConfig<{}, {}>('/');

  export namespace auth {
    export const login = routeConfig<{}, {}>('/auth/login');
    export const signup = routeConfig<{}, {}>('/auth/signup');
    export const passwordReset = routeConfig<{}, {}>('/auth/reset_password');
    export const action = routeConfig<{}, {}>('/auth/action');
  }

  export namespace backoffice {
    // backoffice
    export const home = routeConfig<{}, {}>('/backoffice');
    export const createRestaurant = routeConfig<{}, {}>(`${home.template}/create/restaurant`);

    // super-admin
    export const superAdminHome = routeConfig<{}, {}>(`${home.template}/super-admin`);
    export const browseRestaurants = routeConfig<{}, {}>(`${superAdminHome.template}/restaurants/browse`);

    // restaurant-level
    export const listRestaurants = routeConfig<{}, {}>(`${home.template}/restaurants`);
    export const restaurant = routeConfig<RestaurantId, {}>(`${home.template}/restaurants/:restaurantId`);
    export const editRestaurant = routeConfig<RestaurantId, {}>(`${restaurant.template}/edit`);
    export const createStore = routeConfig<RestaurantId, {}>(`${restaurant.template}/create/store`);
    export const restaurantWebsiteInfo = routeConfig<RestaurantId, {}>(`${restaurant.template}/website/info`);

    // store-level
    export const listStore = routeConfig<RestaurantId, {}>(`${restaurant.template}/stores`);
    export const store = routeConfig<StoreId, {}>(`${restaurant.template}/stores/:storeId`);
    export const editStore = routeConfig<StoreId, {}>(`${store.template}/edit`);

    export const menu = routeConfig<StoreId, {}>(`${store.template}/menu`);
    export const createCategory = routeConfig<StoreId, {}>(`${store.template}/create/category`);
    export const pizzaSettings = routeConfig<StoreId, {}>(`${store.template}/settings/pizza`);
    export const deliverySettings = routeConfig<StoreId, {}>(`${store.template}/settings/delivery`);
    export const orderSettings = routeConfig<StoreId, {}>(`${store.template}/settings/checkout`);
    export const storeTimings = routeConfig<StoreId, {}>(`${store.template}/settings/timings`);
    export const payments = routeConfig<StoreId, StripeLinksAccountUpdateStatus>(`${store.template}/settings/payments`);
    export const orderView = routeConfig<OrderId, {}>(`${store.template}/orders/:orderId`);

    // category-level
    export const listCategory = routeConfig<StoreId, {}>(`${store.template}/menu`);
    export const category = routeConfig<CategoryId, {}>(`${store.template}/categories/:categoryId`);
    export const editCategory = routeConfig<StoreId, {}>(`${category.template}/edit`);
    export const createItem = routeConfig<CategoryId, {}>(`${category.template}/create/item`);
    export const createPizza = routeConfig<CategoryId, {}>(`${category.template}/create/pizza`);
    export const createDeal = routeConfig<CategoryId, {}>(`${category.template}/create/deal`);

    // item-level
    export const item = routeConfig<ItemId, {}>(`${category.template}/items/:itemId`);

    export const editItem = routeConfig<ItemId, {}>(`${item.template}/item/edit`);
    export const editPizza = routeConfig<ItemId, {}>(`${item.template}/pizza/edit`);
    export const editDeal = routeConfig<ItemId, {}>(`${item.template}/deal/edit`);

    export const viewItem = routeConfig<ItemId, {}>(`${item.template}/item/view`);
    export const viewPizza = routeConfig<ItemId, {}>(`${item.template}/pizza/view`);

    // printer
    export const printers = routeConfig<{}, {}>(`${home.template}/printers`);
    export const printer = routeConfig<PrinterId, {}>(`${home.template}/printers/:printerId`);
  }

  export namespace website {
    export const home = routeConfig<{}, {}>('');
    export const restaurant = routeConfig<RestaurantId, {}>(`${home.template}/restaurants/:restaurantId`);
    export const store = routeConfig<StoreId, {}>(`${restaurant.template}/stores/:storeId`);

    export namespace onlineOrdering {
      export const home = routeConfig<StoreId, {}>(`${store.template}/online-ordering`);
      export const login = routeConfig<StoreId, ContinueLink>(`${home.template}/login`);
      export const menu = routeConfig<StoreId, {}>(`${home.template}/menu`);
      export const coupons = routeConfig<StoreId, {}>(`${home.template}/coupons`);
      export const loyaltyCard = routeConfig<StoreId, {}>(`${home.template}/loyalty-card`);
      export const items = routeConfig<CategoryId, {}>(`${home.template}/categories/:categoryId`);
      // prettier-ignore
      export const item = routeConfig<CategoryId & ItemId, {}>(`${items.template}/items/basic/:itemId`);
      // prettier-ignore
      export const pizza = routeConfig<CategoryId & ItemId, {}>(`${items.template}/items/pizza/:itemId`);
      // prettier-ignore
      export const deal = routeConfig<CategoryId & ItemId, {}>(`${items.template}/items/deal/:itemId`);
      export const checkout = routeConfig<StoreId, {}>(`${home.template}/checkout`);

      // requires login
      export namespace secure {
        export const home = routeConfig<StoreId, {}>(`${onlineOrdering.home.template}/secure`);
        export const orderHistory = routeConfig<StoreId, {}>(`${home.template}/orders`);
        export const orderSuccess = routeConfig<OrderId, {}>(`${home.template}/orders/:orderId/success`);
        export const order = routeConfig<OrderId, {}>(`${home.template}/orders/:orderId`);
      }
    }
  }
}
