import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RestaurantCreatePage } from '../../restaurant/RestaurantCreatePage';
import { RestaurantRouter } from '../../restaurant/RestaurantRouter';
import { BackofficeHomePage } from '../pages/BackofficeHomePage';
import { RestaurantBrowsePage } from '../pages/RestaurantBrowsePage';
import { routes } from '@restoplus/core';
import { PrinterListPage } from '../pages/PrinterListPage';
import { PrinterPage } from '../pages/PrinterPage';

export class BackofficeRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={routes.backoffice.home.template} component={BackofficeHomePage} />
        <Route exact path={routes.backoffice.createRestaurant.template} component={RestaurantCreatePage} />
        <Route exact path={routes.backoffice.browseRestaurants.template} component={RestaurantBrowsePage} />
        <Route exact path={routes.backoffice.printers.template} component={PrinterListPage} />
        <Route exact path={routes.backoffice.printer.template} component={PrinterPage} />
        <Route path={routes.backoffice.restaurant.template} component={RestaurantRouter} />
        <Route render={() => <div>Backoffice Router</div>} />
      </Switch>
    );
  }
}
