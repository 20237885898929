import { RestaurantId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { RestaurantEditWidget } from './RestaurantEditWidget';
import React = require('react');

type Props = {};

export class RestaurantEditPage extends Page<Props, RestaurantId> {
  //
  getTitle(): string {
    return 'Edit Restaurant';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <RestaurantEditWidget documentId={this.props.match.params} create={false} />;
  }
}
