import { Schema } from 'nutso';
import { GoogleAddress } from '../models/base/GoogleAddress';

export const googleAddressSchema: Schema<GoogleAddress> = {
  type: 'object',
  properties: {
    formattedAddress: {
      type: 'string'
    },
    suburb: {
      type: 'string'
    },
    postcode: {
      type: 'string'
    },
    country: {
      type: 'object',
      properties: {
        code: {
          type: 'string'
        },
        name: {
          type: 'string'
        }
      }
    },
    geoPoint: {
      type: 'object',
      properties: {
        latitude: {
          type: 'string'
        },
        longitude: {
          type: 'string'
        }
      }
    },
    googlePlaceId: {
      type: 'string'
    }
  }
};
