import { observer } from 'mobx-react';
import { Button } from '../../elements/Button';
import { Page } from '../../elements/Page';
import { userProvider } from '../../provider/userProvider';
import { UserRestaurantListWidget } from '../../user/UserRestaurantListWidget';
import React = require('react');
import { ButtonType, routes } from '@restoplus/core';
import { gotoPath } from '../../utils/browserHistory';

type Props = {};
type RouteProps = {};

@observer
export class BackofficeHomePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Restaurants';
  }

  renderActions(): React.ReactNode {
    return (
      <Button
        icon="la-plus-circle"
        onClick={() => gotoPath(routes.backoffice.createRestaurant.link({}, {}))}
        type={ButtonType.primary}
      >
        Create Restaurant
      </Button>
    );
  }

  renderBody(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return <span></span>;
    return <UserRestaurantListWidget />;
  }
}
