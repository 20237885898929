import { formatAmount } from '@restoplus/core';
import * as React from 'react';
import { restaurantProvider } from '../../provider/restaurantProvider';

export const Section = (args: { className: string; children: React.ReactNode }) => {
  return <div className={`section ${args.className}`}>{args.children}</div>;
};

export const Title = (args: { icon: string; children: string }) => {
  return (
    <div className="title">
      <i className={`icon las ${args.icon}`}></i>
      <span>{args.children}</span>
    </div>
  );
};

export const Header = (args: { children: React.ReactNode }) => {
  return <div className="header">{args.children}</div>;
};

export const Body = (args: { children: React.ReactNode }) => {
  return <div className="body">{args.children}</div>;
};

export const TitleAddAction = (args: { children: string; onClick: () => void }) => {
  return (
    <div className="title-add-action" onClick={args.onClick}>
      <img src="/images/add-icon.svg" />
      <span>{args.children}</span>
    </div>
  );
};

export const TitleValue = (args: { children: string; onClick: () => void; className?: 'close' }) => {
  return (
    <div className={`title-value ${args.className}`} onClick={args.onClick}>
      <span>{args.children}</span>
    </div>
  );
};

export const Amount = (args: { className: string; label: string; value: number }) => {
  if (!args.value) return null;
  return (
    <div className={`amount ${args.className}`}>
      <label>{args.label}</label>
      <div className="value">{formatAmount(args.value, restaurantProvider.$restaurant?.current()?.country)}</div>
    </div>
  );
};
