import { Store } from '@restoplus/core';

const dummyStore: Store = {
  id: 'dummy-store',
  address: {
    postcode: '3000',
    suburb: 'Melbourne',
    line1: '123 Elm Street'
  },
  collectionSettings: {
    delivery: {
      available: true
    },
    pickup: {
      available: true
    },
    dineIn: {
      available: true
    }
  },
  editorUid: 'me',
  timezone: 'Australia/Melbourne',
  hasSplitTimings: false,
  creatorUid: 'me',
  editedTime: '2020-06-11T10:28:35.209+08:00',
  phoneNumber: '+61 3 3123 4567',
  name: 'Melbourne',
  createdTime: '2020-06-11T10:28:35.209+08:00'
};

export const createDummyStore = (partial: Partial<Store>): Store => {
  return {
    ...dummyStore,
    ...partial
  };
};
