export enum DateRangePreset {
  today = 'today',
  yesterday = 'yesterday',
  week = 'week',
  month = 'month',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  yearMonth = 'yearMonth'
}

export const dateRangePresetList: DateRangePreset[] = [
  DateRangePreset.today,
  DateRangePreset.yesterday,
  DateRangePreset.week,
  DateRangePreset.month,
  DateRangePreset.lastWeek,
  DateRangePreset.lastMonth,
  DateRangePreset.yearMonth
];

export const dateRangePresetLabels: { [key in DateRangePreset]: string } = {
  [DateRangePreset.today]: 'Today',
  [DateRangePreset.yesterday]: 'Yesterday',
  [DateRangePreset.week]: 'Week',
  [DateRangePreset.month]: 'Month',
  [DateRangePreset.lastWeek]: 'Last week',
  [DateRangePreset.lastMonth]: 'Last month',
  [DateRangePreset.yearMonth]: 'Choose Month'
};
