/**
 * We are managing sort order using a floating point number b/w 0 and 1
 */

const getZeroInclusive = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

const get = (min: number, max: number) => {
  let order;
  do {
    order = getZeroInclusive(min, max);
  } while (order === 0 || order === min); // we don't want 0 and the min value itself
  return order;
};

export const sortOrder = {
  get
};
