import { ItemSpec, CollectionType, BasicItemSpec, PizzaSpec, DealSpec, PriceSpec } from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { priceSpecUtils } from './priceSpecUtils';

const getitemsForCategory = (categoryId: string, items: ItemSpec[]): ItemSpec[] => {
  if (!items) return [];
  return items.filter(item => item.categoryId === categoryId).sort((a, b) => a.sortOrder - b.sortOrder);
};

/**
 * Min price is used to show the approximate value of the item to the customer in the item list page
 */
const getMinPrice = (item: ItemSpec, collectionType?: CollectionType) => {
  switch (item.type) {
    case ItemType.basic:
      return getBasicItemMinPrice(item, collectionType);
    case ItemType.pizza:
      return getPizzaMinPrice(item, collectionType);
    case ItemType.deal:
      return getDealMinPrice(item, collectionType);
  }
};

const getBasicItemMinPrice = (item: BasicItemSpec, collectionType?: CollectionType) => {
  // take sum of min price of all mandatory attrs
  const requiredModifiers = item.modifiers.filter(modifier => modifier.min > 0);
  const minPrices = requiredModifiers.map(modifier =>
    Math.min(...modifier.options.map(option => option.extraCharge ?? 0))
  );
  // sum
  const extraCharges = minPrices.reduce((a, b) => a + b, 0);
  return priceSpecUtils.getBasePrice(item.price, collectionType) + extraCharges;
};

const getPizzaMinPrice = (item: PizzaSpec, collectionType?: CollectionType) => {
  const sizes = Object.values(item.sizes || []);
  if (!sizes.length) return 0;

  return sizes.reduce((prev, size) => {
    const price = priceSpecUtils.getBasePrice(size.price, collectionType);
    return price < prev ? price : prev;
  }, Infinity);
};

const getDealMinPrice = (item: DealSpec, collectionType?: CollectionType) => {
  return priceSpecUtils.getBasePrice(item.price, collectionType);
};

export const itemSpecUtils = {
  getitemsForCategory,
  getMinPrice
};
