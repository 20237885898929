import {
  DisplayTime,
  Order,
  OrderId,
  OrderProcessSettings,
  OrderStatus,
  OrderStatusCode,
  routes,
  defaults
} from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { repo } from '../../repo/repo';
import { gotoPath } from '../../utils/browserHistory';
import React = require('react');

interface Props {
  order: Order;
}
export const orderId = (order: Order): OrderId => {
  return {
    orderId: order.id,
    ...order
  };
};

@observer
export class OrderProcessButtons extends React.Component<Props, any> {
  //
  @observable
  $orderProcessSettings = repo.orderProcessSettings.bind({ ...this.props.order }, defaults.orderProcessSettings);

  @observable
  processing: boolean = false;

  accept = async (order: Order, eta: DisplayTime | null) => {
    const status: OrderStatus = { code: OrderStatusCode.accepted };
    repo.order.update(orderId(this.props.order), { status });
  };

  reject = async (order: Order, reason: string) => {
    const status: OrderStatus = { code: OrderStatusCode.rejected, reason: 'Please contact store for more info' };
    repo.order.update(orderId(this.props.order), { status });
  };

  complete = async (order: Order) => {
    const status: OrderStatus = { code: OrderStatusCode.completed };
    repo.order.update(orderId(this.props.order), { status });
  };

  renderViewOrderButton = (order: Order, orderProcessSettings: OrderProcessSettings) => {
    const onClick = () => {
      console.log('ORDER: ', order);
      gotoPath(routes.backoffice.orderView.link({ ...order, orderId: order.id }, {}));
    };
    return (
      <button className="view-order" onClick={onClick}>
        View
      </button>
    );
  };

  renderAcceptOrderButton = (order: Order, orderProcessSettings: OrderProcessSettings) => {
    // Only new orders can be accepted
    if (order.status.code !== OrderStatusCode.new) return;
    // accept with ETA
    if (orderProcessSettings.hasEta) {
      // TODO
      return (
        <button className="accept-order has-eta" onClick={() => this.accept(order, null)}>
          Accept
        </button>
      );
    }
    // accept without ETA
    return (
      <button className="accept-order" onClick={() => this.accept(order, null)}>
        Accept
      </button>
    );
  };

  renderRejectOrderButton = (order: Order, orderProcessSettings: OrderProcessSettings) => {
    // only accepted or new orders can be rejected
    switch (order.status.code) {
      case OrderStatusCode.accepted:
      case OrderStatusCode.new:
        break;
      default:
        return;
    }
    return (
      <button className="reject-order" onClick={() => this.reject(order, 'Please contact store for more information.')}>
        Reject
      </button>
    );
  };

  renderCompleteOrderButton = (order: Order, orderProcessSettings: OrderProcessSettings) => {
    // store do not have a complete status
    if (!orderProcessSettings.hasCompleteStatus) return;

    // only accepted orders can be marked as complete
    switch (order.status.code) {
      case OrderStatusCode.accepted:
        break;
      default:
        return;
    }
    return (
      <button className="complete-order" onClick={() => this.complete(order)}>
        Complete
      </button>
    );
  };

  render() {
    const orderProcessSettings = this.$orderProcessSettings.current();
    if (!orderProcessSettings) return null;
    const { order } = this.props;
    return (
      <div className="actions order-process-buttons">
        {this.renderViewOrderButton(order, orderProcessSettings)}
        {this.renderAcceptOrderButton(order, orderProcessSettings)}
        {this.renderRejectOrderButton(order, orderProcessSettings)}
        {this.renderCompleteOrderButton(order, orderProcessSettings)}
      </div>
    );
  }
}
