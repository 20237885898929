import * as React from 'react';
import { toastStore } from './toastStore';

type Props = {
  type: 'success' | 'error' | 'warning' | 'info' | 'loading';
  message: string;
  id: string;
};

export class Toast extends React.Component<Props> {
  renderCloseButton = () => {
    if (this.props.type === 'loading') return;
    return (
      <button className="close" onClick={() => toastStore.remove(this.props.id)}>
        <i className="las la-times-circle" />
      </button>
    );
  };

  renderIcon = () => {
    const { type } = this.props;
    switch (type) {
      case 'success':
        return <i className="icon las la-thumbs-up" />;
      case 'error':
        return <i className="icon las la-fire-alt" />;
      case 'warning':
        return <i className="icon las la-exclamation-triangle" />;
      case 'info':
        return <i className="icon las la-info" />;
      case 'loading':
        return <i className="icon spinner spin las la-circle-notch" />;
    }
  };

  render() {
    return (
      <div className={`toast ${this.props.type}`}>
        {this.renderIcon()}
        <div className="message">{this.props.message}</div>
        {this.renderCloseButton()}
      </div>
    );
  }
}
