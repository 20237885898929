import React = require('react');
import { ModifierSpec, ModifiersValue } from '@restoplus/core';
import { observer } from 'mobx-react';
import { ModifierWidget } from './ModifierWidget';

type Props = {
  value: ModifiersValue;
  onChange: (value: ModifiersValue) => void;
  specs: ModifierSpec[];
};

@observer
export class ModifiersWidget extends React.Component<Props> {
  //
  render() {
    const { specs, value, onChange } = this.props;
    return (
      <div className="modifiers">
        {specs.map(spec => (
          <ModifierWidget
            key={spec.id}
            spec={spec}
            value={value[spec.id] || []}
            onChange={newValue => onChange({ ...value, ...{ [spec.id]: newValue } })}
          />
        ))}
      </div>
    );
  }
}
