import { defaults } from '@restoplus/core';
import { computed } from 'mobx';
import { repo } from '../repo/repo';
import { storeProvider } from './storeProvider';
import { fromPromise } from 'mobx-utils';

class CheckoutSettingsProvider {
  //
  @computed
  get checkoutSettings() {
    const storeId = storeProvider.storeId;
    if (!storeId) return null;
    console.log(`Fetching checkout settings!`);
    return fromPromise(repo.checkoutSettings.getWithDefaultCacheFirst(storeId, defaults.checkoutSettings));
  }
}

export const checkoutSettingsProvider = new CheckoutSettingsProvider();
