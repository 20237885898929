import React = require('react');
import { CollectionType, Store } from '@restoplus/core';
import { customerCart } from '../../provider/customerCartProvider';

type Props = {
  selected?: CollectionType;
  store: Store;
};

const labels: { [key in CollectionType]: string } = {
  [CollectionType.pickup]: 'Pickup',
  [CollectionType.delivery]: 'Delivery',
  [CollectionType.dineIn]: 'Dine In'
};

export class CollectionTypeSelector extends React.Component<Props> {
  render() {
    const { store } = this.props;
    return (
      <div className="collection-type-widget">
        {store.collectionSettings.pickup.available && this.renderCollectionType(CollectionType.pickup)}
        {store.collectionSettings.delivery.available && this.renderCollectionType(CollectionType.delivery)}
        {store.collectionSettings.dineIn.available && this.renderCollectionType(CollectionType.dineIn)}
      </div>
    );
  }

  renderCollectionType(collectionType: CollectionType): React.ReactNode {
    const { selected } = this.props;
    return (
      <div
        className={`collection-type ${collectionType === selected ? 'selected' : ''}`}
        onClick={() => {
          customerCart.collectionType = collectionType;
          customerCart.collectionTime = '';
        }}
      >
        <div className="dot"></div>
        <span>{labels[collectionType]}</span>
      </div>
    );
  }
}
