import { CategoryId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { BasicItemSpecEditWidget } from './BasicItemSpecEditWidget';
import React = require('react');

type Props = {};
type RouteProps = CategoryId & {};

export class BasicItemSpecCreatePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Create Item';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return (
      <BasicItemSpecEditWidget
        documentId={{ ...this.props.match.params, itemId: '' }}
        categoryId={this.props.match.params.categoryId}
        create={true}
      />
    );
  }
}
