import { Page } from '../elements/Page';
import { StoreEditWidget } from './StoreEditWidget';
import React = require('react');
import { RestaurantId } from '@restoplus/core';

type Props = {};
type RouteProps = RestaurantId & {};

export class StoreCreatePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Create Store';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return (
      <StoreEditWidget documentId={{ restaurantId: this.props.match.params.restaurantId, storeId: '' }} create={true} />
    );
  }
}
