import * as React from 'react';
import { Widget } from '../elements/Widget';

type Props = {};
export class IntroWidget extends Widget<any> {
  render() {
    return (
      <div id="intro-widget">
        <div id="logo">
          <img src="/images/logo-black.svg" alt="Restoplus Logo" />
        </div>
        <div className="graphic">
          <img src="/images/svg/greetings.svg" alt="Restoplus Introduction" />
        </div>
        <div className="marketing-message">Help us serve you better. We only ask for your email!</div>
        {/* <div className="marketing-message">Super charge your restaurant with superior technology!</div> */}
      </div>
    );
  }
}
