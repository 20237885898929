import { AxiosPromise } from 'axios';

export const unwrapAxiosResponse = <T>(axiosPromise: AxiosPromise<T>): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    axiosPromise
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status == 401) {
            // redirect to login page
            reject({
              status: error.response.status,
              message: 'Authentication required.'
            });
          }
          if (error.response.status == 500) {
            console.log(error);
            reject({
              status: error.response.status,
              message: 'Server error has occured. We will check the issue asap. Thank you for your patience.'
            });
          }

          console.log(error.response);
          reject({
            status: error.response.status,
            message: `${error.response.data.message}`
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          reject({
            status: 444,
            message: 'The request was made but no response was received'
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          reject({
            status: 417,
            message: 'Something happened in setting up the request that triggered an Error'
          });
        }
        // console.log(error.config);
      });
  });
};
