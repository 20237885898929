import { Schema } from 'nutso';
import { BaseItem } from '../../models/item/Item';
import { categorySchema } from '../categorySchema';
import { itemSummarySchema } from './itemSummarySchema';

export const itemSchema: Schema<Omit<BaseItem, 'spec' | 'selection'>> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    summary: itemSummarySchema,
    type: {
      type: 'string'
    },
    category: categorySchema
  }
};
