import { Schema } from 'nutso';
import { Cart } from '../models/order/Cart';
import { categorySchema } from './categorySchema';
import { deliveryAddressSchema } from './deliveryAddressSchema';
import { promotionSchema } from './promotionSchema';

export const cartSchema: Schema<Cart> = {
  type: 'object',
  properties: {
    createdTime: {
      type: 'string'
    },
    collectionTime: {
      type: 'string',
      optional: true
    },
    collectionType: {
      type: 'string'
    },
    items: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          category: categorySchema,
          spec: undefined as any,
          selection: undefined as any,
          summary: {
            type: 'object',
            properties: {
              name: {
                type: 'string'
              },
              description: {
                type: 'string'
              },
              price: {
                type: 'object',
                properties: {
                  pickup: {
                    type: 'number'
                  },
                  delivery: {
                    type: 'number'
                  },
                  dineIn: {
                    type: 'number'
                  }
                }
              },
              quantity: {
                type: 'number'
              }
            }
          },
          type: {
            type: 'string'
          }
        }
      }
    },
    paymentType: {
      type: 'string'
    },
    currency: {
      type: 'object',
      properties: {
        code: {
          type: 'string'
        },
        symbol: {
          type: 'string'
        }
      }
    },
    discount: {
      type: 'number'
    },
    itemTotal: {
      type: 'number'
    },
    customerName: {
      type: 'string'
    },
    grandTotal: {
      type: 'number'
    },
    comments: {
      type: 'string',
      optional: true,
      maxLength: 300
    },
    couponCode: {
      type: 'string',
      optional: true
    },
    creditCardSurcharge: {
      type: 'object',
      optional: true,
      properties: {
        amount: {
          type: 'number'
        },
        message: {
          type: 'string'
        },
        basis: undefined as any
      }
    },
    delivery: {
      type: 'object',
      optional: true,
      properties: {
        address: deliveryAddressSchema,
        charges: {
          type: 'number'
        }
      }
    },
    loyaltyCardId: {
      type: 'string',
      optional: true
    },
    promotion: {
      ...promotionSchema,
      optional: true
    }
  }
};
