const compile = require('string-template/compile');
const extractValues = require('extract-values');

/**
 * This is one of the most important class in the entire project.
 * This is to model the path of every collection and document in firestore.
 * K => stands for the type of the key
 * T => stands for the type of the entity the collection holds or the document refers to
 *
 * Ex: For an order
 *
 * Firestore Location => restaurants/{restaurantId}/stores/{storeId}/orders/{orderId}
 *
 * K => type OrderId = { restaurantId: string, storeId: string, orderId: string }
 * T => type Order = { ... }
 * template (constructor argument) => restaurants/{restaurantId}/stores/{storeId}/orders/{orderId}
 *
 * NOTE: type parameter T is not used in this class, but checkout FirestoreTrigger where it is used.
 * It is a way to bring together the `key` and `entity` (this has tremendous benefits)
 * Checkout `firestore_paths` to see how it works.
 */
// K = Key, T = Entity that
export class FirestorePath<K, T> {
  template: string;
  compiled: (k: K) => string;

  constructor(template: string) {
    this.template = template;
    this.compiled = compile(template);
  }

  /**
   * Takes a key and returns a real path that points to a specific document or collection in firestore
   */
  path = (key: K) => {
    return this.compiled(key);
  };

  extract = (path: string): K => {
    return extractValues(path, this.template) as K;
  };
}
