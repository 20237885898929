import React = require('react');
import { idFactory } from '@restoplus/core';
import { ModifierSpec, ModifierOption } from '@restoplus/core/src/models/item_spec/ModifierSpec';
import { observer } from 'mobx-react';
import { Result } from 'nutso';
import { Button } from '../elements/Button';
import { FormItem } from '../forms/FormItem';
import { NumberInput } from '../forms/NumberInput';
import { TextInput } from '../forms/TextInput';
import { listUtils } from '../utils/listUtils';
import { FormTextInput } from '../forms/FormTextInput';
import { FormNumberInput } from '../forms/FormNumberInput';
import { FormMeta } from '../forms/FormMeta';
import { validationUtils } from '../utils/validationUtils';
import { toastStore } from '../toast/toastStore';
import { Badge } from '../elements/Badge';

type Props = {
  value: ModifierSpec[];
  onChange: (value: ModifierSpec[]) => void;
  validation: Result<ModifierSpec[]>;
};

@observer
export class ModifierSpecsEditWidget extends FormItem<Props> {
  //
  renderMandatoryBadge(modifier: ModifierSpec): React.ReactNode {
    if (modifier.min > 0) return <Badge type="primary">Required</Badge>;
    return <Badge type="primary">Optional</Badge>;
  }
  renderSelectBadge(modifier: ModifierSpec): React.ReactNode {
    if (modifier.max > 1) return <Badge type="success">Multi-Select</Badge>;
    return <Badge type="success">Single Select</Badge>;
  }
  //
  className() {
    return 'modifiers';
  }

  renderModifierOption = (
    modifierOption: ModifierOption,
    i: number,
    list: ModifierOption[],
    modifier: ModifierSpec,
    validation: Result<ModifierOption>
  ) => {
    return (
      <div className="modifier-option" key={modifierOption.id}>
        <TextInput
          value={modifierOption.name}
          onChange={value => (modifierOption.name = value)}
          validation={validation.properties.name}
        />
        <NumberInput
          value={modifierOption.extraCharge}
          onChange={value => (modifierOption.extraCharge = value)}
          validation={validation.properties.extraCharge}
        />
        <div className="actions">
          <div className="action" onClick={() => (modifier.options = listUtils.moveUp(list, i))}>
            <i className={`icon las la-arrow-circle-up`}></i>
          </div>
          <div className="action" onClick={() => (modifier.options = listUtils.moveDown(list, i))}>
            <i className={`icon las la-arrow-circle-down`}></i>
          </div>
          <div
            className="action"
            onClick={() => {
              if (list.length <= 1) {
                toastStore.error('Need atleast one option');
                return;
              }
              modifier.options = listUtils.remove(list, i);
            }}
          >
            <i className={`icon las la-times-circle`}></i>
          </div>
        </div>
      </div>
    );
  };

  renderModifierActions = (modifier: ModifierSpec, i: number, list: ModifierSpec[]) => {
    return (
      <div className="modifier-actions">
        <div
          className="action"
          title="Add modifier value"
          onClick={() => {
            modifier.options.push({
              id: idFactory.modifierId(),
              name: '',
              extraCharge: 0
            });
          }}
        >
          <i className={`icon las la-plus-circle`}></i>
        </div>
        <div className="action" onClick={() => this.props.onChange(listUtils.moveUp(list, i))}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => this.props.onChange(listUtils.moveDown(list, i))}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div className="action" onClick={() => this.props.onChange(listUtils.remove(list, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  };

  renderModifierInfo = (modifier: ModifierSpec, validation: Result<ModifierSpec>) => {
    return (
      <div className="modifier-info">
        <div className="header">
          <FormTextInput
            label="Name"
            value={modifier.name}
            onChange={value => (modifier.name = value)}
            validation={validation.properties.name}
          />
          <FormNumberInput
            label="Min"
            value={modifier.min}
            onChange={value => {
              if (value < 0) return;
              modifier.min = value;
              if (modifier.max < value) modifier.max = value;
            }}
            validation={validation.properties.min}
          />
          <FormNumberInput
            label="Max"
            value={modifier.max}
            onChange={value => {
              if (value < 1) return;
              modifier.max = value;
              if (modifier.min > value) modifier.min = value;
            }}
            validation={validation.properties.max}
          />
        </div>
        <div className="badges">
          {this.renderMandatoryBadge(modifier)}
          {this.renderSelectBadge(modifier)}
        </div>
        <FormMeta
          validation={validationUtils.success}
          helpText="Ex: Choose Drink. Min and Max specifies the minimum and maximum number of options the user has to choose."
        />
        <div className="modifier-options">
          <div className="head">
            <div className="">Options</div>
          </div>
          <FormMeta
            validation={validationUtils.success}
            helpText="Enter option name and extra charge (optional). Ex: RedBull and Extra Charge: 1 (to charge extra $1 for RedBull)"
          />
          {modifier.options.map((value, i, list) =>
            this.renderModifierOption(value, i, list, modifier, validation.properties.options.items[i])
          )}
        </div>
      </div>
    );
  };

  renderModifier = (modifier: ModifierSpec, i: number, list: ModifierSpec[], validation: Result<ModifierSpec>) => {
    return (
      <div key={modifier.id} className="modifier">
        {this.renderModifierInfo(modifier, validation)}
        {this.renderModifierActions(modifier, i, list)}
      </div>
    );
  };

  renderAddModifierButton = (modifiers: ModifierSpec[]) => {
    return (
      <div className="modifier-list-actions">
        <Button
          onClick={() =>
            modifiers.push({
              id: idFactory.modifierId(),
              name: '',
              min: 0,
              max: 0,
              options: [{ id: idFactory.modifierId(), name: '', extraCharge: 0 }]
            })
          }
        >
          Add Modifier
        </Button>
      </div>
    );
  };

  renderValue() {
    const { value: modifiers, validation: vr } = this.props;
    return (
      <div className="modifier-list">
        {modifiers.map((modifier, i, list) => this.renderModifier(modifier, i, list, vr.items[i]))}
        {this.renderAddModifierButton(modifiers)}
      </div>
    );
  }
}
