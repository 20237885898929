import { DateTime } from 'luxon';

/**
 * HH:mm is a name to represent time with just hour and minute parts 23:15
 * This is just a helper class to deal with that
 */

const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const format = 'HH:mm';

const toDateTime = (hhmm: string) => {
  const match = pattern.test(hhmm || '');
  if (!match) {
    console.error(`toDateTime without proper format!`);
    return DateTime.local();
  }
  return DateTime.fromFormat(hhmm, format);
};

const toHhmm = (dt: DateTime) => {
  return dt.toFormat(format);
};

const toLocaleTime = (dt: DateTime) => {
  return dt.toFormat('h:mm a');
};

export const hhmmUtils = {
  pattern,
  toHhmm,
  toDateTime,
  toLocaleTime
};
