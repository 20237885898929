/**
 * All api calls to the server has to be defined and made from here
 */
import {
  Cart,
  endpoints,
  Order,
  PayoutSchedule,
  StoreId,
  StripeAccount,
  StripeAccountLink,
  TokenId,
  Printer,
  SendVerificationCodeResponse,
  VerifyVerificationCodeResponse,
  SendVerificationCodeRequest,
  VerifyVerificationCodeRequest
} from '@restoplus/core';
import axios from 'axios';
import { userProvider } from '../provider/userProvider';
import { unwrapAxiosResponse } from './unwrapAxiosResponse';
import { webConfig } from './webConfig';

const unwrap = unwrapAxiosResponse;

const config = async () => {
  return {
    headers: {
      Authorization: `Bearer ${await userProvider.user?.getIdToken()}`
    }
  };
};

const server = axios.create({
  baseURL: webConfig.serverUrl,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
});

const placeOrder = async (args: { restaurantId: string; storeId: string; cart: Cart }): Promise<Order> => {
  const endpoint = endpoints.placeOrder;
  return unwrap(server.post<Order>(endpoint.getPath(args), endpoint.getBody(args.cart), await config()));
};

const getActiveStripeAccount = async (args: StoreId) => {
  const endpoint = endpoints.getActiveStripeAccount;
  return unwrap(server.get<StripeAccount>(endpoint.getPath(args), await config()));
};

const createStripeAccountVerificationLink = async (args: StoreId) => {
  const endpoint = endpoints.createStripeAccountVerificationLink;
  return unwrap(server.post<StripeAccountLink>(endpoint.getPath(args), {}, await config()));
};

const createStripeAccountUpdateLink = async (args: StoreId) => {
  const endpoint = endpoints.createStripeAccountUpdateLink;
  return unwrap(server.post<StripeAccountLink>(endpoint.getPath(args), {}, await config()));
};

const archiveStripeAccount = async (args: StoreId) => {
  const endpoint = endpoints.archiveStripeAccount;
  return unwrap(server.post<{}>(endpoint.getPath(args), {}, await config()));
};

const setExternalAccount = async (args: StoreId & TokenId) => {
  const endpoint = endpoints.setExternalAccount;
  return unwrap(server.post<{}>(endpoint.getPath(args), {}, await config()));
};

const setPayoutSchedule = async (args: { storeId: StoreId; payoutSchedule: PayoutSchedule }) => {
  const endpoint = endpoints.setPayoutSchedule;
  return unwrap(server.post<{}>(endpoint.getPath(args.storeId), args.payoutSchedule, await config()));
};

const createPrinter = async () => {
  const endpoint = endpoints.createPrinter;
  return unwrap(server.post<Printer>(endpoint.getPath({}), {}, await config()));
};

const sendVerificationCode = async (request: SendVerificationCodeRequest) => {
  const endpoint = endpoints.sendVerificationCode;
  return unwrap(server.post<SendVerificationCodeResponse>(endpoint.getPath({}), request, await config()));
};

const verifyVerificationCode = async (request: VerifyVerificationCodeRequest) => {
  const endpoint = endpoints.verifyVerificationCode;
  return unwrap(server.post<VerifyVerificationCodeResponse>(endpoint.getPath({}), request, await config()));
};

export const remoteService = {
  placeOrder,
  getActiveStripeAccount,
  archiveStripeAccount,
  createStripeAccountUpdateLink,
  createStripeAccountVerificationLink,
  setExternalAccount,
  setPayoutSchedule,
  createPrinter,
  sendVerificationCode,
  verifyVerificationCode
};
