import { AspectRatio, Restaurant } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Imgix } from '../elements/Imgix';
import { RestaurantViewWidget } from './RestaurantViewWidget';

@observer
export class RestaurantHeaderWidget extends RestaurantViewWidget {
  //
  renderRestaurant = (restaurant: Restaurant) => {
    return (
      <div className="restaurant-header-widget">
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="name">{restaurant.name}</div>
      </div>
    );
  };
}
