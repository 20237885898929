import React = require('react');
import { getFileExtension, idFactory } from '@restoplus/core';
import { storage } from '../integration/fb';
import { toastStore } from '../toast/toastStore';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

type Props = {
  gcsFolder: string;
  onUploadComplete: (path: string) => void;
  dataCy?: string;
};

@observer
export class FileInput extends React.Component<Props> {
  //
  @observable progress = 0;

  uploadToGcs = async (file: File) => {
    //
    const { gcsFolder } = this.props;

    const filename = file.name || '';
    const extension = getFileExtension(filename);

    const fileId = idFactory.fileId({ extension });

    const path = `${gcsFolder}/${fileId}`;

    const ref = storage.ref(path);
    const task = ref.put(file);

    task.on(
      'state_changed',
      snapshot => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.progress = percentage;
      },
      error => {
        toastStore.error(`Uploading ${filename} failed.`);
        console.error(error);
        return;
      },
      () => {
        // on complete
        toastStore.success(`${filename} uploaded successfully.`);
        this.props.onUploadComplete(path);
        return;
      }
    );
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const files = event.target.files;
    if (!files) return;
    if (files.length !== 1) {
      toastStore.error(`Cannot handle multiple files.`);
      return;
    }
    this.uploadToGcs(files.item(0)!);
  };

  renderProgressBar = () => {
    if (this.progress <= 0 || this.progress >= 100) {
      return null;
    }
    return (
      <div>
        <progress max="100" value={this.progress}>
          {this.progress}
        </progress>
      </div>
    );
  };

  render() {
    return (
      <div className="fileinput">
        <input
          className="file-input"
          type="file"
          onChange={this.onChange}
          data-cy={this.props.dataCy ?? 'file-input'}
        />
        {this.renderProgressBar()}
      </div>
    );
  }
}
