import { StoreId } from '@restoplus/core';
import { Page } from '../../elements/Page';
import React = require('react');
import { PizzaSettingsEditWidget } from '../../settings/PizzaSettingsEditWidget';

type Props = {};
type RouteProps = StoreId & {};

export class PizzaSettingsPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Pizza Settings';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <PizzaSettingsEditWidget documentId={this.props.match.params} />;
  }
}
