export type CountryCode = 'AU' | 'US' | 'GB' | 'IN' | 'NZ';
export type CurrencyCode = 'AUD' | 'USD' | 'GBP' | 'INR' | 'NZD';
export type CurrencySymbol = '$' | '£' | '₹';

export type Country = {
  code: CountryCode;
  name: string;
  timezones: string[];
  currencyCode: CurrencyCode;
  currencySymbol: CurrencySymbol;
};

export const NZ: Country = {
  code: 'NZ',
  name: 'New Zealand',
  timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
  currencyCode: 'NZD',
  currencySymbol: '$'
};

export const AU: Country = {
  code: 'AU',
  name: 'Australia',
  timezones: ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Adelaide', 'Australia/Perth', 'Australia/Hobart'],
  currencyCode: 'AUD',
  currencySymbol: '$'
};

export const IN: Country = {
  code: 'IN',
  name: 'India',
  timezones: ['Asia/Kolkata'],
  currencyCode: 'INR',
  currencySymbol: '₹'
};

export const US: Country = {
  code: 'US',
  name: 'United States',
  currencyCode: 'USD',
  currencySymbol: '$',
  timezones: [
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu'
  ]
};

export const GB: Country = {
  code: 'GB',
  name: 'United Kingdom',
  timezones: ['Europe/London'],
  currencyCode: 'GBP',
  currencySymbol: '£'
};

export const countries: { [key: string]: Country } = { AU, US, GB, NZ };
export const currencies: CurrencySymbol[] = ['$', '£', '₹'];
