export const LOYALTY_CARD_COUPON_CODE = 'LOYALTYCARD';

export type LoyaltyCard = {
  id: string;
  prevCardId: string | null; // null if this is the first card
  redeemed: boolean;
  redeemInfo?: {
    orderId: string;
    storeId: string;
    redeemedTime: string;
  };
};
