import { Path } from 'path-parser';

/**
 * The objective of this class is to define a common contract b/w the client and the server,
 * such that the definitions in the client `remote` and the server `expressjs` routes are in sync
 */
export class Endpoint<PathParams, Body = unknown> {
  //
  constructor(readonly routerTemplate: string, readonly handlerTemplate: string) {}

  get template() {
    return `${this.routerTemplate}${this.handlerTemplate}`;
  }

  /**
   * Get will be used on the client-side
   */
  getPath = (params: PathParams) => {
    const path = new Path(this.template);
    return path.build(params);
  };

  // this is just for type-safety
  getBody = (body: Body) => {
    return body;
  };

  /**
   * Parse will be used on the server-side
   */
  castParams = (params: any) => {
    return params as PathParams;
  };

  // this is just for type-safety
  castBody = (body: any) => {
    return body as Body;
  };
}
