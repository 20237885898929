export const max = (a: number, b: number) => {
  return a > b ? a : b;
};

export const range = (from: number, to: number) => {
  const list = [];
  for (let i = from; i < to; i++) {
    list.push(i);
  }
  return list;
};
