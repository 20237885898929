import * as React from 'react';
import { FormItem } from './FormItem';
import { FormItemProps, FormItemContainer } from './FormItemContainer';
import { validationUtils } from '../utils/validationUtils';

type Props = Omit<FormItemProps, 'validation'> & {};

export class FormInlineTitle extends React.Component<Props> {
  //
  render() {
    return <FormItemContainer {...this.props} className="inline-title" validation={validationUtils.success} />;
  }
}
