import { Order, OrderId, OrderStatus, routes, OrderStatusCode } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Alert } from '../../alert/Alert';
import { Page } from '../../elements/Page';
import { ResolveResource } from '../../elements/ResolveResource';
import { OrderViewWidget } from '../../orders/OrderViewWidget';
import { customerCart } from '../../provider/customerCartProvider';
import { repo } from '../../repo/repo';
import { gotoPath } from '../../utils/browserHistory';
import React = require('react');

type Props = {};
type RouteProps = OrderId;

@observer
export class OrderSuccessPage extends Page<Props, RouteProps> {
  //
  @observable
  $order = repo.order.bind(this.props.match.params);

  onPageEnter() {
    customerCart.clear();
  }

  getTitle(): string {
    return 'View Order';
  }

  getAdditionalClassNames() {
    return 'website-order-success-page';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    return <ResolveResource resource={this.$order}>{order => <>{this.renderOrder(order)}</>}</ResolveResource>;
  }

  renderOrder(order: Order | null | undefined) {
    if (!order) return <div>Cannot find order</div>;

    return (
      <>
        {this.renderOrderStatus(order)}
        <OrderViewWidget id={this.props.match.params} document={order} />
        <button
          id="order-history-button"
          onClick={() => gotoPath(routes.website.onlineOrdering.secure.orderHistory.link(this.props.match.params, {}))}
        >
          Order History
        </button>
      </>
    );
  }

  renderOrderWidget(order: Order) {
    return;
  }

  renderOrderStatus(order: Order) {
    switch (order.status.code) {
      case OrderStatusCode.processingPayment: {
        return (
          <Alert type="warning" icon="loading">
            Processing payment. Please wait ...
          </Alert>
        );
      }
      case OrderStatusCode.new: {
        return <Alert type="success">Order placed successfully!</Alert>;
      }
      default: {
        return <Redirect to={routes.website.onlineOrdering.secure.order.link(this.props.match.params, {})} />;
      }
    }
  }
}
