import { StoreTimings, SessionTimings } from '@restoplus/core';

const dummySessionTimings: SessionTimings = {
  hours: {
    duration: 7,
    startHhmm: '10:00'
  },
  available: true
};

const dummyStoreTimings: StoreTimings = {
  monday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  tuesday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  wednesday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  thursday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  friday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  saturday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  },
  sunday: {
    delivery: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    pickup: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    },
    dineIn: {
      session1: dummySessionTimings,
      session2: dummySessionTimings
    }
  }
};

/**
 * @type {SessionTimings} - Calling this with empty parameters gives:
 *  * Start time at 10:00AM.
 *  * Duration is 7 hours.
 *  * Available set to true
 * @param {Partial<SessionTimings>} partial - overriding values to the session timings
 */
export const createDummySessionTimings = (partial: Partial<SessionTimings>): SessionTimings => {
  return {
    ...dummySessionTimings,
    ...partial
  };
};

/**
 * @type {StoreTimings} - Calling this with empty parameters gives:
 *  * All days of week available with Session 1 only.
 *  * All collection types active.
 *  * Session 1 starts at 10:00AM.
 *  * Length of Session 1 is 7 hours.
 * @param {Partial<StoreTimings>} partial - overriding values to the store timings
 */
export const createDummyStoreTimings = (partial: Partial<StoreTimings>): StoreTimings => {
  return {
    ...dummyStoreTimings,
    ...partial
  };
};
