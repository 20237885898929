import * as React from 'react';

type Props = {
  type: 'success' | 'failure' | 'warning' | 'primary';
  icon: string;
};

export class StatusIndicator extends React.Component<Props> {
  render() {
    return (
      <div className={`status-indicator ${this.props.type}`}>
        <i className={`las ${this.props.icon}`} />
      </div>
    );
  }
}
