import { CategoryId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { CategoryEditWidget } from './CategoryEditWidget';
import React = require('react');

type Props = {};
type RouteProps = CategoryId & {};

export class CategoryEditPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Edit Category';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <CategoryEditWidget documentId={this.props.match.params} create={false} />;
  }
}
