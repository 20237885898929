import {
  CollectionType,
  collectionTypeLabel,
  formatAmount,
  Order,
  OrderId,
  PaymentType,
  paymentTypeLabels,
  Item,
  PaymentStatus,
  OrderStatusCode
} from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { DocumentViewWidget } from '../document/DocumentViewWidget';
import { restaurantProvider } from '../provider/restaurantProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';

const Title = (args: { icon: string; children: string }) => {
  return (
    <div className="title">
      <i className={`icon las ${args.icon}`}></i>
      <label>{args.children}</label>
    </div>
  );
};

@observer
export class OrderViewWidget extends DocumentViewWidget<OrderId, Order, {}> {
  //
  getRepo(): DocumentRepo<OrderId, Order> {
    return repo.order;
  }

  renderDocument(order: Order): React.ReactNode {
    return (
      <div className="order-view-widget">
        {this.renderItems(order)}
        {/* {this.renderCollectionType(order)} */}
        {this.renderCollectionTime(order)}
        {this.renderDeliveryAddress(order)}
        {this.renderPayment(order)}
        {this.renderComments(order)}
        <div className="amounts">
          {this.renderSubtotal(order)}
          {this.renderDiscounts(order)}
          {this.renderDeliveryFee(order)}
          {this.renderCreditCardSurcharge(order)}
          {this.renderGrandTotal(order)}
        </div>
        {this.renderCreditCardSurchargeMessage(order)}
      </div>
    );
  }
  renderComments(order: Order): React.ReactNode {
    if (!order.cart.comments) return;
    return (
      <div className="comments">
        <i className={`icon las la-comment-alt`}></i>
        <label>Comments</label>
        <div className="value">{order.cart.comments}</div>
      </div>
    );
  }
  renderCollectionType(order: Order): React.ReactNode {
    return (
      <div className="collection-type">
        <Title icon="la-box">Collection</Title>
        <div className="value">{collectionTypeLabel[order.cart.collectionType]}</div>
      </div>
    );
  }
  renderCollectionTime(order: Order): React.ReactNode {
    if (!order.cart.collectionTime) return;
    return (
      <div className="collection-time">
        <Title icon="la-clock">{`${collectionTypeLabel[order.cart.collectionType]} Time`}</Title>
        <div className="value">{order.cart.collectionTime}</div>
      </div>
    );
  }
  renderDeliveryAddress(order: Order): React.ReactNode {
    if (order.cart.collectionType !== CollectionType.delivery) return;
    return (
      <div className="delivery-address">
        <i className={`icon las la-route`}></i>
        <label>Delivery Address</label>
        <div className="value">
          {`${order.cart.delivery?.address.line1}, ${order.cart.delivery?.address.suburb.name} - ${order.cart.delivery?.address.suburb.postcode}`}
        </div>
      </div>
    );
  }
  renderPayment(order: Order): React.ReactNode {
    return (
      <div className="payment">
        <i className={`icon las la-wallet`}></i>
        <label>Payment</label>
        {this.renderPaymentValue(order)}
        {this.renderPaymentStatus(order)}
      </div>
    );
  }
  renderPaymentValue(order: Order): React.ReactNode {
    const cardInfo = this.renderPaymentCardInfo(order);
    if (cardInfo) return cardInfo;
    return <div className="value">{paymentTypeLabels[order.cart.paymentType]}</div>;
  }
  renderPaymentStatus(order: Order): React.ReactNode {
    const status = order.payment?.status;
    if (!status) return;
    switch (status) {
      case PaymentStatus.success:
        return;
      case PaymentStatus.failure:
        if (order.status.code !== OrderStatusCode.failure) return <div className="status">Unexpected status!</div>;
        return <div className="status">Payment failed. {order.status.message}</div>;
      case PaymentStatus.processing:
        return <div className="status">Payment is being processed. Please wait ...</div>;
      case PaymentStatus.requiresCapture:
        return (
          <div className="status">
            The payment for this order is <u>reserved</u>. It will only be taken from the card when the order is
            accepted.
          </div>
        );
      case PaymentStatus.cancel:
        return (
          <div className="status">
            The payment previously <u>reserved</u> for this order has been cancelled. In short, your card was never
            charged.
          </div>
        );
      case PaymentStatus.refundSuccess:
        return (
          <div className="status">
            The payment has been successfully <u>refunded</u>.
          </div>
        );
      case PaymentStatus.refundFailure:
        return (
          <div className="status">
            The payment refund failed. Please contact
            <a href="mailto:support@restoplus.com.au">support@restoplus.com.au</a>
          </div>
        );
      default:
        console.error(`Unhandled payment status!`);
    }
    return;
  }
  renderPaymentCardInfo(order: Order): React.ReactNode {
    const card = order.payment?.card;
    if (order.cart.paymentType !== PaymentType.card || !card) return;
    return (
      <div className="payment-card-info value">
        {card.wallet && <img className="wallet" src={`/images/payment/${card.wallet}.svg`} />}
        <img className="brand" src={`/images/payment/${card.brand}.svg`} />
        <div className="last4">ending in {card.last4}</div>
      </div>
    );
  }
  renderSubtotal(order: Order): React.ReactNode {
    return (
      <div className="sub-total">
        <label>Sub-total</label>
        <div className="value">
          {formatAmount(order.cart.itemTotal, restaurantProvider.$restaurant?.current()?.country)}
        </div>
      </div>
    );
  }
  renderDiscounts(order: Order): React.ReactNode {
    const discount = order.cart.discount;
    if (discount <= 0) return;
    return (
      <div className="discount">
        <label>Discount</label>
        <div className="value">
          {formatAmount(order.cart.discount, restaurantProvider.$restaurant?.current()?.country)}
        </div>
      </div>
    );
  }
  renderCreditCardSurcharge(order: Order): React.ReactNode {
    const surcharge = order.cart.creditCardSurcharge;
    if (!surcharge) return;
    return (
      <div className="surcharge">
        <label>Surcharge</label>
        <div className="value">
          {formatAmount(surcharge.amount, restaurantProvider.$restaurant?.current()?.country)}
        </div>
      </div>
    );
  }
  renderDeliveryFee(order: Order): React.ReactNode {
    const deliveryCharge = order.cart.delivery?.charges ?? 0;
    if (!deliveryCharge) return;
    return (
      <div className="delivery-fee">
        <label>Delivery Fee</label>
        <div className="value">{formatAmount(deliveryCharge, restaurantProvider.$restaurant?.current()?.country)}</div>
      </div>
    );
  }
  renderGrandTotal(order: Order): React.ReactNode {
    return (
      <div className="grand-total">
        <label>Grand Total</label>
        <div className="value">
          {formatAmount(order.cart.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
        </div>
      </div>
    );
  }
  renderCreditCardSurchargeMessage(order: Order): React.ReactNode {
    const surcharge = order.cart.creditCardSurcharge;
    if (!surcharge) return;
    return <div className="surcharge-message">{surcharge.message}</div>;
  }
  renderItems(order: Order): React.ReactNode {
    return (
      <div className="cart-items">
        <Title icon="la-list-ul">Items</Title>
        {order.cart.items.map(item => this.renderItem(item, order))}
      </div>
    );
  }
  renderItem(item: Item, order: Order): React.ReactNode {
    return (
      <div className="cart-item" key={item.id}>
        <div className="header">
          <div className="name">
            {item.summary.quantity} x {item.summary.name}
          </div>
          <div className="price">
            {formatAmount(
              (item.summary.price[order.cart.collectionType] || 0) * item.summary.quantity,
              restaurantProvider.$restaurant?.current()?.country
            )}
          </div>
        </div>
        <div className="footer">
          <div className="summary">
            <pre>{item.summary.description}</pre>
          </div>
        </div>
      </div>
    );
  }
}
