import { AspectRatio, formatAmount, ItemId, ItemSpec, routes, idFactory } from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SortableDocumentViewWidget } from '../document/SortableDocumentViewWidget';
import { Imgix } from '../elements/Imgix';
import { restaurantProvider } from '../provider/restaurantProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';
import { itemSpecUtils } from '../utils/itemSpecUtils';

type Props = {};

@observer
export class ItemSpecViewWidget extends SortableDocumentViewWidget<ItemId, ItemSpec, Props> {
  //
  getRepo(): DocumentRepo<ItemId, ItemSpec> {
    return repo.item;
  }

  renderDocument(item: ItemSpec): React.ReactNode {
    //
    return (
      <div className="item-spec">
        <div className="body" onClick={() => this.gotoEditItem(item)}>
          <Imgix image={item.image} aspectRatio={AspectRatio.horizontal} />
          <div className="name">{item.name}</div>
          <div className="description">{item.description}</div>
          <div className="price">
            {formatAmount(itemSpecUtils.getMinPrice(item), restaurantProvider.$restaurant?.current()?.country)}
          </div>
        </div>
        {this.renderActions(item)}
      </div>
    );
  }

  renderActions = (item: ItemSpec) => {
    return (
      <div className="actions">
        <div className="action" onClick={() => this.gotoEditItem(item)}>
          <div className="circle">
            <i className={`icon las la-edit`}></i>
          </div>
        </div>
        <div
          className="action"
          onClick={() => {
            const id = idFactory.itemId();
            confirm(`Copy this item?`) &&
              repo.item.put({ ...this.props.id, itemId: id }, { ...item, id, name: `${item.name} Copy` });
          }}
        >
          <i className={`icon las la-copy`}></i>
        </div>
        <div className="action" onClick={() => this.moveUp(item)}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => this.moveDown(item)}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>

        <div className="action" onClick={() => confirm(`Delete Item?`) && repo.item.delete(this.props.id)}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  };

  gotoEditItem(item: ItemSpec): void {
    switch (item.type) {
      case ItemType.basic:
        gotoPath(routes.backoffice.editItem.link(this.props.id, {}));
        return;
      case ItemType.pizza:
        gotoPath(routes.backoffice.editPizza.link(this.props.id, {}));
        return;
      case ItemType.deal:
        gotoPath(routes.backoffice.editDeal.link(this.props.id, {}));
        return;
    }
  }
}
