import { Page } from '../elements/Page';
import { RestaurantEditWidget } from './RestaurantEditWidget';
import React = require('react');

type Props = {};
type RouteProps = {};

export class RestaurantCreatePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Create Restaurant';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <RestaurantEditWidget documentId={{ restaurantId: '' }} create={true} />;
  }
}
