/**
 * State transition
 *
 * processingPayment -> For credit card transactions, we use stripe checkout, so when the order is placed, it will be processingPayment (payment is happening outside of our platform), unless we receive a webhook from stripe to confirm the payment for this order
 * failure -> end
 * new -> rejected -> end // store directly rejects the order - say restaurant closed
 * new -> accepted -> rejected -> end // store first accepts and then rejects, for whatever reason
 * new -> accepted -> completed -> rejected -> end // store first accepts, then prepares and markes the order as complete and then rejects for whatever reason
 * new -> cancelled -> end // customer place the order and then cancels after 10 minutes (before the store accepts the order)
 *
 * The above is just the possible transitions. Most orders will simply be in `accepted` state.
 * `completed` status will only be used by those stores who want to send 'ready-for-pickup' notification to their customers
 */
export enum OrderStatusCode {
  processingPayment = 'processingPayment',
  failure = 'failure',
  new = 'new',
  accepted = 'accepted',
  rejected = 'rejected',
  cancelled = 'cancelled',
  completed = 'completed' // completed, hence ready for pickup
}

type OrderStatusProcessingPayment = {
  code: OrderStatusCode.processingPayment;
};
type OrderStatusNew = {
  code: OrderStatusCode.new;
};
type OrderStatusFailure = {
  code: OrderStatusCode.failure;
  message: string;
};
type OrderStatusAccepted = {
  code: OrderStatusCode.accepted;
};
type OrderStatusRejected = {
  code: OrderStatusCode.rejected;
  reason: string;
};
type OrderStatusCancelled = {
  code: OrderStatusCode.cancelled;
};
type OrderStatusCompleted = {
  code: OrderStatusCode.completed;
};

export type OrderStatus =
  | OrderStatusProcessingPayment
  | OrderStatusNew
  | OrderStatusFailure
  | OrderStatusAccepted
  | OrderStatusRejected
  | OrderStatusCancelled
  | OrderStatusCompleted;
