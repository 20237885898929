import { CategoryId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Button } from '../elements/Button';
import { Page } from '../elements/Page';
import { ItemSpecListWidget } from '../item_spec/ItemSpecListWidget';
import { userProvider } from '../provider/userProvider';
import { repo } from '../repo/repo';
import React = require('react');
import { DropDownListItem, DropDown } from '../elements/DropDown';
import { gotoPath } from '../utils/browserHistory';

type Props = {};
type RouteProps = CategoryId & {};

@observer
export class CategoryHomePage extends Page<Props, RouteProps> {
  //
  $category = repo.category.bind(this.props.match.params);

  getTitle(): string {
    const category = this.$category.current();
    if (!category) return '';
    return category.name;
  }

  renderActions(): React.ReactNode {
    const itemList: DropDownListItem[] = [
      {
        label: 'Basic Item',
        onClick: () => gotoPath(routes.backoffice.createItem.link({ ...this.props.match.params }, {}))
      },
      {
        label: 'Pizza',
        onClick: () => gotoPath(routes.backoffice.createPizza.link({ ...this.props.match.params }, {}))
      },
      {
        label: 'Deal',
        onClick: () => gotoPath(routes.backoffice.createDeal.link({ ...this.props.match.params }, {}))
      }
    ];
    return (
      <DropDown label="Create Item" list={itemList} icon={'la-plus-circle'}>
        Create Item
      </DropDown>
    );
  }

  renderBody(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return <span></span>;
    return (
      <div className={'category-home-page'}>
        <ItemSpecListWidget {...this.props.match.params} />
      </div>
    );
  }
}
