export type TimeOfDay = {
  label: string;
  hhmm: string;
};

export const TIME_OF_DAY_LIST: TimeOfDay[] = [
  {
    label: '00:00 AM',
    hhmm: '00:00'
  },
  {
    label: '00:30 AM',
    hhmm: '00:30'
  },
  {
    label: '01:00 AM',
    hhmm: '01:00'
  },
  {
    label: '01:30 AM',
    hhmm: '01:30'
  },
  {
    label: '02:00 AM',
    hhmm: '02:00'
  },
  {
    label: '02:30 AM',
    hhmm: '02:30'
  },
  {
    label: '03:00 AM',
    hhmm: '03:00'
  },
  {
    label: '03:30 AM',
    hhmm: '03:30'
  },
  {
    label: '04:00 AM',
    hhmm: '04:00'
  },
  {
    label: '04:30 AM',
    hhmm: '04:30'
  },
  {
    label: '05:00 AM',
    hhmm: '05:00'
  },
  {
    label: '05:30 AM',
    hhmm: '05:30'
  },
  {
    label: '06:00 AM',
    hhmm: '06:00'
  },
  {
    label: '06:30 AM',
    hhmm: '06:30'
  },
  {
    label: '07:00 AM',
    hhmm: '07:00'
  },
  {
    label: '07:30 AM',
    hhmm: '07:30'
  },
  {
    label: '08:00 AM',
    hhmm: '08:00'
  },
  {
    label: '08:30 AM',
    hhmm: '08:30'
  },
  {
    label: '09:00 AM',
    hhmm: '09:00'
  },
  {
    label: '09:30 AM',
    hhmm: '09:30'
  },
  {
    label: '10:00 AM',
    hhmm: '10:00'
  },
  {
    label: '10:30 AM',
    hhmm: '10:30'
  },
  {
    label: '11:00 AM',
    hhmm: '11:00'
  },
  {
    label: '11:30 AM',
    hhmm: '11:30'
  },
  {
    label: '12:00 PM',
    hhmm: '12:00'
  },
  {
    label: '12:30 PM',
    hhmm: '12:30'
  },
  {
    label: '01:00 PM',
    hhmm: '13:00'
  },
  {
    label: '01:30 PM',
    hhmm: '13:30'
  },
  {
    label: '02:00 PM',
    hhmm: '14:00'
  },
  {
    label: '02:30 PM',
    hhmm: '14:30'
  },
  {
    label: '03:00 PM',
    hhmm: '15:00'
  },
  {
    label: '03:30 PM',
    hhmm: '15:30'
  },
  {
    label: '04:00 PM',
    hhmm: '16:00'
  },
  {
    label: '04:30 PM',
    hhmm: '16:30'
  },
  {
    label: '05:00 PM',
    hhmm: '17:00'
  },
  {
    label: '05:30 PM',
    hhmm: '17:30'
  },
  {
    label: '06:00 PM',
    hhmm: '18:00'
  },
  {
    label: '06:30 PM',
    hhmm: '18:30'
  },
  {
    label: '07:00 PM',
    hhmm: '19:00'
  },
  {
    label: '07:30 PM',
    hhmm: '19:30'
  },
  {
    label: '08:00 PM',
    hhmm: '20:00'
  },
  {
    label: '08:30 PM',
    hhmm: '20:30'
  },
  {
    label: '09:00 PM',
    hhmm: '21:00'
  },
  {
    label: '09:30 PM',
    hhmm: '21:30'
  },
  {
    label: '10:00 PM',
    hhmm: '22:00'
  },
  {
    label: '10:30 PM',
    hhmm: '22:30'
  },
  {
    label: '11:00 PM',
    hhmm: '23:00'
  },
  {
    label: '11:30 PM',
    hhmm: '23:30'
  }
];
