export enum AspectRatio {
  // none = 'none',
  horizontalThin = 'fourTwo',
  horizontalRegular = 'fourTwoPointFive',
  horizontal = 'fourThree',
  square = 'square',
  vertical = 'fourFive',
  verticalLong = 'fourSix'
}

export type AspectRatioInfo = {
  id: AspectRatio;
  label: string;
  width: number;
  height: number;
};

export const horizontalThin = { id: AspectRatio.horizontalThin, label: 'Horizontal Thin (4x2)', width: 4, height: 2 };
// prettier-ignore
export const horizontalRegular = { id: AspectRatio.horizontalRegular, label: 'Horizontal Regular (4x2.5)', width: 4, height: 2.5 }
export const horizontal = { id: AspectRatio.horizontal, label: 'Horizontal (4x3)', width: 4, height: 3 };
export const square = { id: AspectRatio.square, label: 'Square', width: 1, height: 1 };
export const vertical = { id: AspectRatio.vertical, label: 'Vertical (4x5)', width: 4, height: 5 };
export const verticalLong = { id: AspectRatio.verticalLong, label: 'Vertical Long (4x6)', width: 4, height: 6 };

export const aspectRatioList: AspectRatioInfo[] = [
  horizontalThin,
  horizontalRegular,
  horizontal,
  square,
  vertical,
  verticalLong
];

export const aspectRatioMap: { [key in AspectRatio]: AspectRatioInfo } = {
  [AspectRatio.horizontalThin]: horizontalThin,
  [AspectRatio.horizontalRegular]: horizontalRegular,
  [AspectRatio.horizontal]: horizontal,
  [AspectRatio.square]: square,
  [AspectRatio.vertical]: vertical,
  [AspectRatio.verticalLong]: verticalLong
};
