import { CategoryId, BaseItemSpec, ItemSpec } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ResolveResource } from '../elements/ResolveResource';
import { Widget } from '../elements/Widget';
import { repo } from '../repo/repo';
import { sortOrderProp } from '../utils/sortOrderProp';
import { ItemSpecViewWidget } from './ItemSpecViewWidget';

type Props = CategoryId;

@observer
export class ItemSpecListWidget extends Widget<Props> {
  //
  $items = repo.items.bindCollection(this.props);

  renderItems(items: ItemSpec[]) {
    if (!items.length) return <div>Items empty</div>;
    const sorted = items.sort((a, b) => a.sortOrder - b.sortOrder);
    // filter by cateogry
    const categoryItems = items.filter(item => item.categoryId === this.props.categoryId);
    if (!categoryItems.length) return <div>Items empty</div>;
    return categoryItems.map((item, i, list) => (
      <ItemSpecViewWidget
        key={item.id}
        id={{ itemId: item.id, ...this.props }}
        document={item}
        sortOrder={sortOrderProp(list, i)}
      />
    ));
  }

  render() {
    return (
      <ResolveResource resource={this.$items}>
        {items => <div className="item-spec-list">{this.renderItems(items!)}</div>}
      </ResolveResource>
    );
  }
}
