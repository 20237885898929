import React = require('react');
import { routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { customerCart } from '../../provider/customerCartProvider';
import { gotoPath } from '../../utils/browserHistory';
import { locationProvider } from '../../provider/locationProvider';

// type RouteProps = StoreId & {};

type Props = StoreId & {
  position: 'bottom' | 'top';
};

@observer
export class OnlineOrderingBottomNavBar extends React.Component<Props> {
  renderIcon = (args: { label: string; icon: string; path: string }): React.ReactNode => {
    const selected = args.path ? locationProvider.isSelected(args.path) : false;
    return (
      <div className={`item ${selected && 'selected'}`} onClick={() => gotoPath(args.path)}>
        <div className="icon">
          <i className={`las ${args.icon}`}></i>
        </div>
        <div className="label">{args.label}</div>
      </div>
    );
  };

  renderCart = (args: { label: string; icon: string; path: string; count: number }): React.ReactNode => {
    const selected = locationProvider.isSelected(args.path);
    return (
      <div className={`cart ${selected && 'selected'}`} onClick={() => gotoPath(args.path)}>
        <div className="piller"></div>
        <div className="content">
          <div className="icon">
            <i className={`las ${args.icon}`}></i>
          </div>
          <div className="label">{args.label}</div>
          <div className="count">
            <span>{args.count}</span>
          </div>
        </div>
        <div className="piller"></div>
      </div>
    );
  };

  render() {
    return (
      <footer className={`online-ordering-bottom-nav-bar`}>
        {this.renderIcon({
          label: 'Menu',
          icon: 'la-clipboard-list',
          path: routes.website.onlineOrdering.menu.link(this.props, {})
        })}
        {this.renderIcon({
          label: 'Coupons',
          icon: 'la-ticket-alt',
          path: routes.website.onlineOrdering.coupons.link(this.props, {})
        })}
        {this.renderIcon({
          label: 'Loyalty Card',
          icon: 'la-credit-card',
          path: routes.website.onlineOrdering.loyaltyCard.link(this.props, {})
        })}
        {this.renderCart({
          label: 'Cart',
          icon: 'la-shopping-cart',
          path: routes.website.onlineOrdering.checkout.link(this.props, {}),
          count: customerCart.cartItems.length
        })}
      </footer>
    );
  }
}
