import * as React from 'react';
import { ResolveResource } from '../elements/ResolveResource';
import { DocumentRepo } from '../repo/DocumentRepo';

type Props<Id, T> = {
  id: Id;
  document?: T;
};

export abstract class DocumentViewWidget<Id, T, AdditionalProps> extends React.Component<
  Props<Id, T> & AdditionalProps
> {
  //
  $document = this.getRepo().bind(this.props.id);

  abstract getRepo(): DocumentRepo<Id, T>;
  abstract renderDocument(document: T): React.ReactNode;

  render() {
    const { document } = this.props;
    if (document) return this.renderDocument(document as T);
    return <ResolveResource resource={this.$document}>{doc => this.renderDocument(doc!)}</ResolveResource>;
  }
}
