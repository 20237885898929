import * as React from 'react';
import { FormItem } from './FormItem';
import { FormItemProps, FormItemContainer } from './FormItemContainer';
import { validationUtils } from '../utils/validationUtils';

type Props = Omit<FormItemProps, 'validation'> & {
  value: string;
  icon?: {
    name: string;
    type: 'success' | 'failure' | 'warning';
  };
};

export class FormKeyValuePair extends React.Component<Props> {
  //
  render() {
    const { value } = this.props;
    return (
      <FormItemContainer {...this.props} className="key-value-pair" validation={validationUtils.success}>
        <span>{value}</span>
        {this.renderIcon()}
      </FormItemContainer>
    );
  }

  renderIcon() {
    const { icon } = this.props;
    if (!icon) return;
    return (
      <div className={`icon ${icon.type}`}>
        <i className={`las ${icon.name}`}></i>
      </div>
    );
  }
}
