import { routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from '../../auth/LoginPage';
import { BasicItemPage } from '../pages/BasicItemPage';
import { CheckoutPage } from '../pages/CheckoutPage';
import { DealPage } from '../pages/DealPage';
import { ItemsPage } from '../pages/ItemsPage';
import { MenuPage } from '../pages/MenuPage';
import { PizzaPage } from '../pages/PizzaPage';
import { OnlineOrderingSecureRouter } from './OnlineOrderingSecureRouter';
import { CouponsPage } from '../pages/CouponsPage';
import { LoyaltyCardPage } from '../pages/LoyaltyCardPage';

@observer
export class OnlineOrderingRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path={routes.website.onlineOrdering.home.template}
          render={() => (
            <>
              <Switch>
                <Route exact path={routes.website.onlineOrdering.login.template} component={LoginPage} />
                <Route exact path={routes.website.onlineOrdering.menu.template} component={MenuPage} />
                <Route exact path={routes.website.onlineOrdering.coupons.template} component={CouponsPage} />
                <Route exact path={routes.website.onlineOrdering.loyaltyCard.template} component={LoyaltyCardPage} />
                <Route exact path={routes.website.onlineOrdering.items.template} component={ItemsPage} />
                <Route exact path={routes.website.onlineOrdering.item.template} component={BasicItemPage} />
                <Route exact path={routes.website.onlineOrdering.pizza.template} component={PizzaPage} />
                <Route exact path={routes.website.onlineOrdering.deal.template} component={DealPage} />
                <Route exact path={routes.website.onlineOrdering.checkout.template} component={CheckoutPage} />
                <Route
                  path={routes.website.onlineOrdering.secure.home.template}
                  component={OnlineOrderingSecureRouter}
                />
                <Route render={() => <div>Check website router</div>} />
              </Switch>
            </>
          )}
        />
      </Switch>
    );
  }
}
