import {
  DealSpec,
  DealPizzaSpec,
  flatten,
  repeat,
  DealItemSpec,
  DealPizza,
  DealItem,
  ModifiersValue,
  PizzaSettings,
  PizzaSpec,
  nth,
  CollectionType,
  DealPizzaSelection,
  max,
  collectionTypes,
  defaults,
  ItemType,
  isDealPizzaFull,
  isDealPizzaHalfAndHalf
} from '@restoplus/core';
import { pizzaUtils } from './pizzaUtils';
import { modifierUtils } from './modifierUtils';
import { priceSpecUtils } from './priceSpecUtils';
import { ItemPrice } from '@restoplus/core/src/models/item/ItemPrice';

/**
 * each deal line item has quantity, so these variables here expand the specs according to the quantity,
 * such that it's easy later
 */
const flattenDealPizzaSpecs = (dealSpec: DealSpec | null): DealPizzaSpec[] => {
  if (!dealSpec) return [];
  return flatten(dealSpec.pizzas.map(dealPizza => repeat(dealPizza, dealPizza.quantity)));
};

const flattenDealItemSpecs = (dealSpec: DealSpec | null): DealItemSpec[] => {
  if (!dealSpec) return [];
  return flatten(dealSpec.items.map(dealItem => repeat(dealItem, dealItem.quantity)));
};

const getSummary = (
  dealSpec: DealSpec,
  dealPizzaSpecs: DealPizzaSpec[],
  dealItemSpecs: DealItemSpec[],
  dealPizzas: DealPizza[],
  dealItems: DealItem[],
  modifiersValue: ModifiersValue,
  pizzaSettings: PizzaSettings,
  pizzaList: PizzaSpec[]
) => {
  const itemSeperator = '-';
  // first add the description for deals
  const summaries = [dealSpec.description, itemSeperator];

  // for each pizza
  for (let pi = 0; pi < dealPizzas.length; pi++) {
    // pizza

    const dealPizza = dealPizzas[pi];
    if (!pizzaList.length) return '';

    const dealPizzaSpec = dealPizzaSpecs[pi];
    const sizeId = dealPizzaSpec.sizeId;

    // half & half pizza
    if (isDealPizzaHalfAndHalf(dealPizza)) {
      summaries.push(`${pi + 1}${nth(pi + 1)} Pizza:`);

      // check if both halfs are selected
      const firstHalfId = dealPizza.firstHalf.pizzaId;
      if (firstHalfId) {
        summaries.push(`First Half: ${pizzaList.find(p => p.id == firstHalfId)?.name}`);
        const summary = pizzaUtils.getToppingsSummary({
          settings: pizzaSettings,
          selection: {
            type: ItemType.pizza,
            sizeId,
            removeToppings: dealPizza.firstHalf.removeToppings,
            addToppingsIds: dealPizza.firstHalf.addToppingsIds
          }
        });
        if (summary) summaries.push(summary);
      }

      const secondHalfId = dealPizza.secondHalf.pizzaId;
      if (secondHalfId) {
        summaries.push(`Second Half: ${pizzaList.find(p => p.id == secondHalfId)?.name}`);
        const summary = pizzaUtils.getToppingsSummary({
          selection: {
            type: ItemType.pizza,
            sizeId,
            removeToppings: dealPizza.secondHalf.removeToppings,
            addToppingsIds: dealPizza.secondHalf.addToppingsIds
          },
          settings: pizzaSettings
        });
        if (summary) summaries.push(summary);
      }
    } else {
      const pizzaEntry = dealPizza;
      // full pizza
      if (pizzaEntry.pizzaId) {
        summaries.push(`${pi + 1}${nth(pi + 1)} Pizza: ${pizzaList.find(p => p.id == pizzaEntry.pizzaId)?.name}`);
        const summary = pizzaUtils.getToppingsSummary({
          selection: {
            type: ItemType.pizza,
            sizeId,
            removeToppings: dealPizza.removeToppings,
            addToppingsIds: dealPizza.addToppingsIds
          },
          settings: pizzaSettings
        });
        if (summary) summaries.push(summary);
      }
    }
    summaries.push(itemSeperator);
  }

  // for each item
  for (let ii = 0; ii < dealSpec.items.length; ii++) {
    const dealItem = dealItems[ii];
    const dealItemSpec = dealItemSpecs[ii];

    summaries.push(`${dealItemSpec.name}:`);
    const summary = modifierUtils.getModifiersSummary({
      modifiersSpec: dealItemSpec.modifiers,
      modifiersValue: dealItem.modifiersValue
    });
    if (summary) summaries.push(summary);
    summaries.push(itemSeperator);
  }

  // deal modifiers
  const modifiersSummary = modifierUtils.getModifiersSummary({ modifiersSpec: dealSpec.modifiers, modifiersValue });
  modifiersSummary && summaries.push(modifiersSummary);

  return summaries.join('\n');
};

const getDealPizzaEntryExtraCharge = (args: {
  sizeId: string;
  dealPizzaSpec: DealPizzaSpec;
  dealPizzaSelection: DealPizzaSelection;
  pizzaList: PizzaSpec[];
  pizzaSettings: PizzaSettings;
}): number => {
  // pizza not yet choosen
  const pizzaId = args.dealPizzaSelection.pizzaId;
  if (!pizzaId) return 0;

  // spec
  const pizzaSpec = args.pizzaList.find(p => p.id == pizzaId);
  if (!pizzaSpec) return 0;

  // get extra charge
  return args.dealPizzaSpec.pizzas[pizzaId]?.extraCharge || 0;
};

const getDealPizzaItemExtraCharge = (args: {
  sizeId: string;
  dealPizzaSpec: DealPizzaSpec;
  dealPizza: DealPizza;
  pizzaList: PizzaSpec[];
  pizzaSettings: PizzaSettings;
}): number => {
  // full pizza
  if (isDealPizzaFull(args.dealPizza)) {
    return getDealPizzaEntryExtraCharge({ ...args, dealPizzaSelection: args.dealPizza });
  }

  // half & half
  const firstHalfExtraCharge = getDealPizzaEntryExtraCharge({
    ...args,
    dealPizzaSelection: args.dealPizza.firstHalf
  });

  const secondHalfExtraCharge = getDealPizzaEntryExtraCharge({
    ...args,
    dealPizzaSelection: args.dealPizza.secondHalf
  });

  return max(firstHalfExtraCharge, secondHalfExtraCharge);
};

// const getDealPizzaEntryToppings = (args: {
//   sizeId: string;
//   dealPizzaSpec: DealPizzaSpec;
//   dealPizzaEntry: DealPizzaEntry;
//   pizzaList: PizzaSpec[];
//   pizzaSettings: PizzaSettings;
// }): Topping[] => {
//   // pizza not choosen
//   const pizzaId = args.dealPizzaEntry.pizzaId;
//   if (!pizzaId) return [];

//   // spec
//   const pizzaSpec = args.pizzaList.find(p => p.id == pizzaId);
//   if (!pizzaSpec) return [];

//   // get toppings
//   return pizzaUtils.getAddToppings({
//     ...args,
//     addToppingsIds: args.dealPizzaEntry.addToppingsIds
//   });
// };

const getDealPizzaToppingsExtraCharge = (args: {
  sizeId: string;
  dealPizzaSpec: DealPizzaSpec;
  dealPizza: DealPizza;
  pizzaList: PizzaSpec[];
  pizzaSettings: PizzaSettings;
}): number => {
  // full pizza
  if (isDealPizzaFull(args.dealPizza)) {
    return pizzaUtils.getExtraChargeForToppings({
      settings: args.pizzaSettings,
      selection: {
        removeToppings: [],
        addToppingsIds: args.dealPizza.addToppingsIds,
        sizeId: args.sizeId,
        type: ItemType.pizza
      }
    });
  }

  // half & half
  const firstHalfToppings = pizzaUtils.getSelectedToppings({
    settings: args.pizzaSettings,
    selection: {
      removeToppings: [],
      addToppingsIds: args.dealPizza.firstHalf.addToppingsIds,
      sizeId: args.sizeId,
      type: ItemType.pizza
    }
  });
  const secondHalfToppings = pizzaUtils.getSelectedToppings({
    settings: args.pizzaSettings,
    selection: {
      removeToppings: [],
      addToppingsIds: args.dealPizza.secondHalf.addToppingsIds,
      sizeId: args.sizeId,
      type: ItemType.pizza
    }
  });

  // if customer chooses same topping on both side, charge only once
  const allToppings: { [toppingId: string]: number } = {};
  firstHalfToppings.forEach(topping => (allToppings[topping.id] = topping.prices[args.sizeId] ?? 0));
  secondHalfToppings.forEach(topping => (allToppings[topping.id] = topping.prices[args.sizeId] ?? 0));

  return Object.values(allToppings).reduce((total, current) => total + current, 0);
};

const getPriceForCollectionType = (args: {
  dealSpec: DealSpec;
  dealPizzaSpecs: DealPizzaSpec[];
  dealItemSpecs: DealItemSpec[];
  dealPizzas: DealPizza[];
  dealItems: DealItem[];
  modifiersValue: ModifiersValue;
  pizzaSettings: PizzaSettings;
  pizzaList: PizzaSpec[];
  collectionType: CollectionType;
}) => {
  const { dealSpec, dealPizzaSpecs, dealItemSpecs, dealPizzas } = args;
  const { dealItems, modifiersValue, pizzaSettings, pizzaList, collectionType } = args;

  const basePrice = priceSpecUtils.getBasePrice(dealSpec.price, collectionType);

  // deal items
  const dealItemsExtraCharge = dealItems.reduce((total, dealItem, ii) => {
    const dealItemSpec = dealItemSpecs[ii];
    return (
      total +
      modifierUtils.getModifiersExtraCharge({
        modifierSpecs: dealItemSpec.modifiers,
        modifiersValue: dealItem.modifiersValue
      })
    );
  }, 0);

  // deal pizzas
  const dealPizzaExtraCharge = dealPizzas.reduce((total, dealPizza, pi) => {
    const dealPizzaSpec = dealPizzaSpecs[pi];
    const sizeId = dealPizzaSpec.sizeId;
    // item extra charge
    const dealPizzaItemExtraCharge = getDealPizzaItemExtraCharge({ ...args, sizeId, dealPizzaSpec, dealPizza });
    // toppings extra charge
    const dealPizzaToppingsExtraCharge = getDealPizzaToppingsExtraCharge({ ...args, sizeId, dealPizzaSpec, dealPizza });
    // global half and half extra charge
    const pizzaSizeSpec = pizzaSettings.sizes.find(sizeConfig => sizeConfig.id == sizeId);
    const pizzaHalfAndHalfExtraCharge = isDealPizzaHalfAndHalf(dealPizza)
      ? pizzaSizeSpec?.halfAndHalfExtraCharge || 0
      : 0;

    return total + dealPizzaItemExtraCharge + dealPizzaToppingsExtraCharge + pizzaHalfAndHalfExtraCharge;
  }, 0);

  // deal modifiers
  const dealModifiersExtraCharge = modifierUtils.getModifiersExtraCharge({
    modifierSpecs: dealSpec.modifiers,
    modifiersValue: args.modifiersValue
  });

  return basePrice + dealPizzaExtraCharge + dealItemsExtraCharge + dealModifiersExtraCharge;
};

const getPrice = (args: {
  dealSpec: DealSpec;
  dealPizzaSpecs: DealPizzaSpec[];
  dealItemSpecs: DealItemSpec[];
  dealPizzas: DealPizza[];
  dealItems: DealItem[];
  modifiersValue: ModifiersValue;
  pizzaSettings: PizzaSettings;
  pizzaList: PizzaSpec[];
}): ItemPrice => {
  return collectionTypes.reduce((result, collectionType) => {
    if (!args.dealSpec.availableFor[collectionType]) return result;
    result[collectionType] = getPriceForCollectionType({ ...args, collectionType });
    return result;
  }, defaults.itemPrice);
};

export const dealUtils = {
  flattenDealPizzaSpecs,
  flattenDealItemSpecs,
  getSummary,
  getPrice
};
