import { User } from 'firebase';
import { computed, observable } from 'mobx';
import { fb } from '../integration/fb';
import { toastStore } from '../toast/toastStore';

class UserProvider {
  //
  @observable token: string | null;

  // user is undefined if the app is still booting and if the `onIdTokenChanged` is not called yet.
  // user is null if user not logged in
  @observable user: User | null | undefined;
  @observable isSuperAdmin = false;
  @observable phoneNumber: string | null | undefined;
  @observable displayName: string | null | undefined;

  @computed
  get uid(): string | undefined {
    if (this.user) return this.user.uid;
    return;
  }

  @computed
  get loggedIn(): boolean {
    if (this.user) return true;
    return false;
  }

  @computed
  get email(): string {
    if (this.user) return this.user.email || '';
    return '';
  }

  @computed
  get emailVerified(): boolean {
    if (!this.user) return false;
    return this.user.emailVerified;
  }

  logout = () => {
    fb.auth()
      .signOut()
      .then(() => toastStore.info(`Successfully logged out!`))
      .catch(err => toastStore.error(err.message));
  };
}

export const userProvider = new UserProvider();

fb.auth().onIdTokenChanged(user => {
  console.log(`onIdTokenChanged`, user);
  //
  if (!user) {
    console.log(`User logged out`);
    userProvider.user = null;
    userProvider.isSuperAdmin = false;
    return;
  }
  // store user
  userProvider.user = user;

  // update phone number and name only if it's present
  if (user.phoneNumber) userProvider.phoneNumber = user.phoneNumber;
  if (user.displayName) userProvider.displayName = user.displayName;

  // get token
  user.getIdToken().then(token => {
    userProvider.token = token;
  });

  // super-admin flag
  user.getIdTokenResult().then(result => {
    userProvider.isSuperAdmin = result.claims?.superAdmin ?? false;
  });
});
