import { computed } from 'mobx';
import { Path } from 'path-parser';
import { repo } from '../repo/repo';
import { locationProvider } from './locationProvider';

// provides an observable instance of current restaurant, based on the url
class RestaurantProvider {
  //
  @computed
  get restaurantId(): string | null {
    const template = '*prefix/restaurants/:restaurantId';
    const path = new Path(template);
    const match = path.partialTest(locationProvider.path);
    if (!match) return null;
    return match.restaurantId;
  }

  @computed
  get $restaurant() {
    const restaurantId = this.restaurantId;
    if (!restaurantId) return null;
    return repo.restaurant.bind({ restaurantId });
  }
}

export const restaurantProvider = new RestaurantProvider();
