import { HasSortOrder } from '@restoplus/core';
import * as React from 'react';
import { DocumentRepo } from '../repo/DocumentRepo';
import { getNextSortOrder, getPrevSortOrder, SortOrderProp } from '../utils/sortOrderProp';
import { DocumentViewWidget } from './DocumentViewWidget';

type Props = {
  sortOrder: SortOrderProp;
};

export abstract class SortableDocumentViewWidget<
  Id,
  T extends HasSortOrder,
  AdditionalProps
> extends DocumentViewWidget<Id, T, Props & AdditionalProps> {
  //
  $document = this.getRepo().bind(this.props.id);

  abstract getRepo(): DocumentRepo<Id, T>;
  abstract renderDocument(document: T): React.ReactNode;

  // moveUp = (document: T, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  moveUp = (document: T) => {
    const { sortOrder } = this.props;
    const newSortOrder = getPrevSortOrder(sortOrder);
    if (!newSortOrder) return;
    document.sortOrder = newSortOrder;
    this.getRepo().put(this.props.id, document);
  };

  moveDown = (document: T) => {
    const { sortOrder } = this.props;
    if (!sortOrder) return;
    const newSortOrder = getNextSortOrder(sortOrder);
    if (!newSortOrder) return;
    if (!document) return;
    document.sortOrder = newSortOrder;
    this.getRepo().put(this.props.id, document);
  };
}
