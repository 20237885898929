import { CollectionType, PriceSpec } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result } from 'nutso';
import * as React from 'react';
import { FormNumberInput } from '../forms/FormNumberInput';
import { FormSwitch } from '../forms/FormSwitch';
import { NumberInput } from '../forms/NumberInput';
import { optionalFlag } from '../utils/optionalFlag';
import { validationUtils } from '../utils/validationUtils';

type Props = {
  priceSpec: PriceSpec;
  validation: Result<PriceSpec>;
};

@observer
export class PriceSpecEditWidget extends React.Component<Props> {
  render() {
    const { priceSpec, validation } = this.props;
    return (
      <div className="price-spec">
        <FormNumberInput
          label={'Base Price'}
          value={priceSpec.basePrice}
          onChange={value => (priceSpec.basePrice = value)}
          validation={validation.properties.basePrice}
          className="base-price"
        />
        <FormSwitch
          label="Variable Pricing"
          helpText="Different prices for pickup, delivery and dine-in"
          {...optionalFlag(priceSpec, 'extraCharge', {
            pickup: 0,
            delivery: 0,
            dineIn: 0
          })}
        />
        {this.renderExtraCharges(priceSpec, validation)}
      </div>
    );
  }

  renderExtraCharges(priceSpec: PriceSpec, validation: Result<PriceSpec>) {
    const { extraCharge } = priceSpec;
    if (!extraCharge) return;
    return (
      <div className="extra-charge">
        {this.renderExtraCharge({
          priceSpec,
          validation,
          label: 'Pickup Extra Charge',
          collectionType: CollectionType.pickup
        })}
        {this.renderExtraCharge({
          priceSpec,
          validation,
          label: 'Delivery Extra Charge',
          collectionType: CollectionType.delivery
        })}
        {this.renderExtraCharge({
          priceSpec,
          validation,
          label: 'Dine-in Extra Charge',
          collectionType: CollectionType.dineIn
        })}
      </div>
    );
  }

  renderExtraCharge(args: {
    priceSpec: PriceSpec;
    label: string;
    collectionType: CollectionType;
    validation: Result<PriceSpec>;
  }) {
    const { extraCharge } = args.priceSpec;
    if (!extraCharge) return;
    return (
      <div className="extra">
        <FormNumberInput
          label={args.label}
          value={extraCharge[args.collectionType] || 0}
          onChange={value => (extraCharge[args.collectionType] = value)}
          validation={args.validation.properties.extraCharge!.properties[args.collectionType]!}
          className="extra-price"
        />
        <NumberInput
          disabled={true}
          value={args.priceSpec.basePrice + (extraCharge[args.collectionType] || 0)}
          onChange={() => {}}
          validation={validationUtils.success}
        />
      </div>
    );
  }
}
