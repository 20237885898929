import { AspectRatio, Restaurant, RestaurantId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { DocumentViewWidget } from '../document/DocumentViewWidget';
import { Imgix } from '../elements/Imgix';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';

@observer
export class RestaurantViewWidget extends DocumentViewWidget<RestaurantId, Restaurant, {}> {
  //
  getRepo(): DocumentRepo<RestaurantId, Restaurant> {
    return repo.restaurant;
  }
  renderDocument(restaurant: Restaurant): React.ReactNode {
    return (
      <div
        className="restaurant"
        onClick={() => gotoPath(routes.backoffice.restaurant.link({ restaurantId: restaurant.id }, {}))}
      >
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="name">{restaurant.name}</div>
        <div className="description">{restaurant.description}</div>
        <div className="timezone">{restaurant.timezone}</div>
        <div className="country">{restaurant.country}</div>
      </div>
    );
  }
}
