import { RestaurantId, Store } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { ResolveResource } from '../elements/ResolveResource';
import { repo } from '../repo/repo';
import { StoreListWidget } from '../store/StoreListWidget';

type Props = RestaurantId;

@observer
export class RestaurantStoreListWidget extends React.Component<Props> {
  //

  $stores = repo.stores.bindCollection(this.props);

  renderList = (list: Store[]) => {
    if (!list.length) {
      return <Alert type="info">List is empty</Alert>;
    }
    const restaurantId = this.props.restaurantId;
    return <StoreListWidget storeIds={list.map(store => ({ restaurantId, storeId: store.id }))} />;
  };

  render() {
    if (!this.$stores) return null;
    return (
      <div className="restaurant-store-list-widget">
        <ResolveResource resource={this.$stores}>{list => this.renderList(list)}</ResolveResource>
      </div>
    );
  }
}
