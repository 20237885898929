/**
 * This has all the components for the delivery address
 * - Textarea for add/edit
 * - Buttons for edit and remove address
 */
import * as React from 'react';
import { dateUtils, Comment, idFactory, RestaurantId, commentSchema } from '@restoplus/core';
import { observable, computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { userProvider } from '../../provider/userProvider';
import { Form } from '../../forms/Form';
import { FormTextArea } from '../../forms/FormTextArea';
import { validationUtils } from '../../utils/validationUtils';
import { repo } from '../../repo/repo';
import { customerCart } from '../../provider/customerCartProvider';
import { toastStore } from '../../toast/toastStore';
import { Section, Body, Header, Title, TitleAddAction } from '../widgets/CheckoutElements';
import { validate } from 'nutso';

type Props = { restaurantId: RestaurantId };

@observer
export class CommentWidget extends React.Component<Props> {
  @observable
  showAddComment = false;

  @observable
  comment: Comment | null = null;

  @computed
  get validation() {
    const vr = validate(toJS(this.comment), commentSchema);
    return vr;
  }

  @computed
  get savedComments() {
    const uid = userProvider.uid;
    if (!uid) return;
    return repo.orderComments.bindCollection({ uid, ...this.props.restaurantId });
  }

  render() {
    return (
      <Section className="comments">
        <Header>
          <Title icon="la-comment">Comments</Title>
          {!this.showAddComment && <TitleAddAction onClick={() => this.onAddComment()}>Add Comment</TitleAddAction>}
        </Header>
        <Body>
          {this.renderAddComment()}
          {this.renderSavedComments()}
        </Body>
      </Section>
    );
  }

  renderAddComment() {
    const comment = this.comment;
    const uid = userProvider.uid;
    if (!this.showAddComment || !uid) return;
    if (!comment) {
      toastStore.error(`Cannot find comment to render.`);
      return;
    }

    return (
      <div className="add-comments">
        <Form type="default" validationResult={validationUtils.success}>
          <FormTextArea
            rows={3}
            label=""
            value={comment.value}
            onChange={value => (comment.value = value)}
            helpText="Let us know if you have any special requirements or food allergies."
            validation={this.validation.properties.value}
          />
        </Form>
        <div className="actions">
          <button onClick={() => this.onSaveComment({ uid })} disabled={!this.validation.isValid}>
            <img src="/images/add-icon.svg" />
            <span>Save Comment</span>
          </button>
          <a onClick={() => this.onHideComment()}>Cancel</a>
        </div>
      </div>
    );
  }

  onAddComment = () => {
    const newComment: Comment = {
      createdTime: dateUtils.time(),
      id: idFactory.tiny(),
      value: ''
    };

    this.onEditComment(newComment);
  };

  onEditComment = (comment: Comment) => {
    this.comment = comment;
    this.showAddComment = true;
  };

  onSaveComment = (args: { uid: string }) => {
    const comment = this.comment;
    if (!comment) {
      toastStore.error(`Cannot find comment to render.`);
      return;
    }
    repo.orderComment.put({ uid: args.uid, ...this.props.restaurantId, commentId: comment.id }, comment);
    this.showAddComment = false;
    this.onSelectComment(comment);
  };

  onRemoveComment = (args: { uid: string; commentId: string }) => {
    const comment = customerCart.comment;

    if (comment?.id === args.commentId) {
      customerCart.comment = undefined;
    }

    repo.orderComment.delete({ uid: args.uid, ...this.props.restaurantId, commentId: args.commentId });
  };

  renderSavedComments(): React.ReactNode {
    if (this.showAddComment) return;
    const savedComments = this.savedComments?.current();
    if (!savedComments || !savedComments.length) return;
    return <div className="saved-comments">{savedComments.map((comment, i) => this.renderSavedComment(comment))}</div>;
  }

  renderSavedComment(comment: Comment) {
    return (
      <div className="saved-comment" key={comment.id}>
        <input
          className="radio-button"
          type="radio"
          readOnly
          checked={customerCart.comment?.id === comment.id}
          onClick={() => this.onSelectComment(comment)}
        />
        <span className="comment" onClick={() => this.onSelectComment(comment)}>
          {comment.value}
        </span>
        {this.renderSavedCommentActions(comment)}
      </div>
    );
  }

  renderSavedCommentActions(comment: Comment) {
    const uid = userProvider.uid;

    if (!uid) return;

    return (
      <>
        <span className="edit icon" onClick={() => this.onEditComment(comment)}>
          <i className="las la-edit"></i>
        </span>
        <span
          className="remove icon"
          onClick={() =>
            confirm('Are you sure to delete this comment?') && this.onRemoveComment({ uid, commentId: comment.id })
          }
        >
          <i className="las la-times-circle"></i>
        </span>
      </>
    );
  }

  onSelectComment(comment: Comment) {
    customerCart.comment = comment;
  }

  onHideComment() {
    this.showAddComment = false;
    this.comment = null;
  }
}
