import { Address } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result } from 'nutso';
import * as React from 'react';
import { FormItemContainer, FormItemProps } from './FormItemContainer';
import { FormTextInput } from './FormTextInput';

type Props = FormItemProps & {
  value: Address;
  onChange: (value: Address) => void;
  validation: Result<Address>;
  dataCy?: string;
};

@observer
export class FormAddress extends React.Component<Props> {
  //
  render() {
    const { value: address, validation, onChange, dataCy } = this.props;
    return (
      <FormItemContainer {...this.props} className="form-address">
        <FormTextInput
          label="Line 1"
          value={address.line1}
          validation={validation.properties.line1}
          onChange={value => onChange({ ...address, line1: value })}
          dataCy="address-line1"
        />
        <FormTextInput
          label="Suburb"
          value={address.suburb}
          validation={validation.properties.suburb}
          onChange={value => onChange({ ...address, suburb: value })}
          dataCy="address-suburb"
        />
        <FormTextInput
          label="Postcode"
          value={address.postcode}
          validation={validation.properties.postcode}
          onChange={value => onChange({ ...address, postcode: value })}
          dataCy="address-postcode"
        />
      </FormItemContainer>
    );
  }
}
