import { Schema } from 'nutso';
import { PriceSpec } from '../..';

export const priceSpecSchema: Schema<PriceSpec> = {
  type: 'object',
  properties: {
    basePrice: {
      type: 'number',
      min: 0
    },
    extraCharge: {
      type: 'object',
      optional: true,
      properties: {
        pickup: {
          type: 'number'
        },
        delivery: {
          type: 'number'
        },
        dineIn: {
          type: 'number'
        }
      }
    }
  }
};
