/**
 * Google cloud storage paths
 */

const LogoFolder = (args: { bundleId: string }) => {
  return `mobile_apps/${args.bundleId}`;
};

const Logo = (args: { bundleId: string }) => {
  return `mobile_apps/${args.bundleId}/logo.jpg`;
};

const AndroidGoogleServicesInfoJson = (args: { bundleId: string }) => {
  return `mobile_apps/${args.bundleId}/android/google-services.json`;
};

const IosGoogleServicesInfoPlist = (args: { bundleId: string }) => {
  return `mobile_apps/${args.bundleId}/ios/GoogleService-Info.plist`;
};
const IosApnsCertificate = (args: { bundleId: string }) => {
  return `mobile_apps/${args.bundleId}/ios/AuthKey.p8`;
};

const UserImagesFolder = (args: { uid: string }) => {
  return `users/${args.uid}/images`;
};
const LogoImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/logos`;
};
const HeroImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/hero`;
};
const GalleryImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/gallery`;
};
const LoyaltyCardImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/loyalty_cards`;
};
const CategoryImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/category`;
};
const ItemImages = (args: { uid: string }) => {
  return `${UserImagesFolder(args)}/item`;
};

const images = {
  LogoImages,
  HeroImages,
  GalleryImages,
  LoyaltyCardImages,
  CategoryImages,
  ItemImages
};

const mobileAppMeta = {
  LogoFolder,
  Logo,
  AndroidGoogleServicesInfoJson,
  IosGoogleServicesInfoPlist,
  IosApnsCertificate
};

export const gcsPaths = {
  images,
  mobileAppMeta
};
