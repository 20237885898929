import { Schema } from 'nutso';
import { PizzaSettings } from '../models/item_spec/PizzaSettings';

export const pizzaSettingsSchema: Schema<PizzaSettings> = {
  type: 'object',
  properties: {
    pizzaAvailable: {
      type: 'boolean'
    },
    halfAndHalfAvailable: {
      type: 'boolean'
    },
    sizes: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          hasHalfAndHalf: {
            type: 'boolean'
          },
          halfAndHalfExtraCharge: {
            type: 'number'
          }
        }
      }
    },
    toppings: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          prices: {
            type: 'object',
            properties: {
              '/.*/': {
                type: 'number'
              }
            }
          }
        }
      }
    }
  }
};
