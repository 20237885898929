import {
  Category,
  CategoryId,
  CollectionType,
  defaults,
  idFactory,
  ItemId,
  ItemType,
  Pizza,
  PizzaSelection,
  PizzaSettings,
  PizzaSpec,
  pizzaSchema,
  routes
} from '@restoplus/core';
import { ItemPrice } from '@restoplus/core/src/models/item/ItemPrice';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { Page } from '../../elements/Page';
import { Resolve3Promises } from '../../elements/Resolve3Promises';
import { customerCart } from '../../provider/customerCartProvider';
import { repo } from '../../repo/repo';
import { pizzaUtils } from '../../utils/pizzaUtils';
import { promiseValue } from '../../utils/promiseValue';
import { ItemActionButtons as AddToCartButton } from '../widgets/ItemActionButtons';
import { ModifierWidget } from '../widgets/ModifierWidget';
import React = require('react');
import { validate } from 'nutso';
import { gotoPath } from '../../utils/browserHistory';

type Props = {};
type RouteProps = CategoryId & ItemId & {};

@observer
export class PizzaPage extends Page<Props, RouteProps> {
  //
  @observable
  $spec = fromPromise(repo.pizzaSpec.getCacheFirst(this.props.match.params));

  @observable
  $category = fromPromise(repo.category.getCacheFirst(this.props.match.params));

  @observable
  $settings = fromPromise(repo.pizzaSettings.getCacheFirst(this.props.match.params));

  @observable
  quantity: number = 1;

  @observable
  selection: PizzaSelection = {
    type: ItemType.pizza,
    removeToppings: [],
    addToppingsIds: []
  };

  // @observable sizeId: string;
  // @observable removeToppings: string[] = [];
  // @observable addToppingsIds: string[] = [];

  @computed
  get computedPrice(): ItemPrice {
    //
    const spec = promiseValue(this.$spec);
    const settings = promiseValue(this.$settings);
    const collectionType = customerCart.collectionType;
    const sizeId = this.selection.sizeId;

    if (!spec || !settings || !sizeId || !collectionType) return defaults.itemPrice;
    // size price
    return pizzaUtils.getPrice({
      spec,
      settings,
      selection: this.selection
    });
  }

  @computed
  get summary(): string {
    const spec = promiseValue(this.$spec);
    const settings = promiseValue(this.$settings);
    const sizeId = this.selection.sizeId;
    if (!spec || !settings || !sizeId) return '';
    return pizzaUtils.getSummary({
      spec,
      settings,
      selection: this.selection
    });
  }

  onAddToCart = (args: {
    category: Category;
    spec: PizzaSpec;
    settings: PizzaSettings;
    collectionType: CollectionType;
  }) => {
    //
    const { spec, category } = args;

    const pizza: Pizza = {
      id: idFactory.tiny(),
      type: ItemType.pizza,
      summary: {
        name: spec.name,
        quantity: this.quantity,
        price: this.computedPrice,
        description: this.summary
      },
      spec: spec,
      selection: this.selection,
      category
    };

    const validation = validate(pizza, pizzaSchema);

    if (!validation.isValid) {
      alert(`[${validation.errorPath.join('.')}] ${validation.errorMessage}`);
      return;
    }

    customerCart.addItem(pizza);

    gotoPath(routes.website.onlineOrdering.items.link(this.props.match.params, {}));
  };

  getTitle(): string {
    const spec = promiseValue(this.$spec);
    return spec?.name || '';
  }

  getAdditionalClassNames() {
    return 'website-item-page';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    const collectionType = customerCart.collectionType;
    if (!collectionType) return null;

    return (
      <Resolve3Promises promise1={this.$category} promise2={this.$spec} promise3={this.$settings}>
        {(category, spec, settings) => {
          if (!category) return <div>Cannot find category</div>;
          if (!spec) return <div>Cannot find pizza</div>;
          if (!settings) return <div>Cannot find pizza settings.</div>;
          return this.renderPizza({ category, spec, settings, collectionType });
        }}
      </Resolve3Promises>
    );
  }

  renderPizza(args: {
    category: Category;
    spec: PizzaSpec;
    settings: PizzaSettings;
    collectionType: CollectionType;
  }): React.ReactNode {
    // check if this pizza is available for this collection type
    if (!args.spec.availableFor[args.collectionType]) return;

    return (
      <div className="pizza">
        <div className="modifiers">
          {this.renderSize(args)}
          {this.renderRemoveToppings(args)}
          {this.renderAddToppings(args)}
        </div>
        {this.renderAddToCartButton(args)}
      </div>
    );
  }

  renderSize(args: { category: Category; spec: PizzaSpec; settings: PizzaSettings; collectionType: CollectionType }) {
    return (
      <ModifierWidget
        spec={pizzaUtils.getSizeModifier(args)}
        value={[this.selection.sizeId ?? '']}
        onChange={value => {
          // use has unselected the size
          if (value.length !== 1) {
            this.selection.sizeId = undefined;
            return;
          }
          this.selection.sizeId = value[0];
        }}
      />
    );
  }

  renderRemoveToppings(args: {
    category: Category;
    spec: PizzaSpec;
    settings: PizzaSettings;
    collectionType: CollectionType;
  }) {
    const { spec, settings, collectionType } = args;
    if (!spec.removeToppings || !spec.removeToppings.length) return null;
    return (
      <ModifierWidget
        spec={pizzaUtils.getRemoveToppingsModifier({ spec, settings, collectionType })}
        value={this.selection.removeToppings}
        onChange={value => (this.selection.removeToppings = value)}
      />
    );
  }

  renderAddToppings(args: {
    category: Category;
    spec: PizzaSpec;
    settings: PizzaSettings;
    collectionType: CollectionType;
  }) {
    const { settings, spec, collectionType } = args;
    const sizeId = this.selection.sizeId;
    if (!sizeId) return null;
    if (!settings.toppings || !settings.toppings.length) return null;

    return (
      <ModifierWidget
        spec={pizzaUtils.getAddToppingsModifier({ spec, settings, sizeId, collectionType })}
        value={this.selection.addToppingsIds}
        onChange={value => (this.selection.addToppingsIds = value)}
      />
    );
  }

  renderAddToCartButton(args: {
    category: Category;
    spec: PizzaSpec;
    settings: PizzaSettings;
    collectionType: CollectionType;
  }) {
    const price = this.computedPrice[args.collectionType] ?? 0;
    return (
      <AddToCartButton
        price={price}
        quantity={this.quantity}
        onInc={() => this.quantity++}
        onDec={() => {
          if (this.quantity < 1) return;
          this.quantity--;
        }}
        addToCart={() => this.onAddToCart(args)}
      />
    );
  }
}
