/**
 * This has all the components for the delivery address
 * - Textarea for add/edit
 * - Buttons for edit and remove address
 */
import * as React from 'react';
import { dateUtils, DeliveryAddress, DeliverySuburb, idFactory, StoreId, deliveryAddressSchema } from '@restoplus/core';
import { observable, computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { userProvider } from '../../provider/userProvider';
import { Form } from '../../forms/Form';
import { FormTextInput } from '../../forms/FormTextInput';
import { validationUtils } from '../../utils/validationUtils';
import { repo } from '../../repo/repo';
import { customerCart } from '../../provider/customerCartProvider';
import { toastStore } from '../../toast/toastStore';
import { FormSingleSelect } from '../../forms/FormSingleSelect';
import { Section, Body, Header, Title, TitleAddAction } from '../widgets/CheckoutElements';
import { validate } from 'nutso';

type Props = {
  storeKey: StoreId;
};

@observer
export class DeliveryAddressWidget extends React.Component<Props> {
  @observable
  showAddDeliveryAddress = false;

  @observable
  deliveryAddress: DeliveryAddress | null = null;

  $deliverySettings = repo.deliverySettings.bind(this.props.storeKey);

  @computed
  get validation() {
    const vr = validate(toJS(this.deliveryAddress), deliveryAddressSchema);
    return vr;
  }

  @computed
  get savedDeliveryAddresses() {
    const uid = userProvider.uid;
    if (!uid) return;
    return repo.deliveryAddresses.bindCollection({ uid, ...this.props.storeKey });
  }

  // @computed
  // get deliverySuburbs(): DeliverySuburb[] {
  //   const deliverySettings = this.$deliverySettings.current();
  //   if (!deliverySettings || !deliverySettings.suburbs.length) return [];
  //   return deliverySettings?.suburbs.map(suburb => {
  //     return {
  //       id: suburb.id,
  //       deliveryCharges: suburb.deliveryCharges,
  //       name: suburb.name,
  //       minOrderValue: suburb.minOrderValue,
  //       postcode: suburb.postcode
  //     };
  //   });
  // }

  render() {
    return (
      <Section className="delivery-address">
        <Header>
          <Title icon="la-route">Delivery Address</Title>
          {!this.showAddDeliveryAddress && (
            <TitleAddAction onClick={() => this.onAddDeliveryAddress()}>Add Address</TitleAddAction>
          )}
        </Header>
        <Body>
          {this.renderSavedDeliveryAddresses()}
          {this.renderAddDeliveryAddress()}
        </Body>
      </Section>
    );
  }

  renderAddDeliveryAddress = (): React.ReactNode => {
    const deliveryAddress = this.deliveryAddress;
    const uid = userProvider.uid;
    if (!uid || !this.showAddDeliveryAddress) return;
    if (!deliveryAddress) {
      toastStore.error(`Cannot find delivery address to render.`);
      return;
    }

    return (
      <div className="add-delivery-address">
        <Form type="default" validationResult={validationUtils.success}>
          {this.renderAddAddress()}
        </Form>
        <div className="actions">
          <button
            className="add-address-button"
            onClick={() => this.onSaveDeliveryAddress({ uid })}
            disabled={!this.validation.isValid}
          >
            <img src="/images/add-icon.svg" />
            <span>Save Address</span>
          </button>
          <a onClick={() => this.onHideAddress()}>Cancel</a>
        </div>
      </div>
    );
  };

  renderAddAddress = () => {
    const deliveryAddress = this.deliveryAddress;
    const deliverySuburbs = this.$deliverySettings.current()?.suburbs || [];
    if (!deliveryAddress) {
      toastStore.error(`Cannot find delivery address to render.`);
      return;
    }

    return (
      <>
        <FormSingleSelect
          label="Select Suburb"
          options={deliverySuburbs}
          value={deliveryAddress.suburb}
          onChange={selected => (deliveryAddress.suburb = selected)}
          optionKey={suburb => suburb.id}
          optionLabel={suburb => suburb.name}
          validation={this.validation.properties.suburb}
          dataCy="address-suburb"
        />
        <FormTextInput
          label="Enter Address"
          value={deliveryAddress.line1}
          onChange={value => (deliveryAddress.line1 = value)}
          validation={this.validation.properties.line1}
        />
      </>
    );
  };

  onAddDeliveryAddress = () => {
    const newDeliveryAddress: DeliveryAddress = {
      id: idFactory.tiny(),
      createdTime: dateUtils.time(),
      line1: '',
      suburb: {
        id: '',
        name: '',
        postcode: ''
      }
    };
    this.deliveryAddress = newDeliveryAddress;
    this.showAddDeliveryAddress = true;
  };

  onEditDeliveryAddress = (deliveryAddress: DeliveryAddress) => {
    const editDeliveryAddress: DeliveryAddress = {
      id: deliveryAddress.id,
      createdTime: deliveryAddress.createdTime,
      line1: deliveryAddress.line1,
      suburb: {
        id: deliveryAddress.suburb.id,
        name: deliveryAddress.suburb.name,
        postcode: deliveryAddress.suburb.postcode
      }
    };

    this.deliveryAddress = editDeliveryAddress;
    this.showAddDeliveryAddress = true;
  };

  onSaveDeliveryAddress = (args: { uid: string }) => {
    const deliveryAddress = this.deliveryAddress;

    if (!deliveryAddress) {
      toastStore.error(`Cannot find delivery address to render.`);
      return;
    }

    repo.deliveryAddress.put(
      {
        uid: args.uid,
        ...this.props.storeKey,
        addressId: deliveryAddress.id
      },
      deliveryAddress
    );
    // reset fields
    this.showAddDeliveryAddress = false;
    this.onHideAddress();
    this.onSelectAddress(deliveryAddress);
  };

  onRemoveDeliveryAddress = (args: { uid: string; addressId: string }) => {
    const deliveryAddress = customerCart.deliveryAddress;

    if (deliveryAddress?.id === args.addressId) {
      customerCart.deliveryAddress = undefined;
    }

    repo.deliveryAddress.delete({
      uid: args.uid,
      ...this.props.storeKey,
      addressId: args.addressId
    });
  };

  renderSavedDeliveryAddresses = (): React.ReactNode => {
    const savedAddresses = this.savedDeliveryAddresses?.current();
    if (this.showAddDeliveryAddress || !savedAddresses || !savedAddresses.length) return;
    return (
      <div className="saved-addresses">
        {savedAddresses.map((address, i) => this.renderSavedDeliveryAddress(address))}
      </div>
    );
  };

  renderSavedDeliveryAddress = (deliveryAddress: DeliveryAddress) => {
    return (
      <div className="saved-address" key={deliveryAddress.id}>
        <input
          className="radio-button"
          type="radio"
          readOnly
          checked={customerCart.deliveryAddress?.id === deliveryAddress.id}
          onClick={() => this.onSelectAddress(deliveryAddress)}
        />
        <span
          className="address"
          onClick={() => this.onSelectAddress(deliveryAddress)}
        >{`${deliveryAddress.line1}, ${deliveryAddress.suburb.name} ${deliveryAddress.suburb.postcode}`}</span>
        {this.renderSavedDeliveryAddressActions(deliveryAddress)}
      </div>
    );
  };

  renderSavedDeliveryAddressActions = (deliveryAddress: DeliveryAddress) => {
    const uid = userProvider.uid;
    if (!uid) return;

    return (
      <>
        <span className="edit icon" onClick={() => this.onEditDeliveryAddress(deliveryAddress)}>
          <i className="las la-edit"></i>
        </span>
        <span
          className="remove icon"
          onClick={() => {
            confirm('Are you sure to delete this address?') &&
              this.onRemoveDeliveryAddress({ uid, addressId: deliveryAddress.id });
          }}
        >
          <i className="las la-times-circle"></i>
        </span>
      </>
    );
  };

  onSelectAddress = (deliveryAddress: DeliveryAddress) => {
    customerCart.deliveryAddress = deliveryAddress;
  };

  onHideAddress = () => {
    this.showAddDeliveryAddress = false;
    this.deliveryAddress = null;
  };
}
