import { RestaurantId, Store, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { ResolveResource } from '../elements/ResolveResource';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';

type Props = RestaurantId;

@observer
export class StoreSelectWidget extends React.Component<Props> {
  //
  $stores = repo.stores.bindCollection(this.props);
  renderList = (list: Store[]) => {
    if (!list.length) {
      return <Alert type="info">List is empty</Alert>;
    }
    return <div className="list">{list.map(store => this.renderStore(store))}</div>;
  };

  renderStore(store: Store): React.ReactNode {
    return (
      <div
        className="item"
        key={store.id}
        onClick={() =>
          gotoPath(routes.website.store.link({ storeId: store.id, restaurantId: this.props.restaurantId }, {}))
        }
      >
        <div className="icon">
          <i className={`icon las la-store-alt`} />
        </div>
        <div className="name">{store.name}</div>
        <div className="address">
          {store.address.line1}, {store.address.suburb}, {store.address.postcode}
        </div>
      </div>
    );
  }

  render() {
    if (!this.$stores) return null;
    return (
      <div className="store-select-widget">
        <ResolveResource resource={this.$stores}>{list => this.renderList(list)}</ResolveResource>
      </div>
    );
  }
}
