import { ValidationResult } from 'nutso';
import * as React from 'react';
import { FormMeta } from './FormMeta';

export type FormItemProps = {
  label: string;
  validation: ValidationResult;
  helpText?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  dataCyForm?: string;
};

export class FormItemContainer extends React.Component<FormItemProps> {
  render() {
    const { label, validation, className, inputClassName, disabled, dataCyForm } = this.props;
    return (
      <div className={`form-item ${className}`} data-cy={dataCyForm}>
        {label && <label>{label}</label>}
        <div
          className={`form-input ${validation.isValid ? 'valid' : 'invalid'} ${inputClassName} ${disabled &&
            'disabled'}`}
        >
          {this.props.children}
        </div>
        <FormMeta {...this.props} />
      </div>
    );
  }
}
