import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { RestaurantViewWidget } from './RestaurantViewWidget';

type Props = {
  restaurantIds: string[];
};

@observer
export class RestaurantListWidget extends React.Component<Props> {
  render() {
    const { restaurantIds: list } = this.props;

    if (!list.length) {
      return (
        <Alert title="List is empty" type="info">
          List is empty
        </Alert>
      );
    }

    return (
      <div className="restaurant-list">
        {list.map(restaurantId => (
          <RestaurantViewWidget key={restaurantId} id={{ restaurantId }} />
        ))}
      </div>
    );
  }
}
