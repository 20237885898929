import { firestoreApi, Cursor } from '../integration/firestoreApi';
import { BaseRepo } from './BaseRepo';
import { Page, OrderByDirection } from '@restoplus/core';
import { fromResource, IResource, fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import { fs } from '../integration/fb';
import { toastStore } from '../toast/toastStore';
import { firestore } from 'firebase';

export class CollectionRepo<K, T> extends BaseRepo<K, T> {
  //
  page = (args: {
    key: K;
    orderByField: Extract<keyof T, string>;
    orderByDirection: OrderByDirection;
    limit: number;
    cursor?: Cursor;
  }): Promise<Page<T>> => {
    return firestoreApi.page({ path: this.firestorePath.path(args.key), ...args });
  };

  pageGroup = (args: {
    collectionGroupName: string;
    where: [string, firestore.WhereFilterOp, string];
    where2?: [string, firestore.WhereFilterOp, string];
    orderByField: Extract<keyof T, string>;
    orderByDirection: OrderByDirection;
    limit: number;
    cursor?: Cursor;
  }): Promise<Page<T>> => {
    return firestoreApi.pageCollectionGroup(args);
  };

  listAll = (key: K): Promise<{ [key: string]: T }> => {
    return firestoreApi.getCollection(this.firestorePath.path(key));
  };

  listAllIds = (key: K): Promise<string[]> => {
    return firestoreApi.getCollectionIds(this.firestorePath.path(key));
  };

  listAllValues = (key: K): Promise<T[]> => {
    return firestoreApi.getCollectionValues(this.firestorePath.path(key));
  };

  listAllValuesCacheFirst = (key: K): Promise<T[]> => {
    return firestoreApi.getCollectionValuesCacheFirst(this.firestorePath.path(key));
  };

  listAllValuesCacheFirstObservablePromise = (key: K): IPromiseBasedObservable<T[]> => {
    return fromPromise(firestoreApi.getCollectionValuesCacheFirst(this.firestorePath.path(key)));
  };

  /**
   * If it's undefined, it's loading, empty [] on error and actual array on success
   */
  bindCollection = (key: K): IResource<T[]> => {
    let disposer: () => void;
    return fromResource<T[]>(
      sink => {
        disposer = fs.collection(this.firestorePath.path(key)).onSnapshot(
          snap => {
            sink(snap.docs.map(e => e.data() as T));
          },
          error => {
            console.error(`${key}, error`);
            toastStore.error(error.message);
            sink([]);
          }
        );
      },
      () => {
        disposer();
      },
      undefined
    );
  };

  bindCollectionQuery = (args: {
    key: K;
    where: [string, firestore.WhereFilterOp, string];
    where2?: [string, firestore.WhereFilterOp, string];
    orderByField: Extract<keyof T, string>;
    orderByDirection: OrderByDirection;
    limit?: number;
    cursor?: Cursor;
  }): IResource<T[]> => {
    let disposer: () => void;
    return fromResource<T[]>(
      sink => {
        const path = this.firestorePath.path(args.key);
        const query = firestoreApi.buildCollectionQuery({ ...args, path });
        disposer = query.onSnapshot(
          snap => {
            sink(snap.docs.map(e => e.data() as T));
          },
          error => {
            console.error(`${args}, error`);
            toastStore.error(error.message);
            sink([]);
          }
        );
      },
      () => {
        disposer();
      },
      undefined
    );
  };

  bindCollectionGroup = (args: {
    collectionGroupName: string;
    where: [string, firestore.WhereFilterOp, string];
    where2?: [string, firestore.WhereFilterOp, string];
    orderByField: Extract<keyof T, string>;
    orderByDirection: OrderByDirection;
    limit: number;
  }): IResource<T[]> => {
    let disposer: () => void;
    return fromResource<T[]>(
      sink => {
        const query = firestoreApi.buildCollectionGroupQuery(args);
        disposer = query.onSnapshot(
          snap => {
            sink(snap.docs.map(e => e.data() as T));
          },
          error => {
            console.error(error);
            toastStore.error(error.message);
            sink([]);
          }
        );
      },
      () => {
        disposer();
      },
      undefined
    );
  };
}
