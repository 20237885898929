import * as React from 'react';
import { FormItem } from './FormItem';
import { NumberInput, NumberInputProps } from './NumberInput';
import { FormItemContainer, FormItemProps } from './FormItemContainer';

type Props = NumberInputProps & FormItemProps;

export class FormNumberInput extends React.Component<Props> {
  //
  render() {
    return (
      <FormItemContainer {...this.props}>
        <NumberInput {...this.props} />
      </FormItemContainer>
    );
  }
}
