import { isNil } from '@restoplus/core';
import { ValidationResult } from 'nutso';
/**
 * A few fields are optional based on a flag the user chooses, for example
 *
 */
type Return = {
  value: boolean;
  onChange: (flag: boolean) => void;
  validation: ValidationResult;
};

export const optionalFlag = <T, K extends keyof T>(obj: T, field: K, initialValue: T[K]): Return => {
  // closure
  const onFlagChange = (flag: boolean) => {
    if (flag) {
      obj[field] = initialValue;
      return;
    }
    delete obj[field];
  };

  return {
    value: !isNil(obj[field]),
    onChange: onFlagChange,
    validation: {
      isValid: true,
      errorMessage: '',
      errorPath: []
    }
  };
};
