import { StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { OnlineOrderingHeader } from './OnlineOrderingHeader';
import { OnlineOrderingBottomNavBar } from './OnlineOrderingBottomNavBar';
import { OnlineOrderingRouter } from './OnlineOrderingRouter';
import { OnlineOrderingSidebar } from './OnlineOrderingSidebar';

type Props = StoreId;

@observer
export class OnlineOrderingLayout extends React.Component<RouteComponentProps<Props>> {
  render() {
    return (
      <div className="online-ordering">
        <OnlineOrderingHeader {...this.props.match.params} />
        <OnlineOrderingSidebar {...this.props.match.params} />
        <OnlineOrderingRouter />
        <OnlineOrderingBottomNavBar position="bottom" {...this.props.match.params} />
      </div>
    );
  }
}
