import { routes, StoreId } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { locationProvider } from '../../provider/locationProvider';
import { userProvider } from '../../provider/userProvider';
import { gotoPath } from '../../utils/browserHistory';

const Title = (props: { label: string }) => {
  return <div className="title">{props.label}</div>;
};

// const isSelected = (args:{path:string})
type LinkProps = {
  icon: string;
  label: string;
  path: string;
  onClick?: () => void;
};

@observer
class Link extends React.Component<LinkProps> {
  onClick = () => {
    onlineOrderingSidebarController.hide();
    if (this.props.onClick) {
      this.props.onClick();
      return;
    }
    gotoPath(this.props.path);
  };

  isSelected = () => {
    return locationProvider.isSelected(this.props.path);
  };

  render() {
    const { icon, label } = this.props;
    return (
      <div className={`link ${this.isSelected() ? 'selected' : ''}`} onClick={this.onClick}>
        <i className={`icon las ${icon}`} />
        <span className="label">{label}</span>
      </div>
    );
  }
}

class OnlineOrderingSidebarController {
  //
  @observable visible = false;

  toggle = () => {
    this.visible = !this.visible;
  };

  hide = () => {
    this.visible = false;
  };
}

export const onlineOrderingSidebarController = new OnlineOrderingSidebarController();

type Props = StoreId;

@observer
export class OnlineOrderingSidebar extends React.Component<Props> {
  renderUserNav = () => {
    return (
      <>
        <Title label="User" />
        {/* <Link label="Messages" icon="la-envelope" path="/messages" /> */}
        <Link label="Logout" icon="la-sign-out-alt" path="/todo" onClick={() => userProvider.logout()} />
      </>
    );
  };

  renderMainNav = () => {
    return (
      <>
        <Title label="Main" />
        <Link label="Home" icon="la-hotel" path={routes.website.store.link(this.props, {})} />
        <Link
          label="Order History"
          icon="la-receipt"
          path={routes.website.onlineOrdering.secure.orderHistory.link(this.props, {})}
        />
      </>
    );
  };

  renderUserProfile(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return;
    return (
      <div className="profile">
        <div className="avatar">
          <img src={`https://api.adorable.io/avatars/250/${user.uid}`} />
        </div>
        <div className="name">{user.displayName || 'No Name'}</div>
        <div className="message">Your friendly avatar! A unique creation just for you ...</div>
      </div>
    );
  }

  renderContact(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return;
    return (
      <div className="contact">
        <div className="email item">
          <label>Email</label>
          <div className="value">{user.email || 'No Email'}</div>
        </div>
        <div className="mobile item">
          <label>Mobile</label>
          <div className="value">{user.phoneNumber || 'No Phone Number'}</div>
        </div>
      </div>
    );
  }

  render() {
    const className = onlineOrderingSidebarController.visible ? 'show' : 'hide';
    return (
      <>
        <nav className={className}>
          {this.renderUserProfile()}
          {this.renderContact()}
          {this.renderMainNav()}
          {this.renderUserNav()}
        </nav>
        <div className={`overlay ${className}`} onClick={() => (onlineOrderingSidebarController.visible = false)}></div>
      </>
    );
  }
}
