import { routes } from '@restoplus/core';
import '@stripe/stripe-js';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { BackofficeLayout } from './backoffice/layout/BackofficeLayout';
import { Demo } from './elements/Demo';
import { OnlineOrderingLayout } from './online_ordering/layout/OnlineOrderingLayout';
import { userProvider } from './provider/userProvider';
import { RestaurantWebsiteLayout } from './restaurant_website/layout/RestaurantWebsiteLayout';
import { StoreWebsiteLayout } from './store_website/layout/StoreWebsiteLayout';
import { ToastContainer } from './toast/ToastsContainer';
import { browserHistory } from './utils/browserHistory';
require('./provider/restaurantProvider');
require('./provider/storeProvider');
// require('./provider/menuProvider');

require('../scss/style.scss');
require('./utils/vh');

@observer
class Web extends React.Component<any, any> {
  //
  componentDidMount() {
    const Pushy = (window as any).Pushy;
    if (Pushy) {
      Pushy.listen();
      Pushy.requestStoragePermission();
      //
      // Register the device for push notifications
      Pushy.register(function(err: any, deviceToken: any) {
        // Handle registration errors
        if (err) {
          return alert(err);
        }

        // Display an alert with device token
        alert('Pushy device token: ' + deviceToken);

        // Send the token to your backend server via an HTTP GET request
        //await fetch('https://your.api.hostname/register/device?token=' + deviceToken);

        // Succeeded, optionally do something to alert the user
      });
      // Listen for push notifications
      Pushy.setNotificationListener(function(data: any) {
        // Print notification payload data
        console.log('Received notification: ' + JSON.stringify(data));
        // Display an alert with the "message" payload value
        alert('Received notification: ' + data.message);
        // Clear iOS app badge number
        Pushy.clearBadge();
      });

      Pushy.isRegistered(function(isRegistered: boolean) {
        if (isRegistered) {
          // Subscribe the device to a topic
          Pushy.subscribe('news', function(err: any) {
            // Handle errors
            if (err) {
              return alert(err);
            }
            // Subscribe successful
            alert('Subscribed to topic successfully');
          });
        }
      });
    }
  }
  render() {
    return (
      <div>
        <Router history={browserHistory}>
          <Switch>
            {/* NOTE: Order of routes is important */}
            <Route exact path="/demo" component={Demo} />
            <Route path={routes.backoffice.home.template} component={BackofficeLayout} />
            <Route path={routes.website.onlineOrdering.home.template} component={OnlineOrderingLayout} />
            <Route path={routes.website.store.template} component={StoreWebsiteLayout} />
            <Route path={routes.website.restaurant.template} component={RestaurantWebsiteLayout} />
          </Switch>
        </Router>
        <ToastContainer />
      </div>
    );
  }
}

ReactDOM.render(<Web />, document.getElementById('root'));
