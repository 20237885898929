import { routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { userProvider } from '../../provider/userProvider';
import { currentPath } from '../../utils/currentPath';
import { OrderHistoryPage } from '../pages/OrderHistoryPage';
import { OrderSuccessPage } from '../pages/OrderSuccessPage';
import { OrderViewPage } from '../pages/OrderViewPage';

@observer
export class OnlineOrderingSecureRouter extends React.Component<RouteComponentProps<StoreId>> {
  render() {
    // app is booting
    if (userProvider.user === undefined) return null;
    // user not logged-in
    if (userProvider.user === null) {
      return (
        <Redirect
          to={routes.website.onlineOrdering.login.link(this.props.match.params, { continueLink: currentPath() })}
        />
      );
    }
    return (
      <Switch>
        <Route exact path={routes.website.onlineOrdering.secure.orderSuccess.template} component={OrderSuccessPage} />
        <Route exact path={routes.website.onlineOrdering.secure.order.template} component={OrderViewPage} />
        <Route exact path={routes.website.onlineOrdering.secure.orderHistory.template} component={OrderHistoryPage} />
      </Switch>
    );
  }
}
