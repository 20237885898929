import { Category, CategoryId, defaults, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SortableDocumentViewWidget } from '../document/SortableDocumentViewWidget';
import { Imgix } from '../elements/Imgix';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';

type Props = {};

@observer
export class CategoryViewWidget extends SortableDocumentViewWidget<CategoryId, Category, Props> {
  //
  $menu = repo.menu.bind(this.props.id, defaults.menu);

  getRepo(): DocumentRepo<CategoryId, Category> {
    return repo.category;
  }

  renderDocument(category: Category): React.ReactNode {
    const menu = this.$menu.current();
    if (!menu) return <span></span>;
    return (
      <div className="category">
        <div
          className="body"
          onClick={e => {
            if (e.isPropagationStopped()) return;
            gotoPath(routes.backoffice.category.link(this.props.id, {}));
          }}
        >
          <Imgix image={category.image} aspectRatio={menu.categoryImageAspectRatio} />
          <div className="name">{category.name}</div>
          <div className="description">{category.description}</div>
        </div>
        {this.renderActions(category)}
      </div>
    );
  }

  renderActions = (category: Category) => {
    return (
      <div className="actions">
        <div className="action" onClick={() => gotoPath(routes.backoffice.editCategory.link(this.props.id, {}))}>
          <div className="circle">
            <i className={`icon las la-edit`}></i>
          </div>
        </div>
        <div className="action" onClick={() => this.moveUp(category)}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => this.moveDown(category)}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
      </div>
    );
  };
}
