import { BasicItemSelection, BasicItemSpec, CollectionType, collectionTypes, defaults } from '@restoplus/core';
import { modifierUtils } from './modifierUtils';
import { priceSpecUtils } from './priceSpecUtils';

const getPriceForCollectionType = (args: {
  spec: BasicItemSpec;
  selection: BasicItemSelection;
  collectionType: CollectionType;
}) => {
  const modifierExtraCharge = modifierUtils.getModifiersExtraCharge({
    modifierSpecs: args.spec.modifiers,
    modifiersValue: args.selection.modifiersValue
  });
  const basePrice = priceSpecUtils.getBasePrice(args.spec.price, args.collectionType);
  return basePrice + modifierExtraCharge;
};

const getPrice = (args: { spec: BasicItemSpec; selection: BasicItemSelection }) => {
  return collectionTypes.reduce((result, collectionType) => {
    if (!args.spec.availableFor[collectionType]) return result;
    result[collectionType] = getPriceForCollectionType({ ...args, collectionType });
    return result;
  }, defaults.itemPrice);
};

export const basicItemUtils = {
  // isSame,
  getPrice
};
