/**
 * Messaging a customer in Restoplus
 *
 * Any correspondence sent to the customer is stored as CustomerMessage in firestore
 * On create firestore listener, acts on the message, and sends it via. one of the following channels
 *
 * 1. sms
 * 2. whatsapp
 * 3. push notification
 */
export enum CustomerMessageType {
  orderStatusUpdate = 'orderStatusUpdate',
  bookingStatusUpdate = 'bookingStatusUpdate',
  loyaltyRewardsCredit = 'loyaltyRewardsCredit'
}
