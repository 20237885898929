import {
  AspectRatio,
  collectionTypeLabel,
  dateUtils,
  formatAmount,
  Order,
  OrderStatusCode,
  RestaurantId,
  StoreId
} from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { OrderProcessButtons } from '../backoffice/widgets/OrderProcessButtons';
import { Badge } from '../elements/Badge';
import { Imgix } from '../elements/Imgix';
import { Spin } from '../elements/Spin';
import { Widget } from '../elements/Widget';
import { badgeType, orderStatusLabels } from '../orders/OrderListWidget';
import { restaurantProvider } from '../provider/restaurantProvider';
import { repo } from '../repo/repo';
import { promiseValue } from '../utils/promiseValue';

type Props = { printerId: string };

@observer
export class StoreInfo extends Widget<StoreId> {
  //
  $restaurant = repo.restaurant.getCacheFirstObservablePromise(this.props);
  $store = repo.store.getCacheFirstObservablePromise(this.props);

  render() {
    const restaurant = promiseValue(this.$restaurant);
    const store = promiseValue(this.$store);
    if (!restaurant || !store) return <div></div>;
    return (
      <>
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="store-name">
          {restaurant.name} - {store.name}
        </div>
      </>
    );
  }
}

@observer
export class StoreName extends Widget<StoreId> {
  //
  $store = repo.store.getCacheFirstObservablePromise(this.props);

  render() {
    const store = promiseValue(this.$store);
    if (!store) return <div></div>;
    return <div className="store-name">{store.name}</div>;
  }
}

@observer
export class PrinterQueueWidget extends Widget<Props> {
  //
  @observable
  $orders = repo.orders.bindCollectionGroup({
    collectionGroupName: 'orders',
    limit: 50,
    orderByField: 'placedTime',
    orderByDirection: 'asc',
    where: ['printerId', '==', this.props.printerId],
    where2: ['status.code', '==', OrderStatusCode.new]
  });

  renderOrder(order: Order): React.ReactNode {
    const collectionType = collectionTypeLabel[order.cart.collectionType];
    const orderPlacedTime = dateUtils.timeLabel({ time: order.placedTime });
    const grandTotal = formatAmount(order.cart.grandTotal, restaurantProvider.$restaurant?.current()?.country);

    return (
      <div className="order-list-view" key={order.id}>
        <StoreInfo {...order} />
        <div className="collection-type">
          {collectionType} - {orderPlacedTime}
        </div>
        <div className="grand-total">{grandTotal}</div>
        <OrderProcessButtons order={order} />
      </div>
    );
  }

  renderOrders(): React.ReactNode {
    const orders = this.$orders?.current();
    if (orders === undefined) {
      return <Spin spinning={true} />;
    }
    if (!orders.length) return <div>Orders empty</div>;
    return <div className="list">{orders.map(order => this.renderOrder(order))}</div>;
  }

  render() {
    return (
      <div className="printer-queue">
        <div className="title">Printer Queue</div>
        {this.renderOrders()}
      </div>
    );
  }
}
