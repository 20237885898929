import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { userProvider } from '../../provider/userProvider';
import { RestaurantHeaderWidget } from '../../restaurant/RestaurantHeaderWidget';
import { StoreHeaderWidget } from '../../store/StoreHeaderWidget';
import { backofficeSidebarController } from './BackofficeSidebar';
import { RestaurantId, StoreId, routes } from '@restoplus/core';

const initials = require('initials');

@observer
export class BackofficeHeader extends React.Component {
  renderDefaultTitle = () => {
    return <div className="title">Home</div>;
  };
  renderIcon = () => {
    return (
      <div className="logo icon" onClick={() => backofficeSidebarController.toggle()}>
        {backofficeSidebarController.visible ? <i className="las la-times" /> : <i className="las la-bars" />}
      </div>
    );
  };
  renderTitle = () => {
    return (
      <Switch>
        <Route
          path={routes.backoffice.store.template}
          render={(props: RouteComponentProps<StoreId>) => <StoreHeaderWidget {...props.match.params} />}
        />
        <Route
          path={routes.backoffice.restaurant.template}
          render={(props: RouteComponentProps<RestaurantId>) => (
            <RestaurantHeaderWidget id={{ restaurantId: props.match.params.restaurantId }} />
          )}
        />
        <Route render={props => this.renderDefaultTitle()} />
      </Switch>
    );
  };
  render() {
    return (
      <header>
        {this.renderIcon()}
        <div className="logo full">
          <img src="/images/logo-color.svg" alt="Restoplus Logo" />
        </div>
        {this.renderTitle()}
        <div className="initials">{initials(userProvider.user?.displayName || '').substring(0, 2)}</div>
        <div className="userinfo">
          <div className="name" data-cy="display-name">
            {userProvider.user?.displayName}
          </div>
          <div className="email" data-cy="display-email">
            {userProvider.user?.email}
          </div>
        </div>
      </header>
    );
  }
}
