import { observer } from 'mobx-react';
import { IResource } from 'mobx-utils';
import * as React from 'react';
import { Spin } from './Spin';

/**
 * A component that waits for promise to resolve
 */
type Props<T> = {
  resource: IResource<T>;
  children: (value: T) => React.ReactNode;
};

@observer
export class ResolveResource<T> extends React.Component<Props<T>> {
  render() {
    const { resource } = this.props;
    const value = resource.current();
    if (value === undefined) return <Spin spinning={true} />;
    if (value === null) return <div>Cannot find resource</div>;
    return this.props.children(value);
  }
}
