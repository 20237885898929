import { Schema } from 'nutso';
import { StoreTimings, SessionTimings } from '../models/store/StoreTimings';
import { hoursSchema } from './hoursSchema';

export const sessionSchema: Schema<SessionTimings> = {
  type: 'object',
  properties: {
    hours: hoursSchema,
    available: {
      type: 'boolean'
    }
  }
};

export const storeTimingsSchema: Schema<StoreTimings> = {
  type: 'object',
  properties: {
    monday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    tuesday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    wednesday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    thursday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    friday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    saturday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    },
    sunday: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            session1: sessionSchema,
            session2: {
              ...sessionSchema,
              optional: true
            }
          }
        }
      }
    }
  }
};
