import { AspectRatio, Restaurant, RestaurantId, routes, PrinterId, Printer } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { DocumentViewWidget } from '../document/DocumentViewWidget';
import { Imgix } from '../elements/Imgix';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';

@observer
export class PrinterViewWidget extends DocumentViewWidget<PrinterId, Printer, {}> {
  //
  getRepo(): DocumentRepo<PrinterId, Printer> {
    return repo.printer;
  }

  renderDocument(printer: Printer): React.ReactNode {
    return (
      <div className="printer">
        <div className="id">{printer.id}</div>
        <div className="password">{printer.password}</div>
        <div className="name">{printer.name}</div>
        <div className="created-time">{printer.createdTime}</div>
        <div className="actions">
          <button className="view" onClick={() => gotoPath(routes.backoffice.printer.link(this.props.id, {}))}>
            View
          </button>
        </div>
      </div>
    );
  }
}
