import { fromPromise, fromResource, IResource } from 'mobx-utils';
import { fs } from '../integration/fb';
import { firestoreApi } from '../integration/firestoreApi';
import { toastStore } from '../toast/toastStore';
import { BaseRepo } from './BaseRepo';

export class DocumentRepo<K, T> extends BaseRepo<K, T> {
  //
  get = (key: K): Promise<T | null> => {
    return firestoreApi.getDoc(this.firestorePath.path(key));
  };

  getCacheFirst = (key: K): Promise<T | null> => {
    return firestoreApi.getDocCacheFirst(this.firestorePath.path(key));
  };

  getCacheFirstObservablePromise = (key: K) => {
    return fromPromise(this.getCacheFirst(key));
  };

  getNullSafe = (key: K): Promise<T> => {
    return firestoreApi.getDocNullSafe(this.firestorePath.path(key));
  };

  getWithDefault = (key: K, _default: T): Promise<T> => {
    return firestoreApi.getDocWithDefault(this.firestorePath.path(key), _default);
  };

  getWithDefaultCacheFirst = (key: K, _default: T): Promise<T> => {
    return firestoreApi.getDocWithDefaultCacheFirst(this.firestorePath.path(key), _default);
  };

  put = (key: K, obj: T) => {
    return firestoreApi.putDoc(this.firestorePath.path(key), obj);
  };

  update = (key: K, obj: Partial<T>) => {
    return firestoreApi.mergeDoc(this.firestorePath.path(key), obj);
  };

  delete = (key: K) => {
    return firestoreApi.deleteDoc(this.firestorePath.path(key));
  };

  bind = (key: K, _default?: T): IResource<T | null | undefined> => {
    let disposer: () => void;
    return fromResource<T | null | undefined>(
      sink => {
        console.log(`Subscribing `, key);
        disposer = fs.doc(this.firestorePath.path(key)).onSnapshot(
          snap => {
            if (snap.exists) sink(snap.data() as T);
            else sink(_default ?? null);
          },
          error => {
            console.error(`${this.firestorePath.path(key)}`, error);
            toastStore.error(error.message);
            sink(null);
          }
        );
      },
      () => {
        console.log(`Unsubscribing `, key);
        disposer();
      },
      undefined
    );
  };
}
