import { DateTime } from 'luxon';
import { CollectionType } from '../models/base/CollectionType';
import { Store } from '../models/store/Store';
import { StoreTimings, SessionTimings, BusinessSession } from '../models/store/StoreTimings';
import { hhmmUtils } from './hhmmUtils';
import { hoursUtils } from './hoursUtils';
import { Dow } from '../models/base/Dow';

const quickCollectionTimes = {
  pickup: ['ASAP', '5 minutes', '10 minutes', '15 minutes'],
  delivery: ['ASAP'],
  dineIn: []
};

const getDow = (args: { now: DateTime }) => args.now.weekdayLong.toLowerCase() as Dow;

const getIntervalsForSession = (session: SessionTimings, day: DateTime) => {
  var intervals = hoursUtils.getInterval(session, day).splitBy({ minutes: 30 });
  /**
   * remove the first 30-minute interval, coz if shop opens at 6PM, the user cannot order at 6PM
   * This shift works coz the collection time offset of 30 min matches the interval minutes which is 30 min
   */
  intervals.shift();
  return intervals;
};

const getIntervalsForDay = (args: { [session in BusinessSession]: SessionTimings }, day: DateTime) => {
  return [...getIntervalsForSession(args.session1, day), ...getIntervalsForSession(args.session2, day)];
};

const getInterval = (args: {
  now: DateTime;
  storeTimings: StoreTimings;
  collectionType: CollectionType;
  dow: Dow;
  session: BusinessSession;
}) => {
  return hoursUtils.getInterval(args.storeTimings[args.dow][args.collectionType][args.session], args.now);
};

/**
 * open now?
 *
 * if now present in today's intervals
 * return true
 */
const isOpenNow = (args: {
  now: DateTime;
  store: Store;
  storeTimings: StoreTimings;
  collectionType: CollectionType;
}) => {
  // check store level
  if (!args.store.collectionSettings[args.collectionType].available) return false;

  const yesterday = getDow({ now: args.now.minus({ day: 1 }) });
  const yesterdaysSession1 = getInterval({
    ...args,
    now: args.now.minus({ day: 1 }),
    dow: yesterday,
    session: BusinessSession.session1
  });
  const yesterdaysSession2 = getInterval({
    ...args,
    now: args.now.minus({ day: 1 }),
    dow: yesterday,
    session: BusinessSession.session2
  });
  if (yesterdaysSession1.contains(args.now) || yesterdaysSession2.contains(args.now)) return true;

  const today = getDow(args);
  const todaysSession1 = getInterval({ ...args, dow: today, session: BusinessSession.session1 });
  const todaysSession2 = getInterval({ ...args, dow: today, session: BusinessSession.session2 });
  if (todaysSession1.contains(args.now) || todaysSession2.contains(args.now)) return true;

  return false;
};

// open today

const isOpenToday = (args: {
  now: DateTime;
  store: Store;
  storeTimings: StoreTimings;
  collectionType: CollectionType;
}) => {
  // check store level
  if (!args.store.collectionSettings[args.collectionType].available) return false;

  // if it's open now, then of course it's open today
  if (isOpenNow(args)) return true;

  // check today
  const today = getDow(args);
  const todaysSession1 = getInterval({ ...args, dow: today, session: BusinessSession.session1 });
  const todaysSession2 = getInterval({ ...args, dow: today, session: BusinessSession.session2 });
  if (todaysSession1.isAfter(args.now) || todaysSession2.isAfter(args.now)) return true;

  return false;
};

const isOpen = (args: { now: DateTime; store: Store; storeTimings: StoreTimings; collectionType: CollectionType }) => {
  return {
    openNow: isOpenNow(args),
    openToday: isOpenToday(args)
  };
};

const getCollectionIntervalsForCollectionType = (args: {
  now: DateTime;
  store: Store;
  storeTimings: StoreTimings;
  collectionType: CollectionType;
}) => {
  const today = getDow(args);
  const yesterday = getDow({ now: args.now.minus({ day: 1 }) });

  // do the comparisons first
  // then whatever is the result, return the appropriate periods
  const yesterdaysIntervals = getIntervalsForDay(
    args.storeTimings[yesterday][args.collectionType],
    args.now.minus({ day: 1 })
  );
  if (yesterdaysIntervals.filter(interval => interval.contains(args.now)).length != 0)
    return yesterdaysIntervals.filter(interval => interval.isAfter(args.now));

  const todaysIntervals = getIntervalsForDay(args.storeTimings[today][args.collectionType], args.now);
  return todaysIntervals.filter(interval => interval.isAfter(args.now));
};

export const getCollectionTimes = (args: {
  now: DateTime;
  store: Store;
  storeTimings: StoreTimings;
  collectionType: CollectionType;
}) => {
  const intervals = getCollectionIntervalsForCollectionType(args);
  const times = intervals.map(interval => hhmmUtils.toLocaleTime(interval.start));
  const openNow = isOpenNow(args);

  if (openNow) {
    return [...quickCollectionTimes[args.collectionType], ...times];
  }

  return times;
};

export const collectionTimeService = {
  isOpen,
  getCollectionTimes
};
