import * as React from 'react';

type Props = {
  spinning: boolean;
};

export class Spin extends React.Component<Props> {
  render() {
    if (this.props.spinning)
      return (
        <div className="spinner">
          <i className="spin las la-circle-notch"></i>
        </div>
      );
    return this.props.children;
  }
}
