import { Schema } from 'nutso';
import { Address } from '..';

export const addressSchema: Schema<Address> = {
  type: 'object',
  properties: {
    line1: {
      type: 'string'
    },
    suburb: {
      type: 'string'
    },
    postcode: {
      type: 'string'
    }
  }
};
