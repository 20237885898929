import {
  defaults,
  idFactory,
  ItemId,
  PizzaSizeSettings,
  PizzaSizeSpec,
  PizzaSpec,
  pizzaSpecSchema,
  routes
} from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Button } from '../elements/Button';
import { Form } from '../forms/Form';
import { FormItemContainer } from '../forms/FormItemContainer';
import { FormSwitch } from '../forms/FormSwitch';
import { TextInput } from '../forms/TextInput';
import { ItemSpecBaseEditWidget } from '../item_spec/ItemSpecBaseEditWidget';
import { PriceSpecEditWidget } from '../item_spec/PriceSpecEditWidget';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { listUtils } from '../utils/listUtils';
import { validationUtils } from '../utils/validationUtils';

type Props = {
  categoryId: string;
};

@observer
export class PizzaSpecEditWidget extends DocumentEditWidget<ItemId, PizzaSpec, Props> {
  //

  $pizzaSettings = repo.pizzaSettings.bind(this.props.documentId);

  name(): string {
    return 'Create Item';
  }

  moveUpModifier = () => {};

  createDocument(): PizzaSpec {
    return {
      id: idFactory.itemId(),
      restaurantId: this.props.documentId.restaurantId,
      storeId: this.props.documentId.storeId,
      categoryId: this.props.categoryId,
      ...defaults.pizzaSpec
    };
  }

  getWidgetNameForCssClass() {
    return 'pizza-edit-widget';
  }

  getRepo(): DocumentRepo<ItemId, PizzaSpec> {
    return repo.pizzaSpec;
  }

  getSchema(): Schema<PizzaSpec> {
    return pizzaSpecSchema;
  }

  nextRoute(): string {
    return routes.backoffice.category.link({ ...this.props.documentId, categoryId: this.props.categoryId }, {});
  }

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const pizzaSettings = this.$pizzaSettings.current();
    if (!pizzaSettings)
      return (
        <Alert type="warning">
          <a href={routes.backoffice.pizzaSettings.link(this.props.documentId, {})}>Click here</a> to setup pizzas for
          your store.
        </Alert>
      );

    if (!pizzaSettings.pizzaAvailable)
      return (
        <Alert type="warning">
          <a href={routes.backoffice.pizzaSettings.link(this.props.documentId, {})}>Click here</a> to enable pizzas for
          your store.
        </Alert>
      );

    const pizza = this.document;
    const submitLabel = this.props.create ? 'Create Pizza' : 'Save Pizza';
    return (
      <Form type="responsive" submit={{ label: submitLabel, handler: this.submit }} validationResult={this.validation}>
        <ItemSpecBaseEditWidget item={this.document} validation={this.validation} />
        <FormItemContainer
          label="Sizes"
          validation={this.validation.properties.sizes}
          className="pizza-sizes-form-item"
        >
          {this.renderSizes(pizza.sizes, this.validation.properties.sizes)}
        </FormItemContainer>
        <FormItemContainer
          label="Remove Toppings"
          validation={this.validation.properties.removeToppings}
          className="remove-toppings-form-item"
        >
          {this.renderRemoveToppings(pizza.removeToppings, this.validation.properties.removeToppings)}
        </FormItemContainer>
      </Form>
    );
  }

  renderSizes(
    sizes: { [pizzaSizeId: string]: PizzaSizeSpec },
    validation: Result<{ [pizzaSizeId: string]: PizzaSizeSpec }>
  ): React.ReactNode {
    const pizzaSettings = this.$pizzaSettings.current();
    if (!pizzaSettings || !pizzaSettings.sizes) return;
    return (
      <div className="sizes">
        {pizzaSettings.sizes.map((sizeSettings, i) =>
          this.renderSize(sizeSettings, sizes[sizeSettings.id], validation.properties[sizeSettings.id])
        )}
      </div>
    );
  }

  renderSize(sizeSettings: PizzaSizeSettings, sizeSpec?: PizzaSizeSpec, validation?: Result<PizzaSizeSpec>) {
    return (
      <div className="size" key={sizeSettings.id}>
        <div className="name">
          <FormSwitch
            label={`${sizeSettings.name}`}
            value={sizeSpec ? true : false}
            onChange={value =>
              value
                ? (this.document.sizes = {
                    ...this.document.sizes,
                    ...{ [sizeSettings.id]: { halfAndHalfAvailable: false, price: { basePrice: 0 } } }
                  })
                : delete this.document.sizes[sizeSettings.id]
            }
            validation={validationUtils.success}
          />
        </div>
        {this.renderSizeSpec(sizeSettings, sizeSpec, validation)}
      </div>
    );
  }

  renderSizeSpec(sizeSettings: PizzaSizeSettings, sizeSpec?: PizzaSizeSpec, validation?: Result<PizzaSizeSpec>) {
    if (!sizeSpec || !validation) return;
    return (
      <div className="spec">
        <FormSwitch
          label={`Half & Half Available?`}
          value={sizeSpec.halfAndHalfAvailable}
          onChange={value => (sizeSpec.halfAndHalfAvailable = value)}
          validation={validation!.properties.halfAndHalfAvailable}
        />
        <PriceSpecEditWidget priceSpec={sizeSpec.price} validation={validation.properties.price} />
      </div>
    );
  }

  renderRemoveToppings(removeToppings: string[], validation: Result<string[]>): React.ReactNode {
    return (
      <>
        {this.renderRemoveToppingsList(removeToppings, validation)}
        <div className="actions">{this.renderRemoveToppingsActions(removeToppings)}</div>
      </>
    );
  }

  renderRemoveToppingsActions(removeToppings: string[]): React.ReactNode {
    const onClick = () => (this.document.removeToppings = [...this.document.removeToppings, '']);
    return (
      <div className="actions">
        <Button onClick={onClick}>Add Remove Topping</Button>
      </div>
    );
  }

  renderRemoveToppingsList(removeToppings: string[], validation: Result<string[]>): React.ReactNode {
    if (!removeToppings || !removeToppings.length) return null;
    return (
      <div className="list">
        {removeToppings.map((remoteTopping, i) =>
          this.renderRemoveTopping(remoteTopping, i, removeToppings, validation.items[i])
        )}
      </div>
    );
  }

  renderRemoveTopping(removeTopping: string, i: number, list: string[], validation: Result<string>): any {
    return (
      <div className="item" key={i}>
        <TextInput value={removeTopping} onChange={value => (list[i] = value)} validation={validation} />
        <div className="actions">
          <div className="action" onClick={() => (this.document.removeToppings = listUtils.remove(list, i))}>
            <i className={`icon las la-times-circle`}></i>
          </div>
        </div>
      </div>
    );
  }

  getKey(): ItemId {
    if (this.props.create) {
      return {
        ...this.props.documentId,
        itemId: this.document.id
      };
    }
    return this.props.documentId;
  }
}
