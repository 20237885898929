import { collectionTypeLabel, dateUtils, formatAmount, Order, OrderStatusCode } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Badge, BadgeType } from '../elements/Badge';
import { StatusIndicator } from '../elements/StatusIndicator';
import { restaurantProvider } from '../provider/restaurantProvider';
import React = require('react');

export const orderStatusLabels: { [key in OrderStatusCode]: string } = {
  processingPayment: 'Processing Payment',
  failure: 'Failure',
  new: 'To Be Confirmed',
  accepted: 'Accepted',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  completed: 'Completed'
};
export const badgeType: { [key in OrderStatusCode]: BadgeType } = {
  processingPayment: 'warning',
  failure: 'failure',
  new: 'primary',
  accepted: 'success',
  rejected: 'failure',
  cancelled: 'failure',
  completed: 'failure'
};

type Props = {
  list: Order[] | undefined;
  onClick: (order: Order) => void;
};

@observer
export class OrderListWidget extends React.Component<Props> {
  //
  getMessage = (order: Order) => {
    switch (order.status.code) {
      case OrderStatusCode.processingPayment: {
        return 'Payment in progress. Please wait ...';
      }
      case OrderStatusCode.new: {
        return 'Your order has been sent to the store. Please wait for confirmation email or check status after sometime.';
      }
      case OrderStatusCode.failure: {
        return `Failure. ${order.status.message}`;
      }
      case OrderStatusCode.accepted:
        const eta = order.processing?.eta;
        return eta
          ? `Your order has been accepted. The estimated time is approximately ${eta}`
          : 'Your order has been accepted by the store. ';
      case OrderStatusCode.rejected:
        return 'Your order has been rejected by the store.';
      case OrderStatusCode.cancelled:
        return 'You have cancelled the order.';
      case OrderStatusCode.completed:
        return 'The order has been completed successfully.';
      default: {
        return `Unexpected order status`;
      }
    }
  };

  getIcon = (order: Order): { icon: string; type: 'success' | 'failure' | 'warning' | 'primary' } => {
    switch (order.status.code) {
      case OrderStatusCode.processingPayment:
        return { icon: 'la-credit-card', type: 'warning' };
      case OrderStatusCode.new:
        return { icon: 'la-check', type: 'primary' };
      case OrderStatusCode.failure:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.accepted:
        return { icon: 'la-check', type: 'success' };
      case OrderStatusCode.rejected:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.cancelled:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.completed:
        return { icon: 'la-check-double', type: 'success' };
      default:
        console.error(`Unexpected order status ${order.status}`);
        return { icon: 'la-question', type: 'warning' };
    }
  };

  renderItem(order: Order): React.ReactNode {
    const collectionType = collectionTypeLabel[order.cart.collectionType];
    const orderPlacedTime = dateUtils.timeLabel({ time: order.placedTime });
    const grandTotal = formatAmount(order.cart.grandTotal, restaurantProvider.$restaurant?.current()?.country);
    const message = this.getMessage(order);
    const icon = this.getIcon(order);

    return (
      <div className="order-list-view" key={order.id}>
        <StatusIndicator type={icon.type} icon={icon.icon} />
        <div className="collection-type">{collectionType}</div>
        <div className="time">{orderPlacedTime}</div>
        <Badge className="status" type={badgeType[order.status.code]}>
          {orderStatusLabels[order.status.code]}
        </Badge>
        <div className="grand-total">{grandTotal}</div>
        <div className="message">{message}</div>
        <div className="actions">
          <button className="view-order" onClick={() => this.props.onClick(order)}>
            View Order
          </button>
        </div>
      </div>
    );
  }

  render() {
    const orders = this.props.list;
    if (orders === undefined) return <div>Loading ...</div>;
    if (!orders || !orders.length) return <div>Orders empty</div>;
    return <div className="list">{orders.map(order => this.renderItem(order))}</div>;
  }
}
