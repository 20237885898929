import { Schema } from 'nutso';
import { Printer } from '..';

export const printerSchema: Schema<Printer> = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      minLength: 6,
      maxLength: 6
    },
    name: {
      type: 'string',
      minLength: 3,
      maxLength: 60
    },
    password: {
      type: 'string',
      minLength: 6,
      maxLength: 6
    },
    createdTime: {
      type: 'string'
    }
  }
};
