import { Order, OrderId } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Page } from '../../elements/Page';
import { ResolveResource } from '../../elements/ResolveResource';
import { OrderViewWidget } from '../../orders/OrderViewWidget';
import { repo } from '../../repo/repo';
import React = require('react');

type Props = {};
type RouteProps = OrderId;

@observer
export class OrderViewPage extends Page<Props, RouteProps> {
  //
  @observable
  $order = repo.order.bind(this.props.match.params);

  getTitle(): string {
    return 'View Order';
  }

  getAdditionalClassNames() {
    return 'website-order-view-page';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    return <ResolveResource resource={this.$order}>{order => <>{this.renderOrder(order)}</>}</ResolveResource>;
  }

  renderOrder(order: Order | null | undefined) {
    if (!order) return <div>Cannot find order</div>;
    return <OrderViewWidget id={this.props.match.params} document={order} />;
  }
}
