import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Lambda, IReactionPublic, reaction, when } from 'mobx';
import { IPromiseBasedObservable, FULFILLED, IFulfilledPromise, IResource } from 'mobx-utils';
import { parse } from 'query-string';

export abstract class Page<Props, RouteProps, QueryParams = unknown> extends React.Component<
  Props & RouteComponentProps<RouteProps>
> {
  //
  abstract getTitle(): string;
  abstract renderActions(): React.ReactNode;
  abstract renderBody(): React.ReactNode;

  queryParams = () => {
    return (parse(this.props.location.search ?? '') as unknown) as QueryParams;
  };

  // this function will be called when the page mounts, use this function instead of componentDidMount
  onPageEnter() {
    // for use by sub-classes, leave it empty
  }

  // this function will be called when the user leaves the page, use this function instead of componentWillUnmount
  onPageLeave() {
    // for use by sub-classes, leave it empty
  }

  private whenDisposers: Lambda[] = [];
  private reactionDisposers: Lambda[] = [];

  reaction = <Q extends {}>(
    expression: (r: IReactionPublic) => Q,
    effect: (arg: Q, r: IReactionPublic) => void
  ): void => {
    this.reactionDisposers.push(reaction(expression, effect));
  };

  /**
   * This is a wrapper around mobx when tied to the lifecycle of the page
   * So if the user leaves the page before when is called, then it will be disposed safely
   * So in any page, don't use mobx 'when', use super.when instead.
   */
  when = (predicate: () => boolean, effect: Lambda): void => {
    this.whenDisposers.push(when(predicate, effect));
  };

  whenFulfilled = (promise: IPromiseBasedObservable<any>, effect: Lambda): void => {
    this.whenDisposers.push(when(() => promise.state == FULFILLED, effect));
  };

  /**
   * If you want the value of the fulfilled promise then use this function
   */
  whenFulfilledWithValue = <P extends any>(promise: IPromiseBasedObservable<P>, effect: (value: P) => void): void => {
    this.whenDisposers.push(
      when(
        () => promise.state == FULFILLED,
        () => {
          effect((promise as IFulfilledPromise<P>).value);
        }
      )
    );
  };

  whenResourceAvailableWithValue = <P extends any>(resource: IResource<P>, effect: (value: P) => void): void => {
    this.whenDisposers.push(
      when(
        () => !!resource.current(),
        () => {
          effect(resource.current() as P);
        }
      )
    );
  };

  componentDidMount() {
    // console.log(`Mounting ${this.name()}`);
    // BackHandler.addEventListener("hardwareBackPress", this._hardwardBackHandler);
    this.onPageEnter();
  }

  componentWillUnmount() {
    // console.log(`Unmounting ${this.name()}`);
    // BackHandler.removeEventListener("hardwareBackPress", this._hardwardBackHandler);

    /**
     * Disponse whens
     */
    for (const disposer of this.whenDisposers) {
      console.log('Disposing when');
      disposer();
    }

    /**
     * Disponse reaction
     */
    for (const disposer of this.reactionDisposers) {
      console.log('Disposing reaction');
      disposer();
    }

    this.onPageLeave();
  }

  getFooter(): React.ReactNode {
    return <span></span>;
  }

  getAdditionalClassNames(): string {
    return '';
  }

  render() {
    return (
      <main>
        <div className={`page ${this.getAdditionalClassNames()}`}>
          <div className="header">
            <div className="title">{this.getTitle()}</div>
            <div className="actions">{this.renderActions()}</div>
          </div>
          <div className="body">{this.renderBody()}</div>
          <div className="footer">{this.getFooter()}</div>
        </div>
      </main>
    );
  }
}
