import * as React from 'react';
import { Button } from '../elements/Button';
import { toastStore } from '../toast/toastStore';
import { ValidationResult } from 'nutso';
import { ButtonType } from '@restoplus/core';

export type FormButton = {
  label: string;
  handler: () => void;
};

/**
 * Form is of 3 types,
 *
 * 1. the default type, that is just flows vertical including the labels and input (optimised for mobile first)
 * 2. horizontal - default on mobile and flows side ways on tablet
 * 3. responsive - default on mobile and the label and the input is horizontal on tablet
 */
type Props = {
  validationResult: ValidationResult;
  submit?: FormButton;
  cancel?: FormButton;
  loading?: boolean;
  type: 'default' | 'responsive' | 'horizontal';
  dataCy?: string;
};

export class Form extends React.Component<Props> {
  renderCancel = () => {
    const { cancel } = this.props;
    if (!cancel) return null;
    return <Button onClick={cancel.handler}>{cancel.label}</Button>;
  };

  renderSubmit = () => {
    const { submit, validationResult } = this.props;
    if (!submit) return null;
    return (
      <Button
        disabled={!validationResult.isValid}
        type={ButtonType.primary}
        onClick={() => {
          if (!validationResult.isValid) {
            toastStore.error(`[${validationResult.errorPath.join('.')}] ${validationResult.errorMessage}`);
            return;
          }
          submit.handler();
        }}
        dataCy="submit"
      >
        {submit.label}
      </Button>
    );
  };

  renderButtons = () => {
    const { submit, cancel } = this.props;
    if (!submit && !cancel) return null;

    return (
      <div className="form-buttons">
        {this.renderSubmit()}
        {this.renderCancel()}
      </div>
    );
  };

  render() {
    return (
      <form className={this.props.type} data-cy={this.props.dataCy}>
        <div className="form-items">{this.props.children}</div>
        {this.renderButtons()}
      </form>
    );
  }
}
