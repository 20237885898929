import { RestaurantId, StoreId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { StoreEditWidget } from './StoreEditWidget';
import React = require('react');

type Props = {};

export class StoreEditPage extends Page<Props, StoreId> {
  //
  getTitle(): string {
    return 'Edit Store';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <StoreEditWidget documentId={this.props.match.params} create={false} />;
  }
}
