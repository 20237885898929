import { Hours } from '../models/base/Hours';
import { SessionTimings } from '../models/store/StoreTimings';
import { hhmmUtils } from './hhmmUtils';
import { DateTime, Interval } from 'luxon';

const DISPLAY_FORMAT = 'hh:mm a';

const halfHourTime = (dt: DateTime) => {
  const minute = dt.get('minute');
  if (minute === 0 || minute === 30) return dt;
  const remainder = 30 - (minute % 30);
  return dt.set({ minute: minute + remainder });
};

const getStartDateTime = (session: SessionTimings, day: DateTime = DateTime.local()) => {
  const durationStart = hhmmUtils.toDateTime(session.hours.startHhmm);
  return durationStart.set({ day: day.day, month: day.month });
};

const getStartTimeOfDay = (hours: Hours) => {
  if (!hours) return '';
  const dt = hhmmUtils.toDateTime(hours.startHhmm);
  return dt.toFormat(DISPLAY_FORMAT);
};

const getEndTimeOfDay = (hours: Hours) => {
  const dt = hhmmUtils.toDateTime(hours.startHhmm);
  const endDt = dt.plus({ hours: hours.duration || 0 });
  return endDt.toFormat(DISPLAY_FORMAT);
};

const getInterval = (session: SessionTimings, day: DateTime = DateTime.local()) => {
  if (!session.available) return Interval.fromDateTimes(day, day);
  const startDt = getStartDateTime(session, day);
  const endDt = startDt.plus({ hours: session.hours.duration || 0, minutes: 10 });
  // interval should always start from half-hour time (like hh:00, hh:30 etc...)
  return Interval.fromDateTimes(halfHourTime(startDt), endDt);
};

export const hoursUtils = {
  halfHourTime,
  getStartDateTime,
  getStartTimeOfDay,
  getEndTimeOfDay,
  getInterval
};
