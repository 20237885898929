import { firestoreApi } from '../integration/firestoreApi';
import { FirestorePath } from '@restoplus/core';

/**
 * It's called as Repo on the client side and dao on the server-side,
 * just to keep the names different. Basically DAO is a term used on the server,
 * and repo is a loosely used term, so using it for the client-side
 */
export abstract class BaseRepo<K, T> {
  protected firestorePath: FirestorePath<K, T>;

  constructor(firestorePath: FirestorePath<K, T>) {
    this.firestorePath = firestorePath;
  }

  ref = (key: K) => {
    return firestoreApi.ref(this.firestorePath.path(key));
  };
}
