import { BasicItemSpec, basicItemSpecSchema, defaults, idFactory, ItemId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Form } from '../forms/Form';
import { FormItemContainer } from '../forms/FormItemContainer';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { ItemSpecBaseEditWidget } from './ItemSpecBaseEditWidget';
import { ModifierSpecsEditWidget } from './ModifierSpecsEditWidget';
import { PriceSpecEditWidget } from './PriceSpecEditWidget';

type Props = {
  categoryId: string;
};

@observer
export class BasicItemSpecEditWidget extends DocumentEditWidget<ItemId, BasicItemSpec, Props> {
  //
  name(): string {
    return 'Create Item';
  }

  moveUpModifier = () => {};

  createDocument(): BasicItemSpec {
    return {
      id: idFactory.itemId(),
      restaurantId: this.props.documentId.restaurantId,
      storeId: this.props.documentId.storeId,
      categoryId: this.props.categoryId,
      ...defaults.basicItemSpec
    };
  }

  getWidgetNameForCssClass(): string {
    return 'basic-item-edit-widget';
  }

  getRepo(): DocumentRepo<ItemId, BasicItemSpec> {
    return repo.basicItem;
  }

  getSchema(): Schema<BasicItemSpec> {
    return basicItemSpecSchema;
  }

  nextRoute(): string {
    return routes.backoffice.category.link({ ...this.props.documentId, categoryId: this.props.categoryId }, {});
  }

  renderModifiers = (item: BasicItemSpec, validation: Result<BasicItemSpec>) => {
    return (
      <ModifierSpecsEditWidget
        label="Modifiers"
        value={item.modifiers}
        onChange={value => (item.modifiers = value)}
        validation={validation.properties.modifiers}
      />
    );
  };

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    const item = this.document;
    const submitLabel = this.props.create ? 'Create Item' : 'Save Item';
    return (
      <Form type="responsive" submit={{ label: submitLabel, handler: this.submit }} validationResult={this.validation}>
        <ItemSpecBaseEditWidget item={this.document} validation={this.validation} />
        <FormItemContainer label="Price" validation={this.validation.properties.price} className="form-item-price-spec">
          <PriceSpecEditWidget priceSpec={item.price} validation={this.validation.properties.price} />
        </FormItemContainer>
        {this.renderModifiers(item, this.validation)}
      </Form>
    );
  }

  getKey(): ItemId {
    if (this.props.create) {
      return {
        ...this.props.documentId,
        itemId: this.document.id
      };
    }
    return this.props.documentId;
  }
}
