import { Schema } from 'nutso';
import { BasicItemSelection } from '../../models/item_selection/BasicItemSelection';

export const basicItemSelectionSchema: Schema<BasicItemSelection> = {
  type: 'object',
  properties: {
    modifiersValue: {
      type: 'object',
      properties: {
        '/.*/': {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    },
    type: {
      type: 'string'
    }
  }
};
