import { Category, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ResolveResource } from '../elements/ResolveResource';
import { Widget } from '../elements/Widget';
import { repo } from '../repo/repo';
import { CategoryViewWidget } from './CategoryViewWidget';
import { sortOrderProp } from '../utils/sortOrderProp';

type Props = StoreId;

@observer
export class CategoryListWidget extends Widget<Props> {
  //
  $categories = repo.categories.bindCollection(this.props);

  renderCategories(categories: Category[]) {
    if (!categories.length) return <div>Categories empty</div>;
    const sorted = categories.sort((a, b) => a.sortOrder - b.sortOrder);
    return sorted.map((category, i, list) => (
      <CategoryViewWidget
        key={category.id}
        document={category}
        id={{ ...this.props, categoryId: category.id }}
        sortOrder={sortOrderProp(list, i)}
      />
    ));
  }

  render() {
    return (
      <ResolveResource resource={this.$categories}>
        {categories => <div className="category-list">{this.renderCategories(categories!)}</div>}
      </ResolveResource>
    );
  }
}
