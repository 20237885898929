import React = require('react');
import { Image } from '@restoplus/core';
import { observer } from 'mobx-react';
import { ValidationResult } from 'nutso';
import { FileInput } from '../elements/FileInput';
import { Imgix } from '../elements/Imgix';
import { FormItemContainer, FormItemProps } from './FormItemContainer';

type Props = FormItemProps & {
  value?: Image;
  gcsFolder: string;
  onChange: (value: Image) => void;
  validation: ValidationResult;
  dataCy?: string;
};

@observer
export class FormImage extends React.Component<Props> {
  //
  renderPreview = () => {
    const { value: image } = this.props;
    if (!image || !image.path) return null;
    return (
      <div className="preview">
        <Imgix image={image} aspectRatio={null} />
      </div>
    );
  };
  render() {
    const { gcsFolder, onChange, value: image, dataCy } = this.props;
    return (
      <FormItemContainer {...this.props}>
        {this.renderPreview()}
        <FileInput gcsFolder={gcsFolder} onUploadComplete={path => onChange({ ...image!, path })} dataCy={dataCy} />
      </FormItemContainer>
    );
  }
}
