import * as React from 'react';
import { FormItemContainer, FormItemProps } from './FormItemContainer';
import { TextInput, TextInputProps } from './TextInput';

type Props = TextInputProps & FormItemProps;

export class FormTextInput extends React.Component<Props> {
  render() {
    return (
      <FormItemContainer {...this.props}>
        <TextInput {...this.props} />
      </FormItemContainer>
    );
  }
}
