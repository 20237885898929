import {
  BasicItem,
  basicItemSchema,
  BasicItemSpec,
  Category,
  CategoryId,
  CollectionType,
  defaults,
  idFactory,
  ItemId,
  ModifiersValue,
  routes,
  BasicItemSelection
} from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { validate } from 'nutso';
import { Page } from '../../elements/Page';
import { repo } from '../../repo/repo';
import { basicItemUtils } from '../../utils/basicItemUtils';
import { gotoPath } from '../../utils/browserHistory';
import { modifierUtils } from '../../utils/modifierUtils';
import { promiseValue } from '../../utils/promiseValue';
import { ItemActionButtons } from '../widgets/ItemActionButtons';
import { ModifiersWidget } from '../widgets/ModifiersWidget';
import React = require('react');
import { customerCart } from '../../provider/customerCartProvider';

type Props = {};
type RouteProps = CategoryId & ItemId & {};

@observer
export class BasicItemPage extends Page<Props, RouteProps> {
  //
  @observable
  $spec = repo.basicItem.getCacheFirstObservablePromise(this.props.match.params);

  @observable
  $category = repo.category.getCacheFirstObservablePromise(this.props.match.params);

  @observable
  selection: BasicItemSelection = {
    type: ItemType.basic,
    modifiersValue: {}
  };

  @observable
  quantity: number = 1;

  @computed
  get computedPrice() {
    const spec = promiseValue(this.$spec);
    const collectionType = customerCart.collectionType;
    if (!spec || !collectionType) return defaults.itemPrice;
    return basicItemUtils.getPrice({ spec, selection: this.selection });
  }

  @computed
  get summary(): string | undefined {
    const basicItemSpec = promiseValue(this.$spec);
    if (!basicItemSpec) return '';
    // Check if any Mandatory items are needed
    return modifierUtils.getModifiersSummary({
      modifiersSpec: basicItemSpec.modifiers,
      modifiersValue: this.selection.modifiersValue
    });
  }

  onAddToCart = (args: { category: Category; basicItemSpec: BasicItemSpec; collectionType: CollectionType }) => {
    const isMandatoryModifierSelected = modifierUtils.isMandatoryModifiersSelected({
      modifierSpecs: args.basicItemSpec.modifiers,
      modifiersValue: this.selection.modifiersValue
    });
    if (!isMandatoryModifierSelected) {
      alert('Please check your selections, some of them are mandatory.');
      return;
    }

    const basicItem: BasicItem = {
      id: idFactory.tiny(),
      type: ItemType.basic,
      summary: {
        name: args.basicItemSpec.name,
        description: this.summary,
        quantity: this.quantity,
        price: this.computedPrice
      },
      spec: args.basicItemSpec,
      selection: this.selection,
      category: args.category
    };

    const validation = validate(basicItem, basicItemSchema);
    if (!validation.isValid) {
      alert(`[${validation.errorPath.join('.')}] ${validation.errorMessage}`);
      return;
    }

    customerCart.addItem(basicItem);
    gotoPath(routes.website.onlineOrdering.items.link(this.props.match.params, {}));
  };

  getTitle(): string {
    switch (this.$spec.state) {
      case 'fulfilled':
        console.log(this.$spec.value);
        return this.$spec.value!.name;
    }
    return '';
  }

  getAdditionalClassNames() {
    return 'website-item-page';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    const collectionType = customerCart.collectionType;
    const basicItemSpec = promiseValue(this.$spec);
    const category = promiseValue(this.$category);
    if (!collectionType || !basicItemSpec || !category) return null;
    return this.renderBasicItem({ category, basicItemSpec, collectionType });
  }

  renderBasicItem(args: { category: Category; basicItemSpec: BasicItemSpec; collectionType: CollectionType }) {
    return (
      <div className="basic-item">
        <>
          {this.renderModifiers(args)}
          {this.renderActionButtons(args)}
        </>
      </div>
    );
  }

  renderModifiers(args: {
    category: Category;
    basicItemSpec: BasicItemSpec;
    collectionType: CollectionType;
  }): React.ReactNode {
    return (
      <ModifiersWidget
        value={this.selection.modifiersValue}
        onChange={value => (this.selection.modifiersValue = value)}
        specs={args.basicItemSpec.modifiers}
      />
    );
  }

  renderActionButtons(args: { category: Category; basicItemSpec: BasicItemSpec; collectionType: CollectionType }) {
    const price = this.computedPrice[args.collectionType] ?? 0;
    return (
      <ItemActionButtons
        price={price}
        quantity={this.quantity}
        onInc={() => this.quantity++}
        onDec={() => {
          if (this.quantity < 1) return;
          this.quantity--;
        }}
        addToCart={() => this.onAddToCart(args)}
      />
    );
  }
}
