import { StoreId } from '@restoplus/core';
import { computed } from 'mobx';
import { Path } from 'path-parser';
import { repo } from '../repo/repo';
import { locationProvider } from './locationProvider';

// provides an observable instance of current restaurant, based on the url
class StoreProvider {
  //
  @computed
  get storeId(): StoreId | null {
    const template = '*prefix/restaurants/:restaurantId/stores/:storeId';
    const path = new Path(template);
    const match = path.partialTest(locationProvider.path);
    if (!match) return null;
    return match as StoreId;
  }

  @computed
  get $store() {
    const storeId = this.storeId;
    if (!storeId) return null;
    return repo.store.bind(storeId);
  }
}

export const storeProvider = new StoreProvider();
