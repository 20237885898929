import 'firebase/analytics';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import * as _firebaseui from 'firebaseui';
import { toastStore } from '../toast/toastStore';
import { webConfig } from '../utils/webConfig';

firebase.initializeApp(webConfig);
firebase.analytics();

let firestore = firebase.firestore();
// Testing for local development
if (webConfig.env === 'local') {
  firestore.settings({
    host: 'localhost:8081',
    ssl: false,
    experimentalForceLongPolling: true
  });
}

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(function(err) {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      toastStore.warning(`Failed to enable persistence. Please check if any other tabs are open.`);
    } else if (err.code == 'unimplemented') {
      console.log(`Failed to enable persistence. Unsupported browser.`);
      // toastStore.error(`Failed to enable persistence. Unsupported browser.`);
    }
  });

export const fb = firebase;
export type CollectionReference = firebase.firestore.CollectionReference;

export const fs = firestore;

export const storage = firebase.storage();
export const firebaseui = _firebaseui;
