import { CategoryId, ItemId } from '@restoplus/core';
import { Page } from '../elements/Page';
import { BasicItemSpecEditWidget } from './BasicItemSpecEditWidget';
import React = require('react');

type Props = {};
type RouteProps = CategoryId & ItemId & {};

export class BasicItemSpecEditPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Edit Item';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return (
      <BasicItemSpecEditWidget
        documentId={this.props.match.params}
        categoryId={this.props.match.params.categoryId}
        create={false}
      />
    );
  }
}
