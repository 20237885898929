import {
  Category,
  CategoryId,
  Deal,
  DealItem,
  DealPizza,
  DealPizzaSelection,
  DealSpec,
  defaults,
  flatten,
  formatAmount,
  idFactory,
  ItemId,
  ModifiersValue,
  noItems,
  nth,
  PizzaSpec,
  repeat,
  testId,
  DealSelection,
  dealSchema,
  routes,
  isNil,
  isDealPizzaFull,
  DealPizzaHalfAndHalf,
  isDealPizzaHalfAndHalf
} from '@restoplus/core';
import { ItemPrice } from '@restoplus/core/src/models/item/ItemPrice';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '../../elements/Button';
import { Checkbox } from '../../elements/Checkbox';
import { Page } from '../../elements/Page';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { repo } from '../../repo/repo';
import { dealUtils } from '../../utils/dealUtils';
import { modifierUtils } from '../../utils/modifierUtils';
import { pizzaUtils } from '../../utils/pizzaUtils';
import { promiseValue } from '../../utils/promiseValue';
import { ItemActionButtons } from '../widgets/ItemActionButtons';
import { ModifiersWidget } from '../widgets/ModifiersWidget';
import { ModifierWidget } from '../widgets/ModifierWidget';
import React = require('react');
import { customerCart } from '../../provider/customerCartProvider';
import { validate } from 'nutso';
import { gotoPath } from '../../utils/browserHistory';

const natsort = require('natsort');
const sorter = natsort({ insensitive: true });

const enum Display {
  deal = 'deal',
  choosePizza = 'choosePizza',
  customizePizza = 'customizePizza'
  // CUSTOMIZE_ITEM = 'CUSTOMIZE_ITEM'
}

const enum PizzaType {
  // full = 'full',
  firstHalf = 'firstHalf',
  secondHalf = 'secondHalf'
}

const DEFAULT_PIZZA_ENTRY: DealPizzaSelection = {
  pizzaId: '',
  removeToppings: [],
  addToppingsIds: []
};

type Props = {};
type RouteProps = CategoryId & ItemId & {};

@observer
export class DealPage extends Page<Props, RouteProps> {
  //
  @observable
  $spec = repo.dealSpec.getCacheFirstObservablePromise(this.props.match.params);

  @observable
  $category = repo.category.getCacheFirstObservablePromise(this.props.match.params);

  @observable
  $pizzaSettings = repo.pizzaSettings.bind(this.props.match.params);

  @observable
  $allItems = repo.items.listAllValuesCacheFirstObservablePromise(this.props.match.params);

  @observable
  quantity: number = 1;

  @computed
  get allPizzas() {
    const allItems = promiseValue(this.$allItems);
    if (!allItems) return [];
    return allItems.filter(item => item.type === ItemType.pizza) as PizzaSpec[];
  }

  @observable
  selection: DealSelection = {
    type: ItemType.deal,
    pizzas: [],
    items: [],
    modifiersValue: {}
  };

  // state for the page
  @observable display: Display = Display.deal;
  @observable pizzaIndex: number = 0;
  @observable pizzaType?: PizzaType;
  @observable itemIndex: number = 0;

  @computed
  get dealPizzaSpecs() {
    const dealSpec = promiseValue(this.$spec);
    if (!dealSpec) return [];
    return flatten(dealSpec.pizzas.map(dealPizza => repeat(dealPizza, dealPizza.quantity)));
  }

  @computed
  get dealItemSpecs() {
    const dealSpec = promiseValue(this.$spec);
    if (!dealSpec) return [];
    return flatten(dealSpec.items.map(dealItem => repeat(dealItem, dealItem.quantity)));
  }

  getAdditionalClassNames() {
    return 'deal-page';
  }

  // populate deal with a placeholder, such that we can manipulate it when the user interacts
  onPageEnter() {
    this.whenFulfilledWithValue(this.$spec, dealSpec => {
      const dealItemSpecs = dealUtils.flattenDealItemSpecs(dealSpec);
      const dealPizzaSpecs = dealUtils.flattenDealPizzaSpecs(dealSpec);

      this.selection.items = dealItemSpecs.map((item, i) => {
        const values = item.modifiers.reduce((prev, modifier) => {
          prev[modifier.id] = [];
          return prev;
        }, {} as ModifiersValue);
        return { modifiersValue: values };
      });

      this.selection.pizzas = dealPizzaSpecs.map((pizzaSpec, i) => DEFAULT_PIZZA_ENTRY);
    });
  }

  renderActions(): React.ReactNode {
    return null;
  }

  renderBody(): React.ReactNode {
    switch (this.display) {
      case Display.deal:
        return this.renderDeal();
      case Display.choosePizza:
        return this.renderChoosePizza();
      case Display.customizePizza:
        return this.renderCustomizePizza();
      // case Display.CUSTOMIZE_ITEM:
      //   return this.renderCustomizeItem();
    }
  }

  renderDeal() {
    return (
      <>
        <div className="deal-content">
          {this.renderPizzas()}
          {this.renderItems()}
          {this.renderDealModifiers()}
        </div>
        {this.renderActionButtons()}
      </>
    );
  }

  renderCustomizations() {
    switch (this.display) {
      case Display.deal:
        return null;
      case Display.choosePizza:
        return this.renderChoosePizza();
      case Display.customizePizza:
        return this.renderCustomizePizza();
      // case Display.CUSTOMIZE_ITEM:
      //   return this.renderCustomizeItem();
    }
    return null;
  }

  name(): string {
    return 'Deal Page';
  }

  getTitle(): string {
    const dealSpec = promiseValue(this.$spec);
    if (!dealSpec) return '';

    const pi = this.pizzaIndex + 1;
    const piTitle = `${pi}${nth(pi)}`;

    switch (this.display) {
      case Display.deal:
        return dealSpec.name;
      case Display.choosePizza:
      case Display.customizePizza: {
        const dealPizza = this.selection.pizzas[this.pizzaIndex];
        if (isDealPizzaFull(dealPizza)) return `${piTitle} Pizza`;
        // half & half
        if (this.pizzaType == PizzaType.firstHalf) return `${piTitle} Pizza - First Half`;
        else return `${piTitle} Pizza - Second Half`;
      }
      // case Display.CUSTOMIZE_ITEM: {
      //   const spec = this.dealItemSpecs[this.itemIndex];
      //   return spec.name;
      // }
    }
  }

  // footer(): React.ReactNode {
  //   const doneButton = {
  //     label: 'Done',
  //     onPress: () => (this.display = Display.DEAL),
  //     type: _core.ButtonType.primary
  //   };
  //   const addToCartButton = {
  //     label: 'Add to cart',
  //     onPress: this.addToCart,
  //     type: _core.ButtonType.success
  //     // disabled: !this.valid
  //   };

  //   switch (this.display) {
  //     case Display.DEAL:
  //       return <Buttons left={addToCartButton} />;
  //     case Display.CHOOSE_PIZZA:
  //       return null;
  //     case Display.CUSTOMIZE_PIZZA: {
  //       return <Buttons left={doneButton} />;
  //     }
  //     case Display.CUSTOMIZE_ITEM: {
  //       return <Buttons left={doneButton} />;
  //     }
  //   }
  // }

  /**
   * Deal price is the base price +
   * * the extra charge for each item attributes
   * * the extra charge for pizza specified at the deal level
   * * extra charge for toppings for each selected pizza
   * * half & half charge if the deal pizza is half and half and it's applied in the order
   *
   * basePrice + itemAttrsPrice + pizzaPrice + halfAndHalfPrice + pizzaToppingsPrice + halfAndHalfToppingsPrice [optional]
   */
  @computed
  get computedPrice(): ItemPrice {
    const dealSpec = promiseValue(this.$spec);
    const pizzaSettings = this.$pizzaSettings.current();
    const collectionType = customerCart.collectionType;
    if (!dealSpec || !pizzaSettings || !collectionType) return defaults.itemPrice;

    return dealUtils.getPrice({
      dealSpec,
      dealPizzaSpecs: this.dealPizzaSpecs,
      dealItemSpecs: this.dealItemSpecs,
      dealPizzas: this.selection.pizzas,
      dealItems: this.selection.items,
      modifiersValue: this.selection.modifiersValue,
      pizzaSettings,
      pizzaList: this.allPizzas
    });
  }

  @computed
  get cartItemSummary(): string {
    const dealSpec = promiseValue(this.$spec);
    const pizzaSettings = this.$pizzaSettings.current();
    if (!dealSpec || !pizzaSettings) return '';
    return dealUtils.getSummary(
      dealSpec,
      this.dealPizzaSpecs,
      this.dealItemSpecs,
      this.selection.pizzas,
      this.selection.items,
      this.selection.modifiersValue,
      pizzaSettings,
      this.allPizzas
    );
  }

  onAddToCart = (category: Category, dealSpec: DealSpec) => {
    const isMandatoryModifierSelected = modifierUtils.isMandatoryModifiersSelected({
      modifierSpecs: dealSpec.modifiers,
      modifiersValue: this.selection.modifiersValue
    });
    if (!isMandatoryModifierSelected) {
      alert('Please check your selections, some of them are mandatory.');
      return;
    }

    const deal: Deal = {
      id: idFactory.tiny(),
      summary: {
        name: dealSpec.name,
        quantity: this.quantity,
        price: this.computedPrice,
        description: this.cartItemSummary
      },
      type: ItemType.deal,
      spec: dealSpec,
      category: category,
      selection: toJS(this.selection)
    };

    const validation = validate(toJS(deal), dealSchema);
    console.log(toJS(deal), validation);
    if (!validation.isValid) {
      alert(`[${validation.errorPath.join('.')}] ${validation.errorMessage}`);
      return;
    }

    customerCart.addItem(deal);

    gotoPath(routes.website.onlineOrdering.items.link(this.props.match.params, {}));
  };

  renderDealModifiers = () => {
    const dealSpec = promiseValue(this.$spec);
    if (!dealSpec) return null;
    return (
      <div className="deal-modifiers">
        <ModifiersWidget
          value={this.selection.modifiersValue}
          onChange={value => (this.selection.modifiersValue = value)}
          specs={dealSpec.modifiers}
        />
      </div>
    );
  };

  renderCustomizeItem = () => {
    const dealItem = this.selection.items[this.itemIndex];
    const dealItemSpec = this.dealItemSpecs[this.itemIndex];
    return (
      <div className="customize-item">
        <ModifiersWidget
          value={dealItem.modifiersValue}
          onChange={value => (dealItem.modifiersValue = value)}
          specs={dealItemSpec.modifiers}
        />
      </div>
    );
  };

  renderRemoveToppings(pizzaSpec: PizzaSpec, dealPizzaEntry: DealPizzaSelection) {
    const pizzaSettings = this.$pizzaSettings.current();
    const collectionType = customerCart.collectionType;
    if (!pizzaSettings || !collectionType || noItems(pizzaSpec.removeToppings)) return null;
    return (
      <ModifierWidget
        spec={pizzaUtils.getRemoveToppingsModifier({ spec: pizzaSpec, settings: pizzaSettings, collectionType })}
        value={dealPizzaEntry.removeToppings}
        onChange={value => (dealPizzaEntry.removeToppings = value)}
      />
    );
  }

  renderAddToppings(pizzaSpec: PizzaSpec, dealPizzaEntry: DealPizzaSelection) {
    const pizzaSettings = this.$pizzaSettings.current();
    const collectionType = customerCart.collectionType;
    const sizeId = dealPizzaEntry.pizzaId;

    if (!sizeId || !pizzaSettings || !collectionType || noItems(pizzaSettings.toppings)) return null;

    return (
      <ModifierWidget
        spec={pizzaUtils.getAddToppingsModifier({ spec: pizzaSpec, settings: pizzaSettings, sizeId, collectionType })}
        value={dealPizzaEntry.addToppingsIds}
        onChange={value => (dealPizzaEntry.addToppingsIds = value)}
      />
    );
  }

  renderCustomizePizza = () => {
    const dealPizza = this.selection.pizzas[this.pizzaIndex];
    let dealPizzaSelection: DealPizzaSelection | undefined;

    if (isDealPizzaFull(dealPizza)) {
      dealPizzaSelection = dealPizza;
    } else {
      switch (this.pizzaType) {
        case PizzaType.firstHalf:
          dealPizzaSelection = dealPizza.firstHalf;
          break;
        case PizzaType.secondHalf:
          dealPizzaSelection = dealPizza.secondHalf;
          break;
      }
    }

    if (isNil(dealPizzaSelection)) return <div>Error in rendering deal pizza {}</div>;

    const pizzaSpecs = this.allPizzas;

    // find the pizza spec
    const pizzaSpec = pizzaSpecs.find(pizza => pizza.id == dealPizzaSelection!.pizzaId);
    if (!pizzaSpec) {
      console.error(`Cannot find pizza spec`);
      return null;
    }

    // const dealPizzaSpec = this.dealPizzaSpecs[this.pizzaIndex];

    return (
      <div className="customize-pizza">
        {this.renderRemoveToppings(pizzaSpec, dealPizzaSelection!)}
        {this.renderAddToppings(pizzaSpec, dealPizzaSelection!)}
        <Button className="done-button" onClick={() => (this.display = Display.deal)}>
          Done
        </Button>
      </div>
    );
  };

  choosePizza = (spec: PizzaSpec) => {
    const dealPizza = this.selection.pizzas[this.pizzaIndex];
    if (isDealPizzaFull(dealPizza)) {
      dealPizza.pizzaId = spec.id;
      dealPizza.addToppingsIds = [];
      dealPizza.removeToppings = [];
    } else {
      // half & half
      switch (this.pizzaType) {
        case PizzaType.firstHalf:
          dealPizza.firstHalf = {
            pizzaId: spec.id,
            addToppingsIds: [],
            removeToppings: []
          };
          break;
        case PizzaType.secondHalf:
          dealPizza.secondHalf = {
            pizzaId: spec.id,
            addToppingsIds: [],
            removeToppings: []
          };
          break;
      }
    }
    this.display = Display.deal;
  };

  renderChoosePizza = () => {
    // get pizza list
    let pizzaSpecs = this.allPizzas;
    if (!pizzaSpecs.length) return <div>{`No pizzas found.`}</div>;

    const dealPizzaSpec = this.dealPizzaSpecs[this.pizzaIndex];
    const sizeId = dealPizzaSpec.sizeId;

    // filter by size
    pizzaSpecs = pizzaSpecs.filter(pizza => Object.keys(pizza.sizes).includes(sizeId));

    // filter by pizzas chosen for this deal
    pizzaSpecs = pizzaSpecs.filter(pizza => Object.keys(dealPizzaSpec.pizzas).includes(pizza.id));

    if (!pizzaSpecs.length) return <div>{`No pizzas found for this size.`}</div>;

    // if half & half enabled, filter pizzas that support half & half
    const dealPizza = this.selection.pizzas[this.pizzaIndex];
    if (isDealPizzaHalfAndHalf(dealPizza)) {
      pizzaSpecs = pizzaSpecs.filter(pizzaSpec => pizzaSpec.sizes[sizeId].halfAndHalfAvailable);
    }

    if (!pizzaSpecs.length) return <div>{`Pizzas with half & half not available for this size`}</div>;

    const sorted = pizzaSpecs.sort((a, b) => sorter(a.name, b.name));
    return (
      <div className="choose-pizza">
        {sorted.map((pizzaSpec, i) => {
          const pizzaEntrySpec = dealPizzaSpec.pizzas[pizzaSpec.id];
          return (
            <div className="pizza-spec" key={pizzaSpec.id} onClick={() => this.choosePizza(pizzaSpec)}>
              <div className="header">
                <div className="name">{pizzaSpec.name}</div>
                {pizzaEntrySpec.extraCharge > 0 && (
                  <div className="price">
                    <span>+ </span>
                    {formatAmount(pizzaEntrySpec.extraCharge, restaurantProvider.$restaurant?.current()?.country)}
                  </div>
                )}
              </div>
              <div className="description">{pizzaSpec.description}</div>
            </div>
          );
        })}
        <Button className="done-button" onClick={() => (this.display = Display.deal)}>
          Done
        </Button>
      </div>
    );
  };

  renderItem = (dealItem: DealItem, ii: number) => {
    const dealItemSpec = this.dealItemSpecs[ii];
    const summary = modifierUtils.getModifiersSummary({
      modifiersSpec: dealItemSpec.modifiers,
      modifiersValue: dealItem.modifiersValue
    });
    const onClick = () => {
      this.itemIndex = ii;
      // this.display = Display.CUSTOMIZE_ITEM;
    };
    return (
      <div className="deal-item" onClick={onClick} key={dealItemSpec.id}>
        <div className="header">
          <div className="name">{dealItemSpec.name}</div>
        </div>
        <ModifiersWidget
          value={dealItem.modifiersValue}
          onChange={value => (dealItem.modifiersValue = value)}
          specs={dealItemSpec.modifiers}
        />
      </div>
    );
  };

  renderItems = () => {
    return <div className="deal-items">{this.selection.items.map((item, i) => this.renderItem(item, i))}</div>;
  };

  getDealPizzaSelectionLabel = (selection: DealPizzaSelection, pizzaType?: PizzaType) => {
    // customer has selected pizza
    if (selection.pizzaId) {
      const spec = this.allPizzas.find(item => item.id == selection.pizzaId);
      return spec?.name || 'Spec not found';
    }
    if (!pizzaType) return 'Choose Pizza';
    // customer has not selected pizza
    switch (pizzaType) {
      case PizzaType.firstHalf:
        return 'Choose First Half';
      case PizzaType.secondHalf:
        return 'Choose Second Half';
    }
  };

  renderDealPizzaSelection = (dealPizzaSelection: DealPizzaSelection, pi: number, pizzaType?: PizzaType) => {
    //
    const label = this.getDealPizzaSelectionLabel(dealPizzaSelection, pizzaType);
    const pizzaSettings = this.$pizzaSettings.current();
    const dealPizzaSpec = this.dealPizzaSpecs[pi];
    if (!pizzaSettings) return null;

    const onChoosePizza = () => {
      this.pizzaIndex = pi;
      this.pizzaType = pizzaType;
      this.display = Display.choosePizza;
    };

    const onCustomizePizza = () => {
      this.pizzaIndex = pi;
      this.pizzaType = pizzaType;
      this.display = Display.customizePizza;
    };

    const summary = pizzaUtils.getToppingsSummary({
      settings: pizzaSettings,
      selection: {
        type: ItemType.pizza,
        sizeId: dealPizzaSpec.sizeId,
        removeToppings: dealPizzaSelection.removeToppings,
        addToppingsIds: dealPizzaSelection.addToppingsIds
      }
    });

    return (
      <div className="deal-pizza-entry">
        <div className="choose-container">
          <Button onClick={onChoosePizza} className="choose-pizza-button">
            {label}
          </Button>
          {dealPizzaSelection.pizzaId && (
            <Button
              testId={testId(`btn_customise`, label, `${pi}`)}
              onClick={onCustomizePizza}
              className="customize-pizza-button"
            >
              Customize
            </Button>
          )}
        </div>
        {summary && (
          <div className="summary">
            <pre>{summary}</pre>
          </div>
        )}
      </div>
    );
  };

  renderHalfAndHalfPizza = (halfAndHalf: DealPizzaHalfAndHalf, pi: number) => {
    return (
      <div className="half-and-half">
        {this.renderDealPizzaSelection(halfAndHalf.firstHalf, pi, PizzaType.firstHalf)}
        {this.renderDealPizzaSelection(halfAndHalf.secondHalf, pi, PizzaType.secondHalf)}
      </div>
    );
  };

  renderPizzaBody = (dealPizza: DealPizza, pi: number) => {
    if (isDealPizzaFull(dealPizza)) {
      return this.renderDealPizzaSelection(dealPizza, pi);
    }
    return this.renderHalfAndHalfPizza(dealPizza, pi);
  };

  renderHalfAndHalfSwitch = (dealPizza: DealPizza, pi: number) => {
    // load pizza config
    const pizzaSettings = this.$pizzaSettings.current();
    if (!pizzaSettings) return;

    // check if this size has half and half enabled
    const sizeId = this.dealPizzaSpecs[pi].sizeId;
    const pizzaSizeSettings = pizzaSettings.sizes.find(e => e.id === sizeId);
    if (!pizzaSizeSettings) return null;

    if (!pizzaSizeSettings.hasHalfAndHalf) return null; // no half & half for this size

    // render switch
    return (
      <Checkbox
        label="Half & Half"
        value={isDealPizzaHalfAndHalf(dealPizza)}
        onChange={value => {
          this.selection.pizzas[pi] = value
            ? { firstHalf: DEFAULT_PIZZA_ENTRY, secondHalf: DEFAULT_PIZZA_ENTRY }
            : DEFAULT_PIZZA_ENTRY;
        }}
      />
    );
  };

  renderPizza = (dealPizza: DealPizza, pi: number) => {
    const piTitle = `${pi + 1}${nth(pi + 1)} Pizza`;
    return (
      <div className="deal-pizza" key={piTitle}>
        <div className="header">
          <div className="title">{piTitle}</div>
          {this.renderHalfAndHalfSwitch(dealPizza, pi)}
        </div>
        <div className="body">{this.renderPizzaBody(dealPizza, pi)}</div>
      </div>
    );
  };

  renderPizzas = () => {
    return <div className="deal-pizzas">{this.selection.pizzas.map((pizza, i) => this.renderPizza(pizza, i))}</div>;
  };

  renderActionButtons() {
    const category = promiseValue(this.$category);
    const dealSpec = promiseValue(this.$spec);
    const pizzaSettings = this.$pizzaSettings.current();
    const collectionType = customerCart.collectionType;
    if (!category || !dealSpec || !pizzaSettings || !collectionType) return null;
    const price = this.computedPrice[collectionType] ?? 0;
    return (
      <ItemActionButtons
        price={price}
        quantity={this.quantity}
        onInc={() => this.quantity++}
        onDec={() => {
          if (this.quantity < 1) return;
          this.quantity--;
        }}
        addToCart={() => this.onAddToCart(category, dealSpec)}
      />
    );
  }
}
