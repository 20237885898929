import { Schema } from 'nutso';
import { PizzaSelection } from '../../models/item_selection/PizzaSelection';

export const pizzaSelectionSchema: Schema<PizzaSelection> = {
  type: 'object',
  properties: {
    type: {
      type: 'string'
    },
    sizeId: {
      type: 'string'
    },
    addToppingsIds: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    removeToppings: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  }
};
