import { PriceSpec, CollectionType } from '@restoplus/core';

/**
 * BasePrice also includes extra charge based on collection type
 */
const getBasePrice = (price: PriceSpec, collectionType?: CollectionType): number => {
  if (!price.extraCharge || !collectionType) return price.basePrice;
  return price.basePrice + price.extraCharge[collectionType];
};

export const priceSpecUtils = {
  getBasePrice
};
