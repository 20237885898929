import { Schema } from 'nutso';
import { StorePrinter } from '..';

export const storePrinterSchema: Schema<StorePrinter> = {
  type: 'object',
  properties: {
    restaurantId: {
      type: 'string'
    },
    storeId: {
      type: 'string'
    },
    printerId: {
      type: 'string',
      minLength: 6,
      maxLength: 6,
      optional: true,
      pattern: /^\d{6}$/
    }
  }
};
