import { OrderByDirection } from '@restoplus/core';
import { observable, toJS } from 'mobx';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { Cursor } from '../integration/firestoreApi';
import { CollectionRepo } from '../repo/CollectionRepo';
import { toastStore } from '../toast/toastStore';

// K = Key, T = Type
export abstract class CollectionPageWidget<K, T> extends React.Component<K> {
  //
  @observable private list = observable.array<T>([]);
  @observable private cursor?: Cursor;

  abstract name(): string;
  abstract orderBy(): Extract<keyof T, string>;

  abstract getCollectionRepo(): CollectionRepo<K, T>;
  abstract getItemId(item: T, i: number): string;
  abstract renderItem(item: T): React.ReactNode;

  emptyMessage(): React.ReactNode {
    return <Alert type="info">There are no items in the list.</Alert>;
  }

  orderByDirection(): OrderByDirection {
    return 'asc';
  }

  private load = async () => {
    const hide = toastStore.loading(`Loading ${this.name()} List`);
    const limit = 50;
    const key = this.props;
    const page = await this.getCollectionRepo().page({
      key,
      orderByField: this.orderBy(),
      orderByDirection: this.orderByDirection(),
      limit,
      cursor: this.cursor
    });
    this.cursor = page.cursor;
    this.list.push(...page.list);
    hide();
  };

  componentDidMount() {
    this.load();
  }

  renderLoadMoreButton = () => {
    if (!this.cursor) {
      return null;
    }

    return (
      <button className="more" onClick={this.load}>
        Load More
      </button>
    );
  };

  renderList = () => {
    return (
      <div className="list">
        {this.list.map((item, i) => {
          return (
            <div className="item" key={this.getItemId(item, i)}>
              {this.renderItem(item)}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    if (!this.list.length) {
      return this.emptyMessage();
    }

    return (
      <div className="list-widget">
        {this.renderList()}
        {this.renderLoadMoreButton()}
      </div>
    );
  }
}
