import { toJS } from 'mobx';

const moveUp = <T>(list: T[], i: number): T[] => {
  const js = toJS(list);
  if (i < 1) return js;
  [js[i], js[i - 1]] = [js[i - 1], js[i]];
  return js;
};

const moveDown = <T>(list: T[], i: number): T[] => {
  const js = toJS(list);
  if (i >= list.length - 1) return js;
  [js[i], js[i + 1]] = [js[i + 1], js[i]];
  return js;
};

const remove = <T>(list: T[], index: number) => {
  const js = toJS(list);
  return js.filter((item, i) => i !== index);
};

const sortByNameFn = (a: { name: string }, b: { name: string }) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const sortBySortOrderFn = (a: { sortOrder: number }, b: { sortOrder: number }) => {
  if (a.sortOrder < b.sortOrder) {
    return -1;
  }
  if (a.sortOrder > b.sortOrder) {
    return 1;
  }

  // names must be equal
  return 0;
};

const sortByName = <T extends { name: string }>(list: T[]) => {
  const shallowCopy = [...list];
  return shallowCopy.sort(sortByNameFn);
};

const sortBySortOrder = <T extends { sortOrder: number }>(list: T[]) => {
  const shallowCopy = [...list];
  return shallowCopy.sort(sortBySortOrderFn);
};

export const listUtils = {
  moveUp,
  moveDown,
  remove,
  sortByName,
  sortBySortOrder
};
