import { Schema } from 'nutso';
import { Store } from '..';
import { addressSchema } from './addressSchema';

export const storeSchema: Schema<Store> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    name: {
      type: 'string',
      minLength: 3,
      maxLength: 30
    },
    timezone: {
      type: 'string'
    },
    hasSplitTimings: {
      type: 'boolean',
      optional: true
    },
    creatorUid: {
      type: 'string'
    },
    editorUid: {
      type: 'string'
    },
    createdTime: {
      type: 'string'
    },
    editedTime: {
      type: 'string'
    },
    address: addressSchema,
    phoneNumber: {
      type: 'string'
    },
    collectionSettings: {
      type: 'object',
      properties: {
        pickup: {
          type: 'object',
          properties: {
            available: {
              type: 'boolean'
            }
          }
        },
        delivery: {
          type: 'object',
          properties: {
            available: {
              type: 'boolean'
            }
          }
        },
        dineIn: {
          type: 'object',
          properties: {
            available: {
              type: 'boolean'
            }
          }
        }
      }
    },
    temporarilyClosed: {
      optional: true,
      type: 'object',
      properties: {
        reason: {
          type: 'string'
        }
      }
    },
    permanentlyClosed: {
      optional: true,
      type: 'object',
      properties: {
        reason: {
          type: 'string'
        }
      }
    }
  }
};
