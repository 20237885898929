import * as React from 'react';
import { StoreViewWidget } from './StoreViewWidget';
import { Restaurant, Store, AspectRatio, routes } from '@restoplus/core';
import { Imgix } from '../elements/Imgix';
import { gotoPath } from '../utils/browserHistory';

type Props = {
  onClick?: Function;
};
export class StoreHeaderWidget extends StoreViewWidget<Props> {
  renderStore(restaurant: Restaurant, store: Store): JSX.Element {
    return (
      <div className="store-header-widget" onClick={() => this.props.onClick && this.props.onClick()}>
        <Imgix image={restaurant.logo} aspectRatio={AspectRatio.square} />
        <div className="name">{`${restaurant.name} - ${store.name}`}</div>
      </div>
    );
  }
}
