import { TimeOfDay, TIME_OF_DAY_LIST } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
const ClickOutHandler = require('react-onclickout');
import { ValidationResult } from 'nutso';

type Props = {
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  validation: ValidationResult;
  dataCy?: string;
};

@observer
export class TimeOfDayPicker extends React.Component<Props> {
  //
  @observable open = false;

  getLabel = (value?: string) => {
    if (!value) return;
    const time = TIME_OF_DAY_LIST.find(time => time.hhmm === value);
    if (!time) return;
    return time.label;
  };

  getObject = (hhmm: string) => {
    return TIME_OF_DAY_LIST.find(time => time.hhmm === hhmm)!;
  };

  isSelected = (time: TimeOfDay) => {
    const { value } = this.props;
    return time.hhmm === value;
  };

  // render selected value
  renderSelectedValue = () => {
    const { value, validation } = this.props;
    return (
      <div className={`input-box ${validation.isValid ? 'valid' : 'invalid'}`} onClick={() => (this.open = !this.open)}>
        {this.getLabel(value) || <span>&nbsp;</span>}
      </div>
    );
  };

  renderTimeOfDay = (timeOfDay: TimeOfDay, index: number) => {
    const { onChange } = this.props;
    const selected = this.isSelected(timeOfDay);
    const onClick = () => {
      onChange(timeOfDay.hhmm);
      this.open = false;
    };
    return (
      <div
        key={timeOfDay.hhmm}
        onClick={onClick}
        className={`option ${selected ? 'selected' : ''}`}
        data-cy={timeOfDay.hhmm}
      >
        {timeOfDay.label}
      </div>
    );
  };

  renderOptions = () => {
    if (!this.open) return <div></div>;
    return (
      <div className="overlay">
        <ClickOutHandler onClickOut={() => (this.open = false)}>
          <div className="options-wrapper">
            <div className="options time-list">
              {TIME_OF_DAY_LIST.map((time, index) => this.renderTimeOfDay(time, index))}
            </div>
          </div>
        </ClickOutHandler>
      </div>
    );
  };

  // render selection box
  render() {
    return (
      <div className="form-select single" data-cy={this.props.dataCy}>
        {this.renderSelectedValue()}
        {this.renderOptions()}
      </div>
    );
  }
}
