import { ButtonType, Printer, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { CollectionPageWidget } from '../../document/CollectionPageWidget';
import { Button } from '../../elements/Button';
import { Page } from '../../elements/Page';
import { PrinterViewWidget } from '../../printer/PrinterViewWidget';
import { CollectionRepo } from '../../repo/CollectionRepo';
import { repo } from '../../repo/repo';
import { asyncOperation } from '../../utils/asyncOperation';
import { gotoPath } from '../../utils/browserHistory';
import { remoteService } from '../../utils/remoteService';
import React = require('react');

@observer
class ListWidget extends CollectionPageWidget<any, Printer> {
  name(): string {
    return 'Printer';
  }
  orderBy(): keyof Printer {
    return 'name';
  }
  getCollectionRepo(): CollectionRepo<void, Printer> {
    return repo.printers;
  }
  getItemId(item: Printer, i: number): string {
    return item.id;
  }
  renderItem(item: Printer): JSX.Element {
    return <PrinterViewWidget id={{ printerId: item.id }} document={item} />;
  }
}

@observer
export class PrinterListPage extends Page<{}, {}> {
  getTitle(): string {
    return 'List Printers';
  }
  renderActions(): React.ReactNode {
    return (
      <Button icon="la-plus-circle" onClick={this.onCreatePrinter} type={ButtonType.primary}>
        Create Printer
      </Button>
    );
  }

  onCreatePrinter = async () => {
    await asyncOperation({
      fn: () => remoteService.createPrinter(),
      loadingMessage: 'Creating printer',
      successMessage: 'Printer created successfully',
      onSuccess: printer => gotoPath(routes.backoffice.printer.link({ printerId: printer.id }, {}))
    });
  };

  renderBody(): React.ReactNode {
    return <ListWidget />;
  }

  getAdditionalClassNames() {
    return `printer-list-page`;
  }
}
