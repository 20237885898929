import { ModifiersValue, ModifierSpec, ModifierValue } from '@restoplus/core';

const getModifierSummary = (args: { modifierSpec: ModifierSpec; modifierValue: ModifierValue }): string | undefined => {
  // get selected option ids
  const selectedOptionIds = args.modifierValue;
  // no options selected, ignore
  if (!selectedOptionIds || !selectedOptionIds.length) return;
  // get options spec
  const selectedOptionSpecs = args.modifierSpec.options.filter(option => selectedOptionIds.includes(option.id));
  const selectedOptionNames = selectedOptionSpecs.map(option => option.name);
  return `${args.modifierSpec.name}: ${selectedOptionNames.join(', ')}`;
};

const getModifiersSummary = (args: { modifiersSpec: ModifierSpec[]; modifiersValue: ModifiersValue }) => {
  const summaries = [];

  for (let modifierId of Object.keys(args.modifiersValue)) {
    const modifierSpec = args.modifiersSpec.find(modifier => modifier.id === modifierId);
    if (!modifierSpec) continue;
    const modifierValue = args.modifiersValue[modifierId];
    const modifierSummary = getModifierSummary({ modifierSpec, modifierValue });
    // empty summary
    if (!modifierSummary) continue;
    // add to output
    summaries.push(modifierSummary);
  }

  if (summaries.length === 0) return;
  return summaries.join('\n');
};

const getModifiersExtraCharge = (args: { modifierSpecs: ModifierSpec[]; modifiersValue: ModifiersValue }) => {
  return Object.keys(args.modifiersValue).reduce((total, modifierId) => {
    return args.modifiersValue[modifierId].reduce((subtotal, optionId) => {
      return (
        subtotal +
        (args.modifierSpecs
          .find(modifierSpec => modifierSpec.id === modifierId)
          ?.options.find(option => option.id === optionId)?.extraCharge || 0)
      );
    }, total);
  }, 0);
};

const isMandatoryModifiersSelected = (args: { modifierSpecs: ModifierSpec[]; modifiersValue: ModifiersValue }) => {
  const mandatoryModifiers = args.modifierSpecs.filter(modifierSpec => modifierSpec.min > 0);
  // Check 1: if empty modifiers
  if (mandatoryModifiers.length > 0 && Object.keys(args.modifiersValue).length === 0) return false;

  // Check 2: if none of the selected values are mandatory
  const mandatorySelected = Object.entries(args.modifiersValue).filter(modifierValue => {
    if (mandatoryModifiers.map(mandatorySpec => mandatorySpec.id).includes(modifierValue[0])) return true;
    return false;
  });
  if (mandatorySelected.length !== mandatoryModifiers.length) return false;

  return true;
};

export const modifierUtils = {
  getModifierSummary,
  getModifiersSummary,
  getModifiersExtraCharge,
  isMandatoryModifiersSelected
};
