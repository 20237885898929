import { Restaurant } from '@restoplus/core';
import { observer } from 'mobx-react';
import { CollectionPageWidget } from '../../document/CollectionPageWidget';
import { Page } from '../../elements/Page';
import { CollectionRepo } from '../../repo/CollectionRepo';
import { repo } from '../../repo/repo';
import { RestaurantViewWidget } from '../../restaurant/RestaurantViewWidget';
import React = require('react');

@observer
class ListWidget extends CollectionPageWidget<any, Restaurant> {
  name(): string {
    return 'Restaurant List';
  }
  orderBy(): keyof Restaurant {
    return 'name';
  }
  getCollectionRepo(): CollectionRepo<void, Restaurant> {
    return repo.restaurants;
  }
  getItemId(item: Restaurant, i: number): string {
    return item.id;
  }
  renderItem(item: Restaurant): JSX.Element {
    return <RestaurantViewWidget id={{ restaurantId: item.id }} document={item} />;
  }
}

@observer
export class RestaurantBrowsePage extends Page<{}, {}> {
  getTitle(): string {
    return 'Browse Restaurants';
  }
  renderActions(): React.ReactNode {
    return;
  }
  renderBody(): React.ReactNode {
    return <ListWidget />;
  }
  getAdditionalClassNames() {
    return `restaurant-browse-page`;
  }
}
