/**
 * processing => Customer has been sent to stripe checkout flow and stripe is processing the payment
 * requiresCapture => Checkout flow is complete, and the funds are placed on hold. We should capture the funds when the restaurant accepts the order.
 * success => Payment is complete and we have received the funds.
 * failure => Payment failed, check `order.message` for more info.
 * refundSuccess => Successfully refunded.
 * refundFaliure => Refund failure. Take further action in Stripe dashboard.
 * cancel => If the payment was reserved but never captured.
 */
export enum PaymentStatus {
  processing = 'processing',
  requiresCapture = 'requiresCapture',
  success = 'success',
  failure = 'failure',
  cancel = 'cancel',
  refundSuccess = 'refundSuccess',
  refundFailure = 'refundFailure'
}
