/**
 * File to hold all defaults for the system
 */
import { CustomClaims } from '../models/auth/CustomClaims';
import { Address } from '../models/base/Address';
import { CollectionType } from '../models/base/CollectionType';
import { GoogleAddress } from '../models/base/GoogleAddress';
import { Customer } from '../models/customer/Customer';
import { AspectRatio } from '../models/image/AspectRatio';
import { ItemPrice } from '../models/item/ItemPrice';
import { BasicItemSpec } from '../models/item_spec/BasicItemSpec';
import { DealSpec } from '../models/item_spec/DealSpec';
import { BaseItemSpec } from '../models/item_spec/ItemSpec';
import { ItemType } from '../models/item_spec/ItemType';
import { PizzaSpec } from '../models/item_spec/PizzaSpec';
import { Menu } from '../models/menu/Menu';
import { Cart } from '../models/order/Cart';
import { CheckoutSettings } from '../models/order/CheckoutSettings';
import { CreditCardSurcharge } from '../models/order/CreditCardSurcharge';
import { DeliverySettings } from '../models/order/DeliverySettings';
import { OrderProcessSettings } from '../models/order/OrderProcessSettings';
import { OrderTransactionFee } from '../models/order/OrderTransactionFee';
import { PaymentType } from '../models/order/PaymentType';
import { StoreTimings } from '../models/store/StoreTimings';
import { TableBookingDaySettings, TableBookingSettings } from '../models/table_booking/TableBookingSettings';

const bookingDaySettings: TableBookingDaySettings = {
  breakfast: { on: false, open: '08:00', duration: 3 },
  lunch: { on: false, open: '12:00', duration: 4 },
  dinner: { on: false, open: '17:00', duration: 5 }
};

const tableBookingSettings: TableBookingSettings = {
  on: false,
  minPax: 2,
  maxPax: 10,
  numDaysInAdvance: 90,
  saturday: bookingDaySettings,
  sunday: bookingDaySettings,
  monday: bookingDaySettings,
  tuesday: bookingDaySettings,
  wednesday: bookingDaySettings,
  thursday: bookingDaySettings,
  friday: bookingDaySettings
};

const creditCardSurcharge: CreditCardSurcharge = {
  amount: 0,
  percentage: 0,
  applicableBelowAmount: 0
};

const checkoutSettings: CheckoutSettings = {
  hasCollectionTime: true,
  hasComments: true,
  hasCoupons: true,
  hasCash: true
};

const orderProcessSettings: OrderProcessSettings = {
  hasEta: true,
  hasUnprocessedOrderMonitor: true,
  hasCompleteStatus: false
  // etaType: EtaType.restaurant
};

const orderTransactionFee: OrderTransactionFee = {
  applicable: false,
  microLimit: 2000,
  microAmount: 20,
  microPercentage: 0,
  normalAmount: 80,
  normalPercentage: 0
};

const emptyGoogleAddress: GoogleAddress = {
  googlePlaceId: '',
  formattedAddress: '',
  suburb: '',
  postcode: '',
  country: { name: '', code: '' },
  geoPoint: { latitude: '', longitude: '' }
};

const emptyAddress: Address = {
  line1: '',
  suburb: '',
  postcode: ''
};

const placeholderCart: Cart = {
  createdTime: '',
  items: [],
  collectionType: CollectionType.pickup,
  paymentType: PaymentType.card,
  itemTotal: 0,
  grandTotal: 0,
  currency: {
    code: 'AUD',
    symbol: '$'
  },
  comments: '',
  discount: 0,
  customerName: ''
};

const dayTimings = {
  session1: { available: false, hours: { startHhmm: '10:00', duration: 7 } },
  session2: { available: false, hours: { startHhmm: '18:00', duration: 7 } }
};

const dowTimings = {
  pickup: dayTimings,
  delivery: dayTimings,
  dineIn: dayTimings
};

const storeTimings: StoreTimings = {
  monday: dowTimings,
  tuesday: dowTimings,
  wednesday: dowTimings,
  thursday: dowTimings,
  friday: dowTimings,
  saturday: dowTimings,
  sunday: dowTimings
};

const customer: Omit<Customer, 'uid'> = {
  previousOrderCount: 0
};

const deliverySettings: DeliverySettings = {
  minOrderValue: 0,
  suburbs: []
};

const itemPrice: ItemPrice = {
  pickup: null,
  delivery: null,
  dineIn: null
};

const menu: Menu = {
  categoryImageAspectRatio: AspectRatio.verticalLong
};

const itemSpec: Omit<BaseItemSpec, 'id' | 'restaurantId' | 'storeId' | 'categoryId' | 'spec' | 'type'> = {
  name: '',
  description: '',
  discounted: false,
  available: true,
  availableFor: { pickup: true, delivery: false, dineIn: false },
  sortOrder: 0.9
};

const basicItemSpec: Omit<BasicItemSpec, 'id' | 'restaurantId' | 'storeId' | 'categoryId'> = {
  ...itemSpec,
  type: ItemType.basic,
  modifiers: [],
  price: {
    basePrice: 0
  }
};

const pizzaSpec: Omit<PizzaSpec, 'id' | 'restaurantId' | 'storeId' | 'categoryId'> = {
  ...itemSpec,
  type: ItemType.pizza,
  sizes: {},
  removeToppings: []
};

const dealSpec: Omit<DealSpec, 'id' | 'restaurantId' | 'storeId' | 'categoryId'> = {
  ...itemSpec,
  type: ItemType.deal,
  price: { basePrice: 0 },
  items: [],
  pizzas: [],
  modifiers: []
};

const customClaims: CustomClaims = {
  superAdmin: false,
  printer: false
};

export const defaults = {
  placeholderCart,
  creditCardSurcharge,
  checkoutSettings,
  orderProcessSettings,
  orderTransactionFee,
  tableBookingSettings,
  emptyGoogleAddress,
  emptyAddress,
  storeTimings,
  customer,
  deliverySettings,
  itemPrice,
  itemSpec,
  basicItemSpec,
  pizzaSpec,
  dealSpec,
  menu,
  customClaims
};
