import { computed, observable, toJS } from 'mobx';
import { Schema, validate } from 'nutso';
import * as React from 'react';
import { Spin } from '../elements/Spin';
import { DocumentRepo } from '../repo/DocumentRepo';
import { asyncOperation } from '../utils/asyncOperation';
import { gotoPath } from '../utils/browserHistory';

type Props<K> = {
  documentId: K;
};
/**
 * Few documents are stand alone and are not part of collection,
 * And the id is known as well, like CafeSettings etc.
 * They need not be looked in the context of a collection, just threat them as document
 */
export abstract class StaticDocumentEditWidget<K, T> extends React.Component<Props<K>> {
  //
  @observable private busy = false;
  @observable private ready = false;
  @observable protected document: T;

  abstract name(): string;

  abstract defaultDocument(): T;
  abstract getRepo(): DocumentRepo<K, T>;
  abstract getSchema(): Schema<T>;
  abstract nextRoute(): string;
  abstract form(document: T): React.ReactNode;

  @computed
  get validation() {
    const vr = validate(toJS(this.document), this.getSchema());
    return vr;
  }

  componentDidMount() {
    // fetch doc
    this.fetchDoc();
  }

  abstract className(): string;

  fetchDoc = async () => {
    const document = await asyncOperation({
      fn: () => this.getRepo().get(this.props.documentId),
      loadingMessage: `${this.name()}`
    });

    // doc found
    if (document) {
      this.document = document;
    } else {
      this.document = this.defaultDocument();
    }
    this.ready = true;
  };

  submit = async () => {
    await asyncOperation({
      fn: () => this.getRepo().put(this.props.documentId, toJS(this.document)),
      loadingMessage: `Saving ${this.name()}`,
      setBusyFlag: (busy: boolean) => (this.busy = busy)
    });
    this.nextRoute() && gotoPath(this.nextRoute());
  };

  render() {
    if (!this.ready) return null;
    if (!this.document) return null;
    return (
      <div className={`${this.className()} document-edit-widget`}>
        <Spin spinning={this.busy}>{this.form(this.document)}</Spin>
      </div>
    );
  }
}
