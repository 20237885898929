import {
  gcsPaths,
  Hero,
  idFactory,
  Image,
  RestaurantId,
  RestaurantWebsiteInfo,
  restaurantWebsiteInfoSchema
} from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import * as React from 'react';
import { StaticDocumentEditWidget } from '../document/StaticDocumentEditWidget';
import { Button } from '../elements/Button';
import { Form } from '../forms/Form';
import { FormImage } from '../forms/FormImage';
import { FormItemContainer } from '../forms/FormItemContainer';
import { FormSwitch } from '../forms/FormSwitch';
import { FormTextArea } from '../forms/FormTextArea';
import { FormTextInput } from '../forms/FormTextInput';
import { userProvider } from '../provider/userProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { listUtils } from '../utils/listUtils';
import { optionalFlag } from '../utils/optionalFlag';

@observer
export class RestaurantWebsiteInfoEditWidget extends StaticDocumentEditWidget<RestaurantId, RestaurantWebsiteInfo> {
  name(): string {
    return 'Restaurant Website Info';
  }
  defaultDocument(): RestaurantWebsiteInfo {
    return {
      heros: [],
      photos: []
    };
  }
  getRepo(): DocumentRepo<RestaurantId, RestaurantWebsiteInfo> {
    return repo.restaurantWebsiteInfo;
  }
  getSchema(): Schema<RestaurantWebsiteInfo> {
    return restaurantWebsiteInfoSchema;
  }
  nextRoute(): string {
    return '';
  }
  form(document: RestaurantWebsiteInfo): JSX.Element {
    return (
      <Form type="responsive" dataCy="form-web-settings" submit={{ label: 'Update', handler: this.submit }} validationResult={this.validation}>
        <FormItemContainer
          label="Hero Images"
          validation={this.validation.properties.heros}
          className="heros-form-item"
        >
          {this.renderHeros(document.heros, this.validation.properties.heros)}
        </FormItemContainer>
        <FormSwitch
          label="About Info"
          helpText="Are you providing about info for the restaurant?"
          dataCy="about-info-switch"
          {...optionalFlag(document, 'about', '')}
        />
        {document.about !== undefined && (
          <FormTextArea
            label="About Restaurant"
            value={document.about}
            onChange={value => (document.about = value)}
            validation={this.validation.properties.about!}
            dataCy="about-info-text"
          />
        )}
        <FormItemContainer
          label="Gallery Photos"
          validation={this.validation.properties.photos}
          className="photos-form-item"
        >
          {this.renderPhotos(document.photos, this.validation.properties.photos)}
        </FormItemContainer>
      </Form>
    );
  }

  renderPhotos(photos: Image[], validation: Result<Image[]>): React.ReactNode {
    return (
      <div className="photos">
        {this.renderPhotosList(photos, validation)}
        {this.renderPhotosActions(photos, validation)}
      </div>
    );
  }
  renderPhotosList(photos: Image[], validation: Result<Image[]>): React.ReactNode {
    return (
      <div className="list">{photos.map((photo, i) => this.renderPhoto(photo, i, photos, validation.items[i]))}</div>
    );
  }

  renderPhoto(photo: Image, i: number, photos: Image[], validation: Result<Image>): any {
    return (
      <div className="photo" key={photo.id}>
        {this.renderPhotoItem(photo, i, validation)}
        {this.renderPhotoActions(photo, i, photos)}
      </div>
    );
  }
  renderPhotoItem(photo: Image, i: number, validation: Result<Image>): React.ReactNode {
    return (
      <div className="item inline">
        <FormImage
          label="Image"
          gcsFolder={gcsPaths.images.HeroImages({ uid: userProvider.uid! })}
          value={photo}
          onChange={image => (photo.path = image.path)}
          validation={validation}
          dataCy={`photo-image-input-${i}`}
        />
      </div>
    );
  }
  renderPhotoActions(photo: Image, i: number, list: Image[]): React.ReactNode {
    return (
      <div className="actions">
        <div className="action" data-cy={`photo-move-up-${i}`} onClick={() => (this.document.photos = listUtils.moveUp(list, i))}>
          <i className="icon las la-arrow-circle-up"></i>
        </div>
        <div className="action" data-cy={`photo-move-down-${i}`} onClick={() => (this.document.photos = listUtils.moveDown(list, i))}>
          <i className="icon las la-arrow-circle-down"></i>
        </div>
        <div className="action" data-cy={`photo-remove-${i}`} onClick={() => (this.document.photos = listUtils.remove(list, i))}>
          <i className="icon las la-times-circle"></i>
        </div>
      </div>
    );
  }

  renderPhotosActions(photos: Image[], validation: Result<Image[]>): React.ReactNode {
    const onClick = () => (this.document.photos = [...this.document.photos, { path: '', id: idFactory.tiny() }]);
    return (
      <div className="actions">
        <Button dataCy="btn-add-photo" onClick={onClick}>Add Photo</Button>
      </div>
    );
  }
  className(): string {
    return 'restaurant-website-info';
  }

  renderHeros(heros: Hero[], validation: Result<Hero[]>): React.ReactNode {
    return (
      <div className="heros">
        {this.renderHerosList(heros, validation)}
        {this.renderHerosActions(heros, validation)}
      </div>
    );
  }

  renderHerosList(heros: Hero[], validation: Result<Hero[]>): React.ReactNode {
    return (
      <div className="list">
        {heros.map((hero, i) => this.renderHero(hero, i, heros, validation.items[i]))}
      </div>
    );
  }

  renderHerosActions(heros: Hero[], validation: Result<Hero[]>): React.ReactNode {
    const onClick = () =>
      (this.document.heros = [
        ...this.document.heros,
        { description: '', image: { path: '', id: idFactory.tiny() }, title: '', id: idFactory.tiny() }
      ]);
    return (
      <div className="actions">
        <Button
          onClick={onClick}
          dataCy="btn-add-hero"
        >
          Add Hero Content
        </Button>
      </div>
    );
  }

  renderHero(hero: Hero, i: number, list: Hero[], validation: Result<Hero>): React.ReactNode {
    return (
      <div className="hero" key={hero.id}>
        {this.renderHeroItem(hero, i, validation)}
        {this.renderHeroActions(hero, i, list)}
      </div>
    );
  }

  renderHeroItem(hero: Hero, i: number, validation: Result<Hero>): React.ReactNode {
    return (
      <div className="item inline">
        <FormImage
          label="Image"
          gcsFolder={gcsPaths.images.HeroImages({ uid: userProvider.uid! })}
          value={hero.image}
          onChange={image => (hero.image = image)}
          validation={validation.properties.image}
          dataCy={`hero-image-input-${i}`}
        />
        <FormTextInput
          label={'Title'}
          value={hero.title}
          onChange={value => (hero.title = value)}
          validation={validation.properties.title}
          dataCy={`hero-title-text-${i}`}
        />
        <FormTextInput
          label={'Description'}
          value={hero.description}
          onChange={value => (hero.description = value)}
          validation={validation.properties.description}
          dataCy={`hero-description-text-${i}`}
        />
      </div>
    );
  }
  renderHeroActions(hero: Hero, i: number, list: Hero[]): React.ReactNode {
    return (
      <div className="actions">
        <div
          className="action"
          onClick={() => (this.document.heros = listUtils.moveUp(list, i))}
          data-cy={`hero-move-up-${i}`}
        >
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div
          className="action"
          onClick={() => (this.document.heros = listUtils.moveDown(list, i))}
          data-cy={`hero-move-down-${i}`}
        >
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div
          className="action"
          onClick={() => (this.document.heros = listUtils.remove(list, i))}
          data-cy={`hero-remove-${i}`}
        >
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  }
}
