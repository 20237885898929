import { Hours, hoursUtils } from '@restoplus/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Result } from 'nutso';
import * as React from 'react';
import { FormItemContainer, FormItemProps } from './FormItemContainer';
import { NumberInput } from './NumberInput';
import { TimeOfDayPicker } from './TimeOfDayPicker';

type Props = FormItemProps & {
  value?: Hours;
  onChange: (value: Hours) => void;
  disabled?: boolean;
};

@observer
export class FormHoursPicker extends React.Component<Props> {
  //
  @observable open = false;

  getEndHhmm = (hours: Hours) => {
    return hoursUtils.getEndTimeOfDay(hours);
  };

  renderDuration = (hours: Hours, validation: Result<Hours>) => {
    return (
      <>
        <NumberInput
          value={hours.duration || 0}
          onChange={duration => this.props.onChange({ ...hours, duration })}
          validation={validation.properties.duration}
          dataCy="session-hours"
        />
        <div>{this.getEndHhmm(hours)}</div>
      </>
    );
  };

  renderHours = (hours: Hours, validation: Result<Hours>) => {
    return (
      <div className="duration-picker">
        <TimeOfDayPicker
          value={hours.startHhmm}
          onChange={hhmm => this.props.onChange({ ...hours, startHhmm: hhmm })}
          validation={validation.properties.startHhmm}
          dataCy="session-timeofday-picker"
        />
        {this.renderDuration(hours, validation)}
      </div>
    );
  };

  render() {
    const { value, validation } = this.props;
    if (!value) return <div></div>;
    return (
      <FormItemContainer className={'hours'} {...this.props}>
        <div className="form-select single">{this.renderHours(value, validation as Result<Hours>)}</div>
      </FormItemContainer>
    );
  }
}
