import { Schema } from 'nutso';
import { DealSelection } from '../../models/item_selection/DealSelection';
import { dealPizzaSchema } from './dealPizzaSchema';

export const dealSelectionSchema: Schema<DealSelection> = {
  type: 'object',
  properties: {
    type: {
      type: 'string'
    },
    modifiersValue: {
      type: 'object',
      properties: {
        '/.*/': {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    },
    pizzas: {
      type: 'array',
      items: dealPizzaSchema
    },
    items: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          modifiersValue: {
            type: 'object',
            properties: {
              '/.*/': {
                type: 'array',
                items: {
                  type: 'string'
                }
              }
            }
          }
        }
      }
    }
  }
};
