import {
  FcmTopicListRequest,
  FcmTopicSubscribeRequest,
  FcmTopicUnsubscribeRequest,
  SendVerificationCodeRequest,
  SourceId,
  StoreId,
  TokenId,
  VerifyVerificationCodeRequest
} from '..';
import { PrinterTokenRequest } from '../models/auth/PrinterTokenRequest';
import { Cart } from '../models/order/Cart';
import { PayoutSchedule } from '../models/stripe/StripeAccount';
import { Endpoint } from './Endpoint';

/**
 * All routers must have prefix api
 */
export const routerPrefix = {
  orderRouter: '/api/online-ordering',
  stripeCustomerRouter: '/api/stripe/customers',
  stripeConnectedAccountRouter: '/api/stripe/connect',
  stripeWebhooksRouter: '/api/stripe/webhooks',
  printerRouter: '/api/printers',
  fcmRouter: '/api/fcm',
  phoneAuthRouter: '/api/phone-auth'
};

const placeOrder = new Endpoint<StoreId, Cart>(
  routerPrefix.orderRouter,
  '/restaurants/:restaurantId/stores/:storeId/orders'
);

const saveCreditCard = new Endpoint<TokenId>(routerPrefix.stripeCustomerRouter, '/sources/:tokenId');
const listSavedCreditCards = new Endpoint(routerPrefix.stripeCustomerRouter, '/sources');
const deleteSavedCreditCard = new Endpoint<SourceId>(routerPrefix.stripeCustomerRouter, '/sources/:sourceId');

const getActiveStripeAccount = new Endpoint<StoreId>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/active'
);
const createStripeAccountVerificationLink = new Endpoint<StoreId>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/links/verification'
);
const createStripeAccountUpdateLink = new Endpoint<StoreId>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/links/update'
);

const archiveStripeAccount = new Endpoint<StoreId>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/archive'
);

const setExternalAccount = new Endpoint<StoreId & TokenId>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/external_account/:tokenId'
);

const setPayoutSchedule = new Endpoint<StoreId, PayoutSchedule>(
  routerPrefix.stripeConnectedAccountRouter,
  '/restaurants/:restaurantId/stores/:storeId/account/payout_schedule'
);

const stripeConnectEventHandler = new Endpoint<{}, {}>(routerPrefix.stripeWebhooksRouter, '/connect');
const stripePlatformEventHandler = new Endpoint<{}, {}>(routerPrefix.stripeWebhooksRouter, '/platform');

const createPrinter = new Endpoint<{}, {}>(routerPrefix.printerRouter, '/');
const loginPrinter = new Endpoint<{}, PrinterTokenRequest>(routerPrefix.printerRouter, '/login');

const listSubscribedTopics = new Endpoint<{}, FcmTopicListRequest>(routerPrefix.fcmRouter, '/topics/list');
const subscribeToTopic = new Endpoint<{}, FcmTopicSubscribeRequest>(routerPrefix.fcmRouter, '/topics/subscribe');
const unsubscribeFromTopic = new Endpoint<{}, FcmTopicUnsubscribeRequest>(
  routerPrefix.fcmRouter,
  '/topics/unsubscribe'
);

const sendVerificationCode = new Endpoint<{}, SendVerificationCodeRequest>(
  routerPrefix.phoneAuthRouter,
  '/verification-code/send'
);

const verifyVerificationCode = new Endpoint<{}, VerifyVerificationCodeRequest>(
  routerPrefix.phoneAuthRouter,
  '/verification-code/verify'
);

export const endpoints = {
  placeOrder,
  saveCreditCard,
  listSavedCreditCards,
  deleteSavedCreditCard,
  getActiveStripeAccount,
  archiveStripeAccount,
  createStripeAccountUpdateLink,
  createStripeAccountVerificationLink,
  setExternalAccount,
  setPayoutSchedule,
  stripeConnectEventHandler,
  stripePlatformEventHandler,
  createPrinter,
  loginPrinter,
  listSubscribedTopics,
  subscribeToTopic,
  unsubscribeFromTopic,
  sendVerificationCode,
  verifyVerificationCode
};
