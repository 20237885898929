import { defaults, OrderProcessSettings, orderProcessSettingsSchema, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import { StaticDocumentEditWidget } from '../document/StaticDocumentEditWidget';
import { Form } from '../forms/Form';
import { FormSwitch } from '../forms/FormSwitch';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import React = require('react');

@observer
export class OrderProcessSettingsEditWidget extends StaticDocumentEditWidget<StoreId, OrderProcessSettings> {
  //

  name(): string {
    return 'Order Process Settings';
  }
  defaultDocument(): OrderProcessSettings {
    return defaults.orderProcessSettings;
  }
  getSchema(): Schema<OrderProcessSettings, OrderProcessSettings> {
    return orderProcessSettingsSchema;
  }

  getRepo(): DocumentRepo<StoreId, OrderProcessSettings> {
    return repo.orderProcessSettings;
  }
  nextRoute(): string {
    return '';
  }

  className() {
    return 'order-process-settings-edit-widget';
  }

  form(orderProcessSettings: OrderProcessSettings): JSX.Element {
    return (
      <>
        <div className="title">Order Process Settings</div>
        <Form
          type="default"
          submit={{ label: 'Save', handler: this.submit }}
          validationResult={this.validation}
          dataCy="order-process-settings"
        >
          <FormSwitch
            label="ETA"
            helpText="Do you send an estimated time to the customer?"
            value={orderProcessSettings.hasEta}
            onChange={value => (orderProcessSettings.hasEta = value)}
            validation={this.validation.properties.hasEta}
            dataCy="order-process-eta"
          />
          <FormSwitch
            label="Order Completed Status"
            helpText="Notify the customer when the order is ready?"
            value={orderProcessSettings.hasCompleteStatus}
            onChange={value => (orderProcessSettings.hasCompleteStatus = value)}
            validation={this.validation.properties.hasCompleteStatus}
            dataCy="order-process-completed"
          />
          <FormSwitch
            label="Order Monitor"
            helpText="Alert the store manager if order is not accepted on time?"
            value={orderProcessSettings.hasUnprocessedOrderMonitor}
            onChange={value => (orderProcessSettings.hasUnprocessedOrderMonitor = value)}
            validation={this.validation.properties.hasUnprocessedOrderMonitor}
            dataCy="order-process-monitor"
          />
        </Form>
      </>
    );
  }
}
