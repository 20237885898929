import { Schema } from 'nutso';
import { ItemType } from '../../models/item_spec/ItemType';
import { hoursSchema } from '../hoursSchema';
import { BaseItemSpec } from '../..';

export const itemBaseSpecSchema: Schema<BaseItemSpec> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    restaurantId: {
      type: 'string'
    },
    storeId: {
      type: 'string'
    },
    categoryId: {
      type: 'string'
    },
    sortOrder: {
      type: 'number'
    },
    name: {
      type: 'string',
      minLength: 2,
      maxLength: 24
    },
    type: {
      type: 'string',
      pattern: new RegExp(`^(${ItemType.basic})|(${ItemType.pizza})|(${ItemType.deal})$`)
    },
    description: {
      type: 'string',
      optional: true,
      minLength: 0,
      maxLength: 400
    },
    discounted: {
      type: 'boolean'
    },
    availableDaysOfWeek: {
      type: 'array',
      optional: true,
      minItems: 1,
      maxItems: 7,
      items: {
        type: 'string'
      }
    },
    availableHours: {
      optional: true,
      ...hoursSchema
    },
    available: {
      type: 'boolean'
    },
    availableFor: {
      type: 'object',
      properties: {
        pickup: {
          type: 'boolean'
        },
        delivery: {
          type: 'boolean'
        },
        dineIn: {
          type: 'boolean'
        }
      }
    }
  }
};
