import {
  idFactory,
  PizzaSettings,
  pizzaSettingsSchema,
  PizzaSizeSettings,
  StoreId,
  ToppingSpec
} from '@restoplus/core';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import { StaticDocumentEditWidget } from '../document/StaticDocumentEditWidget';
import { Button } from '../elements/Button';
import { Form } from '../forms/Form';
import { FormItemContainer } from '../forms/FormItemContainer';
import { FormNumberInput } from '../forms/FormNumberInput';
import { FormSwitch } from '../forms/FormSwitch';
import { FormTextInput } from '../forms/FormTextInput';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { listUtils } from '../utils/listUtils';
import React = require('react');

const NEW_PIZZA_SIZE: Omit<PizzaSizeSettings, 'id'> = {
  name: '',
  hasHalfAndHalf: false,
  halfAndHalfExtraCharge: 0
};

const NEW_TOPPING_SPEC: Omit<ToppingSpec, 'id'> = {
  name: '',
  prices: {}
};

@observer
export class PizzaSettingsEditWidget extends StaticDocumentEditWidget<StoreId, PizzaSettings> {
  // topping prices depend on pizza sizes
  dispose = reaction(
    () => this.document?.sizes,
    sizes => {
      if (!sizes || !this.document.toppings) return;
      sizes.forEach(size => {
        this.document.toppings.forEach(topping => {
          if (!topping.prices) topping.prices = {};
          topping.prices[size.id] = topping.prices[size.id] || 0;
        });
      });
    }
  );

  componentWillUnmount() {
    this.dispose();
  }

  name(): string {
    return 'Pizza Settings';
  }
  defaultDocument(): PizzaSettings {
    return {
      pizzaAvailable: false,
      halfAndHalfAvailable: false,
      sizes: [],
      toppings: []
    };
  }
  getSchema(): Schema<PizzaSettings, PizzaSettings> {
    return pizzaSettingsSchema;
  }

  getRepo(): DocumentRepo<StoreId, PizzaSettings> {
    return repo.pizzaSettings;
  }
  nextRoute(): string {
    return '';
  }

  className() {
    return 'pizza-settings-edit-widget';
  }

  form(pizzaSettings: PizzaSettings): JSX.Element {
    return (
      <Form type="responsive" submit={{ label: 'Save', handler: this.submit }} validationResult={this.validation}>
        <FormSwitch
          label="Pizza Available"
          helpText="Do you make pizzas?"
          value={pizzaSettings.pizzaAvailable}
          onChange={value => (pizzaSettings.pizzaAvailable = value)}
          validation={this.validation.properties.pizzaAvailable}
          dataCy="pizza-available"
        />
        {this.renderPizzaSettings(pizzaSettings)}
      </Form>
    );
  }

  renderPizzaSettings = (pizzaSettings: PizzaSettings) => {
    if (!pizzaSettings.pizzaAvailable) return null;
    return (
      <>
        <FormSwitch
          label="Half & Half Available"
          helpText="Do you make half & half in pizzas?"
          value={pizzaSettings.halfAndHalfAvailable}
          onChange={value => (pizzaSettings.halfAndHalfAvailable = value)}
          validation={this.validation.properties.halfAndHalfAvailable}
          dataCy="pizza-half-and-half"
        />
        <FormItemContainer
          label="Pizza Sizes"
          validation={this.validation.properties.sizes}
          className="pizza-sizes-form-item"
        >
          {this.renderPizzaSizes(pizzaSettings.sizes, this.validation.properties.sizes)}
        </FormItemContainer>
        <FormItemContainer
          label="Toppings List"
          validation={this.validation.properties.toppings}
          className="toppings-list-form-item"
        >
          {this.renderToppings(pizzaSettings.toppings, this.validation.properties.toppings)}
        </FormItemContainer>
      </>
    );
  };

  renderToppingsActions(toppings: ToppingSpec[]): React.ReactNode {
    const onClick = () =>
      (this.document.toppings = [...this.document.toppings, { ...NEW_TOPPING_SPEC, id: idFactory.toppingId() }]);
    return (
      <div className="actions">
        <Button onClick={onClick} dataCy="add-toppings">
          Add Topping
        </Button>
      </div>
    );
  }

  renderToppingPrices(topping: ToppingSpec, validation: Result<ToppingSpec>): React.ReactNode {
    const { sizes } = this.document;
    if (!sizes) return;
    return (
      <>
        {sizes.map(size =>
          this.renderToppingExtraChargeForSize(topping, size, validation.properties.prices.properties[size.id])
        )}
      </>
    );
  }

  renderToppingExtraChargeForSize(topping: ToppingSpec, size: PizzaSizeSettings, validation?: Result<number>): any {
    return (
      <FormNumberInput
        label={`Extra Charge for ${size.name} Pizza`}
        key={size.id}
        value={topping.prices[size.id]}
        onChange={value => (topping.prices[size.id] = value)}
        validation={validation || { isValid: true, errorMessage: '', errorPath: [] }}
        dataCy={`pizza-topping-charge-${size.name}`}
      />
    );
  }

  renderTopping(topping: ToppingSpec, i: number, toppings: ToppingSpec[], validation: Result<ToppingSpec>): any {
    return (
      <div className="topping" key={topping.id}>
        <div className="info">
          <FormTextInput
            label="Topping Name"
            value={topping.name}
            onChange={value => (topping.name = value)}
            validation={validation.properties.name}
            dataCy="pizza-topping-name"
          />
          {this.renderToppingPrices(topping, validation)}
        </div>
        {this.renderToppingActions(topping, i, toppings)}
      </div>
    );
  }
  renderToppingActions(topping: ToppingSpec, i: number, list: ToppingSpec[]): React.ReactNode {
    return (
      <div className="actions">
        <div className="action" onClick={() => (this.document.toppings = listUtils.moveUp(list, i))}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => (this.document.toppings = listUtils.moveDown(list, i))}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div className="action" onClick={() => (this.document.toppings = listUtils.remove(list, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  }

  renderToppingsList(toppings: ToppingSpec[], validation: Result<ToppingSpec[]>): React.ReactNode {
    return (
      <div className="list">
        {toppings.map((topping, i) => this.renderTopping(topping, i, toppings, validation.items[i]))}
      </div>
    );
  }

  renderToppings(toppings: ToppingSpec[], validation: Result<ToppingSpec[]>): React.ReactNode {
    return (
      <div className="toppings">
        {this.renderToppingsList(toppings, validation)}
        {this.renderToppingsActions(toppings)}
      </div>
    );
  }

  renderPizzaSizeActions(size: PizzaSizeSettings, i: number, list: PizzaSizeSettings[]): React.ReactNode {
    return (
      <div className="actions">
        <div className="action" onClick={() => (this.document.sizes = listUtils.moveUp(list, i))}>
          <i className={`icon las la-arrow-circle-up`}></i>
        </div>
        <div className="action" onClick={() => (this.document.sizes = listUtils.moveDown(list, i))}>
          <i className={`icon las la-arrow-circle-down`}></i>
        </div>
        <div className="action" onClick={() => (this.document.sizes = listUtils.remove(list, i))}>
          <i className={`icon las la-times-circle`}></i>
        </div>
      </div>
    );
  }

  renderPizzaSizeInfo(size: PizzaSizeSettings, i: number, validation: Result<PizzaSizeSettings>): React.ReactNode {
    return (
      <div className="info inline">
        <FormTextInput
          label={'Size'}
          value={size.name}
          onChange={value => (size.name = value)}
          validation={validation.properties.name}
          helpText="Enter the size of the pizza Ex: Small"
          dataCy="pizza-size"
        />
        <FormSwitch
          label={'Half & Half'}
          value={size.hasHalfAndHalf}
          onChange={value => (size.hasHalfAndHalf = value)}
          validation={validation.properties.hasHalfAndHalf}
          helpText="Do you make half & half in this size?"
          dataCy="pizza-half-size"
        />
        {size.hasHalfAndHalf && (
          <FormNumberInput
            label={'Half & Half Extra Charge'}
            value={size.halfAndHalfExtraCharge}
            onChange={value => (size.halfAndHalfExtraCharge = value)}
            validation={validation.properties.halfAndHalfExtraCharge}
            helpText="Extra charge for this size half & half"
            dataCy="pizza-half-charge"
          />
        )}
      </div>
    );
  }

  renderSize(size: PizzaSizeSettings, i: number, list: PizzaSizeSettings[], validation: Result<PizzaSizeSettings>) {
    return (
      <div className="pizza-size" key={size.id}>
        {this.renderPizzaSizeInfo(size, i, validation)}
        {this.renderPizzaSizeActions(size, i, list)}
      </div>
    );
  }

  renderPizzaSizesActions = (sizes: PizzaSizeSettings[]) => {
    return (
      <div className="actions">
        <Button
          onClick={() =>
            (this.document.sizes = [...this.document.sizes, { ...NEW_PIZZA_SIZE, id: idFactory.pizzaSizeId() }])
          }
          dataCy="pizza-size-add"
        >
          Add Pizza Size
        </Button>
      </div>
    );
  };

  renderPizzaSizesList(sizes: PizzaSizeSettings[], validation: Result<PizzaSizeSettings[]>): React.ReactNode {
    return <div className="list">{sizes.map((size, i) => this.renderSize(size, i, sizes, validation.items[i]))}</div>;
  }

  renderPizzaSizes(sizes: PizzaSizeSettings[], validation: Result<PizzaSizeSettings[]>): React.ReactNode {
    if (!sizes) return;
    return (
      <div className="pizza-sizes">
        {this.renderPizzaSizesList(sizes, validation)}
        {this.renderPizzaSizesActions(sizes)}
      </div>
    );
  }
}
