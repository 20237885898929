import { dateUtils } from '@restoplus/core';
import { DateTime } from 'luxon';
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FormItem } from './FormItem';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export class FormDatePicker extends FormItem<Props> {
  //
  formatDate = (date: Date) => {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_HUGE);
  };

  onDayChange = (value: Date) => {
    this.props.onChange(dateUtils.toDateStr(value));
  };

  renderValue(): JSX.Element {
    const { value, validation } = this.props;
    return (
      <DayPickerInput
        formatDate={this.formatDate}
        onDayChange={this.onDayChange}
        value={dateUtils.toJsDate(value || dateUtils.date())}
      />
    );
  }
}
