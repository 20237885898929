import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';

/**
 * A component that waits for promise to resolve
 */
type Props<P, Q, R> = {
  promise1: IPromiseBasedObservable<P>;
  promise2: IPromiseBasedObservable<Q>;
  promise3: IPromiseBasedObservable<R>;
  children: (value1: P, value2: Q, value3: R) => React.ReactNode;
};

@observer
export class Resolve3Promises<P, Q, R> extends React.Component<Props<P, Q, R>> {
  render() {
    const { promise1, promise2, promise3 } = this.props;

    switch (promise1.state) {
      case 'pending':
        return null;
      case 'rejected':
        return <div>rejected promise1</div>;
      case 'fulfilled':
        break;
    }
    switch (promise2.state) {
      case 'pending':
        return null;
      case 'rejected':
        return <div>rejected promise2</div>;
      case 'fulfilled':
        break;
    }
    switch (promise3.state) {
      case 'pending':
        return null;
      case 'rejected':
        return <div>rejected promise3</div>;
      case 'fulfilled':
        break;
    }

    return this.props.children(promise1.value, promise2.value, promise3.value);
  }
}
