import { AU, ButtonType, countries, US } from '@restoplus/core';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { Form } from '../forms/Form';
import { FormDatePicker } from '../forms/FormDatePicker';
import { FormIconTextInput } from '../forms/FormIconTextInput';
import { FormMultiSelect } from '../forms/FormMultiSelect';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { FormTextInput } from '../forms/FormTextInput';
import { TimeOfDayPicker } from '../forms/TimeOfDayPicker';
import { Toast } from '../toast/Toast';
import { Button } from './Button';
import { CountryFlag } from './CountryFlag';
import { GooglePlacesSearch } from './GooglePlacesSearch';
import { Spin } from './Spin';
import { StatusIndicator } from './StatusIndicator';

type Props = {};

export class Demo extends React.Component<any> {
  render() {
    return (
      <div style={{ margin: '20px' }}>
        <div>
          <h1>
            Testing display of <span className="highlight">HTML elements</span>
          </h1>

          <h2>This is 2nd level heading</h2>
          <p>This is a test paragraph.</p>
          <h3>This is 3rd level heading</h3>
          <p>This is a test paragraph.</p>
          <h4>This is 4th level heading</h4>
          <p>This is a test paragraph.</p>
          <h5>This is 5th level heading</h5>
          <p>This is a test paragraph.</p>
          <h6>This is 6th level heading</h6>
          <p>This is a test paragraph.</p>
          <div className="h7">This is 7th level heading</div>
          <p>This is a test paragraph.</p>

          <h2>Basic block level elements</h2>

          <p>
            This is a normal paragraph (<code>p</code> element). To add some length to it, let us mention that this page
            was primarily written for testing the effect of <strong>user style sheets</strong>. You can use it for
            various other purposes as well, like just checking how your browser displays various HTML elements by
            default. It can also be useful when testing conversions from HTML format to other formats, since some
            elements can go wrong then.
          </p>
          <p>
            This is another paragraph. I think it needs to be added that the set of elements tested is not exhaustive in
            any sense. I have selected those elements for which it can make sense to write user style sheet rules, in my
            opionion.
          </p>
        </div>
        <StatusIndicator type="success" icon="la-check" />
        <StatusIndicator type="primary" icon="la-clock" />
        <StatusIndicator type="warning" icon="la-exclamation" />
        <StatusIndicator type="failure" icon="la-times" />
        <br />
        <br />
        <input type="datetime-local"></input>
        <br />
        <br />
        <Alert type="success" title="Please Wait">
          Hurray, this is our first message.
        </Alert>
        <br />
        <br />
        <Alert type="error" title="Hippo Warning" icon="la-hippo">
          Save all hippos around the world guys.
        </Alert>
        <br />
        <br />
        <Alert type="warning" icon="loading">
          Processing payment. Please wait ...
        </Alert>
        <br />
        <br />
        <Alert type="success">Hurray, this is our first message. No title.</Alert>
        <br />
        <br />
        <Toast type="success" message="Hurray, this is our first toast." id="1" />
        <br />
        <br />
        <Toast type="error" message="Hurray, this is our first toast." id="2" />
        <br />
        <br />
        <Toast type="info" message="Hurray, this is our first toast." id="3" />
        <br />
        <br />
        <Toast type="warning" message="Hurray, this is our first toast." id="4" />
        <br />
        <br />
        <Toast type="loading" message="Hurray, this is our first toast." id="5" />
        <br />
        <br />
        <Toast
          type="warning"
          message="Hurray, this is our long toast and it keeps going on and on and on, and seems like it never ends."
          id="4"
        />
        <br />
        <br />

        <Spin spinning={false}>
          <i className="las la-battery-three-quarters"></i>
          <span>this is demo</span>
        </Spin>
        <Spin spinning={true}>
          <span>this is demo</span>
        </Spin>
        <div style={{ maxWidth: '400px', margin: '30px' }}>
          <Form
            type="default"
            submit={{ label: 'Login', handler: () => {} }}
            validationResult={{ isValid: true, errorMessage: '', errorPath: [] }}
          >
            <GooglePlacesSearch onChange={address => console.log(address)} />
            <FormSingleSelect
              label="Country"
              options={Object.values(countries)}
              value={AU}
              onChange={value => console.log(value)}
              optionKey={country => country.code}
              optionLabel={country => country.name}
              validation={{ isValid: false, errorMessage: 'Hey, select country dude!', errorPath: [] }}
            />
            <FormMultiSelect
              label="Country"
              options={Object.values(countries)}
              value={[AU, US]}
              onChange={value => console.log(value)}
              optionKey={country => country.code}
              optionLabel={country => country.name}
              validation={{ isValid: false, errorMessage: 'Hey, select country dude!', errorPath: [] }}
            />
            <FormIconTextInput
              validation={{ isValid: true, errorMessage: '', errorPath: [] }}
              label="Name"
              value={'krishna'}
              onChange={() => {}}
              helpText="Your name is awesome dude"
              icon={<CountryFlag countryCode={'AU'} />}
            />
            <FormTextInput
              validation={{ isValid: true, errorMessage: '', errorPath: [] }}
              label="Name"
              value={'krishna'}
              onChange={() => {}}
              helpText="Your name is awesome dude"
            />
            <FormTextInput
              validation={{ isValid: false, errorMessage: 'Hey enter your name!', errorPath: [] }}
              label="Name"
              value={'krishna'}
              onChange={() => {}}
              helpText="Your name is awesome dude"
            />
            <FormDatePicker
              validation={{ isValid: true, errorMessage: '', errorPath: [] }}
              label="Name"
              value={'2020-02-23'}
              onChange={() => {}}
              helpText="Your name is awesome dude"
            />
            <FormDatePicker
              validation={{ isValid: false, errorMessage: 'Hey enter your name!', errorPath: [] }}
              label="Name"
              value={'2020-02-23'}
              onChange={() => {}}
              helpText="Your name is awesome dude"
            />
            <FormSingleSelect
              label="Country"
              options={Object.values(countries)}
              value={AU}
              onChange={value => console.log(value)}
              optionKey={country => country.code}
              optionLabel={country => country.name}
              validation={{ isValid: true, errorMessage: '', errorPath: [] }}
            />
            <FormSingleSelect
              label="Country"
              options={Object.values(countries)}
              value={AU}
              onChange={value => console.log(value)}
              optionKey={country => country.code}
              optionLabel={country => country.name}
              helpText="Your home land brother?"
              validation={{ isValid: false, errorMessage: 'Country required.', errorPath: [] }}
            />
            <TimeOfDayPicker
              value="09:00"
              onChange={hhmm => console.log(hhmm)}
              validation={{ isValid: true, errorMessage: '', errorPath: [] }}
            />
          </Form>
          <br />
          <br />
          <Button busy={true} type={ButtonType.primary} onClick={() => {}}>
            Save
          </Button>
          <br />
          <br />
          <Button busy={false} type={ButtonType.primary} icon="la-plus-circle" onClick={() => {}}>
            Update
          </Button>
        </div>
      </div>
    );
  }
}
