import { countries, dateUtils, defaults, idFactory, routes, Store, StoreId, storeSchema } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import * as React from 'react';
import { DocumentEditWidget } from '../document/DocumentEditWidget';
import { Form } from '../forms/Form';
import { FormAddress } from '../forms/FormAddress';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { FormSwitch } from '../forms/FormSwitch';
import { FormTextInput } from '../forms/FormTextInput';
import { userProvider } from '../provider/userProvider';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { optionalFlag } from '../utils/optionalFlag';
import { validationUtils } from '../utils/validationUtils';
import { webConfig } from '../utils/webConfig';

@observer
export class StoreEditWidget extends DocumentEditWidget<StoreId, Store> {
  //

  $restaurant = repo.restaurant.bind(this.props.documentId!);

  name(): string {
    return 'Store';
  }

  createDocument(): Store {
    const id = webConfig.cypress ? 'store_1' : idFactory.store();
    return {
      id,
      name: '',
      timezone: '',
      editorUid: userProvider.user?.uid || '',
      creatorUid: userProvider.user?.uid || '',
      createdTime: dateUtils.time(),
      editedTime: dateUtils.time(),
      address: defaults.emptyAddress,
      hasSplitTimings: false,
      phoneNumber: '',
      collectionSettings: {
        pickup: { available: false },
        delivery: { available: false },
        dineIn: { available: false }
      }
    };
  }

  getWidgetNameForCssClass(): string {
    return 'store-edit-widget';
  }

  getRepo(): DocumentRepo<StoreId, Store> {
    return repo.store;
  }

  getSchema(): Schema<Store> {
    return storeSchema;
  }

  nextRoute(): string {
    const { create } = this.props;
    const { restaurantId } = this.props.documentId!;
    const storeId = this.document!.id;
    if (create) {
      return routes.backoffice.store.link({ restaurantId, storeId }, {});
    }
    return '';
  }

  getKey(): StoreId {
    const { documentId: key, create } = this.props;
    // if create, get key from created doc
    if (create)
      return {
        restaurantId: this.props.documentId!.restaurantId,
        storeId: this.document.id
      };

    // get key from props
    return key!;
  }

  form(): JSX.Element {
    if (!this.document) return <div>Loading ...</div>;
    // wait for restaurant to load
    const restaurant = this.$restaurant.current();
    if (!restaurant) return <div></div>;
    // get country
    const country = countries[restaurant.country];
    const submitLabel = this.props.create ? 'Create Store' : 'Save Store';

    return (
      <Form type="responsive" submit={{ label: submitLabel, handler: this.submit }} validationResult={this.validation}>
        <FormTextInput
          label="Name"
          value={this.document.name}
          onChange={value => (this.document.name = value)}
          validation={this.validation.properties.name}
          dataCy="store-name"
        />
        <FormSingleSelect
          label="Timezone"
          options={country.timezones}
          value={this.document.timezone}
          onChange={selected => (this.document.timezone = selected)}
          optionKey={timezone => timezone}
          optionLabel={timezone => timezone}
          validation={this.validation.properties.timezone}
          dataCy="store-timezone"
        />
        <FormSwitch
          value={this.document.collectionSettings.pickup.available}
          onChange={value => (this.document.collectionSettings.pickup.available = value)}
          label="Pickup"
          validation={validationUtils.success}
          dataCy="store-pickup"
        />
        <FormSwitch
          value={this.document.collectionSettings.delivery.available}
          onChange={value => (this.document.collectionSettings.delivery.available = value)}
          label="Delivery"
          validation={validationUtils.success}
          dataCy="store-delivery"
        />
        <FormSwitch
          value={this.document.collectionSettings.dineIn.available}
          onChange={value => (this.document.collectionSettings.dineIn.available = value)}
          label="DineIn"
          validation={validationUtils.success}
          dataCy="store-dine-in"
        />
        <FormSwitch
          value={this.document.hasSplitTimings}
          onChange={value => (this.document.hasSplitTimings = value)}
          label="Split Timings"
          validation={this.validation.properties.hasSplitTimings}
          helpText="Do close the store during the day and open again such that your store timings are split into session 1 & session 2?"
          dataCy="store-split-timings"
        />
        <FormAddress
          label="Address"
          value={this.document.address}
          onChange={value => (this.document.address = value)}
          validation={this.validation.properties.address}
          dataCy="store-address"
        />
        <FormTextInput
          label="Phone Number"
          value={this.document.phoneNumber}
          validation={this.validation.properties.phoneNumber}
          onChange={value => (this.document.phoneNumber = value)}
          dataCy="store-phone"
        />
        <FormSwitch
          label="Temporarily Closed"
          helpText="Is the store temporarily closed at the moment?"
          {...optionalFlag(this.document, 'temporarilyClosed', { reason: '' })}
          dataCy="store-temp-closed"
        />
        {this.document.temporarilyClosed && (
          <FormTextInput
            label="Temporarily Closed Reason"
            value={this.document.temporarilyClosed.reason}
            onChange={value => (this.document.temporarilyClosed!.reason = value)}
            validation={this.validation.properties.temporarilyClosed!.properties.reason}
            helpText="This message will be shown to the customer."
            dataCy="store-temp-closed-reason"
          />
        )}
        <FormSwitch
          label="Permanently Closed"
          helpText="Is the store permanently closed?"
          {...optionalFlag(this.document, 'permanentlyClosed', { reason: '' })}
          dataCy="store-perm-closed"
        />
        {this.document.permanentlyClosed && (
          <FormTextInput
            label="Permanently Closed Reason"
            value={this.document.permanentlyClosed.reason}
            onChange={value => (this.document.permanentlyClosed!.reason = value)}
            validation={this.validation.properties.permanentlyClosed!.properties.reason}
            helpText="This message will be shown to the customer."
            dataCy="store-perm-closed-reason"
          />
        )}
      </Form>
    );
  }
}
