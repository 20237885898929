import {
  CollectionType,
  ModifierOption,
  ModifierSpec,
  PizzaSettings,
  PizzaSpec,
  collectionTypes,
  defaults,
  PizzaSelection
} from '@restoplus/core';
import { priceSpecUtils } from './priceSpecUtils';
import { ItemPrice } from '@restoplus/core/src/models/item/ItemPrice';

const getSizeModifier = (args: {
  spec: PizzaSpec;
  settings: PizzaSettings;
  collectionType: CollectionType;
}): ModifierSpec => {
  const modifierSpec: ModifierSpec = {
    id: 'pizza-size',
    name: 'Size',
    min: 1,
    max: 1,
    options: getSizeModifierOptions(args)
  };
  return modifierSpec;
};

const getPriceForSize = (args: {
  spec: PizzaSpec;
  sizeId?: string;
  settings: PizzaSettings;
  collectionType: CollectionType;
}): number => {
  const { spec, sizeId, collectionType } = args;
  if (!sizeId) return 0;
  const pizzaSizeSpec = spec.sizes[sizeId];
  if (!pizzaSizeSpec) return 0;
  return priceSpecUtils.getBasePrice(pizzaSizeSpec.price, collectionType);
};

const getExtraChargeForToppings = (args: { settings: PizzaSettings; selection: PizzaSelection }): number => {
  const { sizeId } = args.selection;
  if (!sizeId) return 0;
  const toppings = getSelectedToppings(args);
  const toppingsExtraCharge = toppings.reduce((total, topping) => total + (topping.prices[sizeId] ?? 0), 0);
  return toppingsExtraCharge;
};

const getPriceForCollectionType = (args: {
  spec: PizzaSpec;
  settings: PizzaSettings;
  selection: PizzaSelection;
  collectionType: CollectionType;
}) => {
  return getPriceForSize({ ...args, sizeId: args.selection.sizeId }) + getExtraChargeForToppings(args);
};

const getPrice = (args: { spec: PizzaSpec; selection: PizzaSelection; settings: PizzaSettings }): ItemPrice => {
  return collectionTypes.reduce((result, collectionType) => {
    if (!args.spec.availableFor[collectionType]) return result;
    result[collectionType] = getPriceForCollectionType({ ...args, collectionType });
    return result;
  }, defaults.itemPrice);
};

const getSizeModifierOptions = (args: {
  spec: PizzaSpec;
  settings: PizzaSettings;
  collectionType: CollectionType;
}): ModifierOption[] => {
  const { spec, settings, collectionType } = args;
  const options: ModifierOption[] = [];
  for (const settingsSize of settings.sizes) {
    //
    const pizzaSizeSpec = spec.sizes[settingsSize.id];
    // this size is not available in this pizza
    if (!pizzaSizeSpec) continue;
    options.push({
      id: settingsSize.id,
      name: settingsSize.name,
      extraCharge: getPriceForSize({ ...args, sizeId: settingsSize.id })
    });
  }

  return options;
};

const getRemoveToppingsModifier = (args: {
  spec: PizzaSpec;
  settings: PizzaSettings;
  collectionType: CollectionType;
}): ModifierSpec => {
  const modifierSpec: ModifierSpec = {
    id: 'remove-toppings',
    name: 'Remove Toppings',
    min: 0,
    max: Infinity,
    options: args.spec.removeToppings.map(toppingName => ({ id: toppingName, name: toppingName, extraCharge: 0 }))
  };
  return modifierSpec;
};

const getAddToppingsModifier = (args: {
  spec: PizzaSpec;
  settings: PizzaSettings;
  sizeId: string;
  collectionType: CollectionType;
}): ModifierSpec => {
  const modifierSpec: ModifierSpec = {
    id: 'add-toppings',
    name: 'Add Toppings',
    min: 0,
    max: Infinity,
    options: args.settings.toppings.map(topping => ({
      id: topping.id,
      name: topping.name,
      extraCharge: topping.prices[args.sizeId] || 0
    }))
  };
  return modifierSpec;
};

// const getSize = (args: {
//   sizeId: string;
//   spec: PizzaSpec;
//   settings: PizzaSettings;
//   collectionType: CollectionType;
// }): PizzaSize => {
//   //
//   const sizeSpec = args.spec.sizes[args.sizeId]!;
//   const sizeSettings = args.settings.sizes.find(sizeSettings => sizeSettings.id === args.sizeId);

//   return {
//     id: args.sizeId,
//     name: sizeSettings!.name,
//     price: priceSpecUtils.getExtraCharge(sizeSpec?.price, args.collectionType)
//   };
// };

// const getAddToppings = (args: { addToppingsIds: string[]; settings: PizzaSettings; sizeId: string }): Topping[] => {
//   const { addToppingsIds, settings, sizeId } = args;
//   const selectedSpecs = settings.toppings.filter(topping => addToppingsIds.includes(topping.id));
//   return selectedSpecs.map(toppingSpec => ({
//     id: toppingSpec.id,
//     name: toppingSpec.name,
//     extraCharge: toppingSpec.prices[sizeId] || 0
//   }));
// };

const getSelectedToppings = (args: { selection: PizzaSelection; settings: PizzaSettings }) => {
  const { settings } = args;
  const { addToppingsIds } = args.selection;
  return settings.toppings.filter(topping => addToppingsIds.includes(topping.id));
};

const sizeSummary = (args: { selection: PizzaSelection; settings: PizzaSettings }) => {
  return `Size: ${args.settings.sizes.find(s => s.id === args.selection.sizeId)?.name}`;
};

const getToppingsSummary = (args: { selection: PizzaSelection; settings: PizzaSettings }) => {
  const { removeToppings } = args.selection;
  const summaries = [];
  // remove toppings
  const removeToppingsSummary = removeToppings.join(', ');
  removeToppingsSummary && summaries.push(`Remove Toppings: ${removeToppingsSummary}`);

  // add toppings
  const toppings = getSelectedToppings(args);
  const toppingsSummary = toppings.map(topping => topping.name).join(', ');
  toppingsSummary && summaries.push(`Add Toppings: ${toppingsSummary}`);

  return summaries.join('\n');
};

const getSummary = (args: { spec: PizzaSpec; selection: PizzaSelection; settings: PizzaSettings }) => {
  const summaries = [];
  summaries.push(sizeSummary(args));
  summaries.push(getToppingsSummary(args));
  return summaries.join('\n');
};

export const pizzaUtils = {
  // getSize,
  getSizeModifier,
  getRemoveToppingsModifier,
  getAddToppingsModifier,
  getToppingsSummary,
  getSelectedToppings,
  getSummary,
  getPrice,
  getPriceForSize,
  getExtraChargeForToppings
};
