import * as React from 'react';

export type BadgeType = 'success' | 'primary' | 'failure' | 'warning';
type Props = {
  type: BadgeType;
  children: string;
  className?: string;
};

export class Badge extends React.Component<Props> {
  render() {
    return <div className={`badge ${this.props.type} ${this.props.className}`}>{this.props.children}</div>;
  }
}
