import * as React from 'react';
import { FormItemContainer, FormItemProps } from './FormItemContainer';
import { TextInput, TextInputProps } from './TextInput';

type Props = TextInputProps & FormItemProps & { icon: React.ReactNode };

export class FormIconTextInput extends React.Component<Props> {
  render() {
    return (
      <FormItemContainer {...this.props} inputClassName="has-icon">
        {this.props.icon}
        <TextInput {...this.props} />
      </FormItemContainer>
    );
  }
}
