import {
  CategoryId,
  StoreId,
  Dow,
  dowList,
  collectionTypes,
  businessSessions,
  CollectionType,
  BusinessSession,
  StoreTimings,
  defaults,
  storeTimingsSchema,
  dowLabels,
  collectionTypeLabel
} from '@restoplus/core';
import React = require('react');
import { Page } from '../../elements/Page';
import { StaticDocumentEditWidget } from '../../document/StaticDocumentEditWidget';
import { DocumentRepo } from '../../repo/DocumentRepo';
import { repo } from '../../repo/repo';
import { Schema } from 'nutso';
import { Form } from '../../forms/Form';
import { FormSwitch } from '../../forms/FormSwitch';
import { FormHoursPicker } from '../../forms/FormHoursPicker';
import { observer } from 'mobx-react';
import { FormItemContainer } from '../../forms/FormItemContainer';
import { validationUtils } from '../../utils/validationUtils';

@observer
class StoreTimingsEditWidget extends StaticDocumentEditWidget<StoreId, StoreTimings> {
  //
  $store = repo.store.bind(this.props.documentId);

  name(): string {
    return 'store-timings-edit-widget';
  }
  defaultDocument(): StoreTimings {
    return defaults.storeTimings;
  }
  getRepo(): DocumentRepo<StoreId, StoreTimings> {
    return repo.storeTimings;
  }

  getSchema(): Schema<StoreTimings, StoreTimings> {
    return storeTimingsSchema;
  }

  nextRoute(): string {
    return '';
  }

  form(document: StoreTimings): React.ReactNode {
    if (!document) return <div>Loading store timings</div>;
    return (
      <Form
        type="responsive"
        submit={{ label: 'Save', handler: this.submit }}
        validationResult={this.validation}
        dataCy="store-timings"
      >
        {dowList.map(dow => this.renderDow(dow, document))}
      </Form>
    );
  }

  renderDow(dow: Dow, storeTimings: StoreTimings) {
    return (
      <FormItemContainer
        label={dowLabels[dow]}
        key={dow}
        validation={validationUtils.success}
        className={'dow'}
        dataCyForm={`store-timings-${dow}`}
      >
        {collectionTypes.map(collectionType => this.renderCollectionType(dow, collectionType, storeTimings))}
      </FormItemContainer>
    );
  }

  renderCollectionType(dow: Dow, collectionType: CollectionType, storeTimings: StoreTimings) {
    const store = this.$store.current();
    if (!store || !store.collectionSettings[collectionType].available) return null;
    return (
      <div className="collection-type" key={`${dow}-${collectionType}`}>
        {businessSessions.map(session => this.renderSession(dow, collectionType, session, storeTimings))}
      </div>
    );
  }

  renderSession(dow: Dow, collectionType: CollectionType, session: BusinessSession, storeTimings: StoreTimings): any {
    const session1 = storeTimings[dow][collectionType][BusinessSession.session1];
    const sessionTimings = storeTimings[dow][collectionType][session];
    const validation = this.validation.properties[dow].properties[collectionType].properties[session];
    const hasSplitTimings = this.$store.current()?.hasSplitTimings;
    const session2 = session === BusinessSession.session2;
    // no split timings, so no session2
    if (!hasSplitTimings && session2) return null;
    // no session 2 without session 1
    if (session2 && !session1.available) return null;

    let label = session2 ? `Session 2` : collectionTypeLabel[collectionType];

    return (
      <div
        className={`session ${session}`}
        key={`${dow}-${collectionType}-${session}`}
        data-cy={`${dow}-${collectionType}-${session}`}
      >
        <FormSwitch
          label={label}
          helpText=""
          value={sessionTimings.available}
          onChange={value => (sessionTimings.available = value)}
          validation={validation.properties.available}
          dataCy={`store-timings-${dow}-${collectionType}-${session}`}
        />
        {sessionTimings.available && (
          <FormHoursPicker
            label="Start Time"
            value={sessionTimings.hours}
            onChange={value => (sessionTimings.hours = value)}
            validation={validation.properties.hours}
            helpText="Choose start time and duration in hours"
          />
        )}
      </div>
    );
  }

  className(): string {
    return 'store-timings-edit-widget';
  }
}

type Props = {};
type RouteProps = StoreId & {};

export class StoreTimingsPage extends Page<Props, RouteProps> {
  //
  getTitle(): string {
    return 'Store Timings';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return <StoreTimingsEditWidget documentId={this.props.match.params} />;
  }
}
