import { RestaurantId, routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { DeliverySettingsPage } from '../backoffice/pages/DeliverySettingsPage';
import { PaymentsPage } from '../backoffice/pages/PaymentsPage';
import { StoreTimingsPage } from '../backoffice/pages/StoreTimingsPage';
import { CategoryCreatePage } from '../category/CategoryCreatePage';
import { CategoryEditPage } from '../category/CategoryEditPage';
import { CategoryHomePage } from '../category/CategoryHomePage';
import { DealSpecCreatePage } from '../deal_spec/DealSpecCreatePage';
import { DealSpecEditPage } from '../deal_spec/DealSpecEditPage';
import { Router } from '../elements/Router';
import { BasicItemSpecCreatePage } from '../item_spec/BasicItemSpecCreatePage';
import { BasicItemSpecEditPage } from '../item_spec/BasicItemSpecEditPage';
import { MenuPage } from '../menu/MenuPage';
import { PizzaSpecCreatePage } from '../pizza_spec/PizzaSpecCreatePage';
import { PizzaSpecEditPage } from '../pizza_spec/PizzaSpecEditPage';
import { OrderSettingsPage } from '../backoffice/pages/OrderSettingsPage';
import { PizzaSettingsPage } from '../backoffice/pages/PizzaSettingsPage';
import { StoreEditPage } from './StoreEditPage';
import { OrderHistoryPage } from '../backoffice/pages/OrderHistoryPage';
import { OrderViewPage } from '../backoffice/pages/OrderViewPage';
import React = require('react');

type Props = {};
type RouteProps = RestaurantId & {};

@observer
export class StoreRouter extends Router<RouteProps> {
  render() {
    return (
      <Switch>
        <Route exact path={routes.backoffice.editStore.template} component={StoreEditPage} />
        <Route exact path={routes.backoffice.store.template} component={OrderHistoryPage} />
        <Route exact path={routes.backoffice.menu.template} component={MenuPage} />
        <Route exact path={routes.backoffice.createCategory.template} component={CategoryCreatePage} />
        <Route exact path={routes.backoffice.editCategory.template} component={CategoryEditPage} />
        <Route exact path={routes.backoffice.category.template} component={CategoryHomePage} />
        <Route exact path={routes.backoffice.createItem.template} component={BasicItemSpecCreatePage} />
        <Route exact path={routes.backoffice.createPizza.template} component={PizzaSpecCreatePage} />
        <Route exact path={routes.backoffice.createDeal.template} component={DealSpecCreatePage} />
        <Route exact path={routes.backoffice.editItem.template} component={BasicItemSpecEditPage} />
        <Route exact path={routes.backoffice.editPizza.template} component={PizzaSpecEditPage} />
        <Route exact path={routes.backoffice.editDeal.template} component={DealSpecEditPage} />
        <Route exact path={routes.backoffice.pizzaSettings.template} component={PizzaSettingsPage} />
        <Route exact path={routes.backoffice.deliverySettings.template} component={DeliverySettingsPage} />
        <Route exact path={routes.backoffice.orderSettings.template} component={OrderSettingsPage} />
        <Route exact path={routes.backoffice.storeTimings.template} component={StoreTimingsPage} />
        <Route exact path={routes.backoffice.payments.template} component={PaymentsPage} />
        <Route exact path={routes.backoffice.orderView.template} component={OrderViewPage} />
        <Route render={() => <div>Store Router</div>} />
      </Switch>
    );
  }
}
