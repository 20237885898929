import { StoreId } from '@restoplus/core';
import { Page } from '../../elements/Page';
import { CheckoutSettingsEditWidget } from '../../settings/CheckoutSettingsEditWidget';
import React = require('react');
import { OrderProcessSettingsEditWidget } from '../../settings/OrderProcessSettingsEditWidget';
import { StorePrinterEditWidget } from '../../store/StorePrinterEditWidget';

type Props = {};
type RouteProps = StoreId & {};

export class OrderSettingsPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Order Settings';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  getAdditionalClassNames() {
    return 'order-settings-page';
  }
  renderBody(): React.ReactNode {
    return (
      <>
        <div>
          <CheckoutSettingsEditWidget documentId={this.props.match.params} />
        </div>
        <div>
          <OrderProcessSettingsEditWidget documentId={this.props.match.params} />
        </div>
        <div>
          <StorePrinterEditWidget documentId={this.props.match.params} />
        </div>
      </>
    );
  }
}
