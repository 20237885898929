/**
 * Firestore is also considered as integration as it helps a lot in testing
 */

import { fs } from './fb';
import { undef2null, OrderByDirection, Page } from '@restoplus/core';
import { firestore } from 'firebase';

export type Cursor = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

const getDoc = async <T>(path: string): Promise<T | null> => {
  const doc = await fs.doc(path).get();
  if (doc.exists) return doc.data() as T;
  return null;
};

const getDocCacheFirst = async <T>(path: string): Promise<T | null> => {
  try {
    const doc = await fs.doc(path).get({ source: 'cache' });
    if (doc.exists) return doc.data() as T;
    return null;
  } catch (e) {
    console.warn(`Document: ${path} not found in cache, getting from server.`);
    return getDoc(path);
  }
};

/**
 * This API has to be used if the document is sure to be present
 */
const getDocNullSafe = async <T>(path: string): Promise<T> => {
  const doc = await fs.doc(path).get();
  if (doc.exists) return doc.data() as T;
  throw new Error(`Expected document at path ${path} not found.`);
};

const getDocWithDefault = async <T>(path: string, _default: T): Promise<T> => {
  const doc = await fs.doc(path).get();
  if (doc.exists) return doc.data() as T;
  return _default;
};

const getDocWithDefaultCacheFirst = async <T>(path: string, _default: T): Promise<T> => {
  // get doc cache first, if null, return default
  const doc = await getDocCacheFirst<T>(path);
  if (doc) return doc;
  return _default;
};

const putDoc = async <T>(path: string, obj: T): Promise<void> => {
  undef2null(obj);
  return fs.doc(path).set(obj);
};

const mergeDoc = async <T>(path: string, obj: Partial<T>): Promise<void> => {
  undef2null(obj); // firestore does not support undefined as a value
  return fs.doc(path).set(obj, { merge: true });
};

const deleteDoc = async (path: string): Promise<void> => {
  return fs.doc(path).delete();
};

const getCollectionIds = async (path: string): Promise<string[]> => {
  const collection = await fs.collection(path).get();
  if (collection.empty) return [];
  return collection.docs.map(doc => doc.id);
};

const getCollectionValuesCacheFirst = async <T>(path: string): Promise<T[]> => {
  try {
    const collection = await fs.collection(path).get({ source: 'cache' });
    if (collection.empty) return [];
    return collection.docs.map(doc => doc.data() as T);
  } catch (e) {
    console.warn(`Collection: ${path} not found in cache, getting from server.`);
    return getCollectionValues(path);
  }
};

const getCollectionValues = async <T>(path: string): Promise<T[]> => {
  const collection = await fs.collection(path).get();
  if (collection.empty) return [];
  return collection.docs.map(doc => doc.data() as T);
};

const page = async <T>(args: {
  path: string;
  orderByField: Extract<keyof T, string>;
  orderByDirection: OrderByDirection;
  limit: number;
  cursor?: Cursor;
}): Promise<Page<T>> => {
  //
  const { path, orderByField, orderByDirection, limit = 100, cursor } = args;

  // create query
  let query = fs
    .collection(path)
    .orderBy(orderByField, orderByDirection)
    .limit(limit);
  if (cursor) query = query.startAfter(cursor);

  // get snapshot
  const snapshot = await query.get();

  // empty list
  if (snapshot.empty) return { list: [], cursor: null };

  // return without cursor
  const list = snapshot.docs.map(doc => doc.data() as T);
  if (snapshot.size < limit) return { list, cursor: null };

  // return with cursor
  return { list, cursor: snapshot.docs[snapshot.docs.length - 1] };
};

const buildCollectionQuery = <T>(args: {
  path: string;
  where: [string, firestore.WhereFilterOp, string];
  where2?: [string, firestore.WhereFilterOp, string];
  orderByField: Extract<keyof T, string>;
  orderByDirection: OrderByDirection;
  limit?: number;
  cursor?: Cursor;
}) => {
  //
  const { path, orderByField, orderByDirection, limit = 100, cursor, where, where2 } = args;

  // create query and where clause
  let query = fs.collection(path).where(where[0], where[1], where[2]);
  // where2 clause
  if (where2) query = query.where(where2[0], where2[1], where2[2]);
  // order by
  query = query.orderBy(orderByField, orderByDirection);
  // limit
  if (limit) query = query.limit(limit);
  // cursor
  if (cursor) query = query.startAfter(cursor);

  return query;
};

const buildCollectionGroupQuery = <T>(args: {
  collectionGroupName: string;
  where: [string, firestore.WhereFilterOp, string];
  where2?: [string, firestore.WhereFilterOp, string];
  orderByField: Extract<keyof T, string>;
  orderByDirection: OrderByDirection;
  limit: number;
  cursor?: Cursor;
}) => {
  //
  const { collectionGroupName, where, where2, orderByField, orderByDirection, limit = 100, cursor } = args;

  // create query, where clause
  let query = fs.collectionGroup(collectionGroupName).where(where[0], where[1], where[2]);
  // where2 clause
  if (where2) query = query.where(where2[0], where2[1], where2[2]);
  // order by and limit
  query = query.orderBy(orderByField, orderByDirection).limit(limit);
  // cursor
  if (cursor) query = query.startAfter(cursor);

  return query;
};

const pageCollectionGroup = async <T>(args: {
  collectionGroupName: string;
  where: [string, firestore.WhereFilterOp, string];
  where2?: [string, firestore.WhereFilterOp, string];
  orderByField: Extract<keyof T, string>;
  orderByDirection: OrderByDirection;
  limit: number;
  cursor?: Cursor;
}): Promise<Page<T>> => {
  //
  const query = buildCollectionGroupQuery(args);

  // get snapshot
  const snapshot = await query.get();

  // empty list
  if (snapshot.empty) return { list: [], cursor: null };

  // return without cursor
  const list = snapshot.docs.map(doc => doc.data() as T);
  if (snapshot.size < args.limit) return { list, cursor: null };

  // return with cursor
  return { list, cursor: snapshot.docs[snapshot.docs.length - 1] };
};

const getCollection = async <T>(path: string): Promise<{ [key: string]: T }> => {
  const collection = await fs.collection(path).get();
  if (collection.empty) return {};
  return collection.docs.reduce((map, doc) => {
    map[doc.id] = doc.data() as T;
    return map;
  }, <{ [key: string]: T }>{});
};

const batch = () => {
  return fs.batch();
};

const ref = (path: string) => {
  return fs.doc(path);
};

const runTransaction = <T extends {}>(updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>) => {
  return fs.runTransaction(updateFunction);
};

export const firestoreApi = {
  runTransaction,
  getDoc,
  getDocCacheFirst,
  getDocNullSafe,
  getDocWithDefault,
  getDocWithDefaultCacheFirst,
  putDoc,
  mergeDoc,
  deleteDoc,
  getCollectionIds,
  getCollectionValues,
  getCollectionValuesCacheFirst,
  getCollection,
  page,
  pageCollectionGroup,
  batch,
  ref,
  buildCollectionQuery,
  buildCollectionGroupQuery
};
