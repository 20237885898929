import { Schema } from 'nutso';
import { DealSpec } from '../../models/item_spec/DealSpec';
import { itemBaseSpecSchema } from './itemBaseSpecSchema';
import { modifiersSpecSchema } from './modifierSpecsSchema';
import { priceSpecSchema } from './priceSpecSchema';

export const dealSpecSchema: Schema<DealSpec> = {
  type: 'object',
  properties: {
    ...itemBaseSpecSchema.properties,
    price: priceSpecSchema,
    items: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          name: {
            type: 'string',
            minLength: 2,
            maxLength: 20
          },
          quantity: {
            type: 'number'
          },
          modifiers: modifiersSpecSchema
        }
      }
    },
    pizzas: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          sizeId: {
            type: 'string'
          },
          quantity: {
            type: 'number'
          },
          pizzas: {
            type: 'object',
            properties: {
              '/.*/': {
                type: 'object',
                properties: {
                  extraCharge: {
                    type: 'number'
                  }
                }
              }
            }
          }
        }
      }
    },
    modifiers: modifiersSpecSchema
  }
};
