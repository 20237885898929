import { AspectRatio, routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Imgix } from '../../elements/Imgix';
import { customerCart } from '../../provider/customerCartProvider';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { storeProvider } from '../../provider/storeProvider';
import { userProvider } from '../../provider/userProvider';
import { browserHistory, gotoPath } from '../../utils/browserHistory';
import { currentPath } from '../../utils/currentPath';
import { locationProvider } from '../../provider/locationProvider';
import { onlineOrderingSidebarController } from './OnlineOrderingSidebar';
import { StoreHeaderWidget } from '../../store/StoreHeaderWidget';

const initials = require('initials');
type Props = StoreId;

@observer
export class OnlineOrderingHeader extends React.Component<Props> {
  //
  renderLoginIcon(): React.ReactNode {
    return (
      <div
        className="login-icon"
        onClick={() =>
          browserHistory.push(routes.website.onlineOrdering.login.link(this.props, { continueLink: currentPath() }))
        }
      >
        <i className="icon las la-user-circle"></i>
      </div>
    );
  }

  onLogout = () => {
    const ok = confirm(`You are currently logged in as ${userProvider.user?.email}. Do you wish to logout?`);
    if (ok) userProvider.logout();
  };

  renderStoreName = () => {
    const restaurant = restaurantProvider.$restaurant?.current();
    const store = storeProvider.$store?.current();
    if (!restaurant || !store) return;
    return <StoreHeaderWidget {...this.props} onClick={() => gotoPath(routes.website.store.link(this.props, {}))} />;
  };

  renderActions = () => {
    return (
      <div className="actions">
        {this.renderCart()}
        {userProvider.user ? this.renderAvatar(userProvider.user) : this.renderLoginIcon()}
      </div>
    );
  };

  renderCart = (): React.ReactNode => {
    return (
      <div className="cart" onClick={() => gotoPath(routes.website.onlineOrdering.checkout.link(this.props, {}))}>
        <i className={`icon las la-shopping-cart`}></i>
        <div className="count">
          <span>{customerCart.cartItems.length}</span>
        </div>
      </div>
    );
  };

  renderAvatar = (user: firebase.User) => {
    return (
      <div className="avatar" onClick={() => onlineOrderingSidebarController.toggle()} title={`${user.email}`}>
        <img src={`https://api.adorable.io/avatars/250/${user.uid}`} />
      </div>
    );
  };

  renderUserInfo = () => {
    const user = userProvider.user;
    if (!user) return;
    return (
      <div className="userinfo" onClick={this.onLogout}>
        <div className="avatar">
          <img src={`https://api.adorable.io/avatars/100/${user.uid}`} />
        </div>
        {/* <div className="initials"> {initials(user.displayName || user.email || '').substring(0, 2)}</div>
        <div className="full">
          <div className="name" data-cy="display-name">
            {user.displayName}
          </div>
          <div className="email" data-cy="display-email">
            {user.email}
          </div>
        </div> */}
      </div>
    );
  };

  renderMenuItem = (args: { label: string; path: string }): React.ReactNode => {
    const selected = locationProvider.isSelected(args.path);
    return (
      <div className={`item ${selected && 'selected'}`} onClick={() => gotoPath(args.path)}>
        <div className="label">{args.label}</div>
        <div className="bleed"></div>
      </div>
    );
  };
  renderMenu(): React.ReactNode {
    return (
      <div className="menu">
        {this.renderMenuItem({
          label: 'Menu',
          path: routes.website.onlineOrdering.menu.link(this.props, {})
        })}
        {this.renderMenuItem({
          label: 'Coupons',
          path: routes.website.onlineOrdering.coupons.link(this.props, {})
        })}
        {this.renderMenuItem({
          label: 'Loyalty Card',
          path: routes.website.onlineOrdering.loyaltyCard.link(this.props, {})
        })}
        {this.renderMenuItem({
          label: 'Orders',
          path: routes.website.onlineOrdering.secure.orderHistory.link(this.props, {})
        })}
      </div>
    );
  }

  renderBackButton(): React.ReactNode {
    return (
      <div className="back-button" onClick={() => browserHistory.goBack()}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }
  render() {
    return (
      <header>
        <div className="wrapper">
          {this.renderBackButton()}
          {this.renderStoreName()}
          {this.renderMenu()}
          {this.renderActions()}
        </div>
      </header>
    );
  }
}
