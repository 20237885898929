import * as React from 'react';

import { observer } from 'mobx-react';

import { toastStore } from './toastStore';
import { Toast } from './Toast';
import { ToastInfo } from './ToastInfo';

type Props = {};

@observer
export class ToastContainer extends React.Component<Props> {
  renderToast = (toast: ToastInfo): JSX.Element => {
    return (
      <div className="wrapper" key={toast.id}>
        <Toast type={toast.type} message={toast.message} key={toast.id} id={toast.id} />
      </div>
    );
  };

  render() {
    const toasts = toastStore.toasts;
    // no toasts
    if (!toasts.length) return null;
    return <div id="toasts-container">{toasts.map(toast => this.renderToast(toast))}</div>;
  }
}
