/**
 * Wakeup notification sent to printer to let them know that there is either an order/booking waiting on the server
 */
export const Wakeup = (printerId: string) => {
  return `wakeup-${printerId}`;
};

export const fcmTopics = {
  Wakeup
};
