import { HasSortOrder, sortOrder } from '@restoplus/core';

export type SortOrderPropPrev = {
  prev: number;
  prevPrev: number;
};
export type SortOrderPropNext = {
  next: number;
  nextNext: number;
};
export type SortOrderProp = SortOrderPropPrev & SortOrderPropNext;

const sortOrderPropPrev = <T extends HasSortOrder>(list: T[], currentIndex: number): SortOrderPropPrev => {
  if (currentIndex === 0) {
    return {
      prev: 0,
      prevPrev: 0
    };
  }
  if (currentIndex === 1) {
    return {
      prev: list[0].sortOrder,
      prevPrev: 0
    };
  }
  return {
    prev: list[currentIndex - 1].sortOrder,
    prevPrev: list[currentIndex - 2].sortOrder
  };
};

const sortOrderPropNext = <T extends HasSortOrder>(list: T[], currentIndex: number): SortOrderPropNext => {
  // last element
  if (currentIndex === list.length - 1) {
    return {
      next: 1,
      nextNext: 1
    };
  }
  // penultimate
  if (currentIndex === list.length - 2) {
    return {
      next: list[currentIndex + 1].sortOrder,
      nextNext: 1
    };
  }

  return {
    next: list[currentIndex + 1].sortOrder,
    nextNext: list[currentIndex + 2].sortOrder
  };
};

export const sortOrderProp = <T extends HasSortOrder>(list: T[], currentIndex: number): SortOrderProp => {
  return {
    ...sortOrderPropPrev(list, currentIndex),
    ...sortOrderPropNext(list, currentIndex)
  };
};

export const getPrevSortOrder = (prop: SortOrderPropPrev) => {
  // no-op, it's already first element
  if (prop.prev === 0) return;
  return sortOrder.get(prop.prevPrev, prop.prev);
};

export const getNextSortOrder = (prop: SortOrderPropNext) => {
  // no-op, it's already last element
  if (prop.next === 1) return;
  return sortOrder.get(prop.next, prop.nextNext);
};
