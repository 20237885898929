import { Restaurant, Store, StoreId } from '@restoplus/core';
import * as React from 'react';
import { ResolveResource } from '../elements/ResolveResource';
import { Widget } from '../elements/Widget';
import { repo } from '../repo/repo';

type Props = StoreId;

export abstract class StoreViewWidget<AdditionalProps = {}> extends Widget<Props & AdditionalProps> {
  //
  $restaurant = repo.restaurant.bind(this.props);
  $store = repo.store.bind(this.props);

  abstract renderStore(restaurant: Restaurant, store: Store): JSX.Element;

  render() {
    return (
      <ResolveResource resource={this.$restaurant}>
        {restaurant => (
          <ResolveResource resource={this.$store}>
            {store => {
              return this.renderStore(restaurant!, store!);
            }}
          </ResolveResource>
        )}
      </ResolveResource>
    );
  }
}
