import { routes } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { Widget } from '../../elements/Widget';
import { RestaurantWebsiteHeader } from './RestaurantWebsiteHeader';
import { RestaurantWebsitePage } from '../pages/RestaurantWebsitePage';

type Props = {};

@observer
export class RestaurantWebsiteLayout extends Widget<Props> {
  render() {
    return (
      <div className="restaurant-website">
        <RestaurantWebsiteHeader />
        <Route path={routes.website.restaurant.template} component={RestaurantWebsitePage} />
      </div>
    );
  }
}
