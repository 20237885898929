import {
  Cart,
  CollectionType,
  collectionTypeLabel,
  collectionTypes,
  Comment,
  countries,
  CustomerCart,
  CustomerCartId,
  dateUtils,
  DeliveryAddress,
  DeliverySettings,
  Item,
  PaymentType,
  Store
} from '@restoplus/core';
import { action, computed, observable, toJS, reaction } from 'mobx';
import { repo } from '../repo/repo';
import { toastStore } from '../toast/toastStore';
import { promiseValue } from '../utils/promiseValue';
import { checkoutSettingsProvider } from './checkoutSettingsProvider';
import { restaurantProvider } from './restaurantProvider';
import { storeProvider } from './storeProvider';
import { userProvider } from './userProvider';
import { fb } from '../integration/fb';

const getOnlyCollectionType = (store: Store) => {
  const availableCollectionTypes = collectionTypes.filter(
    collectionType => store.collectionSettings[collectionType].available
  );
  if (availableCollectionTypes.length === 1) return availableCollectionTypes[0];

  return;
};

const defaultCollectionType = (store: Store) => {
  if (store.collectionSettings.pickup.available) return CollectionType.pickup;
  if (store.collectionSettings.delivery.available) return CollectionType.delivery;
  if (store.collectionSettings.dineIn.available) return CollectionType.dineIn;
  return;
};

class CustomerCartProvider {
  //

  /**
   * Managing the collection type is different depending upon if the user is logged in or logged out
   * If the user is logged in, then collection type has to be set and read front customerCart
   * That user is not logged in, then obviously that is no cart, so it has to be read from this local variable
   */
  @observable
  userSelection: CollectionType | undefined;

  @computed
  get cartId(): CustomerCartId | undefined {
    const storeId = storeProvider.storeId;
    const uid = userProvider.uid;
    if (!storeId || !uid) return;
    return { ...storeId, uid };
  }

  @computed
  get $customerCart() {
    if (!this.cartId) return;
    return repo.customerCart.bind(this.cartId);
  }

  /**
   * This will be observed by bottom nav bar => cart items count
   */
  @computed
  get allItems() {
    const cart = this.$customerCart?.current();
    return cart?.items ?? [];
  }

  @computed
  get cartItems() {
    const collectionType = this.collectionType;
    if (!collectionType) return [];
    // filter items by collection type
    return this.allItems.filter(item => item.spec.availableFor[collectionType]);
  }

  @action
  async addItem(item: Item) {
    if (!this.cartId) {
      alert('Cannot find cart-id');
      return;
    }
    this.update({ items: [...this.allItems, item] });
    toastStore.success('Item added to cart');
  }

  @action
  incItem(index: number) {
    let items = toJS(this.cartItems);
    if (index >= items.length || !this.cartId) {
      alert(`Cannot increment item count.`);
      return;
    }
    items[index].summary.quantity++;
    this.update({ items });
  }

  @action
  decItem(index: number) {
    let items = toJS(this.cartItems);
    if (index >= items.length || !this.cartId) {
      alert(`Cannot decrement item count.`);
      return;
    }

    const quantity = items[index].summary.quantity;

    if (quantity === 1) {
      // remove item
      items = items.filter((item, i) => i !== index);
      repo.customerCart.update(this.cartId, { items });
      return;
    }

    // dec quantity
    items[index].summary.quantity = quantity - 1;
    this.update({ items });
  }

  @computed
  get collectionType(): CollectionType | undefined {
    //
    const store = storeProvider.$store?.current();
    if (!store) return;

    // if store has only one collection type, return that
    const onlyCollectionType = getOnlyCollectionType(store);
    if (onlyCollectionType) return onlyCollectionType;

    // user not logged-in or user has not selected a collection type, return userSelection or default
    let customerSelection = this.$customerCart?.current()?.collectionType;
    if (!customerSelection) return this.userSelection ?? defaultCollectionType(store);

    // return customer selection, if it's available
    if (store.collectionSettings[customerSelection].available) {
      return customerSelection;
    }

    return;
  }

  set collectionType(collectionType: CollectionType | undefined) {
    if (!collectionType) return;
    // if user logged-in,
    if (this.$customerCart) {
      this.update({ collectionType });
      return;
    }
    // user not logged-in, so save in local variable
    this.userSelection = collectionType;
  }

  @computed
  get currency() {
    const countryCode = restaurantProvider.$restaurant?.current()?.country;
    if (!countryCode) return;
    const country = countries[countryCode];
    return {
      code: country.currencyCode,
      symbol: country.currencySymbol
    };
  }

  @computed
  get creditCardSurcharge(): number {
    // if not cc order, return 0
    const cart = this.$customerCart?.current();
    const paymentType = cart?.paymentType;

    if (!paymentType || paymentType !== PaymentType.card) {
      return 0;
    }

    // if checkout config not loaded, return 0
    const checkoutSettings = promiseValue(checkoutSettingsProvider.checkoutSettings);
    if (!checkoutSettings) return 0;

    // if cc handling charge not enabled, return 0
    const creditCardSurcharge = checkoutSettings.creditCardSurcharge;
    if (!creditCardSurcharge) return 0;

    const amountPayable = this.itemTotal + this.deliveryCharges - this.discount;

    // check if amountPayable is more than ccMinimumOrderValue
    if (creditCardSurcharge.applicableBelowAmount !== 0 && amountPayable >= creditCardSurcharge.applicableBelowAmount) {
      return 0;
    }

    // init cc handling charge
    let surcharge = 0;

    // if amount present, add it
    if (creditCardSurcharge.amount > 0) {
      surcharge += creditCardSurcharge.amount;
    }

    // if percentage present, add it
    if (creditCardSurcharge.percentage > 0) {
      surcharge += (amountPayable * creditCardSurcharge.percentage) / 100;
    }

    // return
    return Number(parseFloat(`${surcharge}`).toFixed(2));
  }

  @computed
  get discount() {
    // TODO
    return 0;
  }

  @computed
  get itemTotal(): number {
    const collectionType = this.collectionType;
    if (!collectionType) return 0;
    return this.cartItems.reduce<number>(
      (total, item) => total + item.summary.quantity * (item.summary.price[collectionType] ?? 0),
      0
    );
  }

  @computed
  get deliverySettings() {
    const storeId = storeProvider.storeId;
    if (!this.cartId || !storeId) return;
    return repo.deliverySettings.bind(storeId);
  }

  @computed
  get deliveryCharges(): number {
    if (this.collectionType !== CollectionType.delivery) return 0;
    // todo derive from delivery address and delivery suburbs
    const cart = this.$customerCart?.current();
    if (!cart) return 0;

    // delivery address
    const deliveryAddress = cart.deliveryAddress;
    if (!deliveryAddress) return 0;

    const suburbId = deliveryAddress.suburb.id;

    // find the charges for this suburb
    const deliverySettings = this.deliverySettings?.current();
    if (!deliverySettings) return 0;

    const suburb = deliverySettings.suburbs.find(suburb => suburb.id === suburbId);
    if (!suburb) {
      console.error(`Cannot find the suburb, so setting the delivery charges to 0`);
      return 0;
    }

    return suburb.deliveryCharges;
  }

  @computed
  get grandTotal(): number {
    return this.itemTotal + this.deliveryCharges + this.creditCardSurcharge - this.discount;
  }

  @computed
  get collectionTime(): string {
    return this.$customerCart?.current()?.collectionTime ?? '';
  }

  set collectionTime(collectionTime: string) {
    this.update({ collectionTime });
  }

  @computed
  get paymentType(): PaymentType {
    return this.$customerCart?.current()?.paymentType ?? PaymentType.card;
  }

  set paymentType(paymentType: PaymentType) {
    this.update({ paymentType });
  }

  @computed
  get comment(): Comment | undefined {
    return this.$customerCart?.current()?.comment;
  }

  set comment(comment: Comment | undefined) {
    this.update({ comment });
  }

  @computed
  get deliveryAddress(): DeliveryAddress | undefined {
    return this.$customerCart?.current()?.deliveryAddress;
  }

  set deliveryAddress(deliveryAddress: DeliveryAddress | undefined) {
    this.update({ deliveryAddress });
  }

  @computed
  get validationError(): string | undefined {
    const collectionType = this.collectionType;
    const collectionTime = this.collectionTime;
    const paymentType = this.paymentType;
    const currency = this.currency;
    const deliveryAddress = this.deliveryAddress;

    if (!collectionType) return `Please choose pickup, delivery or dine-in.`;
    if (!collectionTime) return `Please choose ${collectionTypeLabel[collectionType]} time.`;
    if (!paymentType) return `Please choose payment method.`;
    if (!currency) return `Cannot find the currency for the order.`;
    if (!userProvider.phoneNumber) return `Please enter your mobile number.`;
    if (collectionType === CollectionType.delivery && !deliveryAddress?.line1) return 'Please add a delivery address.';
    return;
  }

  /**
   * Will be observed by the validator in place order page
   */
  @computed
  get cart(): Cart | null {
    //
    if (this.validationError) return null;

    const delivery = this.deliveryAddress && {
      address: this.deliveryAddress,
      charges: this.deliveryCharges
    };

    return {
      createdTime: dateUtils.time(),
      items: toJS(this.cartItems),
      collectionType: this.collectionType!,
      collectionTime: this.collectionTime,
      paymentType: this.paymentType,
      delivery: delivery,
      itemTotal: this.itemTotal,
      grandTotal: this.grandTotal,
      comments: this.comment?.value,
      discount: this.discount,
      currency: this.currency!,
      customerName: userProvider.displayName ?? ''
    };
  }

  @action
  clear() {
    console.log(`Clearing customer cart@!`);
    if (!this.cartId) {
      alert(`Cannot reset cart`);
      return;
    }

    repo.customerCart.put(this.cartId, { items: [], ...this.cartId, paymentType: PaymentType.card });
  }

  // use this api to update the remote cart
  update(partialCart: Partial<CustomerCart>) {
    if (!this.cartId) {
      alert(`Cannot update cart at the moment! Please try again later.`);
      return;
    }
    repo.customerCart.update(this.cartId, partialCart).catch(e => {
      console.error(e);
      alert(`Error adding item to cart!`);
    });
  }
}

export const customerCart = new CustomerCartProvider();
