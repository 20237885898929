import { CheckoutSettings, checkoutSettingsSchema, StoreId, defaults } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Result, Schema } from 'nutso';
import { StaticDocumentEditWidget } from '../document/StaticDocumentEditWidget';
import { Form } from '../forms/Form';
import { FormNumberInput } from '../forms/FormNumberInput';
import { FormSwitch } from '../forms/FormSwitch';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import React = require('react');
import { optionalFlag } from '../utils/optionalFlag';

@observer
export class CheckoutSettingsEditWidget extends StaticDocumentEditWidget<StoreId, CheckoutSettings> {
  //

  name(): string {
    return 'Checkout Settings';
  }
  defaultDocument(): CheckoutSettings {
    return defaults.checkoutSettings;
  }
  getSchema(): Schema<CheckoutSettings, CheckoutSettings> {
    return checkoutSettingsSchema;
  }

  getRepo(): DocumentRepo<StoreId, CheckoutSettings> {
    return repo.checkoutSettings;
  }
  nextRoute(): string {
    return '';
  }

  className() {
    return 'checkout-settings-edit-widget';
  }

  form(checkoutSettings: CheckoutSettings): JSX.Element {
    return (
      <>
        <div className="title">Checkout Settings</div>
        <Form type="default" submit={{ label: 'Save', handler: this.submit }} validationResult={this.validation}>
          <FormSwitch
            label="Collection Time"
            helpText="Allow pre-ordering?"
            value={checkoutSettings.hasCollectionTime}
            onChange={value => (checkoutSettings.hasCollectionTime = value)}
            validation={this.validation.properties.hasCollectionTime}
            dataCy="checkout-collection-time"
          />
          <FormSwitch
            label="Comments"
            helpText="Accept user comments?"
            value={checkoutSettings.hasComments}
            onChange={value => (checkoutSettings.hasComments = value)}
            validation={this.validation.properties.hasComments}
            dataCy="checkout-user-comments"
          />
          <FormSwitch
            label="Coupons"
            helpText="Do you accept coupons?"
            value={checkoutSettings.hasCoupons}
            onChange={value => (checkoutSettings.hasCoupons = value)}
            validation={this.validation.properties.hasCoupons}
            dataCy="checkout-coupons"
          />
          <FormSwitch
            label="Cash"
            helpText="Do you accept cash for online orders?"
            value={checkoutSettings.hasCash}
            onChange={value => (checkoutSettings.hasCash = value)}
            validation={this.validation.properties.hasCash}
            dataCy="checkout-cash"
          />
          <FormSwitch
            label="Credit Card Surcharge"
            helpText="Do you charge the customer for credit card payments?"
            {...optionalFlag(checkoutSettings, 'creditCardSurcharge', defaults.creditCardSurcharge)}
            dataCy="checkout-card-surcharge"
          />
          {this.renderCreditCardSurcharge(checkoutSettings, this.validation)}
        </Form>
      </>
    );
  }

  renderCreditCardSurcharge(
    checkoutSettings: CheckoutSettings,
    _validation: Result<CheckoutSettings>
  ): React.ReactNode {
    const surcharge = checkoutSettings.creditCardSurcharge;
    if (!surcharge) return null;
    const validation = _validation.properties.creditCardSurcharge!;
    return (
      <>
        <FormNumberInput
          label={'Amount'}
          value={surcharge.amount}
          onChange={value => (surcharge.amount = value)}
          validation={validation.properties.amount}
          className="amount"
          helpText="Amount to charge for credit card transactions. Ex: .30 for 30 cents"
          dataCy="checkout-card-surcharge-amt"
        />
        <FormNumberInput
          label={'Percentage'}
          value={surcharge.percentage}
          onChange={value => (surcharge.percentage = value)}
          validation={validation.properties.percentage}
          className="percentage"
          helpText="Percentage to charge for credit card transactions. Ex: 1.75"
          dataCy="checkout-card-percent"
        />
        <FormNumberInput
          label={'Applicable below amount'}
          value={surcharge.applicableBelowAmount}
          onChange={value => (surcharge.applicableBelowAmount = value)}
          validation={validation.properties.applicableBelowAmount}
          className="applicable-below-amount"
          helpText="Apply surcharge only if grand total is below this amount. Enter 0 to apply surcharge for all transactions"
          dataCy="checkout-card-below-amt"
        />
      </>
    );
  }
}
