import { dowList, gcsPaths, BaseItemSpec } from '@restoplus/core';
import { ItemType } from '@restoplus/core/src/models/item_spec/ItemType';
import { observer } from 'mobx-react';
import { Result } from 'nutso';
import * as React from 'react';
import { FormHoursPicker } from '../forms/FormHoursPicker';
import { FormImage } from '../forms/FormImage';
import { FormMultiSelect } from '../forms/FormMultiSelect';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { FormSwitch } from '../forms/FormSwitch';
import { FormTextArea } from '../forms/FormTextArea';
import { FormTextInput } from '../forms/FormTextInput';
import { userProvider } from '../provider/userProvider';
import { optionalFlag } from '../utils/optionalFlag';
import { validationUtils } from '../utils/validationUtils';

type Props = {
  item: BaseItemSpec;
  validation: Result<BaseItemSpec>;
};

@observer
export class ItemSpecBaseEditWidget extends React.Component<Props> {
  //

  render() {
    const { item, validation } = this.props;
    return (
      <>
        <FormImage
          label="Image"
          gcsFolder={gcsPaths.images.ItemImages({ uid: userProvider.uid! })}
          value={item.image}
          onChange={image => (item.image = image)}
          validation={validation.properties.image || validationUtils.success}
        />
        <FormTextInput
          label="Name"
          value={item.name}
          onChange={value => (item.name = value)}
          validation={validation.properties.name}
        />
        <FormTextArea
          label="Description"
          value={item.description ?? ''}
          onChange={value => (item.description = value)}
          validation={validation.properties.description ?? validationUtils.success}
        />
        <FormSingleSelect
          label={'Type'}
          options={[ItemType.basic, ItemType.pizza, ItemType.deal]}
          optionKey={itemType => itemType}
          optionLabel={itemType => itemType}
          value={item.type}
          onChange={value => (item.type = value)}
          helpText=""
          validation={validation.properties.type}
          disabled={true}
        />
        <FormSwitch
          label="Available"
          helpText="This item is available for customers to order?"
          value={item.available}
          onChange={value => (item.available = value)}
          validation={validation.properties.available}
        />
        <FormSwitch
          label="Available For Pickup"
          helpText=""
          validation={validationUtils.success}
          value={item.availableFor.pickup}
          onChange={value => (item.availableFor.pickup = value)}
        />
        <FormSwitch
          label="Available For Delivery"
          helpText=""
          validation={validationUtils.success}
          value={item.availableFor.delivery}
          onChange={value => (item.availableFor.delivery = value)}
        />
        <FormSwitch
          label="Available For Dine In"
          helpText=""
          validation={validationUtils.success}
          value={item.availableFor.dineIn}
          onChange={value => (item.availableFor.dineIn = value)}
        />
        <FormSwitch
          label="Day of Week Applicable"
          helpText="Is this item available only for few days in a week?"
          {...optionalFlag(item, 'availableDaysOfWeek', [])}
        />
        {item.availableDaysOfWeek && (
          <FormMultiSelect
            label={'Days of Week'}
            options={dowList}
            optionKey={dow => dow}
            optionLabel={dow => dow}
            value={item.availableDaysOfWeek}
            onChange={value => (item.availableDaysOfWeek = value)}
            helpText="Applicable days of week"
            validation={validation.properties.availableDaysOfWeek!}
          />
        )}
        <FormSwitch
          label="Time of Day Applicable"
          helpText="Category available during specific hours?"
          {...optionalFlag(item, 'availableHours', { startHhmm: '', duration: 0 })}
        />
        {this.renderApplicableHours(item, validation)}
      </>
    );
  }
  renderApplicableHours = (item: BaseItemSpec, validation: Result<BaseItemSpec>) => {
    if (!item.availableHours) return null;
    return (
      <FormHoursPicker
        label="Start Time"
        value={item.availableHours}
        onChange={value => (item.availableHours = value)}
        validation={validation.properties.availableHours!}
        helpText="Choose start time and duration in hours"
      />
    );
  };
}
