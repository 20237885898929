import { observer } from 'mobx-react';
import * as React from 'react';
import { Widget } from '../elements/Widget';
import { fb } from '../integration/fb';
import { IntroWidget } from './IntroWidget';
import firebaseui = require('firebaseui');
import { webConfig } from '../utils/webConfig';

var uiConfig: firebaseui.auth.Config = {
  // signInSuccessUrl: window.location.href,
  signInOptions: [
    {
      provider: fb.auth.GoogleAuthProvider.PROVIDER_ID,
      authMethod: 'https://accounts.google.com',
      clientId: webConfig.googleOAuth2ClientId
    },
    fb.auth.FacebookAuthProvider.PROVIDER_ID,
    // fb.auth.TwitterAuthProvider.PROVIDER_ID,
    // 'apple.com',
    'yahoo.com'
    // 'microsoft.com'
    // fb.auth.EmailAuthProvider.PROVIDER_ID,
    // fb.auth.PhoneAuthProvider.PROVIDER_ID
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: 'https://www.restoplus.co/tos',
  privacyPolicyUrl: 'https://www.restoplus.co/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  callbacks: {
    signInSuccessWithAuthResult: () => {
      /**
       * Return false, coz we are not supplying a signInSuccessUrl
       * This is not required for our app, coz for us login page is not a seperate URL
       * It's simply rendered if the user is not logged in
       */
      return false;
    }
  }
};

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(fb.auth());

@observer
export class LoginWidget extends Widget<any> {
  //
  componentDidMount() {
    ui.start('#firebaseui', uiConfig);
  }

  render() {
    return (
      <div id="auth-widget">
        <IntroWidget />
        <div id="firebaseui"></div>
      </div>
    );
  }
}
