import { StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from '../alert/Alert';
import { StoreCardWidget } from './StoreCardWidget';

type Props = {
  storeIds: StoreId[];
};

@observer
export class StoreListWidget extends React.Component<Props> {
  //
  render() {
    const { storeIds: list } = this.props;

    if (!list.length) {
      return <Alert type="info">List is empty</Alert>;
    }

    return (
      <div className="store-list">
        {list.map(id => (
          <StoreCardWidget key={id.storeId} {...id} />
        ))}
      </div>
    );
  }
}
