import { Schema } from 'nutso';
import { ModifierSpec } from '../../models/item_spec/ModifierSpec';

export const modifiersSpecSchema: Schema<ModifierSpec[]> = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: {
        type: 'string'
      },
      name: {
        type: 'string',
        minLength: 1,
        maxLength: 32
      },
      min: {
        type: 'number',
        min: 0,
        max: 10
      },
      max: {
        type: 'number',
        min: 0,
        max: 10
      },
      options: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string'
            },
            name: {
              type: 'string',
              minLength: 2,
              maxLength: 32
            },
            extraCharge: {
              type: 'number'
            }
          }
        }
      }
    }
  }
};
