import { aspectRatioList, aspectRatioMap, ButtonType, defaults, routes, StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { CategoryListWidget } from '../category/CategoryListWidget';
import { Button } from '../elements/Button';
import { Page } from '../elements/Page';
import { Form } from '../forms/Form';
import { FormSingleSelect } from '../forms/FormSingleSelect';
import { userProvider } from '../provider/userProvider';
import { repo } from '../repo/repo';
import { gotoPath } from '../utils/browserHistory';
import { validationUtils } from '../utils/validationUtils';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

@observer
export class MenuPage extends Page<Props, RouteProps> {
  //
  $menu = repo.menu.bind(this.props.match.params, defaults.menu);

  getTitle(): string {
    return 'Menu';
  }

  renderActions(): React.ReactNode {
    return (
      <Button
        icon="la-plus-circle"
        type={ButtonType.primary}
        onClick={() => gotoPath(routes.backoffice.createCategory.link({ ...this.props.match.params }, {}))}
        dataCy="menu-create-category"
      >
        Create Category
      </Button>
    );
  }

  getAdditionalClassNames() {
    return 'backoffice-menu-page';
  }

  renderBody(): React.ReactNode {
    const user = userProvider.user;
    const menu = this.$menu.current();
    if (!user || !menu) return <span></span>;

    return (
      <>
        <Form validationResult={validationUtils.success} type="default" dataCy="menu-main">
          <FormSingleSelect
            label="Aspect Ratio"
            options={aspectRatioList}
            optionKey={aspectRatio => aspectRatio.id}
            optionLabel={aspectRatio => aspectRatio.label}
            value={aspectRatioMap[menu.categoryImageAspectRatio]}
            onChange={value => repo.menu.update(this.props.match.params, { categoryImageAspectRatio: value.id })}
            validation={validationUtils.success}
            dataCy="menu-aspect-ratio"
          />
        </Form>
        <CategoryListWidget {...this.props.match.params} />
      </>
    );
  }
}
