import { IPromiseBasedObservable } from 'mobx-utils';

export const promiseValue = <T>(promise: IPromiseBasedObservable<T> | null | undefined) => {
  if (!promise) return null;
  switch (promise.state) {
    case 'pending':
      return;
    case 'rejected':
      console.error(`Promise rejected ${promise}`);
      return;
  }
  return promise.value;
};
