import {
  CollectionType,
  collectionTypeLabel,
  DateRangePreset,
  dateRangePresetLabels,
  dateRangePresetList,
  dateUtils,
  formatAmount,
  Order,
  OrderStatusCode,
  PaymentType,
  range,
  StoreId,
  toTimeRange
} from '@restoplus/core';
import { DateTime } from 'luxon';
import { computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { CSVLink } from 'react-csv';
import { Badge } from '../../elements/Badge';
import { Page } from '../../elements/Page';
import { Spin } from '../../elements/Spin';
import { StatusIndicator } from '../../elements/StatusIndicator';
import { Form } from '../../forms/Form';
import { FormSingleSelect } from '../../forms/FormSingleSelect';
import { badgeType, orderStatusLabels } from '../../orders/OrderListWidget';
import { restaurantProvider } from '../../provider/restaurantProvider';
import { storeProvider } from '../../provider/storeProvider';
import { userProvider } from '../../provider/userProvider';
import { repo } from '../../repo/repo';
import { validationUtils } from '../../utils/validationUtils';
import { OrderProcessButtons } from '../widgets/OrderProcessButtons';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

type Stat = { count: number; grandTotal: number };
type Stats = { overall: Stat; pickup: Stat; delivery: Stat; dineIn: Stat; cash: Stat; creditCard: Stat };

type Month = {
  label: string;
  isoMonth: string;
};

const MONTH_LABEL = 'MMM, yyyy';

const previousMonths = (): Month[] => {
  const _range = range(0, 15); // get 0,1,2..14 (15 months)
  return _range.map(offset => {
    const month = dateUtils.now().minus({ month: offset });
    return {
      label: month.toFormat(MONTH_LABEL),
      isoMonth: month.toFormat(dateUtils.ISO_MONTH)
    };
  });
};

type CsvRow = {
  orderTime: string;
  restaurant: string;
  store: string;
  mobile: string;
  collectionType: string;
  paymentType: string;
  paymentStatus: string;
  deliveryCharges: number;
  grandTotal: number;
  couponCode: string;
};

const Statistic = (props: { title: string; value: string | number; orderCount?: string; className: string }) => {
  return (
    <div className={`statistic ${props.className}`}>
      <div className="title">{props.title}</div>
      <div className="value">
        <div className="prefix">{props.value}</div>
        {props.orderCount && <div className="suffix">{props.orderCount} orders</div>}
      </div>
    </div>
  );
};

@observer
export class OrderHistoryPage extends Page<Props, RouteProps> {
  //
  @observable range: DateRangePreset = DateRangePreset.today;
  @observable month: Month = {
    isoMonth: `${dateUtils.now().toFormat(dateUtils.ISO_MONTH)}`,
    label: dateUtils.now().toFormat(MONTH_LABEL)
  };

  getAdditionalClassNames() {
    return `order-history-page`;
  }

  @computed
  get timeRange() {
    return toTimeRange(
      toJS(this.range),
      toJS(this.month.isoMonth),
      restaurantProvider.$restaurant?.current()?.timezone
    );
  }

  @computed
  get $orders() {
    const { startTime, endTime } = this.timeRange;
    return repo.orders.bindCollectionQuery({
      key: this.props.match.params,
      where: ['placedTime', '>', startTime],
      where2: ['placedTime', '<', endTime],
      orderByDirection: 'desc',
      orderByField: 'placedTime'
    });
  }

  @computed
  get acceptedOrders() {
    const orders = this.$orders.current();
    if (!orders) return [];
    // only 'accepted' and 'completed' orders
    return orders.filter(order => [OrderStatusCode.accepted, OrderStatusCode.completed].includes(order.status.code));
  }

  @computed
  get stats(): Stats {
    const acceptedOrders = this.acceptedOrders;
    const count = acceptedOrders.length;
    const grandTotal = acceptedOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    const creditCardOrders = acceptedOrders.filter(order => order.cart.paymentType === PaymentType.card);
    const creditCardOrderCount = creditCardOrders.length;
    const creditCardOrderGrandTotal = creditCardOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    const cashOrders = acceptedOrders.filter(order => order.cart.paymentType === PaymentType.cash);
    const cashOrderCount = cashOrders.length;
    const cashOrderGrandTotal = cashOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    const pickupOrders = acceptedOrders.filter(order => order.cart.collectionType === CollectionType.pickup);
    const pickupOrderCount = pickupOrders.length;
    const pickupOrderGrandTotal = pickupOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    const deliveryOrders = acceptedOrders.filter(order => order.cart.collectionType === CollectionType.delivery);
    const deliveryOrderCount = deliveryOrders.length;
    const deliveryOrderGrandTotal = deliveryOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    const dineInOrders = acceptedOrders.filter(order => order.cart.collectionType === CollectionType.dineIn);
    const dineInOrderCount = dineInOrders.length;
    const dineInOrderGrandTotal = dineInOrders.reduce((total, order) => total + order.cart.grandTotal, 0);

    return {
      overall: {
        count,
        grandTotal
      },
      pickup: {
        count: pickupOrderCount,
        grandTotal: pickupOrderGrandTotal
      },
      delivery: {
        count: deliveryOrderCount,
        grandTotal: deliveryOrderGrandTotal
      },
      dineIn: {
        count: dineInOrderCount,
        grandTotal: dineInOrderGrandTotal
      },
      cash: {
        count: cashOrderCount,
        grandTotal: cashOrderGrandTotal
      },
      creditCard: {
        count: creditCardOrderCount,
        grandTotal: creditCardOrderGrandTotal
      }
    };
  }

  getTitle(): string {
    return 'Stores';
  }

  renderActions(): React.ReactNode {
    return <>{this.renderDownloadCsv()}</>;
  }

  /**
   * When this component is mounted, it will trigger a download
   * So the setTimeout will unmount it, such that the download will happen if the user clicks download again.
   */
  renderDownloadCsv = () => {
    const headers = [
      { label: 'Order Time', key: 'orderTime' },
      { label: 'Restaurant', key: 'restaurant' },
      { label: 'Store', key: 'store' },
      { label: 'Mobile', key: 'mobile' },
      { label: 'Collection', key: 'collectionType' },
      { label: 'Payment', key: 'paymentType' },
      { label: 'Payment Status', key: 'paymentStatus' },
      { label: 'Delivery Charges', key: 'deliveryCharges' },
      { label: 'Grand Total', key: 'grandTotal' },
      { label: 'Coupon', key: 'couponCode' },
      { label: 'Previous Orders', key: 'previousOrderCount' }
    ];

    const restaurant = restaurantProvider.$restaurant?.current()?.name || '';
    const store = storeProvider.$store?.current()?.name || '';

    const data = this.acceptedOrders.map<CsvRow>(order => {
      return {
        orderTime: DateTime.fromISO(order.placedTime).toLocaleString(DateTime.DATETIME_MED),
        restaurant,
        store,
        mobile: order.customer.mobile,
        collectionType: order.cart.collectionType || '',
        paymentType: order.cart.paymentType,
        paymentStatus: order.payment?.status ?? '',
        deliveryCharges: order.cart.delivery?.charges ?? 0,
        grandTotal: order.cart.grandTotal,
        previousOrderCount: order.customer.previousOrderCount,
        couponCode: order.cart.promotion?.coupon ?? ''
      };
    });

    return (
      <>
        <CSVLink data={data} headers={headers} filename={'orders.csv'}>
          <button className="primary">
            <span className="label">Download</span>
          </button>
        </CSVLink>
      </>
    );
  };

  renderFilters(): React.ReactNode {
    return (
      <div>
        <Form validationResult={validationUtils.success} type="horizontal">
          <FormSingleSelect
            label="Time Range"
            options={dateRangePresetList}
            value={this.range}
            onChange={value => (this.range = value)}
            optionKey={option => option}
            optionLabel={option => dateRangePresetLabels[option]}
            validation={validationUtils.success}
          />
          {this.range == DateRangePreset.yearMonth && (
            <FormSingleSelect
              label="Month"
              options={previousMonths()}
              value={this.month}
              onChange={value => (this.month = value)}
              optionKey={option => option.isoMonth}
              optionLabel={option => option.label}
              validation={validationUtils.success}
            />
          )}
        </Form>
        <div className="date-range">
          {DateTime.fromISO(this.timeRange.startTime).toFormat('ccc, dd MMM, hh:mma')}&nbsp;~&nbsp;
          {DateTime.fromISO(this.timeRange.endTime).toFormat('ccc, dd MMM, hh:mma')}
        </div>
      </div>
    );
  }

  renderSummary = () => {
    const summary = this.stats;
    return (
      <div className="summary">
        <Statistic title="Orders" value={summary.overall.count} className="orders" />
        <Statistic
          title="Grand Total"
          value={formatAmount(summary.overall.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          className="grand-total"
        />
        <Statistic
          title="Cash"
          value={formatAmount(summary.cash.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          orderCount={`${summary.cash.count}`}
          className="cash"
        />
        <Statistic
          title="Credit Card"
          value={formatAmount(summary.creditCard.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          orderCount={`${summary.creditCard.count}`}
          className="credit-card"
        />
        <Statistic
          title="Pickup"
          value={formatAmount(summary.pickup.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          orderCount={`${summary.pickup.count}`}
          className="pickup"
        />
        <Statistic
          title="Delivery"
          value={formatAmount(summary.delivery.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          orderCount={`${summary.delivery.count}`}
          className="delivery"
        />
        <Statistic
          title="DineIn"
          value={formatAmount(summary.dineIn.grandTotal, restaurantProvider.$restaurant?.current()?.country)}
          orderCount={`${summary.dineIn.count}`}
          className="dine-in"
        />
      </div>
    );
  };

  renderBody(): React.ReactNode {
    const user = userProvider.user;
    if (!user) return <span></span>;
    return (
      <div>
        {this.renderFilters()}
        {this.renderSummary()}
        {this.renderOrders()}
      </div>
    );
  }

  getIcon = (order: Order): { icon: string; type: 'success' | 'failure' | 'warning' | 'primary' } => {
    switch (order.status.code) {
      case OrderStatusCode.processingPayment:
        return { icon: 'la-credit-card', type: 'warning' };
      case OrderStatusCode.new:
        return { icon: 'la-check', type: 'primary' };
      case OrderStatusCode.failure:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.accepted:
        return { icon: 'la-check', type: 'success' };
      case OrderStatusCode.rejected:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.cancelled:
        return { icon: 'la-times', type: 'failure' };
      case OrderStatusCode.completed:
        return { icon: 'la-check-double', type: 'success' };
      default:
        console.error(`Unexpected order status ${order.status}`);
        return { icon: 'la-question', type: 'warning' };
    }
  };

  getMessage = (order: Order) => {
    switch (order.status.code) {
      case OrderStatusCode.processingPayment: {
        return 'Payment in progress. Please wait ...';
      }
      case OrderStatusCode.new: {
        return 'Your order has been sent to the store. Please wait for confirmation email or check status after sometime.';
      }
      case OrderStatusCode.failure: {
        return `Failure. ${order.status.message}`;
      }
      case OrderStatusCode.accepted:
        const eta = order.processing?.eta;
        return eta
          ? `Your order has been accepted. The estimated time is approximately ${eta}`
          : 'Your order has been accepted by the store. ';
      case OrderStatusCode.rejected:
        return 'Your order has been rejected by the store.';
      case OrderStatusCode.cancelled:
        return 'You have cancelled the order.';
      case OrderStatusCode.completed:
        return 'The order has been completed successfully.';
      default: {
        return `Unexpected order status`;
      }
    }
  };

  renderOrder(order: Order): React.ReactNode {
    const collectionType = collectionTypeLabel[order.cart.collectionType];
    const orderPlacedTime = dateUtils.timeLabel({ time: order.placedTime });
    const grandTotal = formatAmount(order.cart.grandTotal, restaurantProvider.$restaurant?.current()?.country);
    const message = this.getMessage(order);
    const icon = this.getIcon(order);

    return (
      <div className="order-list-view" key={order.id}>
        <StatusIndicator type={icon.type} icon={icon.icon} />
        <div className="collection-type">{collectionType}</div>
        <div className="time">{orderPlacedTime}</div>
        <Badge className="status" type={badgeType[order.status.code]}>
          {orderStatusLabels[order.status.code]}
        </Badge>
        <div className="grand-total">{grandTotal}</div>
        <div className="message">{message}</div>
        <OrderProcessButtons order={order} />
      </div>
    );
  }

  renderOrders(): React.ReactNode {
    const orders = this.$orders?.current();
    if (orders === undefined) {
      return <Spin spinning={true} />;
    }
    if (!orders.length) return <div>Orders empty</div>;
    return <div className="list">{orders.map(order => this.renderOrder(order))}</div>;
  }
}
