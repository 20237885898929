import { CategoryId } from '@restoplus/core';
import { Page } from '../elements/Page';
import React = require('react');
import { DealSpecEditWidget } from './DealSpecEditWidget';

type Props = {};
type RouteProps = CategoryId & {};

export class DealSpecCreatePage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Create Deal';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return (
      <DealSpecEditWidget
        documentId={{ ...this.props.match.params, itemId: '' }}
        categoryId={this.props.match.params.categoryId}
        create={true}
      />
    );
  }
}
