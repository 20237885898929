import { CategoryId, ItemId } from '@restoplus/core';
import { Page } from '../elements/Page';
import React = require('react');
import { DealSpecEditWidget } from './DealSpecEditWidget';

type Props = {};
type RouteProps = CategoryId & ItemId & {};

export class DealSpecEditPage extends Page<Props, RouteProps> {
  getTitle(): string {
    return 'Edit Deal';
  }
  renderActions(): React.ReactNode {
    return <span></span>;
  }
  renderBody(): React.ReactNode {
    return (
      <DealSpecEditWidget
        documentId={this.props.match.params}
        categoryId={this.props.match.params.categoryId}
        create={false}
      />
    );
  }
}
