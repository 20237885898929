import React = require('react');
import { Result } from 'nutso';

export type NumberInputProps = {
  value: number;
  onChange: (value: number) => void;
  validation: Result<number>;
  disabled?: boolean;
  dataCy?: string;
};

export const NumberInput = (props: NumberInputProps) => {
  return (
    <input
      type="number"
      value={`${props.value || 0}`}
      onChange={e => props.onChange(e.target.valueAsNumber)}
      className={props.validation.isValid ? 'valid' : 'invalid'}
      disabled={props.disabled}
      data-cy={props.dataCy}
    />
  );
};
