import { observable } from 'mobx';
import { ToastInfo, ToastOptions, ToastType } from './ToastInfo';
import { idFactory } from '@restoplus/core';

class ToastStore {
  @observable toasts: ToastInfo[] = [];

  info = (message: string, options: ToastOptions = {}) => {
    this.add({ message, type: 'info', options });
  };

  success = (message: string, options: ToastOptions = {}) => {
    this.add({ message, type: 'success' });
  };

  warning = (message: string, options: ToastOptions = {}) => {
    this.add({ message, type: 'warning' });
  };

  error = (message: string, options: ToastOptions = {}) => {
    this.add({ message, type: 'error' });
  };

  loading = (message: string, options: ToastOptions = {}) => {
    return this.add({ message, type: 'loading' });
  };

  remove = (id: string) => {
    this.toasts = this.toasts.filter(e => e.id !== id);
  };

  private add = (args: { message: string; type: ToastType; options?: ToastOptions }) => {
    const { message, type } = args;
    // create id
    const id = idFactory.id();
    // add to list
    this.toasts.push({ message, type, id });
    // calling this function will remove this item from the list
    const removeFn = () => {
      this.remove(id);
    };

    if (type !== 'loading') {
      setTimeout(removeFn, 5000);
    }

    return removeFn;
  };
}

export const toastStore = new ToastStore();
