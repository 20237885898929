import { Schema } from 'nutso';
import { DeliverySettings } from '../models/order/DeliverySettings';

export const deliverySettingsSchema: Schema<DeliverySettings> = {
  type: 'object',
  properties: {
    minOrderValue: {
      type: 'number'
    },
    suburbs: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          name: {
            type: 'string',
            maxLength: 15
          },
          postcode: {
            type: 'string',
            optional: true,
            maxLength: 10
          },
          deliveryCharges: {
            type: 'number'
          },
          minOrderValue: {
            type: 'number',
            optional: true
          }
        }
      }
    }
  }
};
