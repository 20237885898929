import { StoreId } from '@restoplus/core';
import { observer } from 'mobx-react';
import { Alert } from '../../alert/Alert';
import { Page } from '../../elements/Page';
import React = require('react');

type Props = {};
type RouteProps = StoreId & {};

@observer
export class CouponsPage extends Page<Props, RouteProps> {
  //

  getTitle(): string {
    return 'Coupons';
  }

  renderActions(): React.ReactNode {
    return <span></span>;
  }

  renderBody(): React.ReactNode {
    return <Alert type="info">Coming soon!</Alert>;
  }
}
