import { Schema } from 'nutso';
import { RestaurantWebsiteInfo } from '../models/website/RestaurantWebsiteInfo';

export const restaurantWebsiteInfoSchema: Schema<RestaurantWebsiteInfo> = {
  type: 'object',
  properties: {
    heros: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          image: {
            type: 'object',
            properties: {
              id: {
                type: 'string'
              },
              path: {
                type: 'string'
              }
            }
          },
          description: {
            type: 'string',
            maxLength: 140
          },
          title: {
            type: 'string',
            maxLength: 24
          }
        }
      }
    },
    photos: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          path: {
            type: 'string'
          }
        }
      }
    },
    about: {
      type: 'string',
      optional: true,
      maxLength: 400
    }
  }
};
