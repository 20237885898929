import { ModifierSpec } from '@restoplus/core';

const modifierSpec: ModifierSpec = {
  min: 0,
  max: 1,
  id: 'one',
  name: 'Choose Syrup',
  options: [
    {
      id: 'one',
      name: 'Cinnamon',
      extraCharge: 0
    },
    {
      id: 'two',
      name: 'Strawberry',
      extraCharge: 0
    },
    {
      id: 'three',
      name: 'Blueberry',
      extraCharge: 2
    },
    {
      id: 'four',
      name: 'Apricot',
      extraCharge: 3
    },
    {
      id: 'five',
      name: 'Alolan Pinap',
      extraCharge: 3
    }
  ]
};

/**
 * @type {ModifierSpec} - Calling this with empty parameters gives a modifier with:
 *  * Minimum 0 and maximum 1 selection.
 *  * Five options provided, the last three with an extraCharge parameter
 *
 * @param {Partial<ModifierSpec>} partial - overriding values to the modifier spec
 **/
export const createDummyModifierSpec = (partial: Partial<ModifierSpec>): ModifierSpec => {
  return {
    ...modifierSpec,
    ...partial
  };
};
