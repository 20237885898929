import { defaults, StoreId, StorePrinter, storePrinterSchema } from '@restoplus/core';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { Schema } from 'nutso';
import { Alert } from '../alert/Alert';
import { StaticDocumentEditWidget } from '../document/StaticDocumentEditWidget';
import { Form } from '../forms/Form';
import { FormItemContainer } from '../forms/FormItemContainer';
import { FormTextInput } from '../forms/FormTextInput';
import { DocumentRepo } from '../repo/DocumentRepo';
import { repo } from '../repo/repo';
import { validationUtils } from '../utils/validationUtils';
import React = require('react');

@observer
export class StorePrinterEditWidget extends StaticDocumentEditWidget<StoreId, StorePrinter> {
  //
  name(): string {
    return 'Printer Settings';
  }
  defaultDocument(): StorePrinter {
    return {
      restaurantId: this.props.documentId.restaurantId,
      storeId: this.props.documentId.storeId
    };
  }
  getSchema(): Schema<StorePrinter> {
    return storePrinterSchema;
  }

  getRepo(): DocumentRepo<StoreId, StorePrinter> {
    return repo.storePrinter;
  }
  nextRoute(): string {
    return '';
  }

  className() {
    return 'store-printer-edit-widget';
  }

  @computed
  get printer() {
    return repo.printer.bind({ printerId: this.document.printerId || '' });
  }

  form(storePrinter: StorePrinter): JSX.Element {
    return (
      <>
        <div className="title">Printer</div>
        <Form type="default" submit={{ label: 'Save', handler: this.submit }} validationResult={this.validation}>
          <FormTextInput
            label="Printer ID"
            value={this.document.printerId || ''}
            onChange={value => (this.document.printerId = value)}
            validation={this.validation.properties.printerId || validationUtils.success}
            helpText="The ID of the printer that will be used to print the order."
          />
          {this.renderPrinterStatus()}
        </Form>
      </>
    );
  }

  renderPrinterStatus(): React.ReactNode {
    if (!this.document.printerId || !this.validation.properties.printerId?.isValid) return;
    const printer = this.printer.current();
    if (!printer) {
      return (
        <FormItemContainer label="" validation={validationUtils.success}>
          <Alert type="error">Cannot find printer with id {this.document.printerId}.</Alert>
        </FormItemContainer>
      );
    }
    return (
      <FormItemContainer label="" validation={validationUtils.success}>
        <Alert type="success">
          Connected to <b>{printer.name}</b>.
        </Alert>
      </FormItemContainer>
    );
  }
}
