import { IPromiseBasedObservable } from 'mobx-utils';
import React = require('react');

export const promiseView = (promise: IPromiseBasedObservable<any>, message: string) => {
  switch (promise.state) {
    case 'pending':
      return;
    case 'rejected':
      return <div>{message}</div>;
    case 'fulfilled':
      return;
  }
};
