import { Schema } from 'nutso';
import { Promotion } from '..';
import { PromotionType } from '../models/promotion/PromotionType';

export const promotionSchema: Schema<Promotion> = {
  type: 'object',
  properties: {
    id: {
      type: 'string'
    },
    coupon: {
      type: 'string'
    },
    startDate: {
      type: 'string'
    },
    endDate: {
      type: 'string'
    },
    dows: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    description: {
      type: 'string'
    },
    type: {
      type: 'string'
    },
    maxRedeems: {
      type: 'number'
    },
    exclusive: {
      type: 'boolean'
    },
    collectionType: {
      type: 'string',
      optional: true
    },
    firstOrderDiscount: {
      type: 'boolean'
    },
    applicableOverAmount: {
      type: 'number'
    },
    discountAmount: {
      type: 'number',
      optional: promotion => promotion.type !== PromotionType.FLAT_AMOUNT_DISCOUNT
    },
    discountPercentage: {
      type: 'number',
      optional: promotion => promotion.type !== PromotionType.FLAT_PERCENTAGE_DISCOUNT
    }
  }
};
