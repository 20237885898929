import { ButtonType, StoreId, StripeAccount, countries, nth, PayoutSchedule, dowList } from '@restoplus/core';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { CreateTokenBankAccountData, Stripe, StripeElements } from '@stripe/stripe-js';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import { Schema, validate } from 'nutso';
import { Button } from '../../elements/Button';
import { Page } from '../../elements/Page';
import { Form } from '../../forms/Form';
import { FormInlineTitle } from '../../forms/FormInlineTitle';
import { FormKeyValuePair } from '../../forms/FormKeyValuePair';
import { FormSingleSelect } from '../../forms/FormSingleSelect';
import { FormTextInput } from '../../forms/FormTextInput';
import { $stripe } from '../../integration/stripe';
import { userProvider } from '../../provider/userProvider';
import { promiseValue } from '../../utils/promiseValue';
import { promiseView } from '../../utils/promiseView';
import { remoteService } from '../../utils/remoteService';
import { validationUtils } from '../../utils/validationUtils';
import React = require('react');
import { restaurantProvider } from '../../provider/restaurantProvider';
import { asyncOperation } from '../../utils/asyncOperation';
import { FormNumberInput } from '../../forms/FormNumberInput';

const exclamationMark = { name: 'la-exclamation-triangle', type: 'failure' as const };

const bankAccountDetailsSchema: Schema<CreateTokenBankAccountData> = {
  type: 'object',
  properties: {
    account_holder_name: {
      type: 'string'
    },
    account_holder_type: {
      type: 'string',
      pattern: /(individual)|(company)/
    },
    account_number: {
      type: 'string'
    },
    country: {
      type: 'string'
    },
    currency: {
      type: 'string'
    },
    routing_number: {
      type: 'string',
      optional: true
    }
  }
};

const payoutScheduleSchema: Schema<PayoutSchedule> = {
  type: 'object',
  properties: {
    delay_days: {
      type: 'string',
      optional: true,
      pattern: /(minimum)/
    },
    interval: {
      type: 'string',
      pattern: /(daily)|(weekly)|(monthly)/
    },
    weekly_anchor: {
      type: 'string',
      optional: true,
      pattern: /(monday)|(tuesday)|(wednesday)|(thurdsay)|(friday)|(saturday)|(sunday)/
    },
    monthly_anchor: {
      type: 'number',
      optional: true,
      min: 1,
      max: 31
    }
  }
};

@observer
export class PaymentsPage extends Page<{}, StoreId> {
  //
  @observable
  $connectedAccount = fromPromise(remoteService.getActiveStripeAccount(this.props.match.params));

  @observable
  bankAccountDetails: CreateTokenBankAccountData = {
    account_holder_name: '',
    account_holder_type: '',
    account_number: '',
    routing_number: '',
    country: '',
    currency: ''
  };

  @observable
  payoutSchedule: PayoutSchedule = {
    delay_days: 'minimum',
    interval: ''
  };

  @observable
  showUpdateBankAccount = false;
  @observable
  showUpdateSchedule = false;

  @observable
  savingBankAccount = false;

  @observable
  updatingPayoutSchedule = false;

  @computed
  get bankAccountDetailsValidation() {
    return validate(this.bankAccountDetails, bankAccountDetailsSchema);
  }

  @computed
  get payoutScheduleValidation() {
    return validate(this.payoutSchedule, payoutScheduleSchema);
  }

  @computed
  get derivedPayoutSchedule(): PayoutSchedule {
    switch (this.payoutSchedule.interval) {
      case 'daily': {
        const { interval } = this.payoutSchedule;
        return { interval, delay_days: 'minimum' };
      }
      case 'weekly': {
        const { interval, weekly_anchor } = this.payoutSchedule;
        return { interval, weekly_anchor };
      }
      case 'monthly': {
        const { interval, monthly_anchor } = this.payoutSchedule;
        return { interval, monthly_anchor };
      }
    }
    return { interval: 'daily' };
  }

  onPageEnter() {
    this.whenResourceAvailableWithValue(restaurantProvider.$restaurant!, restaurant => {
      this.bankAccountDetails.country = restaurant!.country;
      this.bankAccountDetails.currency = countries[restaurant!.country].currencyCode;
    });
  }

  // auBankAccount?: StripeAuBankAccountElementChangeEvent;

  // get stripe account
  getTitle(): string {
    return 'Payments';
  }
  renderActions(): React.ReactNode {
    if (!userProvider.isSuperAdmin) return;
    return (
      <Button icon="la-archive" onClick={() => this.onArchiveStripeAccount()} type={ButtonType.failure}>
        Archive Account
      </Button>
    );
  }
  renderBody(): React.ReactNode {
    const stripeAccount = promiseValue(this.$connectedAccount);
    if (!stripeAccount)
      return promiseView(this.$connectedAccount, 'Cannot find connected account. Contact support@restoplus.com.au');
    return (
      <>
        {this.renderStripeAccount(stripeAccount)}
        {this.renderExternalAccount(stripeAccount)}
        {/* <pre>{JSON.stringify(stripeAccount, null, 2)}</pre> */}
      </>
    );
  }

  renderExternalAccount = (stripeAccount: StripeAccount) => {
    return (
      <div className="wrapper">
        {this.renderCurrentExternalAccount(stripeAccount)}
        {this.renderAddExternalAccount(stripeAccount)}
        {this.renderPayoutSchedule(stripeAccount)}
        {this.renderUpdatePayoutSchedule(stripeAccount)}
      </div>
    );
  };

  renderPayoutSchedule(stripeAccount: StripeAccount): React.ReactNode {
    const schedule = stripeAccount.settings?.payouts?.schedule;
    if (!schedule || this.showUpdateSchedule) return;
    return (
      <div className="wrapper">
        <div className="payout-schedule">
          <div className="title">Payout Schedule</div>
          <Form type="default" validationResult={validationUtils.success}>
            <FormKeyValuePair
              label="Payout Frequency"
              value={schedule.interval ?? ''}
              helpText={`The funds will be paid to your bank account ${schedule.interval}`}
            />

            {schedule.interval === 'daily' && (
              <FormKeyValuePair
                label="Interval"
                value={`${schedule.delay_days}` ?? ''}
                helpText={`Payouts are made daily and contain payments processed ${schedule.interval} business days prior. i.e. ${schedule.interval} days rolling basis.`}
              />
            )}
            {schedule.interval === 'weekly' && (
              <FormKeyValuePair
                label="Payout Day"
                value={schedule.weekly_anchor ?? ''}
                helpText={`The funds will be paid to your bank account every ${schedule.weekly_anchor}`}
              />
            )}
            {schedule.interval === 'monthly' && (
              <FormKeyValuePair
                label="Payout Day"
                value={`${schedule.monthly_anchor!}${nth(schedule.monthly_anchor!)}`}
                helpText={`The funds will be paid to your bank account on ${schedule.monthly_anchor!}${nth(
                  schedule.monthly_anchor!
                )} of every month`}
              />
            )}
          </Form>
          <div className="actions">
            <Button onClick={() => (this.showUpdateSchedule = true)} type={ButtonType.primary}>
              Update Schedule
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderUpdatePayoutSchedule = (stripeAccount: StripeAccount): React.ReactNode => {
    if (!this.showUpdateSchedule) return;
    const validation = this.payoutScheduleValidation;
    return (
      <div className="update-payout-schedule">
        <div className="title">Update Payout Schedule</div>
        <Form type="default" validationResult={validationUtils.success}>
          <FormSingleSelect
            label="Frequency"
            options={['daily', 'weekly', 'monthly']}
            value={this.payoutSchedule.interval}
            onChange={value => (this.payoutSchedule.interval = value)}
            optionKey={option => option}
            optionLabel={option => option}
            validation={validation.properties.interval}
          />
          {this.payoutSchedule.interval === 'weekly' && (
            <FormSingleSelect
              label="Payout Day"
              options={dowList}
              value={this.payoutSchedule.weekly_anchor ?? ''}
              onChange={value => (this.payoutSchedule.weekly_anchor = value)}
              optionKey={option => option}
              optionLabel={option => option}
              validation={validation.properties.weekly_anchor || validationUtils.success}
            />
          )}
          {this.payoutSchedule.interval === 'monthly' && (
            <FormNumberInput
              label="Payout Day"
              value={this.payoutSchedule.monthly_anchor ?? 1}
              onChange={value => (this.payoutSchedule.monthly_anchor = value)}
              validation={validation.properties.monthly_anchor || validationUtils.success}
            />
          )}
        </Form>
        <div className="actions">
          <Button
            busy={this.updatingPayoutSchedule}
            onClick={() => this.onUpdatePayoutSchedule()}
            type={ButtonType.primary}
            validation={this.payoutScheduleValidation}
          >
            Update Schedule
          </Button>
          <a className="cancel" onClick={() => (this.showUpdateSchedule = false)}>
            Cancel
          </a>
        </div>
      </div>
    );
  };

  async onUpdatePayoutSchedule() {
    //
    const payoutSchedule = this.derivedPayoutSchedule;
    await asyncOperation({
      fn: () => remoteService.setPayoutSchedule({ storeId: this.props.match.params, payoutSchedule }),
      loadingMessage: 'Saving payout schedule',
      setBusyFlag: flag => this.updatingPayoutSchedule
    });
    this.$connectedAccount = fromPromise(remoteService.getActiveStripeAccount(this.props.match.params));
    this.showUpdateSchedule = false;
  }

  renderCurrentExternalAccount = (stripeAccount: StripeAccount): React.ReactNode => {
    const externalAccount = stripeAccount.external_accounts?.data[0];
    if (this.showUpdateBankAccount || !externalAccount) return;
    if (externalAccount.object !== 'bank_account') {
      alert(`Unsupported external account type`);
      return;
    }
    return (
      <div className="wrapper">
        <div className="current-external-account">
          <div className="title">Bank Account</div>
          <Form type="default" validationResult={validationUtils.success}>
            <FormKeyValuePair label="Account Holder Name" value={externalAccount.account_holder_name ?? ''} />
            <FormKeyValuePair label="Account Type" value={externalAccount.account_holder_type ?? ''} />
            <FormKeyValuePair label="Bank Name" value={externalAccount.bank_name ?? ''} />
            {externalAccount.routing_number && (
              <FormKeyValuePair
                label="Routing Number"
                value={externalAccount.routing_number}
                helpText="Optional in some countries. In Australia it's also known as BSB."
              />
            )}
            <FormKeyValuePair label="Account Last4 Digits" value={externalAccount.last4 ?? ''} />
          </Form>
          <div className="actions">
            <Button onClick={() => (this.showUpdateBankAccount = true)} type={ButtonType.primary}>
              Update Account
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderAddExternalAccount = (stripeAccount: StripeAccount): React.ReactNode => {
    const externalAccount = stripeAccount.external_accounts?.data[0];
    if (!(this.showUpdateBankAccount || !externalAccount)) return;
    return (
      <div className="add-external-account">
        <div className="title">Update Bank Account</div>
        <Form type="default" validationResult={validationUtils.success}>
          <FormTextInput
            label="Account Holder Name"
            value={this.bankAccountDetails.account_holder_name}
            onChange={value => (this.bankAccountDetails.account_holder_name = value)}
            validation={this.bankAccountDetailsValidation.properties.account_holder_name}
          />
          <FormTextInput
            label="Routing Number (BSB)"
            value={this.bankAccountDetails.routing_number || ''}
            onChange={value => (this.bankAccountDetails.routing_number = value)}
            validation={this.bankAccountDetailsValidation.properties.routing_number || validationUtils.success}
            helpText="Optional, depending on the country you are located. For example, it's required for Australian Bank Accounts."
          />
          <FormTextInput
            label="Account Number"
            value={this.bankAccountDetails.account_number}
            onChange={value => (this.bankAccountDetails.account_number = value)}
            validation={this.bankAccountDetailsValidation.properties.account_number}
          />
          <FormSingleSelect
            label="Account Type"
            value={this.bankAccountDetails.account_holder_type}
            onChange={value => (this.bankAccountDetails.account_holder_type = value)}
            validation={this.bankAccountDetailsValidation.properties.account_holder_type}
            options={['individual', 'company']}
            optionKey={option => option}
            optionLabel={option => option}
          />
        </Form>
        <div className="actions">
          <Elements stripe={$stripe}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Button
                  busy={this.savingBankAccount}
                  onClick={() => this.onSaveBankAccount(stripe, elements)}
                  type={ButtonType.primary}
                  disabled={!stripe || !this.bankAccountDetailsValidation.isValid}
                >
                  Save Account
                </Button>
              )}
            </ElementsConsumer>
          </Elements>
          {externalAccount && (
            <a className="cancel" onClick={() => (this.showUpdateBankAccount = false)}>
              Cancel
            </a>
          )}
        </div>
      </div>
    );
  };

  onSaveBankAccount = async (stripe: Stripe | null, elements: StripeElements | null) => {
    if (!stripe) {
      alert(`Stripe or Element is null`);
      return;
    }

    const result = await asyncOperation({
      fn: () => stripe.createToken('bank_account', this.bankAccountDetails),
      loadingMessage: `Creating bank account token`,
      setBusyFlag: busy => (this.savingBankAccount = busy)
    });

    if (result.error) {
      alert(result.error.message);
      return;
    }

    const token = result.token;
    if (!token) {
      alert(`Cannot find bank account token`);
      return;
    }

    await asyncOperation({
      fn: () => remoteService.setExternalAccount({ ...this.props.match.params, tokenId: token.id }),
      loadingMessage: `Saving bank account token`,
      setBusyFlag: busy => (this.savingBankAccount = busy)
    });

    this.$connectedAccount = fromPromise(
      asyncOperation({
        fn: () => remoteService.getActiveStripeAccount(this.props.match.params),
        loadingMessage: `Getting active stripe account`,
        setBusyFlag: busy => (this.savingBankAccount = busy)
      })
    );

    this.showUpdateBankAccount = false;
    // console.log(result);
  };

  onArchiveStripeAccount() {
    if (!confirm('Are you sure you want to archive the current stripe account and create a new one?')) return;
    remoteService.archiveStripeAccount(this.props.match.params).then(() => {
      alert('Created new connected account');
      this.$connectedAccount = fromPromise(remoteService.getActiveStripeAccount(this.props.match.params));
    });
  }

  renderStripeAccount(stripeAccount: StripeAccount): React.ReactNode {
    return (
      <div className="wrapper">
        <div className="connected-account">
          <div className="title">Stripe Connect Account</div>
          <Form type="default" validationResult={validationUtils.success}>
            <FormKeyValuePair
              label="Charges Enabled"
              value={stripeAccount.charges_enabled ? 'Yes' : 'No'}
              helpText="Indicates if your account can accept credit card payments."
              icon={stripeAccount.charges_enabled ? undefined : exclamationMark}
            />
            <FormKeyValuePair
              label="Payouts Enabled"
              value={stripeAccount.payouts_enabled ? 'Yes' : 'No'}
              helpText="Indicates if the payments received will be transferred to your bank account."
              icon={stripeAccount.payouts_enabled ? undefined : exclamationMark}
            />
            <FormKeyValuePair label="Business Type" value={stripeAccount.business_type || ''} />
            <FormKeyValuePair label="Website" value={stripeAccount.business_profile?.url || ''} />

            <FormInlineTitle label="Capabilities" />
            <FormKeyValuePair
              label="Transfers"
              value={stripeAccount.capabilities?.transfers ?? ''}
              helpText="Specifies the ability for the platform to make transfers to you."
              icon={stripeAccount.capabilities?.transfers === 'active' ? undefined : exclamationMark}
            />
            <FormKeyValuePair
              label="Card Payments"
              value={stripeAccount.capabilities?.card_payments ?? ''}
              helpText="Specifies the ability for your account to accept card payments."
              icon={stripeAccount.capabilities?.card_payments === 'active' ? undefined : exclamationMark}
            />
            {this.renderIndividual(stripeAccount)}
            {this.renderBusiness(stripeAccount)}
            {this.renderRequirements(stripeAccount)}
          </Form>
          <div className="actions">
            {this.renderVerifyAccount(stripeAccount)}
            {this.renderUpdateAccount(stripeAccount)}
          </div>
        </div>
      </div>
    );
  }
  renderUpdateAccount(connectedAccount: StripeAccount): React.ReactNode {
    return <button onClick={() => this.onUpdateStripeAccount()}>Update Account</button>;
  }

  renderVerifyAccount(connectedAccount: StripeAccount): React.ReactNode {
    switch (connectedAccount.business_type) {
      case 'individual':
        if (connectedAccount.individual?.verification?.status === 'verified') return;
    }
    return (
      <Button onClick={() => this.onVerifyStripeAccount()} type={ButtonType.primary}>
        Verify Account
      </Button>
    );
  }
  renderRequirements(connectedAccount: StripeAccount): React.ReactNode {
    const requirements = connectedAccount.requirements;
    if (!requirements) return;
    const pastDue = requirements.past_due;
    const currentlyDue = requirements.currently_due;
    const eventuallyDue = requirements.eventually_due;
    if (pastDue?.length || currentlyDue?.length || eventuallyDue?.length)
      return (
        <>
          <FormInlineTitle
            label="Requirements"
            helpText="Stripe is requesting the following information from you to continue processing payments on your behalf."
          />
          {this.renderRequirementsDues(connectedAccount)}
        </>
      );
    return;
  }

  renderRequirementsDues(connectedAccount: StripeAccount): React.ReactNode {
    const requirements = connectedAccount.requirements;
    if (!requirements) return;
    const pastDue = requirements.past_due;
    const currentlyDue = requirements.currently_due;
    const eventuallyDue = requirements.eventually_due;
    if (pastDue && pastDue.length) {
      return this.renderRequirementsDue('Past Due', pastDue);
    }

    if (currentlyDue && currentlyDue.length) {
      return this.renderRequirementsDue('Currently Due', currentlyDue);
    }

    if (eventuallyDue && eventuallyDue.length) {
      return this.renderRequirementsDue('Eventually Due', eventuallyDue);
    }
    return <div>Nothing is due</div>;
  }

  renderRequirementsDue(label: string, dues: string[]): React.ReactNode {
    if (!dues || !dues.length) return;
    return (
      <FormKeyValuePair
        label={label}
        value={dues.join(', ')}
        helpText={`This information is ${label.toLowerCase()} and has to be provided as soon as possible. Click on 'Verify Account' below. NOTE: if 'external_account' is due update your bank account.`}
        icon={exclamationMark}
      />
    );
  }

  renderIndividual(connectedAccount: StripeAccount): React.ReactNode {
    if (connectedAccount.business_type !== 'individual') return;
    const dob = connectedAccount.individual?.dob;
    const address = connectedAccount.individual?.address;
    return (
      <>
        <FormInlineTitle label="Individual Information" />
        <FormKeyValuePair label="First Name" value={connectedAccount.individual?.first_name ?? ''} />
        <FormKeyValuePair label="Last Name" value={connectedAccount.individual?.last_name ?? ''} />
        {dob && <FormKeyValuePair label="DOB" value={`${dob.day}/${dob.month}/${dob.year}`} />}
        {address && (
          <FormKeyValuePair
            label="Address"
            value={`${address.line1}, ${address.city}, ${address.state} - ${address.postal_code} `}
          />
        )}
        <FormKeyValuePair label="Email" value={connectedAccount.individual?.email ?? ''} />
        <FormKeyValuePair label="Phone" value={connectedAccount.individual?.phone ?? ''} />
        <FormKeyValuePair label="Verification Status" value={connectedAccount.individual?.verification?.status ?? ''} />
      </>
    );
  }
  renderBusiness(stripeAccount: StripeAccount): React.ReactNode {
    const company = stripeAccount.company;
    if (stripeAccount.business_type !== 'company' || !company) return;
    const dob = stripeAccount.individual?.dob;
    const address = company.address;
    return (
      <>
        <FormInlineTitle label="Company Information" />
        <FormKeyValuePair label="Business Name" value={stripeAccount.business_profile?.name || ''} />
        <FormKeyValuePair label="Company Name" value={company.name ?? ''} />
        {address && (
          <FormKeyValuePair
            label="Address"
            value={`${address.line1}, ${address.city}, ${address.state} - ${address.postal_code} `}
          />
        )}
        <FormKeyValuePair label="Email" value={company.email ?? ''} />
        <FormKeyValuePair label="Phone" value={company.phone ?? ''} />
        <FormKeyValuePair label="Directors Provided" value={company.directors_provided ? 'Yes' : 'No'} />
        <FormKeyValuePair label="Executives Provided" value={company.executives_provided ? 'Yes' : 'No'} />
        <FormKeyValuePair label="Owners Provided" value={company.owners_provided ? 'Yes' : 'No'} />
      </>
    );
  }

  async onUpdateStripeAccount() {
    // get the update link
    const updateLink = await remoteService.createStripeAccountUpdateLink(this.props.match.params);
    window.open(updateLink.url, '_self');
  }

  async onVerifyStripeAccount() {
    const verificationLink = await remoteService.createStripeAccountVerificationLink(this.props.match.params);
    window.open(verificationLink.url, '_self');
  }

  getAdditionalClassNames() {
    return `payments-page`;
  }
}
