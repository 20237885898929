import { Schema } from 'nutso';
import { OrderProcessSettings } from '../models/order/OrderProcessSettings';

export const orderProcessSettingsSchema: Schema<OrderProcessSettings> = {
  type: 'object',
  properties: {
    hasEta: {
      type: 'boolean'
    },
    hasCompleteStatus: {
      type: 'boolean'
    },
    hasUnprocessedOrderMonitor: {
      type: 'boolean'
    }
  }
};
