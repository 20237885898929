import { observable } from 'mobx';
import { Path } from 'path-parser';
import { parse } from 'query-string';
import { browserHistory } from '../utils/browserHistory';
import { QueryParams } from '@restoplus/core';

/**
 * Exposes the current location an observable
 */
class LocationProvider {
  //
  @observable path = '';
  @observable queryParams?: QueryParams;

  isSelected = (pathTemplate: string) => {
    const path = new Path(pathTemplate);
    if (path.test(this.path)) return true;
    return false;
  };
}

export const locationProvider = new LocationProvider();

browserHistory.listen(location => {
  locationProvider.path = location.pathname;
  if (location.search) locationProvider.queryParams = parse(location.search);
});

// init
locationProvider.path = browserHistory.location.pathname;
locationProvider.queryParams = browserHistory.location.search ? parse(browserHistory.location.search) : {};
