import {
  ActiveStripeAccount,
  ArchivedStripeAccount,
  CustomerCart,
  ItemSpec,
  PizzaSettings,
  TableBookingTransactionFee,
  Printer
} from '..';
import { Comment } from '../models/base/Comment';
import { DeliveryAddress } from '../models/base/DeliveryAddress';
import { Category } from '../models/category/Category';
import { Customer } from '../models/customer/Customer';
import { CustomerMessage } from '../models/customer_message/CustomerMessage';
import { DeploymentInfo } from '../models/deployment/DeploymentInfo';
import { EmailAlertSettings } from '../models/email/EmailAlertSettings';
import { ActiveLoyaltyCard } from '../models/loyalty/ActiveLoyaltyCard';
import { LoyaltyCard } from '../models/loyalty/LoyaltyCard';
import { LoyaltyProgram } from '../models/loyalty/LoyaltyProgram';
import { LoyaltyReward } from '../models/loyalty/LoyaltyReward';
import { RedeemedLoyaltyCard } from '../models/loyalty/RedeemedLoyaltyCard';
import { Menu } from '../models/menu/Menu';
import { AppstoreInfo } from '../models/mobile_app/AppstoreInfo';
import { MobileApp } from '../models/mobile_app/MobileApp';
import { MobileDevice } from '../models/mobile_app/MobileDevice';
import { PlaystoreInfo } from '../models/mobile_app/PlaystoreInfo';
import { CheckoutSettings } from '../models/order/CheckoutSettings';
import { CollectionName } from '../models/order/CollectionName';
import { CustomCollectionSchedule } from '../models/order/CustomCollectionSchedule';
import { DeliverySettings } from '../models/order/DeliverySettings';
import { Order } from '../models/order/Order';
import { OrderProcessSettings } from '../models/order/OrderProcessSettings';
import { OrderTransactionFee } from '../models/order/OrderTransactionFee';
import { Promotion } from '../models/promotion/Promotion';
import { RedeemTracker } from '../models/promotion/RedeemTracker';
import { Restaurant } from '../models/restaurant/Restaurant';
import { RestaurantUser } from '../models/restaurant/RestaurantUser';
import { Store } from '../models/store/Store';
import { StoreTimings } from '../models/store/StoreTimings';
import { StoreUser } from '../models/store/StoreUser';
import { TableBooking } from '../models/table_booking/TableBooking';
import { TableBookingSettings } from '../models/table_booking/TableBookingSettings';
import { User } from '../models/user/User';
import { RestaurantWebsiteInfo } from '../models/website/RestaurantWebsiteInfo';
import { StoreWebsiteInfo } from '../models/website/StoreWebsiteInfo';
import { FirestorePath } from './FirestorePath';
import { StorePrinter } from '../models/store/StorePrinter';
import { Verification } from '../models/phone_auth/Verification';
import {
  Uid,
  RestaurantUserId,
  StoreUserId,
  RestaurantId,
  StoreId,
  PrinterId,
  VerificationId,
  CustomerId,
  CustomerCartId,
  CustomerMobileDeviceId,
  CustomerMessageId,
  DeliveryAddressId,
  CustomerCollectionNameId,
  CommentId,
  CategoryId,
  ItemId,
  OrderId,
  TableBookingId,
  RestaurantPromotionId,
  StorePromotionId,
  RedeemTrackerId,
  ArchivedStripeAccountId,
  LoyaltyCardId,
  LoyaltyRewardId,
  RedeemedLoyaltyCardId,
  BundleId
} from '../models/base/Id';

/**
 * user level
 */
const users = new FirestorePath<void, void>('users');
const user = new FirestorePath<Uid, User>(`${users.template}/{uid}`);

// user_restaurants and restaurant_users are bi-directional relationship, but we are storing only once, coz we can use collection group queries
const userRestaurants = new FirestorePath<Uid, RestaurantUser>(`${user.template}/restaurants`);
const userRestaurant = new FirestorePath<RestaurantUserId, RestaurantUser>(
  `${userRestaurants.template}/{restaurantId}`
);

const restaurantUsers = userRestaurants;
const restaurantUser = userRestaurant;

const userStores = new FirestorePath<Uid, StoreUser>(`${user.template}/stores`);
const userStore = new FirestorePath<StoreUserId, StoreUser>(`${userStores.template}/{storeId}`);

const storeUsers = userStores;
const storeUser = userStore;

const verifications = new FirestorePath<void, Verification>(`${user.template}/verifications`);
const verification = new FirestorePath<VerificationId, Verification>(`${verifications.template}/{verificationId}`);

// restaurants

const restaurants = new FirestorePath<void, Restaurant>('restaurants');
const restaurant = new FirestorePath<RestaurantId, Restaurant>(`${restaurants.template}/{restaurantId}`);

const stores = new FirestorePath<RestaurantId, Store>(`${restaurant.template}/stores`);
const store = new FirestorePath<StoreId, Store>(`${stores.template}/{storeId}`);

const printers = new FirestorePath<void, Printer>('printers');
const printer = new FirestorePath<PrinterId, Printer>(`${printers.template}/{printerId}`);

const storePrinter = new FirestorePath<StoreId, StorePrinter>(`${store.template}/store_printer/default`);

// WARNING: Do not create the concept of store customer, based on reality, there is no such thing as McDonalds - Northgate customer, it's just McDonald's Customer,
// so customer is always at the RestaurantLevel and not at the store level
const customers = new FirestorePath<RestaurantId, Customer>(`${restaurant.template}/customers`);
const customer = new FirestorePath<CustomerId, Customer>(`${customers.template}/{uid}`);

// NOTE: usually customer is at restaurant level, but CustomerCart is at store level.
// one cart per customer per store
const customerCarts = new FirestorePath<StoreId, CustomerCart>(`${store.template}/carts`);
const customerCart = new FirestorePath<CustomerCartId, CustomerCart>(`${customerCarts.template}/{uid}`);

const orderTransactionFee = new FirestorePath<StoreId, OrderTransactionFee>(`${store.template}/transaction_fee/order`);
const tableBookingTransactionFee = new FirestorePath<StoreId, TableBookingTransactionFee>(
  `${store.template}/transaction_fee/table_booking`
);

const customerMobileDevices = new FirestorePath<CustomerId, MobileDevice>(
  `${customer.template}/customer_mobile_devices`
);
const customerMobileDevice = new FirestorePath<CustomerMobileDeviceId, MobileDevice>(
  `${customerMobileDevices.template}/{deviceId}`
);

const customerMessages = new FirestorePath<CustomerId, CustomerMessage>(`${customer.template}/customer_messages`);
const customerMessage = new FirestorePath<CustomerMessageId, CustomerMessage>(
  `${customerMessages.template}/{messageId}`
);

const deliveryAddresses = new FirestorePath<CustomerId, DeliveryAddress>(`${customer.template}/delivery_addresses`);
const deliveryAddress = new FirestorePath<DeliveryAddressId, DeliveryAddress>(
  `${deliveryAddresses.template}/{addressId}`
);

const customerCollectionNames = new FirestorePath<CustomerId, CollectionName>(`${customer.template}/collection_names`);
const customerCollectionName = new FirestorePath<CustomerCollectionNameId, CollectionName>(
  `${customerCollectionNames.template}/{nameId}`
);

const orderComments = new FirestorePath<CustomerId, Comment>(`${customer.template}/order_comments`);
const orderComment = new FirestorePath<CommentId, Comment>(`${orderComments.template}/{commentId}`);

const customerTableBookingComments = new FirestorePath<CustomerId, Comment>(
  `${customer.template}/table_booking_comments`
);
const customerTableBookingComment = new FirestorePath<CommentId, Comment>(
  `${customerTableBookingComments.template}/{commentId}`
);

const menu = new FirestorePath<StoreId, Menu>(`${store.template}/menu/default`);

const categories = new FirestorePath<StoreId, Category>(`${store.template}/categories`);
const category = new FirestorePath<CategoryId, Category>(`${categories.template}/{categoryId}`);

const items = new FirestorePath<StoreId, ItemSpec>(`${store.template}/items`);
const item = new FirestorePath<ItemId, ItemSpec>(`${items.template}/{itemId}`);

const orders = new FirestorePath<StoreId, Order>(`${store.template}/orders`);
const order = new FirestorePath<OrderId, Order>(`${orders.template}/{orderId}`);

const tableBookings = new FirestorePath<StoreId, TableBooking>(`${store.template}/table_bookings`);
const tableBooking = new FirestorePath<TableBookingId, TableBooking>(`${tableBookings.template}/{tableBookingId}`);

const restaurantPromotions = new FirestorePath<RestaurantId, Promotion>(`${restaurant.template}/restaurant_promotions`);
const restaurantPromotion = new FirestorePath<RestaurantPromotionId, Promotion>(
  `${restaurantPromotions.template}/{promotionId}`
);

const storePromotions = new FirestorePath<StoreId, Promotion>(`${store.template}/store_promotions`);
const storePromotion = new FirestorePath<StorePromotionId, Promotion>(`${storePromotions.template}/{promotionId}`);

const redeemTracker = new FirestorePath<RedeemTrackerId, RedeemTracker>(
  `${customer.template}/redeem_trackers/{promotionId}`
);

// store level settings
const storeSettingsHome = new FirestorePath<StoreId, void>(`${store.template}/settings`);
const checkoutSettings = new FirestorePath<StoreId, CheckoutSettings>(
  `${storeSettingsHome.template}/checkout_settings`
);
const orderProcessSettings = new FirestorePath<StoreId, OrderProcessSettings>(
  `${storeSettingsHome.template}/order_process_settings`
);
const pizzaSettings = new FirestorePath<StoreId, PizzaSettings>(`${storeSettingsHome.template}/pizza_settings`);
const deliverySettings = new FirestorePath<StoreId, DeliverySettings>(
  `${storeSettingsHome.template}/delivery_settings`
);
const tableBookingSettings = new FirestorePath<StoreId, TableBookingSettings>(
  `${storeSettingsHome.template}/table_booking_settings`
);
const emailAlertsSettings = new FirestorePath<StoreId, EmailAlertSettings>(
  `${storeSettingsHome.template}/email_alerts_settings`
);

const customCollectionSchedule = new FirestorePath<StoreId, CustomCollectionSchedule>(
  `${storeSettingsHome.template}/custom_collection_schedule`
);

const storeTimings = new FirestorePath<StoreId, StoreTimings>(`${storeSettingsHome.template}/store_timings`);
const activeStripeAccount = new FirestorePath<StoreId, ActiveStripeAccount>(
  `${store.template}/active_stripe_account/default`
);

const archivedStripeAccounts = new FirestorePath<StoreId, ArchivedStripeAccount>(
  `${store.template}/archived_stripe_accounts`
);
const archivedStripeAccount = new FirestorePath<ArchivedStripeAccountId, ArchivedStripeAccount>(
  `${archivedStripeAccounts.template}/{stripeAccountId}`
);

// loyalty
const loyaltyProgram = new FirestorePath<RestaurantId, LoyaltyProgram>(
  `${restaurant.template}/loyalty_program/default`
);

const loyaltyCards = new FirestorePath<CustomerId, LoyaltyCard>(`${customer.template}/loyalty_cards`);
const loyaltyCard = new FirestorePath<LoyaltyCardId, LoyaltyCard>(`${loyaltyCards.template}/{loyaltyCardId}`);

const loyaltyRewards = new FirestorePath<LoyaltyCardId, LoyaltyReward>(`${loyaltyCard.template}/rewards`);
const loyaltyReward = new FirestorePath<LoyaltyRewardId, LoyaltyReward>(`${loyaltyRewards.template}/{orderId}`);

const activeLoyaltyCard = new FirestorePath<CustomerId, ActiveLoyaltyCard>(
  `${customer.template}/active_loyalty_card/default`
);
const redeemedLoyaltyCards = new FirestorePath<RestaurantId, RedeemedLoyaltyCard>(
  `${restaurant.template}/redeemed_loyalty_cards`
);
const redeemedLoyaltyCard = new FirestorePath<RedeemedLoyaltyCardId, RedeemedLoyaltyCard>(
  `${redeemedLoyaltyCards.template}/{loyaltyCardId}`
);

/**
 * Mobile Apps
 */
const mobileApps = new FirestorePath<void, MobileApp>('mobile_apps');
const mobileApp = new FirestorePath<BundleId, MobileApp>(`${mobileApps.template}/{bundleId}`);
const appstore = new FirestorePath<BundleId, void>(`${mobileApp.template}/appstore`);
const playstore = new FirestorePath<BundleId, void>(`${mobileApp.template}/playstore`);

const appstoreInfo = new FirestorePath<BundleId, AppstoreInfo>(`${appstore.template}/info`);
const playstoreInfo = new FirestorePath<BundleId, PlaystoreInfo>(`${playstore.template}/info`);

const appstoreDeployment = new FirestorePath<BundleId, DeploymentInfo>(`${appstore.template}/deployment`);
const playstoreDeployment = new FirestorePath<BundleId, DeploymentInfo>(`${playstore.template}/deployment`);

/**
 * Website
 */
const restaurantWebsiteInfo = new FirestorePath<RestaurantId, RestaurantWebsiteInfo>(
  `${restaurant.template}/website/info`
);
const storeWebsiteInfo = new FirestorePath<StoreId, StoreWebsiteInfo>(`${store.template}/website/info`);

/**
 * It should have been called firestorePaths, but it is being used in a lot of places
 * so just to make it concise, it's named as fspaths
 */
export const fspaths = {
  restaurants,
  restaurant,
  stores,
  store,
  printers,
  printer,
  users,
  user,
  customers,
  customer,
  userRestaurants,
  userRestaurant,
  restaurantUsers,
  restaurantUser,
  userStores,
  userStore,
  storeUsers,
  storeUser,
  customerMobileDevices,
  customerMobileDevice,
  customerMessages,
  customerMessage,
  customerAddresses: deliveryAddresses,
  customerAddress: deliveryAddress,
  customerCollectionNames,
  customerCollectionName,
  orderComments,
  orderComment,
  customerTableBookingComments,
  customerTableBookingComment,
  items,
  item,
  menu,
  categories,
  category,
  customerCarts,
  customerCart,
  orders,
  order,
  orderTransactionFee,
  restaurantPromotions,
  restaurantPromotion,
  storePromotions,
  storePromotion,
  redeemTracker,
  deliverySettings,
  storeTimings,
  storeSettingsHome,
  pizzaSettings,
  checkoutSettings,
  emailAlertsSettings,
  customCollectionSchedule,
  orderProcessSettings,
  tableBookingSettings,
  tableBookings,
  tableBooking,
  tableBookingTransactionFee,
  loyaltyProgram,
  loyaltyCards,
  loyaltyCard,
  loyaltyRewards,
  loyaltyReward,
  activeLoyaltyCard,
  redeemedLoyaltyCards,
  redeemedLoyaltyCard,
  mobileApps,
  mobileApp,
  appstoreInfo,
  appstoreDeployment,
  playstoreInfo,
  playstoreDeployment,
  restaurantWebsiteInfo,
  storeWebsiteInfo,
  activeStripeAccount,
  archivedStripeAccount,
  archivedStripeAccounts,
  storePrinter,
  verifications,
  verification
};
